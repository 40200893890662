import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Explore = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.00006C3.584 1.00006 0 4.58406 0 9.00006C0 13.4161 3.584 17.0001 8 17.0001C12.416 17.0001 16 13.4161 16 9.00006C16 4.58406 12.416 1.00006 8 1.00006ZM8 15.4001C4.472 15.4001 1.6 12.5281 1.6 9.00006C1.6 5.47206 4.472 2.60006 8 2.60006C11.528 2.60006 14.4 5.47206 14.4 9.00006C14.4 12.5281 11.528 15.4001 8 15.4001ZM3.6 13.4001L9.608 10.6081L12.4 4.60006L6.392 7.39206L3.6 13.4001ZM8 8.12006C8.488 8.12006 8.88 8.51206 8.88 9.00006C8.88 9.48806 8.488 9.88006 8 9.88006C7.512 9.88006 7.12 9.48806 7.12 9.00006C7.12 8.51206 7.512 8.12006 8 8.12006Z"
      fill="#3B87F7"
    />
  </svg>
);

export const ExploreIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Explore} {...props} />
);
