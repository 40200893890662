import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import {
  wbtc,
  ethicon,
  about,
  filtericon,
  chart,
} from "../../../Constants/ImagesConstants";
import "./PoolsAccordian.scss";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import TooltipCustom from "../TooltipCustom/TooltipCustom";

const PoolsAccordian = () => {
  const PoolsItem = [
    {
      id: 1,
      poolname: "A0X215",
      liquidity: "$5,78,800",
      volume: "5,080",
      apy: "0.38",
    },
    {
      id: 2,
      poolname: "A0X216",
      liquidity: "$7,78,800",
      volume: "15,080",
      apy: "0.45",
    },
    {
      id: 3,
      poolname: "A0X217",
      liquidity: "$78,800",
      volume: "24,450",
      apy: "0.67",
    },
    {
      id: 4,
      poolname: "A0X217",
      liquidity: "$78,800",
      volume: "24,450",
      apy: "0.67",
    },
  ];
  return (
    <Accordion defaultActiveKey="1" className="pools-accordian">
      {PoolsItem.map((data: any) => (
        <Accordion.Item eventKey={data.id}>
          <Accordion.Header>
            <PoolsHeader
              poolname={data.poolname}
              liquidity={data.liquidity}
              volume={data.volume}
              apy={data.apy}
            />
          </Accordion.Header>
          <Accordion.Body>
            <div className="pools-details">
              <Row className="align-items-md-end">
                <Col lg={4} md={12} xs={12} className="order-lg-1 mb-3 mb-lg-0">
                  <div className="pools-details__right-card">
                    <ul className="details-list">
                      <li>
                        <span>Liquidity </span>
                        <span>$578,800.21</span>
                      </li>
                      <li>
                        <span>Volume (24h)</span>
                        <span>$0.00</span>
                      </li>
                      <li>
                        <span>Earnings (24h)</span>
                        <span>$0.00</span>
                      </li>
                      <li className="total-list text-blue">
                        <span>Total APY</span>
                        <span>0.38%</span>
                      </li>
                      <li className="text-blue mb-5">
                        <span>Pool APY</span>
                        <span>0.38%</span>
                      </li>
                    </ul>
                    <ConnectWallet className="mt-4 w-100" />
                  </div>
                </Col>
                <Col lg={8} md={12} xs={12} className="order-lg-0">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Volume"
                  >
                    <Nav
                      variant="pills"
                      className="Bottomline_tabs pools-details__tabs"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="Volume">Volume</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Liquidity">Liquidity</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Earning">Earning</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="Trade_Tabs_content">
                      <Tab.Pane eventKey="Volume">
                        <div className="pools-details__charts">
                          <img src={chart} alt="chart" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Liquidity">
                        <div className="pools-details__charts">
                          <img src={chart} alt="chart" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Earning">
                        <div className="pools-details__charts">
                          <img src={chart} alt="chart" />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const PoolsHeader = ({ poolname, liquidity, volume, apy, onClick }: any) => {
  return (
    <div className="commn-grid">
      <div className="pool-wrap pool-name mb-3 mb-lg-0">
        <div className="pool-wrap__icon pool-wrap__icon--name">A</div>
        <a href="/#">{poolname}</a>
      </div>
      <div className="d-flex align-items-center pools-coin pool-info mb-3 mb-lg-0">
        <div className="pool-wrap">
          <div className="pool-wrap__icon">
            <img src={ethicon} alt="icon" />
          </div>
          <div className="pools-coin__name">
            <p>50%</p>
            <a href="/#">ETH</a>
          </div>
        </div>
        <div className="pool-wrap">
          <div className="pool-wrap__icon">
            <img src={wbtc} alt="icon" />
          </div>
          <div className="pools-coin__name">
            <p>50%</p>
            <a href="/#">WBTC</a>
          </div>
        </div>
      </div>
      <div className="pool-liquidity">
        <span className="d-inline-block d-lg-none text-capitalize">
          Liquidity
        </span>
        <span>{liquidity}</span>
      </div>
      <div className="pool-volume">
        <span className="d-inline-block d-lg-none text-capitalize">Volume</span>
        <span>{volume}</span>
      </div>
      <div className="pool-apy d-flex align-items-center">
        <span className="d-inline-block d-lg-none text-capitalize">Apy</span>
        <span>
          {apy}
          <TooltipCustom text="These names are variables that can be named anything you would like." />
        </span>
      </div>
      <div className="pool-govr mb-3 mb-lg-0">
        <button className="pools-governance" onClick={onClick}>
          <img src={filtericon} alt="icon" />
          <span className="d-none d-sm-inline-block">Pool Governanace</span>
        </button>
      </div>
    </div>
  );
};

export default PoolsAccordian;
