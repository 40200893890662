import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const EmergencyExit = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2304_10674)">
      <path
        d="M14.7627 9.73341L15.6314 3.86541C14.1541 2.51541 12.1867 1.69141 10.0274 1.69141C5.43875 1.69141 1.71875 5.41141 1.71875 10.0001C1.71875 14.5887 5.43875 18.3087 10.0274 18.3087C12.0914 18.3087 13.9801 17.5561 15.4327 16.3101L16.7854 12.0014L14.7627 9.73341ZM10.2314 4.82407C10.9147 4.82407 11.4687 5.40274 11.4687 6.11741C11.4687 6.83207 10.9147 7.41074 10.2314 7.41074C9.54808 7.41074 8.99408 6.83207 8.99408 6.11741C8.99408 5.40274 9.54808 4.82407 10.2314 4.82407ZM12.4107 9.31741V10.3121L11.0447 11.1514L10.2181 10.6507L9.39075 11.1514L8.02475 10.3121V9.31741L10.2181 10.6507L12.4114 9.31741H12.4107ZM6.02742 7.58941L8.78875 5.92341C8.78008 5.98674 8.77608 6.05207 8.77608 6.11741C8.77608 6.66341 9.05142 7.14274 9.46475 7.41141L7.59875 8.53741V10.1421L6.02742 9.19407V7.58941ZM14.4074 12.6467L10.2174 15.1754L6.02742 12.6467V9.96341L7.59875 10.9121V11.6981L10.2174 13.2787L12.8361 11.6981V10.9401L14.4074 9.99141V12.6467ZM14.4074 9.22207L12.8361 10.1701V8.53741L10.9841 7.41941C11.4054 7.15274 11.6867 6.66874 11.6867 6.11741C11.6867 6.05741 11.6834 5.99874 11.6761 5.94074L14.4074 7.58941V9.22207Z"
        fill="#9B9CA3"
      />
      <path
        d="M18.1221 11.8833L15.9121 9.0133L16.9014 5.3313C17.8074 6.66197 18.3361 8.26863 18.3361 9.99997C18.3361 10.648 18.2621 11.2786 18.1214 11.8833H18.1221Z"
        fill="#9B9CA3"
      />
      <path
        d="M14.7633 9.73333L15.632 3.86533C14.1547 2.51533 12.1873 1.69133 10.028 1.69133C5.43933 1.69133 1.71933 5.41133 1.71933 10C1.71933 14.5887 5.43933 18.3087 10.028 18.3087C12.092 18.3087 13.9807 17.556 15.4333 16.31L16.786 12.0013L14.7633 9.73333ZM10.232 4.824C10.9153 4.824 11.4693 5.40267 11.4693 6.11733C11.4693 6.832 10.9153 7.41067 10.232 7.41067C9.54867 7.41067 8.99467 6.832 8.99467 6.11733C8.99467 5.40267 9.54867 4.824 10.232 4.824ZM12.4113 9.31733V10.312L11.0453 11.1513L10.2187 10.6507L9.39133 11.1513L8.02533 10.312V9.31733L10.2187 10.6507L12.412 9.31733H12.4113ZM6.028 7.58933L8.78933 5.92333C8.78067 5.98667 8.77667 6.052 8.77667 6.11733C8.77667 6.66333 9.052 7.14267 9.46533 7.41133L7.59933 8.53733V10.142L6.028 9.194V7.58933ZM14.408 12.6467L10.218 15.1753L6.028 12.6467V9.96333L7.59933 10.912V11.698L10.218 13.2787L12.8367 11.698V10.94L14.408 9.99133V12.6467ZM14.408 9.222L12.8367 10.17V8.53733L10.9847 7.41933C11.406 7.15267 11.6873 6.66867 11.6873 6.11733C11.6873 6.05733 11.684 5.99867 11.6767 5.94067L14.408 7.58933V9.222ZM0.556 10C0.556667 4.78467 4.78467 0.556667 10 0.556667C12.1973 0.556667 14.22 1.30733 15.8247 2.566L15.9173 1.938C14.26 0.719333 12.214 0 10 0C9.936 0 9.87267 0 9.80933 0.002C4.37467 0.103333 0 4.54067 0 10C0 15.4593 4.37467 19.8967 9.80933 19.998C9.87267 19.9993 9.93667 20 10 20C11.6713 20 13.2467 19.59 14.6313 18.8653L14.8747 18.0893C13.452 18.9487 11.7833 19.4433 10 19.4433C4.78467 19.4433 0.556667 15.2153 0.556667 10H0.556Z"
        fill="#9B9CA3"
      />
      <path
        d="M18.1221 11.8833L15.9121 9.0133L16.9014 5.3313C17.8074 6.66197 18.3361 8.26863 18.3361 9.99997C18.3361 10.648 18.2621 11.2786 18.1214 11.8833H18.1221Z"
        fill="#9B9CA3"
      />
      <path
        d="M20.0008 9.99996C20.0008 13.4333 18.2708 16.462 15.6348 18.2626L16.1148 17.1966C18.1514 15.4646 19.4441 12.8833 19.4441 9.99996C19.4441 7.70796 18.6274 5.60596 17.2688 3.97062L17.4434 3.32129C19.0334 5.09196 20.0008 7.43262 20.0008 9.99996Z"
        fill="#9B9CA3"
      />
    </g>
    <defs>
      <clipPath id="clip0_2304_10674">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EmergencyExitIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmergencyExit} {...props} />
);
