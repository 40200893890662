import React from "react";
import { Table } from "react-bootstrap";
import { table_icon } from "../../../../Constants/ImagesConstants";
import { graph_img } from "../../../../Constants/ImagesConstants";
import { Link } from "react-router-dom";

const StrateigiesTable = () => {
  const strategiesdata = [
    {
      id: "1",
      table_icon: table_icon,
      title: "Shido Turbo",
      value: "Next rebalancing",
      date: "on Oct 14, 22",
      graph_img: graph_img,
      totalvalue: "1,714,556.51",
      totalname: "Shido",
      stakevalue: "0,00",
      stakename: " Shido",
      strikevalue: "$ 0.75",
      strikename: "USD",
      poolvalue: "6.2%",
    },
    {
      id: "1",
      table_icon: table_icon,
      title: "Shido Turbo",
      value: "Next rebalancing",
      date: "on Oct 14, 22",
      graph_img: graph_img,
      totalvalue: "1,714,556.51",
      totalname: "Shido",
      stakevalue: "0,00",
      stakename: " Shido",
      strikevalue: "$ 0.75",
      strikename: "USD",
      poolvalue: "6.2%",
    },
    {
      id: "1",
      table_icon: table_icon,
      title: "Shido Turbo",
      value: "Next rebalancing",
      date: "on Oct 14, 22",
      graph_img: graph_img,
      totalvalue: "1,714,556.51",
      totalname: "Shido",
      stakevalue: "0,00",
      stakename: " Shido",
      strikevalue: "$ 0.75",
      strikename: "USD",
      poolvalue: "6.2%",
    },
    {
      id: "1",
      table_icon: table_icon,
      title: "Shido Turbo",
      value: "Next rebalancing",
      date: "on Oct 14, 22",
      graph_img: graph_img,
      totalvalue: "1,714,556.51",
      totalname: "Shido",
      stakevalue: "0,00",
      stakename: " Shido",
      strikevalue: "$ 0.75",
      strikename: "USD",
      poolvalue: "6.2%",
    },
  ];

  return (
    <div className="strateigies_table Commom_Table">
      <Table>
        <thead>
          <tr>
            <th>Pool</th>
            <th>Performance</th>
            <th>Total staked</th>
            <th>Your stake</th>
            <th>Strike price</th>
            <th>Pool APR</th>
          </tr>
        </thead>
        <tbody>
          {strategiesdata.map((item) => {
            return (
              <tr>
                <td>
                  <Link to="/shidoturbo">
                    <div className="table_data d-flex align-items-center">
                      <span>
                        <img src={item.table_icon} alt="img" />
                      </span>
                      <div className="table_contant">
                        <h5>{item.title}</h5>
                        <p>
                          {item.value}
                          <span>{item.date}</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  <img src={item.graph_img} alt="img" />
                </td>
                <td>
                  {item.totalvalue}
                  <span>{item.totalname}</span>
                </td>
                <td>
                  {item.stakevalue}
                  <span>{item.stakename}</span>
                </td>
                <td>
                  {item.strikevalue}
                  <span>{item.strikename}</span>
                </td>
                <td className="text-right">{item.poolvalue}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default StrateigiesTable;
