import { Card, Col, Row, Tooltip } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChainId } from "wagmi";
import { ExpiresInIcon } from "../../../../Assets/Svg/SvgImages";
import { colors } from "../../../../Assets/Theme/colors";
import { disabledLetters } from "../../../../Constants/inputConstants";
import { fetchTokenPrices } from "../../../../redux/api/swap/swapAPIs";
import {
  setLimitOrderAction,
  setLimitOrderAmount,
  setLimitOrderValue,
  setLimitPrice,
  setTokenOutAmount,
} from "../../../../redux/reducers/limitOrderData/limitOrderData";
import {
  bigNumberToFixed,
  uptoFourDecimal,
} from "../../../../services/helpers/swapHelpers";
import { getModifiedTokenSymbol } from "../../../../services/helpers/tokenListHelper";
import { roundToFiveDecimalPlaces } from "../../../../utils";
import { PrimaryButton } from "../../../Button";
import { Text } from "../../../Text";
import ReviewOrderModal from "../../CommonModals/ReviewOrderModal/ReviewOrderModal";
import CustomShimmer from "../../CustomShimmer/CustomShimmer";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import YouSellBuyCard from "../SwapCard/YouSellBuyCard";
import "./LimitCard.scss";

type LimitOrderTab = "Buy" | "Sell";

const LimitCard = ({ createLimitOrder }: any) => {
  const currentPath = window.location.pathname;
  const dispatch = useDispatch();
  const { sm } = useBreakpoint();

  const limitPriceLoading = useSelector(
    (state: any) => state.loadingData.limitPriceLoading,
  );
  const [token1DollarValue, setToken1DollarValue] = useState<number>(0);
  const [token2DollarValue, setToken2DollarValue] = useState<number>(0);
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const input1 = useSelector((state: any) => state.swapData.input1Value);
  const limitPrice = useSelector(
    (state: any) => state.limitOrderData.limitPrice,
  );
  const initialLimitPrice = useSelector(
    (state: any) => state.limitOrderData.initialLimitPrice,
  );
  const buyPrice = useSelector((state: any) => state.swapData.buyPrice);

  const [minDollarWorthSatisfied, setMinDollarWorthSatisfied] = useState(false);
  const [
    showLimitPriceBelowMarketWarning,
    setShowLimitPriceBelowMarketWarning,
  ] = useState(false);

  // Whether limit order tokens are toggled or not
  const [toggled, setToggled] = useState(false);

  const selectedtoken1 = useSelector((state: any) => state.swapData.token1);
  const selectedtoken2 = useSelector((state: any) => state.swapData.token2);

  // Below state and funciton is for custom date & time
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const showReviewModal = () => {
    setShow(true);
  };

  const limitTimePeriodoptions: any = [
    { value: "10M", label: "10 Minutes" },
    { value: "1H", label: "1 Hour" },
    { value: "1D", label: "1 Day" },
    { value: "3D", label: "3 Days" },
    { value: "7D", label: "7 Days" },
    { value: "30D", label: "30 Days" },
    { value: "1Month", label: "1 Month" },
    { value: "3Month", label: "3 Months" },
    { value: "6Month", label: "6 Months" },
    { value: "1Y", label: "1 Year" },
    // {
    //   value: "custom",
    //   label: (
    //     <span className="custom" onClick={handleShow}>
    //       Custom
    //     </span>
    //   ),
    // },
  ];

  const selectedTimePeriod = useSelector(
    (state: any) => state?.limitOrderData.orderExpireDate,
  );

  const limitOrderAmount = useSelector(
    (state: any) => state?.limitOrderData.amount,
  );

  const limitOrderValue = useSelector(
    (state: any) => state?.limitOrderData.value,
  );

  useEffect(() => {
    dispatch(setLimitOrderValue(limitPrice));
  }, []);
  const chain_id = useChainId();
  const getPrices = async () => {
    setPriceLoading(true);
    try {
      const prices = await fetchTokenPrices(
        chain_id,
        selectedtoken1.address,
        selectedtoken2.address,
      );

      if (prices.fromPrice !== 0 && prices.toPrice !== 0) {
        setToken1DollarValue(prices.fromPrice);
        setToken2DollarValue(prices.toPrice);
        setPriceLoading(false);
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (show && selectedtoken1 && selectedtoken2) {
      getPrices();
    }
  }, [selectedtoken1, selectedtoken2, show]);

  useEffect(() => {
    const token1DollarValueToFixed = Number(token1DollarValue).toFixed(2);
    if (Number(token1DollarValueToFixed) >= 0.02) {
      setMinDollarWorthSatisfied(true);
    } else {
      setMinDollarWorthSatisfied(false);
    }
  }, [token1DollarValue]);

  useEffect(() => {
    if (toggled) {
      // dispatch(setTokenOutAmount(Number(input1 * 1 / limitPrice)?.toFixed(4)));
      const calculatedValue: any = bigNumberToFixed(
        Number((input1 * 1) / limitPrice),
      );
      const fourDecimalPlaceValue = uptoFourDecimal(calculatedValue);
      dispatch(setTokenOutAmount(fourDecimalPlaceValue));
    } else {
      // dispatch(setTokenOutAmount(Number(input1 * limitPrice)?.toFixed(4)));
      const calculatedValue: any = bigNumberToFixed(
        Number(input1 * limitPrice),
      );
      const fourDecimalPlaceValue = uptoFourDecimal(calculatedValue);
      dispatch(setTokenOutAmount(fourDecimalPlaceValue));
    }
  }, [input1]);

  useEffect(() => {
    // dispatch(setLimitPrice(selectedtoken1.usd || 1));
    handleLimitPriceChange(selectedtoken1?.usd || 1);
  }, [selectedtoken1]);

  const limitOrderTabs: LimitOrderTab[] = ["Buy", "Sell"];
  const [limitOrderTab, setLimitOrderTab] = useState<LimitOrderTab>("Buy");

  function formatNumber(amount: any) {
    const amountString = String(amount);

    const decimalPlaces = amountString.split(".")[1];
    if (decimalPlaces && decimalPlaces.length > 5) {
      return Number(amount).toFixed(5);
    } else {
      return Number(amount);
    }
  }

  const handleLimitPriceChange = (inputValue: any) => {
    const value = String(inputValue);

    let values: any;

    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;

    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (value?.length <= maxLength) {
      values = value;
    } else if (value?.length > maxLength) {
      return;
    }

    if (
      values?.toString().charAt(0) == "." ||
      values?.toString().charAt(0) == ","
    ) {
      values = "0" + values;
    }

    const newLimitPrice = values.replace(",", ".");
    dispatch(setLimitPrice(newLimitPrice));
    updateValues(newLimitPrice, limitOrderAmount);
  };

  const handleAmountChange = (inputValue: any) => {
    const value = String(inputValue);

    let values: any;

    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;

    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (value?.length <= maxLength) {
      values = value;
    } else if (value?.length > maxLength) {
      return;
    }

    if (
      values?.toString().charAt(0) == "." ||
      values?.toString().charAt(0) == ","
    ) {
      values = "0" + values;
    }

    const newAmount = values.replace(",", ".");
    dispatch(setLimitOrderAmount(newAmount));
    updateValues(limitPrice, newAmount);
  };

  const handleValueChange = (inputValue: any) => {
    const value = String(inputValue);

    let values: any;

    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;

    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (value?.length <= maxLength) {
      values = value;
    } else if (value?.length > maxLength) {
      return;
    }

    if (
      values?.toString().charAt(0) == "." ||
      values?.toString().charAt(0) == ","
    ) {
      values = "0" + values;
    }

    const newValue = values.replace(",", ".");
    dispatch(setLimitOrderValue(newValue));
    updateValuesInverse(limitPrice, newValue);
  };

  const updateValues = (lp: any, limitOrderamount: any) => {
    const lpNum = parseFloat(lp);
    const limitOrderamountNum = parseFloat(limitOrderamount);

    if (!isNaN(lpNum) && !isNaN(limitOrderamountNum)) {
      const newValue = (lpNum * limitOrderamountNum).toString();

      dispatch(setLimitOrderValue(newValue));
    }
  };

  const updateValuesInverse = (lp: any, usdt: any) => {
    const lpNum = parseFloat(lp);
    const usdtNum = parseFloat(usdt);

    if (!isNaN(lpNum) && !isNaN(usdtNum)) {
      const newAmount = (usdtNum / lp).toString();
      dispatch(setLimitOrderAmount(newAmount));
    }
  };

  const amountCard = (
    <Card
      className={"card-no-padding"}
      style={{
        backgroundColor: colors.secondary600,
        borderRadius: 8,
        padding: sm ? "16px 20px" : 14,
        height: "100%",
      }}
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col>
              <Text fontSize={12} color={colors.secondary500}>
                Amount
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-between"} gutter={16}>
            <Col className={"limit-input"} span={10}>
              {limitPriceLoading ? (
                <CustomShimmer width={50} height={15} />
              ) : (
                <input
                  onKeyDown={(evt) => {
                    evt.code === "ArrowDown" && evt.preventDefault();
                    evt.code === "ArrowUp" && evt.preventDefault();
                    disabledLetters.includes(evt.key) && evt.preventDefault();
                  }}
                  type="text"
                  inputMode="decimal"
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e) => handleAmountChange(e.target.value)}
                  value={roundToFiveDecimalPlaces(limitOrderAmount)}
                  className="form-control"
                  placeholder="0.00"
                  style={{
                    border: "none",
                    padding: 0,
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                />
              )}
            </Col>
            <Col span={14} style={{ textAlign: "right" }}>
              <Text fontSize={18} fontWeight={600}>
                {getModifiedTokenSymbol(
                  selectedtoken1?.symbol,
                  selectedtoken1?.isNative,
                  currentPath,
                )}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );

  const valueCard = (
    <Card
      className={"card-no-padding"}
      style={{
        backgroundColor: colors.secondary600,
        borderRadius: 8,
        padding: sm ? "16px 20px" : 14,
        height: "100%",
      }}
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col>
              <Text fontSize={12} color={colors.secondary500}>
                Value
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-between"} gutter={16}>
            <Col className={"limit-input"} span={10}>
              {limitPriceLoading ? (
                <CustomShimmer width={50} height={15} />
              ) : (
                <input
                  onKeyDown={(evt) => {
                    evt.code === "ArrowDown" && evt.preventDefault();
                    evt.code === "ArrowUp" && evt.preventDefault();
                    disabledLetters.includes(evt.key) && evt.preventDefault();
                  }}
                  type="text"
                  inputMode="decimal"
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e) => handleValueChange(e.target.value)}
                  value={roundToFiveDecimalPlaces(limitOrderValue)}
                  className="form-control"
                  placeholder="0.00"
                  style={{
                    border: "none",
                    padding: 0,
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                />
              )}
            </Col>
            <Col span={14} style={{ textAlign: "right" }}>
              <Text fontSize={18} fontWeight={600}>
                {getModifiedTokenSymbol(
                  selectedtoken2?.symbol,
                  selectedtoken2?.isNative,
                  currentPath,
                )}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );

  return (
    <>
      <Row style={{ margin: "-2em 0 3em 0" }}>
        <Card
          className={"card-no-padding"}
          style={{
            width: "100%",
            backgroundColor: colors.secondary600,
            borderRadius: 8,
          }}
        >
          <Row>
            {limitOrderTabs.map((tab) => (
              <Col span={12} key={tab}>
                <PrimaryButton
                  fullWidth
                  onClick={() => {
                    setLimitOrderTab(tab);
                    dispatch(setLimitOrderAction(tab));
                  }}
                  style={{
                    backgroundColor:
                      limitOrderTab === tab
                        ? tab === "Buy"
                          ? colors.success
                          : colors.error
                        : "transparent",
                    height: 40,
                  }}
                >
                  {tab}
                </PrimaryButton>
              </Col>
            ))}
          </Row>
        </Card>
        {/*<Segmented block options={['BUY', 'SELL']} width={'100%'} />*/}
      </Row>
      <YouSellBuyCard
        showReviewModal={showReviewModal}
        isCoversionHide
        showHalfPriceButton
        minDollarWorthSatisfied={minDollarWorthSatisfied}
        showLimitPriceBelowMarketWarning={showLimitPriceBelowMarketWarning}
        setShowLimitPriceBelowMarketWarning={
          setShowLimitPriceBelowMarketWarning
        }
        handleLimitPriceChange={handleLimitPriceChange}
      >
        <Row gutter={[22, 22]} style={{ marginBottom: "2em" }}>
          <Col span={12}>{amountCard}</Col>
          <Col span={12}>{valueCard}</Col>
          <Col span={24}>
            <Card
              className={"card-no-padding"}
              style={{
                backgroundColor: colors.secondary600,
                borderRadius: 8,
                padding: sm ? "16px 20px" : 14,
              }}
            >
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col>
                      <Text fontSize={12} color={colors.secondary500}>
                        Expires in
                      </Text>
                    </Col>
                    <Tooltip
                      trigger={"click"}
                      title={
                        "Please note: When a limit order expires, no cancellation fee is charged. In case of manually cancelling a limit order, a gas fee is charged at the time of signing the transaction."
                      }
                      placement={"topRight"}
                      overlayStyle={{ width: 400 }}
                    >
                      <Col>
                        <ExpiresInIcon />
                      </Col>
                    </Tooltip>
                  </Row>
                </Col>
                <Col span={24}>
                  <DropdownCustom
                    placeHolder={selectedTimePeriod}
                    options={limitTimePeriodoptions}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </YouSellBuyCard>
      <ReviewOrderModal
        token1DollarValue={token1DollarValue}
        token2DollarValue={token2DollarValue}
        priceLoading={priceLoading}
        show={show}
        handleClose={handleClose}
        createLimitOrder={createLimitOrder}
        selectedTimePeriod={selectedTimePeriod}
      />
    </>
  );
};

export default LimitCard;
