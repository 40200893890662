import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../../Assets/Theme/colors";
import { PrimaryButton } from "../../../Button";
import { HeaderText, Text } from "../../../Text";
import "./trade.scss";

interface TradeProps {
  text: string;
  img: string;
  alt: string;
  paragraph: string;
  navigateTo?: string;
}

export const Trade = (props: TradeProps) => {
  const navigate = useNavigate();
  const { secondary500 } = colors;
  const { text, img, alt, paragraph, navigateTo } = props;

  const onButtonClick = () => {
    if (navigateTo) {
      if (navigateTo == "https://perp.shido.io/") {
        window.open("https://perp.shido.io/", "_blank");
      } else {
        navigate(navigateTo);
      }
    }
  };
  return (
    <Row className="trade-comp">
      <div className="img">
        <img src={img} alt={alt} />
      </div>
      <div className="heading">
        <HeaderText
          fontSize={24}
          fontWeight={700}
          style={{ lineHeight: "normal" }}
        >
          {text}
        </HeaderText>
      </div>
      <div className="text">
        <Text
          color={secondary500}
          fontSize={14}
          fontWeight={400}
          style={{ lineHeight: "normal" }}
        >
          {paragraph}
        </Text>
      </div>
      <div className="btn-col">
        {["Cross Chain", "Limit Orders"].includes(text)
          ? <PrimaryButton disabled onClick={onButtonClick}>Coming soon</PrimaryButton>
          : navigateTo && (
              <PrimaryButton onClick={onButtonClick}>Trade Now</PrimaryButton>
            )}
      </div>
    </Row>
  );
};
