import React, { useState, useEffect } from "react";
import "./InternetConnectionStatus.scss";

const InternetConnectionStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>
      {!isOnline && (
        <div className="internet-overlay">
          <p>You are currently offline</p>
        </div>
      )}
      {/* <div className={`internet-status ${isOnline ? "online" : "offline"}`}>
        {isOnline ? "Online" : "Offline"}
      </div> */}
    </div>
  );
};

export default InternetConnectionStatus;
