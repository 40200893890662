import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const SuccessMessage = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="32" height="32" rx="16" fill="#39CA7F" />
    <g clip-path="url(#clip0_778_17629)">
      <path
        d="M21.6244 18.2265V19.6177L16 22.5187L10.3756 19.6177V18.1745L7 16.4339V21.3583L16 25.9999L25 21.3583V16.4848L21.6244 18.2265ZM19.1335 9.04932C19.1486 9.15538 19.1556 9.26352 19.1556 9.37374C19.1556 10.3865 18.5515 11.2745 17.6471 11.7642L21.6244 13.8157V16.8124L25 15.0718V12.0751L19.1335 9.04932ZM12.9038 9.37374C12.9038 9.25312 12.9119 9.13355 12.9305 9.01709L7 12.0751V15.0208L10.3756 16.7614V13.8157L14.3832 11.7486C13.4951 11.2557 12.9038 10.3761 12.9038 9.37374Z"
        fill="white"
      />
      <path
        d="M11.2896 15.2476V17.0734L14.224 18.6133L16.0002 17.6952L11.2896 15.2476Z"
        fill="white"
      />
      <path
        d="M20.7107 15.2476V17.0734L17.7763 18.6133L16 17.6952L20.7107 15.2476Z"
        fill="white"
      />
      <path
        d="M16.029 11.7477C17.4966 11.7477 18.6864 10.6849 18.6864 9.37383C18.6864 8.0628 17.4966 7 16.029 7C14.5613 7 13.3716 8.0628 13.3716 9.37383C13.3716 10.6849 14.5613 11.7477 16.029 11.7477Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_778_17629">
        <rect width="18" height="19" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export const SuccessMessageIcon = (
  props: Partial<CustomIconComponentProps>,
) => <Icon component={SuccessMessage} {...props} />;
