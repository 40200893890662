{
  /*  */
}

import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const DownLoad = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.163 1.33637C15.4559 1.62926 15.4559 2.10414 15.163 2.39703L9.69635 7.8637C9.40345 8.15659 8.92858 8.15659 8.63569 7.8637C8.34279 7.5708 8.34279 7.09593 8.63569 6.80304L14.1024 1.33637C14.3952 1.04348 14.8701 1.04348 15.163 1.33637Z"
      fill="#9B9CA3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2168 1.33325C11.2168 0.919038 11.5526 0.583252 11.9668 0.583252H15.1668C15.581 0.583252 15.9168 0.919038 15.9168 1.33325V4.53325C15.9168 4.94747 15.581 5.28325 15.1668 5.28325C14.7526 5.28325 14.4168 4.94747 14.4168 4.53325V2.08325H11.9668C11.5526 2.08325 11.2168 1.74747 11.2168 1.33325Z"
      fill="#9B9CA3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.38699 1.88626C3.34646 0.926786 4.76001 0.583252 6.50065 0.583252H7.83398C8.2482 0.583252 8.58398 0.919038 8.58398 1.33325C8.58398 1.74747 8.2482 2.08325 7.83398 2.08325H6.50065C4.90796 2.08325 3.98818 2.40638 3.44765 2.94692C2.90712 3.48745 2.58398 4.40722 2.58398 5.99992V9.99992C2.58398 11.5926 2.90712 12.5124 3.44765 13.0529C3.98818 13.5935 4.90796 13.9166 6.50065 13.9166H10.5007C12.0933 13.9166 13.0131 13.5935 13.5537 13.0529C14.0942 12.5124 14.4173 11.5926 14.4173 9.99992V8.66658C14.4173 8.25237 14.7531 7.91658 15.1673 7.91658C15.5815 7.91658 15.9173 8.25237 15.9173 8.66658V9.99992C15.9173 11.7406 15.5738 13.1541 14.6143 14.1136C13.6548 15.073 12.2413 15.4166 10.5007 15.4166H6.50065C4.76001 15.4166 3.34646 15.073 2.38699 14.1136C1.42752 13.1541 1.08398 11.7406 1.08398 9.99992V5.99992C1.08398 4.25928 1.42752 2.84572 2.38699 1.88626Z"
      fill="#9B9CA3"
    />
  </svg>
);

export const DownLoadIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DownLoad} {...props} />
);
