import { createSlice } from "@reduxjs/toolkit";
import { EthereumMainnetData } from "../../../Constants/NetworkConstants";

let initialNetworkData: any = [];
const currentEnvironment = process.env.REACT_APP_NODE_ENV;
switch (currentEnvironment) {
  case "development":
    initialNetworkData = EthereumMainnetData;
    break;
  case "production":
  case "qa":
    initialNetworkData = EthereumMainnetData;
    break;
  default:
    break;
}

export const networkSlice: any = createSlice({
  name: "network",
  initialState: initialNetworkData,
  reducers: {
    resetNetworkSlice: (state: any, action) => {
      state.initialNetworkData = initialNetworkData;
    },
    setCurrentNetworkData: (state: any, action: any) => {
      console.log("current network changes");
      state.currentNetwork = action.payload.currentNetwork;
      state.RPCURL = action.payload.RPCURL;
      state.ChainID = action.payload.ChainID;
      state.CHAINNAME = action.payload.CHAINNAME;
      state.BLOCKEXPLORER = action.payload.BLOCKEXPLORER;
      state.SYMBOL = action.payload.SYMBOL;
      state.CUSTOM_RPC_URL = action.payload.CUSTOM_RPC_URL;
    },
  },
});

export const { setCurrentNetworkData, resetNetworkSlice } =
  networkSlice.actions;

export default networkSlice.reducer;
