import { Card, Col, Input, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./SelectTokenModalCrossChain.scss";

import { SearchOutlined } from "@ant-design/icons";
import { useAccount } from "wagmi";
import { colors } from "../../../../Assets/Theme/colors";
import { HeaderText, Text } from "../../../Text";

const SelectTokenModalCrossChain = ({
  handleClose,
  show,
  disableToken,
  tokens,
  chain,
  type,
  selectedItem,
  selectItem,
}: any) => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const [searchMessage, setSearchMessage] = useState("");
  const [filteredTokenList, setFilteredTokenList] = useState<any>([]);

  useEffect(() => {
    if (tokens) {
      setFilteredTokenList(tokens.slice(0, 40));
    }
  }, [tokens]);

  const handleTokenSearch = async (userInput: any) => {
    if (userInput?.length === 0) {
      setFilteredTokenList(tokens.slice(0, 40));
    } else if (userInput?.length > 0) {
      const filteredResults = tokens.filter((token: any) =>
        ["name", "address", "symbol"].some((property) =>
          token[property].toLowerCase().includes(userInput.toLowerCase()),
        ),
      );

      if (filteredResults) {
        setFilteredTokenList(filteredResults);
      }
    }
  };

  const handleTokenSelect = (item: any) => {
    if (disableToken && item.symbol === disableToken.symbol) return;
    selectItem(item);
    handleClose();
  };

  return (
    <>
      <Modal
        centered
        open={show}
        onCancel={() => {
          handleClose();
          setSearchMessage("");
        }}
        title={
          <Text fontSize={30} fontWeight={600}>
            Select a token
          </Text>
        }
        footer={null}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Input
              prefix={
                <SearchOutlined
                  style={{ color: colors.secondary400, marginRight: 8 }}
                />
              }
              placeholder={"Search by name or paste address"}
              onChange={(e) => handleTokenSearch(e.target.value)}
            />
          </Col>

          <Card
            style={{
              backgroundColor: colors.secondary600,
              borderRadius: 12,
              maxHeight: "50vh",
              overflowY: "auto",
              width: "100%",
            }}
            className={`card-sm-padding scroll-container ${
              tokens === undefined || !!searchMessage.length
                ? "loading-container"
                : ""
            }`}
          >
            <Row>
              {tokens === undefined || !!searchMessage.length ? (
                <Col span={24}>
                  {searchMessage ? (
                    <Text fontWeight={600}>{searchMessage}</Text>
                  ) : (
                    <Spin size={"large"} />
                  )}
                </Col>
              ) : (
                filteredTokenList &&
                filteredTokenList?.map((item: any, idx: number) => (
                  <Col
                    span={24}
                    key={idx}
                    className={`clickable ${
                      item.symbol === disableToken?.symbol
                        ? "disabled"
                        : "hoverable-list-item"
                    }`}
                    onClick={() => handleTokenSelect(item)}
                  >
                    <Row
                      align={"middle"}
                      justify={"space-between"}
                      style={{
                        padding: "0.7em",
                        paddingRight: "1.2em",
                        borderRadius: 8,
                        backgroundColor:
                          selectedItem?.symbol === item.symbol
                            ? colors.primary500
                            : "transparent",
                      }}
                    >
                      <Col>
                        <Row align={"middle"} gutter={12}>
                          <Col>
                            <img
                              src={item?.logoURI}
                              alt="img"
                              style={{
                                height: 40,
                                borderRadius: "44px",
                                width: "40px",
                              }}
                            />
                          </Col>
                          <Col style={{ display: "grid" }}>
                            <HeaderText fontSize={16}>{item.symbol}</HeaderText>
                            <Text
                              fontSize={12}
                              color={
                                selectedItem?.symbol === item.symbol
                                  ? "white"
                                  : colors.secondary500
                              }
                            >
                              {item.name}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <HeaderText fontSize={16}>
                          {/*<span>{getBal(item)?getBal(item):0}</span>*/}
                        </HeaderText>
                      </Col>
                    </Row>
                  </Col>
                ))
              )}
            </Row>
          </Card>
        </Row>
      </Modal>
    </>
  );
};

export default SelectTokenModalCrossChain;
