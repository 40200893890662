import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const useModalWidth = () => {
  const { sm, md, lg, xl } = useBreakpoint();

  const getWidth = () => {
    if (xl) return "30%";
    if (lg) return "50%";
    if (md) return "60%";
    if (sm) return "80%";
    return "90%";
  };

  return {
    width: getWidth(),
  };
};

export default useModalWidth;
