import { useSelector } from 'react-redux';
import TransactionLoaderModal from '../Common/TransactionLoaderModal/TransactionLoaderModal';
import './LayoutStyle.scss';

const PrimaryLayout = ({ children }: any) => {
  const showTransactionModal: any = useSelector(
    (state: any) => state.loadingData.showTransactionModal,
  );

  return (
    <div className="primary-layout">
      {showTransactionModal && <TransactionLoaderModal />}
      <div className="primary-layout__inner">{children}</div>
    </div>
  );
};

export default PrimaryLayout;
