import { disconnect, switchNetwork } from "@wagmi/core";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useNetwork } from "wagmi";
import { supportedChains } from "../../../App";
import {
  setChainId,
  setWalletAddress,
  setWalletType,
} from "../../../redux/reducers/address/address";
import {
  setIsLoggedIn,
  setWrongNetworkSelected,
} from "../../../redux/reducers/login/login";
import { getMessageConfig } from "../../../utils";
import { PrimaryButton } from "../../Button";
import { Text } from "../../Text";
import "./ConnectWallet.scss";

type Props = {
  className?: string;
  header?: boolean;
};

const ConnectWallet = ({ className, header }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { address, isConnected, connector } = useAccount();
  const { chain } = useNetwork();
  const dispatch: any = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);
  const slicedAddress = `${address?.slice(0, 5)}...${address?.slice(
    address.length - 4,
    address.length,
  )}`;

  const [showLogoutModal, setshowLogoutModal] = useState(false);

  const handleCloseLogoutModal = () => setshowLogoutModal(false);
  const handleShowLogoutModal = () => setshowLogoutModal(true);

  useEffect(() => {
    if (address && chain) {
      let isChainValid = supportedChains.find((ch) => ch.id === chain.id);
      if (!isChainValid) {
        message.error(
          getMessageConfig({
            type: "error",
            title: "This network is currently not supported!",
            body: "Please connect to another network.",
          }),
        );
        dispatch(setWrongNetworkSelected(true));
        switchNetwork({
          chainId: supportedChains[0].id,
        });
      } else if (isChainValid) {
        dispatch(setWalletAddress(address));
        dispatch(setIsLoggedIn(true));
        dispatch(setChainId(chain.id));
        dispatch(setWrongNetworkSelected(false));
      }
    } else {
      dispatch(setWalletAddress(""));
      dispatch(setIsLoggedIn(false));
      dispatch(setChainId(null));
      dispatch(setWrongNetworkSelected(false));
    }
  }, [address, chain]);
  useEffect(() => {
    if (isConnected && connector) {
      setWalletType(connector.name);
    }
  }, [isConnected, connector]);

  const handleLogout = async () => {
    handleCloseLogoutModal();
    dispatch(setWalletAddress(""));
    dispatch(setChainId(""));
    dispatch(setIsLoggedIn(false));
    await disconnect();
    message.success(
      getMessageConfig({
        type: "success",
        title: "Wallet disconnected!",
      }),
    );
  };

  // const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const { open } = useWeb3Modal();
  return (
    <>
      {contextHolder}
      {!isConnected && (
        <PrimaryButton
          size={header ? "middle" : "large"}
          fullWidth={!header}
          onClick={() => open({ view: "Networks" })}
        >
          Connect Wallet
        </PrimaryButton>
      )}
      {isConnected && (
        <PrimaryButton
          onClick={handleShowLogoutModal}
          size={header ? "middle" : "large"}
          fullWidth={!header}
        >
          {slicedAddress}
        </PrimaryButton>
      )}

      {/* Connection Modal */}

      {/* Disconnect Modal */}
      <Modal
        open={showLogoutModal}
        onCancel={handleCloseLogoutModal}
        title={
          <Text fontWeight={600} fontSize={30}>
            {isLoggedIn ? slicedAddress : "Connect Wallet"}
          </Text>
        }
        centered
        className="connect_wallet"
        footer={null}
      >
        <div className="modal_list ">
          <div className="Choose_Network">
            <PrimaryButton fullWidth size={"large"} onClick={handleLogout}>
              Disconnect{" "}
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConnectWallet;
