import axios from "axios";
import { SITE_URL } from "../../Constants/AppVariables/appVariable";

export const getCoingeckoTokenPrice = async (ids: string) => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd&include_24hr_change=true`,
    );
    return response;
  } catch (error) {
    console.log("Error in => getShidoPrice", error);
    return error;
  }
};

export const getShidoTokenPrice = async () => {
  try {
    const response = await axios.get(`${SITE_URL}/api/shido-price/`);
    return response;
  } catch (error) {
    console.log("Error in => getShidoPrice", error);
    return error;
  }
};

export const getShidoTotalSupply = async () => {
  try {
    const response = await axios.get(`${SITE_URL}/api/total-supply`);
    return response.data;
  } catch (error) {
    console.log("Error in => getShidoSupply", error);
    return error;
  }
};
