import {
  Address,
  LimitOrder,
  MakerTraits,
  getLimitOrderContract,
  getLimitOrderV4Domain,
} from "@1inch/limit-order-sdk";
import { Web3 } from "@openocean.finance/api/lib/utils/web3";
import { Card, Col, Row, message } from "antd";
import { BigNumber, providers } from "ethers";
import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { erc20ABI } from "wagmi";
import { RouterABI } from "../../../../Assets/abis/1inchRouterABI/Router";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../../Constants/NetworkNames/NetworkNames";
import { OPEN_OCEAN_CHAIN_NAMES } from "../../../../Constants/TYPES/openOceanChainNames";
import {
  getActiveLimitOrders1nch,
  getEventsForOrderHash,
  getLimitOrdersHistory1nch,
  submitLimitOrderToAPI1nch,
} from "../../../../redux/api/limit0rder/limitOrderAPIs";
import {
  setLoading,
  setShowTransactionModal,
  setSwapTransactionLoading,
} from "../../../../redux/reducers/loadingData/loadingData";
import {
  fromReadableAmount,
  getMessageConfig,
  getWrappedTokenAddress,
} from "../../../../utils";
import { PrimaryButton } from "../../../Button";
import TradeCard from "../../../Common/TradeCards/TradeCard";
import TradingViewWidget from "../../TradingChartComponent/TradingViewWidget";
import "./LimitOrder.scss";
import ReviewLimitOrderTable from "./ReviewLimitOrderTable/ReviewLimitOrderTable";
// import { useAccount, useChainId } from "wagmi";
const { useAccount, useChainId } = require("wagmi");

type Tab = "Active" | "History";

const LimitOrderPage = () => {
  const dispatch = useDispatch();
  const { address, connector } = useAccount();

  const walletAddress: string = useSelector(
    (state: any) => state.addressSlice.walletAddress,
  );
  const [page, setPage] = useState<number>(1);
  const [page2, setPage2] = useState<number>(1);

  // const chainId = useSelector((state: any) =>
  //     state.networkSlice.ChainID?.toString(),
  // );
  const chainId: any = useChainId();

  const expiry: string = useSelector(
    (state: any) => state.limitOrderData.orderExpireDate,
  );

  const limitAmount = useSelector((state: any) => state.limitOrderData.amount);

  const limitValue = useSelector((state: any) => state.limitOrderData.value);

  const limitOrderAction = useSelector(
    (state: any) => state.limitOrderData.action,
  );
  const selectedtoken1 = useSelector((state: any) => state.swapData.token1);
  const selectedtoken2 = useSelector((state: any) => state.swapData.token2);

  const currentNetwork = useSelector(
    (state: any) => state.networkSlice.currentNetwork,
  );
  const tabs: Tab[] = ["Active", "History"];
  const [selectedTab, setSelectedTab] = useState<Tab>("Active");
  const [limitorderdata, setlimitorderdata] = useState<any>();
  const [orderHistoryData, setOrderHistoryData] = useState<any>();
  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);

  let chainName: OPEN_OCEAN_CHAIN_NAMES;

  switch (currentNetwork) {
    case EHTEREUM_NETWORK:
      chainName = "eth";
      break;

    case BNB_NETWORK:
      chainName = "bsc";
      break;

    case POLYGON_NETWORK:
      chainName = "polygon";
      break;

    case AVALANCHE_NETWORK:
      chainName = "avax";
      break;

    case ARBITRUM_NETWORK:
      chainName = "arbitrum";
      break;

    case FANTOM_NETWORK:
      chainName = "fantom";
      break;

    default:
      break;
  }

  useEffect(() => {
    if (address) {
      setInterval(() => {
        activeLimitOrders();
      }, 20000);

      activeLimitOrders();
      limitOrderHistory();
    }
    dispatch(setSwapTransactionLoading(false));
  }, [address]);

  const createLimitOrder = async () => {
    const provider = new providers.Web3Provider(await connector.getProvider());
    const signer = provider.getSigner();
    const web3 = new Web3(await connector.getProvider());

    let select1address = selectedtoken1.address;
    let select2address = selectedtoken2.address;

    try {
      let makerTokenAddress = select2address;
      let makerTokenDecimals = selectedtoken2.decimals;
      let takerTokenAddress = select1address;
      let takerTokenDecimals = selectedtoken1.decimals;

      let makerAmount = fromReadableAmount(
        Number(limitValue),
        selectedtoken2.decimals,
      );

      let takerAmount = fromReadableAmount(
        Number(limitAmount),
        selectedtoken1.decimals,
      );

      // swap tokens if selling
      if (limitOrderAction === "Sell") {
        makerTokenAddress = select1address;
        makerTokenDecimals = selectedtoken1.decimals;
        takerTokenAddress = select2address;
        takerTokenDecimals = selectedtoken2.decimals;
        makerAmount = fromReadableAmount(
          Number(limitValue),
          selectedtoken1.decimals,
        );
        takerAmount = fromReadableAmount(
          Number(limitValue),
          selectedtoken2.decimals,
        );
      }

      //   calculate proper expiration
      const valueToSeconds: any = {
        "10M": "600", // 10 * 60
        "1H": "3600", // 1 * 60 * 60
        "1D": "86400", // 1 * 24 * 60 * 60
        "3D": "259200", // 3 * 24 * 60 * 60
        "7D": "604800", // 7 * 24 * 60 * 60
        "30D": "2592000", // 30 * 24 * 60 * 60
        "1Month": "2628000", // 30.44 * 24 * 60 * 60 (average month)
        "3Month": "7884000", // 3 * 30.44 * 24 * 60 * 60 (average month)
        "6Month": "15768000", // 6 * 30.44 * 24 * 60 * 60 (average month)
        "1Y": "31536000", // 365 * 24 * 60 * 60
      };

      const expiresIn = BigInt(valueToSeconds[expiry]);

      const expiration = BigInt(Math.floor(Date.now() / 1000)) + expiresIn;

      const domain = getLimitOrderV4Domain(Number(chainId));

      // check for native tokens
      let useNative = false;

      if (makerTokenAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
        useNative = true;
        makerTokenAddress = getWrappedTokenAddress(chainId);
      }

      if (takerTokenAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
        useNative = true;
        takerTokenAddress = getWrappedTokenAddress(chainId);
      }

      //Orders must call the approve function prior to being submitted
      // Approve the makerAsset contract to spend on behalf of the maker
      const makerAssetContract = new web3.eth.Contract(
        // @ts-ignore
        erc20ABI,
        makerTokenAddress,
      );

      if (makerTokenAddress !== "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
        try {
          // Check current allowance
          const currentAllowance = await makerAssetContract.methods
            .allowance(walletAddress, domain.verifyingContract)
            .call();

          // If the current allowance is sufficient, no need to approve again
          if (BigNumber.from(currentAllowance).lt(makerAmount)) {
            const isUSDT =
              makerTokenAddress ===
              "0xdac17f958d2ee523a2206206994597c13d831ec7";

            if (isUSDT && Number(currentAllowance.toString()) !== 0) {
              // If current allowance is non-zero, set it to zero first
              const zeroEstimateGas = await makerAssetContract.methods
                .approve(domain.verifyingContract, 0)
                .estimateGas({ from: walletAddress });

              if (!zeroEstimateGas) {
                throw new Error(
                  "Failed to estimate gas for zero approval transaction.",
                );
              }

              // Approve the spending with the estimated gas
              await makerAssetContract.methods
                .approve(domain.verifyingContract, 0)
                .send({
                  from: walletAddress,
                  gas: zeroEstimateGas,
                });
            }

            // Estimate gas for the final approval transaction
            const gasEstimate = await makerAssetContract.methods
              .approve(domain.verifyingContract, makerAmount)
              .estimateGas({ from: walletAddress });

            if (!gasEstimate) {
              throw new Error(
                "Failed to estimate gas for approval transaction.",
              );
            }

            // Approve the spending with the estimated gas
            await makerAssetContract.methods
              .approve(domain.verifyingContract, makerAmount)
              .send({
                from: walletAddress,
                gas: gasEstimate,
              });
          }
        } catch (error) {
          console.log(error);
          throw new Error("Failed to approve makerAsset spend.");
        }
      }

      let makerTraits = MakerTraits.default()
        .withExpiration(BigInt(expiration))
        .allowPartialFills()
        .allowMultipleFills();

      if (useNative) {
        makerTraits = MakerTraits.default()
          .withExpiration(BigInt(expiration))
          .allowPartialFills()
          .allowMultipleFills()
          .enableNativeUnwrap();
      }

      const order = new LimitOrder(
        {
          makerAsset: new Address(makerTokenAddress),
          takerAsset: new Address(takerTokenAddress),
          makingAmount: makerAmount.toBigInt(),
          takingAmount: takerAmount.toBigInt(),
          maker: new Address(await signer.getAddress()),
          salt: BigInt(Math.floor(Math.random() * 100000000)),
          receiver: new Address(await signer.getAddress()),
        },
        makerTraits,
      );

      // @ts-ignore
      const typedData = order.getTypedData(domain);
      const converted = { ...typedData.domain, chainId: chainId };
      const signature = await signer._signTypedData(
        converted,
        { Order: typedData.types.Order },
        typedData.message,
      );

      // submit order
      try {
        const payload = {
          orderHash: order.getOrderHash(chainId),
          signature: signature,
          data: {
            ...order.build(),
            extension: order.extension.encode(),
          },
        };
        const response = await submitLimitOrderToAPI1nch(chainId, payload);
      } catch (e) {
        console.log(e, "-----e");
      }

      // must wait at least 1.05 seconds after submitting the order to query it
      await new Promise((resolve) => setTimeout(resolve, 1050));

      await activeLimitOrders();
      dispatch(setSwapTransactionLoading(false));
      dispatch(setShowTransactionModal(false));

      message.success(
        getMessageConfig({
          type: "success",
          title: "Order created successfully",
        }),
      );
    } catch (e) {
      message.success(
        getMessageConfig({
          type: "error",
          title: "Could not create order",
        }),
      );
      dispatch(setSwapTransactionLoading(false));
      dispatch(setShowTransactionModal(false));
    }
  };

  const limitOrderHistory = async () => {
    try {
      const result = await getLimitOrdersHistory1nch(chainId, address, page2);

      setOrderHistoryData(result);
    } catch (error) {
      console.error("Error fetching order history data:", error);
    }
  };

  const activeLimitOrders = async () => {
    // dispatch(setLoading(true));
    try {
      // @ts-ignore
      // const result = await getActiveLimitOrders(chainId, address);
      const result = await getActiveLimitOrders1nch(chainId, address, page);
      if (result) {
        setlimitorderdata(result);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error getting limit order address:", error);
      return false;
    }
  };

  const cancelLimitOrderByHash = async (selectedOrder: any) => {
    try {
      const web3 = new Web3(await connector.getProvider());
      const contractAddress = getLimitOrderContract(chainId);
      // @ts-ignore
      const contract = new web3.eth.Contract(RouterABI, contractAddress);

      const orderMakerTraits = selectedOrder.data.makerTraits;
      const orderHash = selectedOrder.orderHash;

      const response = await contract.methods
        .cancelOrder(orderMakerTraits, orderHash)
        .send({ from: walletAddress });

      await getEventsForOrderHash(chainId, orderHash);

      await activeLimitOrders();

      message.success(
        getMessageConfig({
          type: "success",
          title: "Order Cancelled successfully",
        }),
      );

      return response;
    } catch (e) {
      message.success(
        getMessageConfig({
          type: "error",
          title: "Could not cancel order",
        }),
      );
      console.log(e);
    }
  };

  const orderTabs = (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row gutter={8}>
            {tabs.map((tab, key: number) => (
              <Col key={key}>
                <PrimaryButton
                  onClick={() => {
                    setSelectedTab(tab);
                    // navigate(`/${tab.link}`);
                  }}
                  style={{
                    ...(tab !== selectedTab && {
                      backgroundColor: "transparent",
                    }),
                  }}
                >
                  {tab}
                </PrimaryButton>
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={24}>
          {selectedTab === "Active" ? (
            <ReviewLimitOrderTable
              type="Exchange"
              limitorderdata={limitorderdata}
              cancelLimitOrderByHash={cancelLimitOrderByHash}
            />
          ) : (
            <ReviewLimitOrderTable
              type="TradeHistory"
              orderHistoryData={orderHistoryData}
            />
          )}
        </Col>
      </Row>
    </Card>
  );

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24} xxl={0}>
          <TradeCard limitOrderProps={{ createLimitOrder }} />
        </Col>
        <Col xs={24} xxl={16}>
          <Row gutter={[20, 20]}>
            <Col xs={isLoggedIn ? 24 : 0} xxl={0}>
              {orderTabs}
            </Col>
            <Col span={24}>
              <TradingViewWidget />/
            </Col>
            <Col xs={0} xxl={isLoggedIn ? 24 : 0}>
              {orderTabs}
            </Col>
          </Row>
        </Col>
        <Col xs={0} xxl={8}>
          <TradeCard limitOrderProps={{ createLimitOrder }} />
        </Col>
      </Row>
      {/*<RouteInfoModal show={show} handleClose={handleClose} />*/}
    </>
  );
};

export default LimitOrderPage;
