import {
  Button,
  Col,
  ConfigProvider,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Typography,
  message,
} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import BN from "bn.js";
import { utils } from "ethers";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAccount, useChainId } from "wagmi";
import Web3 from "web3";
import { ChevronDownIcon } from "../../../../Assets/icons/ChevronDown";
import { ArrowDownIcon2 } from "../../../../Assets/icons/arrowDown2";
import shidoTokenIcon from "../../../../Assets/icons/shido_token_img.png";
import tokenPlaceholder from "../../../../Assets/icons/token_img_placeholder.png";
import ConnectWallet from "../../../../Components/Common/ConnectWallet/ConnectWallet";
import {
  fetchApprovalTransaction,
  fetchQuote,
  fetchTokenAllowance,
  fetchTokenPrices,
  initiateSwap,
} from "../../../../redux/api/swap/swapAPIs";
import store from "../../../../redux/store";
import { getTokenBalanceFull } from "../../../../services/contractServices/tokenServices";
import { PrimaryButton } from "../../../Button";
import SelectTokenModalSimple from "../../CommonModals/SelectTokenModal/SelectTokenModalSimple";
import CustomInputNumber from "./CustomInputNumber";
import "./OneInchSwap.scss";

const { Option } = Select;

interface Token {
  address: string;
  symbol: string;
  decimals: number;
  name: string;
  logoURI: string;
  price?: number;
}

interface Quote {
  dstAmount: string;
}

const OneInchSwap: React.FC = () => {
  const [fromToken, setFromToken] = useState<any>("");
  const [toToken, setToToken] = useState<any>("");
  const [amount, setAmount] = useState<string>("");
  const [quoteAmount, setQuoteAmount] = useState<string>("");
  const { address, isConnected } = useAccount();
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const chain_id = useChainId();
  const [fromTokenUsdValue, setFromTokenUsdValue] = useState<string>("");
  const [toTokenUsdValue, setToTokenUsdValue] = useState<string>("");
  const [maxBalance, setMaxBalance] = useState("0.00");
  const [isSwapping, setIsSwapping] = useState(false);
  const [selectedMaxTokenBalance, setSelectedMaxTokenBalance] =
    useState<string>("");
  const [isUsdValueLoading, setIsUsdValueLoading] = useState(false);
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const { Text } = Typography;
  const tokens = useSelector((state: any) => state.tokenCollection.swapTokens);
  const tokenRemoved = useSelector(
    (state: any) => state.tokenCollection.tokenRemoved,
  );

  const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (window.ethereum) {
      const bscWeb3 = new Web3(window.ethereum as any);
      setWeb3(bscWeb3);
    } else {
      message.error("Ethereum wallet not detected. Please install MetaMask.");
    }
  }, []);

  useEffect(() => {
    setAmount("");
    setQuoteAmount("");
    setMaxBalance("0.00");
    setFromTokenUsdValue("");
    setToTokenUsdValue("");
  }, [chain_id, fromToken, toToken]);

  useEffect(() => {
    const validTokens = tokens.filter(
      (token: Token) => token.symbol.length <= 6,
    );

    if (validTokens.length > 1) {
      let randomIndex1 = Math.floor(Math.random() * validTokens.length);
      let randomIndex2 = Math.floor(Math.random() * validTokens.length);

      while (randomIndex1 === randomIndex2) {
        randomIndex2 = Math.floor(Math.random() * validTokens.length);
      }
      setFromToken(validTokens[randomIndex1]);
      setToToken(validTokens[randomIndex2]);
      fetchMaxBalance(validTokens[randomIndex1], true);
    }
  }, [tokenRemoved]);

  useEffect(() => {
    if (address && fromToken) {
      fetchMaxBalance(fromToken, true);
    }
  }, [address, fromToken, toToken]);

  const handleMaxButtonClick = async () => {
    if (fromToken) {
      await fetchMaxBalance(fromToken, false);
      setAmount(selectedMaxTokenBalance);
      const tokenDecimals = tokens.find(
        (token: any) => token.address === fromToken.address,
      )?.decimals;
      if (tokenDecimals !== undefined) {
        const amountInSmallestUnit = toSmallestUnit(
          selectedMaxTokenBalance,
          tokenDecimals,
        );
        debouncedFetchQuote(
          chain_id,
          fromToken,
          toToken,
          amountInSmallestUnit.toString(),
          selectedMaxTokenBalance,
        );
      }
    }
  };

  const debouncedFetchQuote = useCallback(
    debounce(
      async (
        chain_id,
        fromToken,
        toToken,
        amountInSmallestUnit,
        amountString,
      ) => {
        setIsLoadingQuote(true);
        setIsUsdValueLoading(true);
        if (!fromToken || !toToken || !amountInSmallestUnit || !web3) {
          setIsLoadingQuote(false);
          setIsUsdValueLoading(false);
          return;
        }

        try {
          const quote = await fetchQuote(
            chain_id,
            fromToken.address,
            toToken.address,
            amountInSmallestUnit,
          );
          const calculatedQuote = formatAmount(
            quote.dstAmount,
            tokens.find((token: any) => token.address === toToken.address)
              ?.decimals,
          );
          setQuoteAmount(calculatedQuote);

          if (calculatedQuote) {
            await delay(1000);
            const prices = await fetchTokenPrices(
              chain_id,
              fromToken.address,
              toToken.address,
            );
            const fromUsdValue = formatPrice(
              prices.fromPrice * parseFloat(amountString) || 0,
            );
            const toUsdValue = formatPrice(
              prices.toPrice * parseFloat(calculatedQuote) || 0,
            );
            setFromTokenUsdValue(fromUsdValue);
            setToTokenUsdValue(toUsdValue);
          }
        } catch (error) {
          console.error("Failed to fetch quote or prices:", error);
          message.error("Failed to get quote or prices.");
          setQuoteAmount("");
          setFromTokenUsdValue("0.00");
          setToTokenUsdValue("0.00");
        } finally {
          setIsLoadingQuote(false);
          setIsUsdValueLoading(false);
        }
      },
      1300,
    ),
    [chain_id, fromToken, toToken, web3, tokens],
  );
  const handleSelectFromToken = async (token: any) => {
    setFromToken(token);
    await fetchMaxBalance(token, true);
  };

  const handleSelectToToken = (token: any) => {
    setToToken(token);
  };

  const handleAmountChange = (value: any) => {
    if (value !== null && value !== undefined) {
      const amountString = value.toString();
      const sanitizedValue = amountString.replace(/[^0-9.]/g, "");
      if (
        sanitizedValue &&
        !isNaN(Number(sanitizedValue)) &&
        Number(sanitizedValue) >= 0
      ) {
        setAmount(sanitizedValue);
        if (sanitizedValue && fromToken && toToken && web3) {
          const tokenDecimals = tokens.find(
            (token: any) => token.address === fromToken.address,
          )?.decimals;
          if (tokenDecimals !== undefined) {
            const amountInSmallestUnit = toSmallestUnit(
              sanitizedValue,
              tokenDecimals,
            );
            debouncedFetchQuote(
              chain_id,
              fromToken,
              toToken,
              amountInSmallestUnit.toString(),
              sanitizedValue,
            );
          }
        }
      } else {
        setAmount("");
        setQuoteAmount("");
      }
    } else {
      setAmount("");
      setQuoteAmount("");
    }
  };

  function toSmallestUnit(amount: string, decimals: number): string {
    if (!amount) return utils.parseUnits("0", decimals).toString();
    if (typeof amount === "string") {
      return utils.parseUnits(amount, decimals).toString();
    }
    return utils.parseUnits(amount, decimals).toString();
  }

  const fetchMaxBalance = async (
    token?: any,
    setSelectedMax: boolean = false,
  ) => {
    // if (!web3 || (!fromToken && !token) || !address) {
    //   message.error("Make sure your wallet is connected.");
    //   return;
    // }

    try {
      const selectedToken = token || fromToken;
      const maxBalance = await getTokenBalanceFull(
        address,
        selectedToken.address,
        web3,
      );

      const maxBalanceString = maxBalance ? maxBalance.toString() : "0.00";

      if (setSelectedMax) {
        setSelectedMaxTokenBalance(maxBalanceString);
      } else {
        setMaxBalance(maxBalanceString);
        const tokenDecimals = tokens.find(
          (token: any) => token.address === selectedToken.address,
        )?.decimals;
        if (tokenDecimals !== undefined && maxBalance) {
          const amountInSmallestUnit = toSmallestUnit(
            maxBalanceString,
            tokenDecimals,
          );
          debouncedFetchQuote(
            chain_id,
            selectedToken.address,
            toToken.address,
            amountInSmallestUnit,
            maxBalanceString,
          );
        }
      }
    } catch (error) {
      console.error("Error fetching max balance:", error);
      if (setSelectedMax) {
        setSelectedMaxTokenBalance("0.00");
      } else {
        setMaxBalance("0.00");
      }
    }
  };

  const handleSwap = async () => {
    setIsSwapping(true);
    if (!fromToken || !toToken || !amount || !address || !web3) {
      message.error(
        "Please ensure all fields are correctly filled and you are connected to a wallet.",
      );
      return;
    }

    try {
      const allowance = await fetchTokenAllowance(
        chain_id,
        fromToken.address,
        address,
      );
      const slippageTolerance = store.getState().swapData.slippageTolerance;
      await delay(1200);
      let amountInSmallestUnit = amount.toString();
      const amountInWei = web3.utils.toWei(amount, "ether");

      const tokenDecimals = tokens.find(
        (token: any) => token.address === fromToken.address,
      )?.decimals;

      if (fromToken && toToken && tokenDecimals !== undefined && web3) {
        amountInSmallestUnit = toSmallestUnit(amount.toString(), tokenDecimals);
      }

      if (
        web3.utils.toBN(allowance.allowance).lt(web3.utils.toBN(amountInWei))
      ) {
        if (
          web3.utils.toBN(allowance.allowance).gt(web3.utils.toBN(0)) &&
          fromToken.address === "0xdac17f958d2ee523a2206206994597c13d831ec7" &&
          web3.utils.toBN(amountInWei).gt(web3.utils.toBN(allowance.allowance))
        ) {
          const zeroApproveData = await fetchApprovalTransaction(
            chain_id,
            fromToken.address,
            "0",
          );
          await delay(1000);

          const transaction = {
            from: address,
            to: fromToken.address,
            data: zeroApproveData.data,
            value: "0",
          };

          await web3.eth.sendTransaction(transaction);
        }
        const approvalData: any = await fetchApprovalTransaction(
          chain_id,
          fromToken.address,
          amountInSmallestUnit,
        );
        await delay(1000);

        const transaction = {
          from: address,
          to: fromToken.address,
          data: approvalData.data,
          value: "0",
        };

        await web3.eth.sendTransaction(transaction);
        message.success("Approval successful. Proceeding to swap...");
      }
      await delay(1000);
      const swapData = await initiateSwap(
        chain_id,
        fromToken.address,
        toToken.address,
        amountInWei,
        address,
        slippageTolerance,
      );

      if (swapData && swapData.tx) {
        await web3.eth.sendTransaction(swapData.tx);
        message.success("Swap executed successfully.");
      }
    } catch (error: any) {
      console.error(error);
      message.error(error.message);
    } finally {
      setIsSwapping(false);
    }
  };

  function formatPrice(value: number): string {
    const decimalPlaces = value < 1 ? 5 : 2;
    return value.toFixed(decimalPlaces);
  }

  function formatAmount(amount: any, decimals: any) {
    const divisor = new BN(10).pow(new BN(decimals));
    const amountBN = new BN(amount);
    const wholePart = amountBN.div(divisor);
    const fractionPart = amountBN.mod(divisor);

    const fractionStr = fractionPart.toString(10).padStart(decimals, "0");

    const trimmedFraction = fractionStr.slice(0, 6);
    const finalFraction = trimmedFraction.replace(/0+$/, "");

    return `${wholePart.toString(10)}.${finalFraction.padEnd(6, "0")}`;
  }

  const [SwitchTokensBtnActive, setSwitchTokensBtnActive] = useState(false);

  const switchTokens = async () => {
    const newFromToken = toToken;
    const newToToken = fromToken;
    setFromToken(newFromToken);
    setToToken(newToToken);
    setAmount("");
    setQuoteAmount("");
    setSwitchTokensBtnActive(!SwitchTokensBtnActive);
    await fetchMaxBalance(newFromToken, true);
  };

  useEffect(() => {
    return () => {
      debouncedFetchQuote.cancel();
    };
  }, [debouncedFetchQuote]);
  const { xs, sm, md, lg, xxl } = useBreakpoint();

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);

  const handleShow1 = () => setShowModal1(true);
  const handleShow2 = () => setShowModal2(true);

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            activeBg: "transparent",
            handleBg: "transparent",
            colorBgContainer: "transparent",
            colorBorder: "transparent",
          },
        },
      }}
    >
      <div style={{}}>
        <Row
          style={{
            justifyContent: "end",
            alignItems: "center",
            gap: "12px",
            marginTop: sm ? "0" : "-40px",
            height: sm ? "auto" : "40px",
            marginBottom: "12px",
          }}
        >
          <Text>
            Balance: {address && fromToken ? selectedMaxTokenBalance : "0.00"}
          </Text>
          <PrimaryButton
            size="small"
            onClick={handleMaxButtonClick}
            style={{
              height: "auto",
              padding: "4px 12px",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Max
          </PrimaryButton>
        </Row>

        <Row
          style={{
            backgroundColor: "#37373C",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 15px",
            borderRadius: "9px",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleShow1}
          >
            {fromToken ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    fromToken.logoURI
                      ? fromToken.logoURI
                      : fromToken.address ===
                        "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b"
                      ? shidoTokenIcon
                      : tokenPlaceholder
                  }
                  alt={fromToken.symbol}
                  style={{ width: 35, marginRight: 12 }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    lineHeight: "1",
                    gap: "4px",
                  }}
                >
                  <p
                    style={{
                      color: "#7C7C82",
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      margin: "0",
                      marginBottom: "1px",
                    }}
                  >
                    Swap from
                  </p>
                  <div
                    className="fromToken-symbol-wrap"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "normal",
                        margin: "0",
                        marginBottom: "1px",
                      }}
                    >
                      {fromToken.symbol}
                    </p>
                    <div className="svg-wrap">
                      <ChevronDownIcon style={{ color: "white" }} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>...</div>
            )}
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              paddingRight: "11px",
              alignItems: "flex-end",
            }}
          >
            <CustomInputNumber
              size="small"
              controls={false}
              value={amount}
              onChange={handleAmountChange}
              placeholder="0"
              style={{
                textAlign: "right",
                color: "white",
                width: "200px",
              }}
              className="SwapInputNumber"
            />
            <Text style={{ fontSize: "12px", color: "#7C7C82" }}>
              {isUsdValueLoading ? (
                <Skeleton.Input
                  active={true}
                  size={"small"}
                  style={{ width: 50, display: "inline-block" }}
                />
              ) : fromTokenUsdValue ? (
                `~$ ${fromTokenUsdValue}`
              ) : (
                "~$ 0.00"
              )}
            </Text>
          </Col>
        </Row>

        <Button
          type="link"
          onClick={switchTokens}
          icon={<ArrowDownIcon2 style={{ color: "white" }} />}
          style={{
            width: "54px",
            height: "54px",
            border: "none",
            padding: "0",
            display: "flex",
            margin: "-12px auto",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.3s 0s ease-out",
            transform: SwitchTokensBtnActive
              ? "rotate(-180deg)"
              : "rotate(0deg)",
          }}
        />

        <Row
          style={{
            backgroundColor: "#37373C",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 15px",
            borderRadius: "9px",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleShow2}
          >
            {toToken ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    toToken.logoURI
                      ? toToken.logoURI
                      : toToken.address ===
                        "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b"
                      ? shidoTokenIcon
                      : tokenPlaceholder
                  }
                  alt={toToken.symbol}
                  style={{ width: 35, marginRight: 12 }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    lineHeight: "1",
                    gap: "4px",
                  }}
                >
                  <p
                    style={{
                      color: "#7C7C82",
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      margin: "0",
                      marginBottom: "1px",
                    }}
                  >
                    Swap to
                  </p>
                  <div
                    className="fromToken-symbol-wrap"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "normal",
                        margin: "0",
                        marginBottom: "1px",
                      }}
                    >
                      {toToken.symbol}
                    </p>
                    <div className="svg-wrap">
                      <ChevronDownIcon style={{ color: "white" }} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>...</div>
            )}
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              paddingRight: "11px",
              alignItems: "flex-end",
            }}
          >
            <InputNumber
              size="small"
              controls={false}
              value={quoteAmount}
              disabled={true}
              style={{
                textAlign: "right",
                color: "white",
                width: sm ? "200px" : "70px",
              }}
              className="SwapInputNumber"
              placeholder="0"
              formatter={(value) => {
                const formattedValue = value?.replace(/[^0-9.]/g, "") ?? "";
                if (formattedValue === "") return "";
                if (isNaN(Number(formattedValue)) || Number(formattedValue) < 0)
                  return "0";
                return formattedValue;
              }}
              parser={(value) => {
                const parsedValue = value?.replace(/[^0-9.]/g, "") ?? "";
                if (parsedValue === "") return "";
                if (isNaN(Number(parsedValue)) || Number(parsedValue) < 0)
                  return "0";
                return parsedValue;
              }}
            ></InputNumber>
            <Text style={{ fontSize: "12px", color: "#7C7C82" }}>
              {isUsdValueLoading ? (
                <Skeleton.Input
                  active={true}
                  size={"small"}
                  style={{ width: 50, display: "inline-block" }}
                />
              ) : toTokenUsdValue ? (
                `~$ ${toTokenUsdValue}`
              ) : (
                "~$ 0.00"
              )}
            </Text>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center" }}>
          <Text
            style={{
              color: "#7C7C82",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: quoteAmount && toToken && amount ? "12px" : "20px",
              marginBottom: quoteAmount && toToken && amount ? "36px" : "0",
              transition: "all 0.22s 0s ease-out",
            }}
          >
            {quoteAmount && toToken && amount && (
              <>
                {
                  tokens.find(
                    (token: any) => token.address === fromToken.address,
                  )?.symbol
                }{" "}
                {isLoadingQuote ? (
                  <Skeleton.Input
                    active={true}
                    size={"small"}
                    style={{ width: 50, display: "inline-block" }}
                  />
                ) : (
                  amount
                )}{" "}
                ={" "}
                {isLoadingQuote ? (
                  <Skeleton.Input
                    active={true}
                    size={"small"}
                    style={{ width: 50, display: "inline-block" }}
                  />
                ) : (
                  quoteAmount
                )}{" "}
                {
                  tokens.find((token: any) => token.address === toToken.address)
                    ?.symbol
                }
              </>
            )}
          </Text>
        </Row>

        {isConnected ? (
          <PrimaryButton
            onClick={handleSwap}
            loading={isSwapping}
            style={{
              width: "100%",
              height: "auto",
              padding: "14px",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            {isSwapping ? "Swapping..." : "Swap"}
          </PrimaryButton>
        ) : (
          <ConnectWallet header={false} />
        )}
      </div>

      <SelectTokenModalSimple
        show={showModal1}
        handleClose={handleClose1}
        onSelectToken={handleSelectFromToken}
        disableToken={toToken}
        selectedToken={fromToken}
      />
      <SelectTokenModalSimple
        show={showModal2}
        handleClose={handleClose2}
        onSelectToken={handleSelectToToken}
        disableToken={fromToken}
        selectedToken={toToken}
      />
    </ConfigProvider>
  );
};

export default OneInchSwap;
