import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import CrossChainSwap from "../../Pages/Trade/CrossChainSwap";
import useCookieReset from "../../Pages/Trade/useCookieReset";
import LimitOrderSwapCard from "./SwapCard/LimitOrderSwapCard";
import SwapCard from "./SwapCard/SwapCard";
import "./TradeCard.scss";
import TradeCardTabs from "./TradeCardTabs";

type TabKey = "swap" | "limit" | "cross-chain";

const TradeCard = ({ limitOrderProps }: { limitOrderProps?: any }) => {
  useCookieReset(); //this is a function we made so that when cookies are cleared we can reinitialize the things
  const [selectedTab, setSelectedTab] = useState<TabKey>("swap");
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (currentPath === "/limit-order") {
      setSelectedTab("limit");
    } else if (currentPath === "/simple-mode") {
      setSelectedTab("swap");
    } else if (currentPath === "/cross-chain-swap") {
      setSelectedTab("cross-chain");
    }
  }, [currentPath]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card>
            <TradeCardTabs />
            {selectedTab === "swap" ? (
              <SwapCard />
            ) : selectedTab === "cross-chain" ? (
              <CrossChainSwap />
            ) : (
              selectedTab === "limit" && (
                <LimitOrderSwapCard {...limitOrderProps} />
              )
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TradeCard;
