import { Card, Col, Spin, Table } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import "chartjs-adapter-moment";
import { useState } from "react";
import ethereumImageSm from "../../../../Assets/icons/EthereumIconSmall.png";
import { Text } from "../../../Text";
import "./transactions.scss";

export const Transactions = () => {
  const dataSource = [
    {
      key: "1",
      time: "3 minutes ago",
      type: "Buy",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
    {
      key: "2",
      time: "3 minutes ago",
      type: "Sell",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
    {
      key: "3",
      time: "3 minutes ago",
      type: "Buy",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
    {
      key: "4",
      time: "3 minutes ago",
      type: "Sell",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
    {
      key: "5",
      time: "3 minutes ago",
      type: "Sell",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
    {
      key: "6",
      time: "3 minutes ago",
      type: "Sell",
      eth: "2,55B",
      for: 5.05,
      usd: "$18.574,55",
      wallet_address: "0x31Ab...73bF",
    },
  ];
  const { xs, sm, md, lg, xxl } = useBreakpoint();
  const [filterValue, setFilterValue] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);

  const handleSearch = (value: any) => {
    const filteredData = dataSource.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setFilteredDataSource(filteredData);
    setFilterValue(value);
  };

  const columns = [
    {
      title: "Time",
      dataIndex: "time",
      className: "time",
      render: (name: any, record: any) => (
        <>
          <Text fontWeight={500} color="#7C7C82" fontSize={"14px"}>
            3 minutes ago
          </Text>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: any, record: any) => (
        <>
          {type === "Buy" ? (
            <Text fontWeight={500} color="#39CA7F" fontSize={"14px"}>
              {type}
            </Text>
          ) : (
            <Text fontWeight={500} color="#EA332D" fontSize={"14px"}>
              {type}
            </Text>
          )}
        </>
      ),
    },
    {
      title: "$ETH",
      dataIndex: "eth",
      key: "$eth",
      render: (eth: any, record: any) => (
        <>
          <Text fontWeight={500} color="#fff" fontSize={"14px"}>
            {eth}
          </Text>
        </>
      ),
    },
    {
      title: "For",
      dataIndex: "for",
      key: "for",
      render: (day: any, record: any) => (
        <div className="for">
          <Text fontWeight={600} color="#fff" fontSize={"14px"}>
            {day}
          </Text>
          <div>
            <img src={ethereumImageSm} alt={"ethereumImageSm"} />
            <Text
              fontWeight={600}
              color="#fff"
              fontSize={"14px"}
              className="text"
            >
              ETH
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: "USD",
      dataIndex: "usd",
      key: "usd",
      render: (usd: any, record: any) => (
        <Text fontWeight={500} color="#fff" fontSize={"14px"}>
          {usd}
        </Text>
      ),
    },
    {
      title: "Wallet",
      dataIndex: "wallet_address",
      key: "wallet_address",
      render: (wallet_address: any, record: any) => (
        <Text fontWeight={500} color="#fff" fontSize={"14px"}>
          {wallet_address}
        </Text>
      ),
    },
  ];

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={16}
      xxl={16}
      style={{ paddingRight: "unset" }}
    >
      <Card className="transaction-card">
        <div className="heading-row">
          <Text fontWeight={600} color="white" fontSize={"24px"}>
            Transactions
          </Text>
          <Text fontWeight={600} color="#7C7C82" fontSize={"24px"}>
            Pools
          </Text>
        </div>
        {false ? (
          <Spin size={"large"} className="spinner" />
        ) : (
          <div className="transaction-table">
            <Table
              dataSource={filteredDataSource}
              columns={columns}
              scroll={{ x: "max-content" }}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: false,
                // pageSizeOptions: ["5", "20", "30"],
              }}
              style={{
                marginTop: "16px",
              }}
            />
          </div>
        )}
      </Card>
    </Col>
  );
};
