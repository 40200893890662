import Modal from "react-bootstrap/Modal";
import ButtonCustom from "../../../../Common/ButtonCustom/ButtonCustom";

const LimitOrderConfirmationModal = ({ handleClose, onConfirm, show }: any) => {
  return (
    <>
      <Modal
        className="Common_Modal confirmation-modal"
        centered
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to proceed?</p>

          <div className="confirmation-modal-btns">
            <ButtonCustom
              onClick={(e: any) => {
                onConfirm();
                handleClose();
              }}
              title="Confirm"
            />
            <ButtonCustom onClick={() => onConfirm()} title="Cancel" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LimitOrderConfirmationModal;
