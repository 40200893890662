import { waitForTransaction } from "@wagmi/core";
import { message } from "antd";
import { GAS_CALCUATIONS } from "../../Constants/TYPES/gasCalculations";
import { STAKING_TOKEN_VERSIONS } from "../../Constants/TYPES/stakingTokenTypes";
import { STAKING_VERSIONS } from "../../Constants/TYPES/stakingTypes";

import { BigNumber } from "ethers";
import { APIURL } from "../../Constants/APIconstants/apiConstants";
import { SITE_URL } from "../../Constants/AppVariables/appVariable";
import {
  setShowTransactionModal,
  setSubmitTransactionLoading,
  setSubmittedTransactionHash,
  setSwapTransactionLoading,
  setTransactionErrorMessage,
  setTransactionHash,
  setTransactionSuccessMessage,
} from "../../redux/reducers/loadingData/loadingData";
import store from "../../redux/store";
import { getMessageConfig } from "../../utils";
import { apiCallPost } from "../apiServices/ApiServices";
import { callContractMethod, callWeb3 } from "../walletServices";
import {
  commonAbiInstancesForCallFunctions,
  commonAbiInstancesForSendFunctionsAutoGasPrice,
} from "./GlobalCall";

const getGasCalculations = async () => {
  const web3 = await callWeb3();
  const chainId = store.getState().networkSlice.ChainID.toString();

  // Get the latest block
  const latestBlock = await web3.eth.getBlock("latest");

  // Get the base fee from the latest block
  const baseFee: any = latestBlock.baseFeePerGas;

  // Fetch the current market gas price
  let gasPrice: any = await web3.eth.getGasPrice();

  // Calculate the gas price increase percentage based on chainId
  const gasPriceIncreasePercentage = chainId === "56" ? 0 : 50; // 0% for BNB, 50% for Polygon

  // Calculate the increased gas price based on the percentage
  gasPrice = Math.ceil(gasPrice * (1 + gasPriceIncreasePercentage / 100));

  // Calculate the priority fee as the difference between market gas price and base fee
  const priorityFee = Math.max(0, gasPrice - baseFee);

  // Calculate the max fee using the EIP-1559 formula
  const maxFeePerGas = baseFee + priorityFee;

  const result: GAS_CALCUATIONS = {
    gasPrice,
    priorityFee,
    maxFeePerGas,
  };

  return result;
};

export const getUserStakes = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods
        .userStake(walletAddress)
        .call();
      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getUserStakes", error);
    // return error;
  }
};

export const getUserRewards = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods
        .getUserReward(walletAddress)
        .call();
      // console.log('getUserRewards =>', result);

      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getUserRewards", error);
    // return error;
  }
};

export const getUserLockedTokens = async (
  walletAddress: any,
  LOCKING_VERSION: any,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == LOCKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );

    if (contract) {
      const result: any = await contract.methods
        .userShidoV1(walletAddress)
        .call();

      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getUserLockedTokens", error);
    // return error;
  }
};

export const getTotalStakings = async (STAKING_VERSION: STAKING_VERSIONS) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods.getTotalStakes().call();
      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getTotalStakings", error);
    // return error;
  }
};

export const getEmergencyExitFees = async (
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods.emergencyExitFees().call();
      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getEmergencyExitFees", error);
    // return error;
  }
};

export const getRewardRate = async (STAKING_VERSION: STAKING_VERSIONS) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods.rewardRate().call();
      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getRewardRate", error);
    // return error;
  }
};

export const getUserRewardValueInDollars = async (
  amount: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const tokenAddress = TOKENdata[0]?.address;
    const data = {
      tokenAddress: tokenAddress,
      chainId: chainId,
      // havePool: false,
    };
  } catch (error) {
    console.log("Error in => getUserRewardValueInDollars", error);
  }
};

export const getUserLockedTokensInDollars = async (
  amount: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const tokenAddress = TOKENdata[0]?.address;
    const data = {
      tokenAddress: tokenAddress,
      chainId: chainId,
      // havePool: false,
    };
  } catch (error) {
    console.log("Error in => getUserLockedTokensInDollars", error);
  }
};

export const getUserStakedValueInDollars = async (
  amount: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const tokenAddress = TOKENdata[0]?.address;
    const data = {
      tokenAddress: tokenAddress,
      chainId: chainId,
      // havePool: false,
    };
  } catch (error) {
    console.log("Error in => getUserStakedValueInDollars", error);
  }
};

export const getUserShidoValueInDollars = async (
  amount: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const tokenAddress = TOKENdata[0]?.address;
    const data = {
      tokenAddress: tokenAddress,
      chainId: chainId,
      // havePool: false,
    };
  } catch (error) {
    console.log("Error in => getUserStakedValueInDollars", error);
  }
};

export const getTokenValueInDollars = async (
  amount: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const tokenAddress = TOKENdata[0]?.address;
    const data = {
      tokenAddress: tokenAddress,
      chainId: chainId,
      // havePool: false,
    };
  } catch (error) {
    console.log("Error in => getTokenValueInDollars", error);
  }
};

export const stakeTokens = async (
  walletAddress: any,
  amount: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol === STAKING_VERSION,
    );

    const contractAddress = STAKINGdata[0]?.address;
    const contractABI: any[] = JSON.parse(JSON.stringify(STAKINGdata[0]?.abi));
    const functionName = "stake";
    const fromAddress = walletAddress;
    const args = [amount];

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      fromAddress,
      args,
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data.blockHash && data.transactionHash) {
      try {
        const stakingData = {
          transactionHash: result.hash,
        };
        await apiCallPost(SITE_URL, APIURL.SAVE_STAKING, stakingData);
      } catch (e) {}

      const returnData = {
        status: true,
        hash: result.hash,
      };

      return returnData;
    }
    return {
      status: false,
    };
  } catch (error: any) {
    console.log("Error in => stakeTokens", error);
    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please increase your gas for this transaction",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const migrateTokens = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const { gasPrice } = await getGasCalculations();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );

    if (contract) {
      const gasLimit = await contract.methods?.migrateTokens().estimateGas({
        from: walletAddress,
        // value: "0",
      });
      const result = await contract?.methods?.migrateTokens().send({
        from: walletAddress,
        gas: gasLimit,
        gasPrice,
        // gas: gasLimit,
        // value: "0"
      });
      return result;
    }
  } catch (error: any) {
    console.log("Error in => migrateTokens", error);
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const lockTokens = async (walletAddress: any, LOCKING_VERSION: any) => {
  try {
    const { gasPrice } = await getGasCalculations();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == LOCKING_VERSION,
    );
    let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );

    if (contract) {
      const gasLimit = await contract.methods?.lockTokens().estimateGas({
        from: walletAddress,
        // value: "0",
      });
      const result = await contract?.methods?.lockTokens().send({
        from: walletAddress,
        gas: gasLimit,
        gasPrice,
        // gas: gasLimit,
        // value: "0"
      });
      return result;
    }
  } catch (error: any) {
    console.log("Error in => lockTokens", error);
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const claimOldTokens = async (
  walletAddress: any,
  LOCKING_VERSION: any,
) => {
  try {
    const { gasPrice } = await getGasCalculations();
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == LOCKING_VERSION,
    );
    let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
      STAKINGdata[0]?.address,
      JSON.parse(JSON.stringify(STAKINGdata[0]?.abi)),
    );

    if (contract) {
      const gasLimit = await contract.methods?.claimTokens().estimateGas({
        from: walletAddress,
        // value: "0",
      });
      const result = await contract?.methods?.claimTokens().send({
        from: walletAddress,
        gas: gasLimit,
        gasPrice,
        // gas: gasLimit,
        // value: "0"
      });
      return result;
    }
  } catch (error: any) {
    console.log("Error in => claimOldTokens", error);
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const claimRewards = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );
    const contractAddress = STAKINGdata[0]?.address;
    const contractABI: any[] = JSON.parse(JSON.stringify(STAKINGdata[0]?.abi));
    const functionName = "claimReward";
    const fromAddress = walletAddress;

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      fromAddress,
      value: BigInt("0"),
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data.blockHash && data.transactionHash) {
      const returnData = {
        status: true,
        hash: result.hash,
      };

      return returnData;
    }
    return {
      status: false,
    };
    // let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
    //   STAKINGdata[0]?.address,
    //   JSON.parse(JSON.stringify(STAKINGdata[0]?.abi))
    // );

    // if (contract) {

    //   const result = await contract?.methods?.claimReward().send({
    //     from: walletAddress,
    //     value: "0",
    //     gasPrice,
    //   });
    //   return result;
    // }
  } catch (error: any) {
    console.log("Error in => claimRewards", error);
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const stakeShidoRewards = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );

    // let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
    //   STAKINGdata[0]?.address,
    //   JSON.parse(JSON.stringify(STAKINGdata[0]?.abi))
    // );
    // if (contract) {
    //   const gasLimit = await contract.methods?.stakeShidoReward().estimateGas({
    //     from: walletAddress,
    //     value: "0",
    //   });
    //   const result = await contract?.methods?.stakeShidoReward().send({
    //     from: walletAddress,
    //     gas: gasLimit,
    //     gasPrice,
    //     // gas: gasLimit,
    //     value: "0",
    //   });
    //   return result;
    // }

    const contractAddress = STAKINGdata[0]?.address;
    const contractABI: any[] = JSON.parse(JSON.stringify(STAKINGdata[0]?.abi));
    const functionName = "stakeShidoReward";
    const fromAddress = walletAddress;
    const args: any = [];

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      fromAddress,
      args,
      value: BigInt("0"),
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data.blockHash && data.transactionHash) {
      const returnData = {
        status: true,
        hash: result.hash,
      };

      return returnData;
    }
    return {
      status: false,
    };
  } catch (error: any) {
    console.log("Error in => stakeShidoRewards", error);
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));
    store.dispatch(setSwapTransactionLoading(false));

    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const withdrawUserStakings = async (
  walletAddress: any,
  amount: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const { gasPrice } = await getGasCalculations();

    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol === STAKING_VERSION,
    );

    const contractAddress = STAKINGdata[0]?.address;
    const contractABI: any[] = JSON.parse(JSON.stringify(STAKINGdata[0]?.abi));
    const functionName = "withdraw";
    const fromAddress = walletAddress;
    const args: any = [amount];

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      fromAddress,
      args,
      value: BigInt("0"),
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data.blockHash && data.transactionHash) {
      const returnData = {
        status: true,
        hash: result.hash,
      };

      return returnData;
    }
    return {
      status: false,
    };

    // let contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
    //   STAKINGdata[0]?.address,
    //   JSON.parse(JSON.stringify(STAKINGdata[0]?.abi))
    // );
    // if (contract) {
    //   // check why gas limit for withdraw is failing
    //   // const gasLimit = await contract?.methods?.withdraw(amount).estimateGas({
    //   //   from: walletAddress,
    //   //   value: 0,
    //   // });

    //   const result = await contract?.methods?.withdraw(amount).send({
    //     from: walletAddress,
    //     // using static gas value because its failing above
    //     gas: 9322,
    //     gasPrice,
    //   });
    //   return result;
    // }
  } catch (error: any) {
    console.log("Error in => withdrawUserStakings", error);
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const emergencyExit = async (
  walletAddress: any,
  amount: any,
  STAKING_VERSION: STAKING_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const STAKINGdata: any = contractCollection.filter(
      (a: any) => a.symbol == STAKING_VERSION,
    );

    const contractAddress = STAKINGdata[0]?.address;
    const contractABI: any[] = JSON.parse(JSON.stringify(STAKINGdata[0]?.abi));
    const functionName = "emergencyExit";
    const fromAddress = walletAddress;
    const args = [amount];

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      fromAddress,
      args,
      value: BigInt("0"),
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data.blockHash && data.transactionHash) {
      const returnData = {
        status: true,
        hash: result.hash,
      };

      return returnData;
    }
    return {
      status: false,
    };
  } catch (error: any) {
    console.log("Error in => emergencyExit", error);
    store.dispatch(setSwapTransactionLoading(false));

    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const getAllowanceStakingContract = async (
  walletAddress: any,
  STAKING_VERSION: STAKING_VERSIONS,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    // Here we are using contractCollection for token collection as the staking token
    // will be present in contract collection
    const tokenCollection =
      store.getState().contractCollection.contractCollection;

    const TOKENdata: any = tokenCollection.filter(
      (a: any) => a.symbol === STAKING_TOKEN_VERSION,
    );

    const ROUTERdata: any = contractCollection.filter(
      (a: any) => a.symbol === STAKING_VERSION,
    );

    let contract = await commonAbiInstancesForCallFunctions(
      TOKENdata[0]?.address,
      JSON.parse(JSON.stringify(TOKENdata[0]?.abi)),
    );

    if (contract) {
      const result: any = BigNumber.from(
        await contract.methods
          .allowance(walletAddress, ROUTERdata[0]?.address)
          .call(),
      );

      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getAllowanceStakingContract", error);
    return error;
  }
};

export const getAllowanceLockingContract = async (
  walletAddress: any,
  LOCKING_VERSION: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    // Here we are using contractCollection for token collection as the staking token
    // will be present in contract collection
    const tokenCollection =
      store.getState().contractCollection.contractCollection;
    const TOKENdata: any = tokenCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const ROUTERdata: any = contractCollection.filter(
      (a: any) => a.symbol == LOCKING_VERSION,
    );
    let contract = await commonAbiInstancesForCallFunctions(
      TOKENdata[0]?.address,
      JSON.parse(JSON.stringify(TOKENdata[0]?.abi)),
    );
    if (contract) {
      const result: any = await contract.methods
        .allowance(walletAddress, ROUTERdata[0]?.address)
        .call();
      if (result) {
        return result;
      }
    }
  } catch (error) {
    console.log("Error in => getAllowanceStakingContract", error);
    return error;
  }
};

export const GetApprovalLockingContract = async (
  walletAddress: any,
  LOCKING_VERSION: any,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
  inputAmout: any,
) => {
  try {
    // Here we are using contractCollection for token collection as the staking token
    // will be present in contract collection
    const { gasPrice } = await getGasCalculations();

    const tokenCollection =
      store.getState().contractCollection.contractCollection;
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    // console.log("REACHH");
    const tokenNamedata: any = tokenCollection.filter(
      (a: any) => a.symbol == STAKING_TOKEN_VERSION,
    );
    const ROUTERdata: any = contractCollection.filter(
      (a: any) => a.symbol == LOCKING_VERSION,
    );
    const contract = await commonAbiInstancesForSendFunctionsAutoGasPrice(
      tokenNamedata[0]?.address,
      JSON.parse(JSON.stringify(tokenNamedata[0]?.abi)),
    );

    if (contract) {
      let limit: any = inputAmout;
      let gasLimit = await contract.methods
        .approve(ROUTERdata[0]?.address, limit)
        .estimateGas({
          from: walletAddress,
        });

      const result: any = await contract.methods
        .approve(ROUTERdata[0]?.address, limit)
        .send({
          from: walletAddress,
          // gas: gasLimit
          gas: gasLimit,
          gasPrice,
        });
      return result;
    }
  } catch (error: any) {
    console.log("Error in => GetApprovalStakingContract", error);

    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("execution reverted")) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else if (error?.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User denied transaction",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Approval failed, please try again",
        }),
      );
    }
    // let msg = error.message.split(",")[1];
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error?.message?.includes("MetaMask") || isMobile ? error?.message : msg
    // }));
    return error;
  }
};

export const GetApprovalStakingContract = async (
  walletAddress: any,
  amount: any,
  STAKING_VERSION: STAKING_VERSIONS,
  STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
) => {
  try {
    // Here we are using contractCollection for token collection as the staking token
    // will be present in contract collection

    const tokenCollection =
      store.getState().contractCollection.contractCollection;
    const contractCollection =
      store.getState().contractCollection.contractCollection;

    const tokenNamedata: any = tokenCollection.filter(
      (a: any) => a.symbol === STAKING_TOKEN_VERSION,
    );
    const ROUTERdata: any = contractCollection.filter(
      (a: any) => a.symbol === STAKING_VERSION,
    );

    const contractAddress = tokenNamedata[0]?.address;
    const contractABI: any[] = JSON.parse(
      JSON.stringify(tokenNamedata[0]?.abi),
    );
    const functionName = "approve";
    const args: any[] = [ROUTERdata[0]?.address, amount];
    const fromAddress = walletAddress;

    const result = await callContractMethod({
      contractAddress,
      contractABI,
      functionName,
      args,
      fromAddress,
      // gasPrice,
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    if (data) {
      const returnData = {
        status: true,
      };

      return returnData;
    }

    return {
      status: false,
    };
  } catch (error: any) {
    console.log("Error in => GetApprovalStakingContract", error);
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("execution reverted")) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else if (error?.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User denied transaction",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Approval failed, please try again",
        }),
      );
    }
    // let msg = error.message.split(",")[1];
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error?.message?.includes("MetaMask") || isMobile ? error?.message : msg
    // }));
    return error;
  }
};
