import { switchNetwork } from "@wagmi/core";
import { Card, Col, Row, Tooltip } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { erc20ABI, useAccount, useNetwork } from "wagmi";
import Web3 from "web3";
import { DownIcon, DownIcon2 } from "../../../Assets/Svg/SvgImages";
import { colors } from "../../../Assets/Theme/colors";
import { FROM_CHAIN, TO_CHAIN } from "../../../Constants/TYPES/crossChainTypes";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  setFromChainCross,
  setFromChainIdCross,
  setToChainCross,
  setToChainIdCross,
} from "../../../redux/reducers/swapDataCrossChain/swapDataCrossChain";
import store from "../../../redux/store";
import {
  balanceFormatWithoutRoundOffCrypto,
  decimalFormatWithRoundOffDollar,
} from "../../../services/helpers/swapHelpers";
import { fromReadableAmount, toReadableAmount } from "../../../utils";
import { PrimaryButton } from "../../Button";
import SelectTokenModalCrossChain from "../../Common/CommonModals/SelectTokenModalCrossChain/SelectTokenModalCrossChain";
import ConnectWallet from "../../Common/ConnectWallet/ConnectWallet";
import CustomShimmer from "../../Common/CustomShimmer/CustomShimmer";
import SelectBlockchain from "../../Common/SelectBlockchain/SelectBlockchain";
import SelectChain from "../../Common/SelectBlockchain/SelectChain";
import CrossChainRoute from "../../Common/SwapButtonCrossChain/CrossChainRoute";
import SwapButtonCrossChain from "../../Common/SwapButtonCrossChain/SwapButtonCrossChain";
import TradeCardTabs from "../../Common/TradeCards/TradeCardTabs";
import { HeaderText, Text } from "../../Text";
import useCookieReset from "./useCookieReset";

const integratorId = "shido-dex-d3e760eb-2fd4-4b8a-8c33-3f0503c11955";
const CrossChainSwap2 = () => {
  useCookieReset();

  // new
  const [chain1SwapTokens, setChain1SwapTokens] = useState<any>([]);
  const [chain2SwapTokens, setChain2SwapTokens] = useState<any>([]);
  const [fromToken, setFromToken] = useState<any>("");
  const [toToken, setToToken] = useState<any>("");
  const [fromAmount, setFromAmount] = useState(0.0);
  const [toAmount, setToAmount] = useState<any>(0.0);
  const { address, connector } = useAccount();
  const [debouncedFromAmount, setDebouncedFromAmount] = useState(0);
  const fromAmountDebounce = useDebounce(fromAmount, 800);
  const [routeError, setRouteError] = useState("");
  const [minAmountError, setMinAmountError] = useState("");
  const [loading, setLoading] = useState(false);
  const [tradeDataLoading, setTradeDataLoading] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [token1DollarValue, setToken1DollarValue] = useState(1);
  const [token2DollarValue, setToken2DollarValue] = useState(0);
  const [token1Balance, setToken1Balance] = useState(0);
  const [route, setRoute] = useState<any>(null);
  const { chain } = useNetwork();

  const [correctChainConnected, setCorrectChainConnected] =
    useState<boolean>(true);

  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);

  const handleShow1 = () => setShowModal1(true);
  const handleShow2 = () => setShowModal2(true);

  const dispatch = useDispatch();

  const fromChainValue: any = useSelector(
    (state: any) => state.swapDataCrossChain.fromChainCross,
  );
  const toChainValue: any = useSelector(
    (state: any) => state.swapDataCrossChain.toChainCross,
  );

  // Function to get the optimal route for the swap using Squid API
  const getRoute = async (params: any) => {
    try {
      const result = await axios.get("https://api.0xsquid.com/v1/route", {
        params,
        headers: {
          "x-integrator-id": integratorId,
          "Content-Type": "application/json",
        },
      });
      const requestId = result.headers["x-request-id"]; // Retrieve request ID from response headers
      return { data: result.data, requestId: requestId };
    } catch (error: any) {
      if (error.response) {
        console.error("API error:", error.response.data);
      }
      console.error("Error with parameters:", params);
      throw error;
    }
  };

  const getTokenPrice = async (params: any) => {
    try {
      const result = await axios.get("https://api.0xsquid.com/v1/token-price", {
        params,
        headers: {
          "x-integrator-id": integratorId,
          "Content-Type": "application/json",
        },
      });
      // Retrieve request ID from response headers
      return result.data.price ? result.data.price : 0;
    } catch (error: any) {
      if (error.response) {
        console.error("API error:", error.response.data);
      }
      console.error("Error with parameters:", params);
      return 0;
    }
  };

  // Function to get the status of the transaction using Squid API
  const getStatus = async (params: any) => {
    try {
      const result = await axios.get("https://api.0xsquid.com/v1/status", {
        params: {
          transactionId: params.transactionId,
          requestId: params.requestId,
          fromChainId: params.fromChainId,
          toChainId: params.toChainId,
        },
        headers: {
          "x-integrator-id": integratorId,
        },
      });
      return result.data;
    } catch (error: any) {
      if (error.response) {
        console.error("API error:", error.response.data);
      }
      console.error("Error with parameters:", params);
      throw error;
    }
  };

  const fetchSwapTokens = async (chain_id: string) => {
    try {
      const result = await axios.get(
        `https://api.0xsquid.com/v1/tokens?chainId=${chain_id}`,
        {},
      );

      return result.data.tokens;
    } catch (error: any) {
      if (error.response) {
        console.error("API error:", error.response.data);
      }

      throw error;
    }
  };

  const init = async () => {
    setLoading(true);
    const [swapTokens1, swapTokens2] = await Promise.all([
      fetchSwapTokens(fromChainValue),
      fetchSwapTokens(toChainValue),
    ]);

    setChain1SwapTokens(swapTokens1);
    setChain2SwapTokens(swapTokens2);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [fromChainValue, toChainValue]);

  useEffect(() => {
    if (chain1SwapTokens && chain1SwapTokens.length > 0) {
      if (fromChainValue === 56) {
        let defaultFromToken = chain1SwapTokens.find(
          (token: any) => token.symbol === "BNB",
        );

        if (!defaultFromToken) {
          defaultFromToken = chain1SwapTokens[0];
        }
        setFromToken(defaultFromToken);
      } else if (fromChainValue === 1) {
        let defaultFromToken = chain1SwapTokens.find(
          (token: any) => token.symbol === "ETH",
        );

        if (!defaultFromToken) {
          defaultFromToken = chain1SwapTokens[0];
        }
        setFromToken(defaultFromToken);
      } else {
        setFromToken(chain1SwapTokens[0]);
      }
    }
  }, [chain1SwapTokens]);

  useEffect(() => {
    if (chain2SwapTokens && chain2SwapTokens.length > 0) {
      if (toChainValue === 56) {
        let defaultFromToken = chain2SwapTokens.find(
          (token: any) => token.symbol === "BNB",
        );

        if (!defaultFromToken) {
          defaultFromToken = chain2SwapTokens[0];
        }
        setToToken(defaultFromToken);
      } else if (toChainValue === 1) {
        let defaultFromToken = chain1SwapTokens.find(
          (token: any) => token.symbol === "ETH",
        );

        if (!defaultFromToken) {
          defaultFromToken = chain2SwapTokens[0];
        }
        setToToken(defaultFromToken);
      } else {
        setToToken(chain2SwapTokens[0]);
      }
    }
  }, [chain2SwapTokens]);

  const handleChangeOne = async (inputValue: any) => {
    const value = String(inputValue);

    let values: any;

    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;

    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (value?.length <= maxLength) {
      values = value;
    } else if (value?.length > maxLength) {
      return;
    }

    if (
      values?.toString().charAt(0) == "." ||
      values?.toString().charAt(0) == ","
    ) {
      values = "0" + values;
    }

    setFromAmount(values.replace(",", "."));
  };

  useEffect(() => {
    setDebouncedFromAmount(fromAmount);
  }, [fromAmountDebounce]);

  useEffect(() => {
    const getPrice = async () => {
      setTradeDataLoading(true);

      const params = {
        chainId: fromChainValue,
        tokenAddress: fromToken.address,
      };

      const price = await getTokenPrice(params);
      setToken1DollarValue(price);
      setTradeDataLoading(false);
    };

    if (fromToken) {
      getPrice();
    }
  }, [fromToken]);

  useEffect(() => {
    const getBalance = async () => {
      const web3 = new Web3(await connector?.getProvider());

      if (
        String(fromToken.address).toLowerCase() ===
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
      ) {
        // Handle native token balance
        const nativeBalance: string = await web3.eth.getBalance(
          address ? address : "",
        );

        setToken1Balance(Number(nativeBalance) / 10 ** fromToken.decimals);
      } else {
        // @ts-ignore
        const contract = new web3.eth.Contract(erc20ABI, fromToken.address);
        const userBalance = await contract.methods.balanceOf(address).call();
        setToken1Balance(Number(userBalance) / 10 ** fromToken.decimals);
      }
    };

    if (fromToken && connector && address) {
      getBalance();
    }
  }, [fromToken, connector, address]);

  useEffect(() => {
    setDebouncedFromAmount(fromAmount);
  }, [fromAmountDebounce]);

  useEffect(() => {
    const checkRoute = async () => {
      setRouteError("");
      setLoading(true);

      const params = {
        fromAddress: address,
        fromChain: fromChainValue,
        fromToken: fromToken.address,
        fromAmount: fromReadableAmount(fromAmountDebounce, fromToken.decimals),
        toChain: toChainValue,
        toToken: toToken.address,
        toAddress: address,
        slippage: 10,
        slippageConfig: {
          autoMode: 1,
        },
      };

      const routeData = await getRoute(params);

      if (routeData.data.errors) {
        setRouteError(routeData.data.errors[0].message);
        setLoading(false);
        setToken2DollarValue(0.0);
        setRoute(null);
        return;
      }

      setRoute(routeData.data.route);
      setRouteError("");
      setLoading(false);
    };
    if (address && debouncedFromAmount > 0) checkRoute();
  }, [debouncedFromAmount, fromToken, toToken]);

  useEffect(() => {
    if (route) {
      setToAmount(toReadableAmount(route.estimate.toAmount, toToken.decimals));
      setToken2DollarValue(Number(route.estimate.toAmountUSD));
    }
  }, [route]);

  useEffect(() => {
    if (token1DollarValue > 0) {
      if (Number(token1DollarValue) * fromAmount < 5) {
        setMinAmountError("Minimum of $5 required to complete transaction");
      } else {
        setMinAmountError("");
      }
    } else {
      setMinAmountError("");
    }
  }, [fromAmount, token1DollarValue]);

  const switchTokens = async () => {
    const fromChainId: string =
      store.getState().swapDataCrossChain.fromChainIdCross;
    const toChainId: string =
      store.getState().swapDataCrossChain.toChainIdCross;

    dispatch(setFromChainCross(toChainValue));
    dispatch(setToChainCross(fromChainValue));
    dispatch(setFromChainIdCross(toChainId));
    dispatch(setToChainIdCross(fromChainId));

    setFromToken(toToken);
    setToToken(fromToken);
  };

  useEffect(() => {
    if (chain && chain.id === fromChainValue) {
      setCorrectChainConnected(true);
    } else if (chain) {
      switchNetwork({ chainId: fromChainValue });
      setCorrectChainConnected(false);
    }
  }, [fromChainValue, chain]);

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={9}>
          <Card>
            <TradeCardTabs />
            <div className="YouSellBuyCard">
              {address && (
                <Row justify={"end"} gutter={6}>
                  <Col>
                    <Text>Balance: </Text>
                  </Col>
                  {token1Balance && (
                    <Col>
                      <Tooltip
                        title={balanceFormatWithoutRoundOffCrypto(
                          token1Balance,
                        )}
                      >
                        <Text>
                          {balanceFormatWithoutRoundOffCrypto(token1Balance)}
                        </Text>
                      </Tooltip>
                    </Col>
                  )}
                  <Col>
                    <PrimaryButton
                      size={"small"}
                      // onClick={() => enterInputOneMax(tokenOneDisplayBalance)}
                    >
                      MAX
                    </PrimaryButton>
                  </Col>
                </Row>
              )}
              {/* Token 1 Select */}
              <Row
                align={"middle"}
                justify={"start"}
                gutter={6}
                style={{ margin: "0.7em 0" }}
              >
                <Col xs={4} sm={2} md={3} style={{ textAlign: "left" }}>
                  <Text>From</Text>
                </Col>
                <Col xs={20} sm={20} xl={15}>
                  <SelectChain type={FROM_CHAIN} />
                </Col>
              </Row>

              <div className="Swap_You_Cards" style={{ marginBottom: "0.5em" }}>
                <div className="d-flex justify-content-between">
                  <Row
                    align={"middle"}
                    gutter={4}
                    className={"swap_from_content"}
                  >
                    <Col>
                      <img
                        className="SwapIcon"
                        src={fromToken?.logoURI}
                        alt="icons"
                      />
                    </Col>
                    <Col style={{ display: "grid", justifyItems: "start" }}>
                      <Text fontSize={12} color={colors.secondary500}>
                        Swap from
                      </Text>
                      <Row align={"middle"} gutter={8}>
                        <Col>
                          <HeaderText
                            fontSize={18}
                            className={"clickable"}
                            onClick={handleShow1}
                          >
                            {fromToken?.symbol}
                            {"  "}
                          </HeaderText>
                        </Col>
                        <Col className={"swap-svg"}>
                          <DownIcon />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="swap_from_price d-flex align-items-center">
                    <div className="swap_from_content text-end ms-3">
                      <input
                        onKeyDown={(evt) => {
                          evt.code === "ArrowDown" && evt.preventDefault();
                          evt.code === "ArrowUp" && evt.preventDefault();
                          // disabledLetters.includes(evt.key) &&
                          //   evt.preventDefault();
                        }}
                        type="text"
                        inputMode="decimal"
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e) => handleChangeOne(e.target.value)}
                        value={fromAmount}
                        className="form-control"
                        placeholder="0.00"
                      />

                      <Row align={"middle"} gutter={4} justify={"end"}>
                        <Col>
                          <Text fontSize={12} color={colors.secondary500}>
                            ~$
                          </Text>
                        </Col>
                        <Col>
                          {tradeDataLoading || !fromToken ? (
                            <div className="height_adjust">
                              <CustomShimmer width={60} height={15} light />
                            </div>
                          ) : (
                            <Text fontSize={12} color={colors.secondary500}>
                              {decimalFormatWithRoundOffDollar(
                                token1DollarValue * Number(fromAmount),
                              )}
                            </Text>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <Row justify={"center"}>
                <div
                  className="cross-swap_value_change"
                  onClick={() => switchTokens()}
                >
                  <DownIcon2 />
                </div>
              </Row>
              {/* Token 2 Select */}

              <Row
                align={"middle"}
                justify={"start"}
                gutter={6}
                style={{ margin: "0.7em 0" }}
              >
                <Col xs={4} sm={2} md={3} style={{ textAlign: "left" }}>
                  <Text>To</Text>
                </Col>
                <Col xs={20} sm={20} xl={15}>
                  <SelectChain type={TO_CHAIN} />
                </Col>
              </Row>

              <div
                className="Swap_You_Cards"
                // style={{
                //   marginBottom:
                //     Number(token1DollarValue) < 5 || lowInputError
                //       ? "1em"
                //       : "1.5em",
                // }}
              >
                <div className="d-flex justify-content-between">
                  <Row
                    align={"middle"}
                    gutter={4}
                    className={"swap_from_content"}
                  >
                    <Col>
                      <img
                        className="SwapIcon"
                        src={toToken?.logoURI}
                        alt="icons"
                      />
                    </Col>
                    <Col style={{ display: "grid", justifyItems: "start" }}>
                      <Text fontSize={12} color={colors.secondary500}>
                        Swap to
                      </Text>
                      <Row align={"middle"} gutter={8}>
                        <Col>
                          <HeaderText
                            fontSize={18}
                            className={"clickable"}
                            onClick={handleShow2}
                          >
                            {toToken?.symbol}
                            {"  "}
                          </HeaderText>
                        </Col>
                        <Col className={"swap-svg"}>
                          <DownIcon />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="swap_from_price d-flex align-items-center">
                    <div className="swap_from_content text-end ms-3">
                      {!route ? (
                        <div className="height_adjust">No Route</div>
                      ) : (
                        <input
                          disabled
                          value={toAmount}
                          type="text"
                          className="form-control"
                          placeholder="0.00"
                        />
                      )}

                      <Row align={"middle"} gutter={4} justify={"end"}>
                        <Col>
                          <Text fontSize={12} color={colors.secondary500}>
                            ~$
                          </Text>
                        </Col>
                        <Col>
                          {loading || !toToken ? (
                            <div className="height_adjust">
                              <CustomShimmer width={60} height={15} light />
                            </div>
                          ) : (
                            <Text fontSize={12} color={colors.secondary500}>
                              {decimalFormatWithRoundOffDollar(
                                token2DollarValue,
                              )}
                            </Text>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {address ? (
              <SwapButtonCrossChain
                userTokenOneBalance={token1Balance}
                routeError={routeError}
                loading={loading}
                minAmountError={minAmountError}
                tokenSelect1={fromToken}
                tokenSelect2={toToken}
                input1Amount={fromAmount}
                input2Amount={toAmount}
                receiverAddress={address}
                receiverAddressInvalid={false}
                receiverAddressEmpty={false}
                selectedRoute={route}
                fromChainValue={fromChainValue}
                toChainValue={toChainValue}
                token1DollarValue={token1DollarValue}
                token2DollarValue={token2DollarValue}
              />
            ) : !address ? (
              <SelectBlockchain
                useAsButton
                switchToNetwork={{ value: fromChainValue }}
                className="select-chain"
              />
            ) : (
              <ConnectWallet className="w-100" />
            )}

            {routeError && routeError.length > 0 && (
              <Card
                style={{
                  backgroundColor: `${colors.error}15`,
                  borderRadius: 8,
                  marginBottom: "1em",
                }}
                className={"card-sm-padding"}
              >
                <Text color={colors.error}>{routeError}</Text>
              </Card>
            )}

            {minAmountError && minAmountError.length > 0 && (
              <Card
                style={{
                  backgroundColor: `${colors.error}15`,
                  borderRadius: 8,
                  marginBottom: "1em",
                  marginTop: "1em",
                }}
                className={"card-sm-padding"}
              >
                <Text color={colors.error}>{minAmountError}</Text>
              </Card>
            )}

            <SelectTokenModalCrossChain
              show={showModal1}
              handleClose={handleClose1}
              type={"tk1"}
              chain={fromChainValue}
              tokens={chain1SwapTokens}
              selectedItem={fromToken}
              selectItem={setFromToken}
              // setSelectedRoute={setSelectedRoute}
              // setRoutes={setRoutes}
              disableToken={toToken}
            />

            <SelectTokenModalCrossChain
              show={showModal2}
              handleClose={handleClose2}
              type={"tk1"}
              chain={toChainValue}
              tokens={chain2SwapTokens}
              selectedItem={toToken}
              selectItem={setToToken}
              // setSelectedRoute={setSelectedRoute}
              // setRoutes={setRoutes}
              disableToken={fromToken}
            />
          </Card>
        </Col>
        <Col xs={24} lg={15}>
          <CrossChainRoute
            selectedRoute={route}
            fromChainValue={fromChainValue}
            toChainValue={toChainValue}
            fromToken={fromToken}
            toToken={toToken}
          />
        </Col>
      </Row>
      {/* <AddTokenModalCrossChain show={show} handleClose={handleClose} /> */}
    </>
  );
};

export default CrossChainSwap2;
