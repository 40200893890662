import { Row } from "antd";
import { ShidoTvl } from "./Graphs/ShidoTvl";
import { ShidoVolume } from "./Graphs/ShidoVolume";
import { ExploreHero } from "./Hero/ExploreHero";
import { ShidoTokens } from "./Table/ShidoToken";

const Explore = () => {
  return (
    <Row gutter={[0, 20]}>
      <ExploreHero />
      <Row
        style={{ width: "100%" }}
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
          { xs: 20, sm: 20, md: 20, lg: 20, xl: 0 },
        ]}
      >
        <ShidoTvl />
        <ShidoVolume />
      </Row>
      <ShidoTokens />
    </Row>
  );
};

export default Explore;
