import { ApolloProvider } from "@apollo/client";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import "antd/dist/reset.css";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { WagmiConfig } from "wagmi";
import "./Assets/scss/App.scss";
import { GqClient } from "./graphql";
import store from "./redux/store";

import {
  arbitrum,
  avalanche,
  bsc,
  bscTestnet,
  mainnet,
  polygon,
} from "wagmi/chains";
// @ts-ignore
import { Col, Row } from "antd";
import {
  CHAINGE_CHAIN_AVALANCHE,
  CHAINGE_CHAIN_BSC,
  CHAINGE_CHAIN_ETH,
  CHAINGE_CHAIN_FANTOM,
  CHAINGE_CHAIN_POLYGON,
} from "./Constants/TYPES/crossChainTypes";
import Main from "./Main";

let persistor = persistStore(store);

const projectId = "06cd73c01555f937a6963abee3431312";
const metadata = {
  name: "Unlock your crypto potential with SHIDO",
  description:
    "Embark on your crypto adventure – connect your wallet now and experience seamless swaps, secure stakes, and a world of financial empowerment on SHIDO.",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const environment = process.env.REACT_APP_NODE_ENV;

// 2. Configure wagmi client
let chains: any[] = [];
if (environment === "production") {
  chains = [mainnet, bsc, polygon, avalanche, arbitrum];
} else if (environment === "qa" || environment === "development") {
  chains = [mainnet, bsc, polygon, avalanche, arbitrum, bscTestnet];
}
const getChainName = (fromChainValue: number) => {
  if (fromChainValue === 1) {
    // for_more_chains
    return CHAINGE_CHAIN_ETH;
  } else if (fromChainValue === 56 || fromChainValue === 97) {
    return CHAINGE_CHAIN_BSC;
  } else if (fromChainValue === 137) {
    return CHAINGE_CHAIN_POLYGON;
  } else if (fromChainValue === 43114) {
    return CHAINGE_CHAIN_AVALANCHE;
  } else if (fromChainValue === 42161) {
    return "arbitrum";
  } else if (fromChainValue === 250) {
    return CHAINGE_CHAIN_FANTOM;
  }
};

export const supportedChains = chains.map((chain: any) => {
  return {
    ...chain,
    value: chain.id,
    img: "/" + chain.id + ".svg",
    openName: getChainName(chain.id),
    label: (
      <Row
        justify={"start"}
        align={"middle"}
        gutter={4}
        className={"blockchain-options"}
      >
        <Col>
          <img src={"/chains/" + chain.id + ".svg"} alt="eth" />
        </Col>
        <Col>
          <span>{chain.name}</span>
        </Col>
      </Row>
    ),
  };
});
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  useEffect(() => {
    const currentVersion = "1.0.1";
    const storedVersion = localStorage.getItem("appVersion");

    if (storedVersion !== currentVersion) {
      localStorage.clear();
      //   @ts-ignore
      localStorage.setItem("appVersion", currentVersion);
    }
  }, []);
  return (
    <div className={"App"}>
      <WagmiConfig config={wagmiConfig}>
        <ApolloProvider client={GqClient}>
          <PersistGate loading={null} persistor={persistor}>
            <Provider store={store}>
              <Main />
            </Provider>
          </PersistGate>
        </ApolloProvider>
      </WagmiConfig>
    </div>
  );
}

export const storeInstance = store;

export default App;
