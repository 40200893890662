import { Card, Carousel, Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import arrowDown from "../../../../Assets/Images/arrowDown.svg";
import crossChain from "../../../../Assets/Images/crosschain.png";
import limitOrderImg from "../../../../Assets/Images/limitorder.png";
import perpetualImage from "../../../../Assets/Images/rocket.png";
import swapperArrows from "../../../../Assets/Images/swap.png";
import { Text } from "../../../Text";
import { Trade } from "./Trade";
import "./tradeMain.scss";

export const TradeMain = () => {
  const { md, lg, sm, xs, xl } = useBreakpoint();

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  const CardSlider = () => {
    return (
      <Carousel afterChange={onChange}>
        <Row>
          <Col span={24}>
            <Trade
              text="Staking"
              img={swapperArrows}
              alt="swapper-arrows"
              paragraph="Swap your favorite assets and make use of many different liquidity sources!"
              navigateTo="/simple-mode"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Trade
              text="Cross Chain"
              img={crossChain}
              alt="cross-chain"
              paragraph="Swap your asset from one chain to the other using the SHIDO Crosschain Swap!"
              navigateTo="/cross-chain-swap"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Trade
              text="Limit Orders"
              img={limitOrderImg}
              alt="limit-orders"
              paragraph="Set and forget! Target your price to execute, sit back and wait until your order executes!"
              navigateTo="/limit-order"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Trade
              text="Perpetual"
              img={perpetualImage}
              alt="perpetuals"
              paragraph="Are you an experienced trader? Trade your assets with leverage!"
              navigateTo="https://perp.shido.io/"
            />
          </Col>
        </Row>
      </Carousel>
    );
  };

  return (
    <Col
      xs={24}
      lg={24}
      style={{ height: "auto" }}
      className="trade_info trade_info-col"
    >
      <Card className="trade_card">
        <Row justify={"center"}>
          <Col span={24} className="trade_info-item-col">
            <img src={arrowDown} />
          </Col>
          <Col span={24} className="heading trade_info-item-col">
            <Text
              fontSize={40}
              fontWeight={600}
              style={{ lineHeight: "normal" }}
              className="trade-heading"
            >
              Start Trading
            </Text>
          </Col>
          <Col
            xs={24}
            sm={20}
            md={20}
            lg={20}
            xl={12}
            style={{ marginTop: "15px" }}
            className="info-text trade_info-item-col"
          >
            <Text
              fontSize={16}
              fontWeight={400}
              style={{ lineHeight: "normal" }}
              color="#9B9CA3"
            >
              Ready to dive in? Connect Wallet and embark on a journey of
              trading, staking, and rewarding experiences with SHIDO. Your
              financial future begins here.
            </Text>
          </Col>
          <Col span={24} className="shido-images trade_info-item-col">
            {lg ? (
              <Row gutter={[20, 20]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="blocks"
                >
                  <Trade
                    text="Staking"
                    img={swapperArrows}
                    alt="swapper-arrows"
                    paragraph="Swap your favorite assets and make use of many different liquidity sources!"
                    navigateTo="/simple-mode"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="blocks"
                >
                  <Trade
                    text="Cross Chain"
                    img={crossChain}
                    alt="cross-chain"
                    paragraph="Swap your asset from one chain to the other using the SHIDO Crosschain Swap!"
                    navigateTo="/cross-chain-swap"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="blocks"
                >
                  <Trade
                    text="Limit Orders"
                    img={limitOrderImg}
                    alt="limit-orders"
                    paragraph="Set and forget! Target your price to execute, sit back and wait until your order executes!"
                    navigateTo="/limit-order"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="blocks"
                >
                  <Trade
                    text="Perpetuals"
                    img={perpetualImage}
                    alt="perpetuals"
                    paragraph="Are you an experienced trader? Trade your assets with leverage!"
                    navigateTo="https://perp.shido.io/"
                  />
                </Col>
              </Row>
            ) : md ? (
              <CardSlider />
            ) : sm ? (
              <CardSlider />
            ) : (
              xs && <CardSlider />
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
