import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import addressSlice from "./reducers/address/address";
import loginSlice from "./reducers/login/login";
import networkSlice from "./reducers/network/network";
import tokenCollection from "./reducers/tokenCollection/tokenCollection";
import contractCollection from "./reducers/contractCollection/contractCollection";
import currentChainToken from "./reducers/currentChainToken/currentChainToken";
import swapData from "./reducers/swapData/swapData";
import tradeData from "./reducers/tradeData/tradeData";
import loadingData from "./reducers/loadingData/loadingData";
import walletConnectivity from "./reducers/walletConnectivity/walletConnectivity";
import swapDataCrossChain from "./reducers/swapDataCrossChain/swapDataCrossChain";
import tradeDataCrossChain from "./reducers/tradeDataCrossChain/tradeDataCrossChain";
import limitOrderData from "./reducers/limitOrderData/limitOrderData";
import { app } from "./reducers";

const rootReducer = combineReducers({
  addressSlice: addressSlice,
  loginSlice: loginSlice,
  networkSlice: networkSlice,
  tokenCollection: tokenCollection,
  contractCollection: contractCollection,
  currentChainToken: currentChainToken,
  swapData: swapData,
  limitOrderData: limitOrderData,
  swapDataCrossChain: swapDataCrossChain,
  tradeData: tradeData,
  tradeDataCrossChain: tradeDataCrossChain,
  loadingData: loadingData,
  walletConnectivity: walletConnectivity,
  app,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: any = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
