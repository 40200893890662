import { message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_OCEAN_CONTRACT_ROUTER } from "../../../Constants/AppVariables/appVariable";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../Constants/NetworkNames/NetworkNames";
import { OPEN_OCEAN_CHAIN_NAMES } from "../../../Constants/TYPES/openOceanChainNames";
import { CUSTOM, NATIVE, SWAP_TYPES } from "../../../Constants/TYPES/swapTypes";
import { SWAP_SUCCESS_DB_PAYLOAD } from "../../../Constants/swapConstants";
import {
  addUserTransaction,
  getSwapQuoteDataFunction,
  saveSwap,
} from "../../../redux/api/swap/swapAPIs";
import {
  setShowTransactionModal,
  setSwapTransactionLoading,
  setTransactionErrorMessage,
  setTransactionHash,
} from "../../../redux/reducers/loadingData/loadingData";
import { setRecentTxnHashOpenOcean } from "../../../redux/reducers/swapData/swapData";
import store from "../../../redux/store";
import {
  GetRouterApprovalOpenOcean,
  SwapByOpenOceanCustom,
  SwapByOpenOceanNative,
  getAllowance,
} from "../../../services/contractServices/SwapServices";
import { isNativeToken } from "../../../services/contractServices/tokenServices";
import { callWeb3 } from "../../../services/walletServices";
import { getMessageConfig } from "../../../utils";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import "./SwapButton.scss";

const SwapButton = ({
  className,
  opData,
  tokenSelect1,
  tokenSelect2,
  swapType,
  input1Amount,
  input2Amount,
  userTokenOneBalance,
  fetchTokenOneBalance,
  refreshTokenListBalances,
}: any) => {
  const dispatch = useDispatch();
  const waddress: string = useSelector(
    (state: any) => state.addressSlice.walletAddress,
  );
  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );
  const currentNetwork = useSelector(
    (state: any) => state.networkSlice.currentNetwork,
  );

  const proceedSwapOpenOcean = async (
    swapQuoteData: any,
    routerAddress: any,
    swapType: any,
  ) => {
    const { estimatedGas, data, gasPrice } = swapQuoteData;

    const swapParams = {
      from: waddress,
      to: routerAddress,
      gas: estimatedGas,
      gasPrice: gasPrice,
      data,
      fromtoken: tokenSelect1,
      toToken: tokenSelect2,
      chain_id: currentNetwork,
    };

    let result: any;

    if (swapType === NATIVE) {
      result = await SwapByOpenOceanNative(waddress, swapParams);
    } else if (swapType === CUSTOM) {
      result = await SwapByOpenOceanCustom(waddress, swapParams);
    }

    if (result.transactionHash) {
      // save swap
      try {
        const swapData = {
          wallet_address: waddress,
          from_token: tokenSelect1.code,
          to_token: tokenSelect2.code,
          chain_id: tokenSelect1.chainId,
          amount: `${input1Amount}`,
          transaction_hash: result.transactionHash,
        };

        await saveSwap(swapData);
      } catch (e) {
        console.log("swap not saved");
      }
    }
    if (result?.status) {
      dispatch(setTransactionHash(result?.transactionHash));
      dispatch(setSwapTransactionLoading(false));
      await fetchTokenOneBalance();
      await refreshTokenListBalances();
      message.success(
        getMessageConfig({
          type: "success",
          title: "Swap successful!",
        }),
      );

      // ADD Swap Transaction Details To DB
      const amountIn: any = JSON.stringify(
        opData?.inputAmount / 10 ** Number(tokenSelect1?.decimals),
      );
      const addTransactionPayload: any = {
        walletAddress: waddress,
        tokenFrom: opData.tokens[0],
        tokenTo: opData.tokens[1],
        amountIn: amountIn,
        txnHash: result?.transactionHash,
        txnStatus: SWAP_SUCCESS_DB_PAYLOAD,
      };

      const addUserTransactionResult = await addUserTransaction(
        addTransactionPayload,
      );

      if (addUserTransactionResult?.status === 200) {
        dispatch(setRecentTxnHashOpenOcean(addUserTransactionResult.txnHash));
      } else {
        let pendingTxns = JSON.parse(
          localStorage.getItem("AllHistory") || "[]",
        );
        pendingTxns.push(addTransactionPayload);
        localStorage.setItem("AllHistory", JSON.stringify(pendingTxns));
      }
    } else if (result?.status === false) {
      dispatch(setSwapTransactionLoading(false));
      dispatch(
        setTransactionErrorMessage(
          "Please increase your gas for this transaction.",
        ),
      );
      message.error(
        getMessageConfig({
          type: "error",
          title:
            "You are not using enough enough gas to complete this transaction!",
          body: "Please increase your gas to the required gas needed to complete this order.",
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(setSwapTransactionLoading(false));
    dispatch(setShowTransactionModal(false));
  }, []);

  const executeSwapOpenOcean = async () => {
    dispatch(setSwapTransactionLoading(true));
    dispatch(setShowTransactionModal(true));

    const tokenCollection = store.getState().tokenCollection.tokenCollection;
    const allTokens = store.getState().tokenCollection.allOpenOceantokens;

    let token1Collection = tokenCollection;
    let token2Collection = tokenCollection;

    if (tokenSelect2.symbol !== "SHIDO") {
      token2Collection = allTokens;
    }

    if (tokenSelect1.symbol !== "SHIDO") {
      token1Collection = allTokens;
    }

    const tokenDetails1: any = token1Collection.filter(
      (a: any) => a.symbol == tokenSelect1?.symbol,
    );

    const token1Data: any = tokenDetails1.map((element: any) => element.symbol);

    const isNative1: any = await isNativeToken(tokenDetails1[0].address);

    const tokenDetails2: any = token2Collection.filter(
      (a: any) => a.symbol == tokenSelect2?.symbol,
    );

    const isNative2: any = await isNativeToken(tokenDetails2[0].address);

    const routerAddress = OPEN_OCEAN_CONTRACT_ROUTER;

    let chainName: OPEN_OCEAN_CHAIN_NAMES;

    switch (currentNetwork) {
      case EHTEREUM_NETWORK:
        chainName = "eth";
        break;

      case BNB_NETWORK:
        chainName = "bsc";
        break;

      case POLYGON_NETWORK:
        chainName = "polygon";
        break;

      case AVALANCHE_NETWORK:
        chainName = "avax";
        break;

      case ARBITRUM_NETWORK:
        chainName = "arbitrum";
        break;

      case FANTOM_NETWORK:
        chainName = "fantom";
        break;

      default:
        break;
    }

    let preparedInTokenAddress;
    let preparedOutTokenAddress;

    if (isNative1) {
      preparedInTokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
      preparedOutTokenAddress = tokenDetails2[0].address;
    } else if (isNative2) {
      preparedInTokenAddress = tokenDetails1[0].address;
      preparedOutTokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
    } else {
      preparedInTokenAddress = tokenDetails1[0].address;
      preparedOutTokenAddress = tokenDetails2[0].address;
    }

    // const userSelectedGasPrice = store.getState().swapData.userSelectedGas;
    const slippageTolerance = store.getState().swapData.slippageTolerance;

    const web3 = await callWeb3();
    const gasPriceResult = await web3.eth.getGasPrice();
    const estimatedGasPrice = web3.utils.fromWei(gasPriceResult, "Gwei");

    const payload = {
      inTokenAddress: preparedInTokenAddress,
      outTokenAddress: preparedOutTokenAddress,
      amount: Number(input1Amount) / 10 ** tokenDetails1[0].decimals,
      gasPrice: estimatedGasPrice,
      slippage: slippageTolerance,
      account: waddress,
      referrer: "0x641F6ab42Cd252cE841eDcbd080DCD0514F8eaC0", // this is shido platform's owner address
      referrerFee: 0.5,
    };

    const swapQuoteData = await getSwapQuoteDataFunction(chainName, payload);

    let swapType: SWAP_TYPES;

    // If tokenA is native then directly proceed swap
    if (isNative1) {
      swapType = NATIVE;
      await proceedSwapOpenOcean(swapQuoteData, routerAddress, swapType);
    } else {
      swapType = CUSTOM;
      const allowanceResultA = await getAllowance(waddress, token1Data[0]);

      if (Number(allowanceResultA) >= Number(opData.inputAmount)) {
        await proceedSwapOpenOcean(swapQuoteData, routerAddress, swapType);
      } else if (Number(allowanceResultA) < Number(opData.inputAmount)) {
        let approvalResult: any = false;

        // // In case of USDT token we first have to reset the allowance to 0
        // // Then only we can increase the allowance, so metamask will popup two times

        if (token1Data[0] === "USDT") {
          // Reset USDT approval to 0
          const resetApprovalResult = await GetRouterApprovalOpenOcean(
            waddress,
            token1Data[0],
            0,
            routerAddress,
          );

          if (resetApprovalResult) {
            approvalResult = await GetRouterApprovalOpenOcean(
              waddress,
              token1Data[0],
              opData.inputAmount,
              routerAddress,
            );
          }
          // Incase of all other tokens we can directly increase the approval
        } else {
          approvalResult = await GetRouterApprovalOpenOcean(
            waddress,
            token1Data[0],
            opData.inputAmount,
            routerAddress,
          );
        }

        if (approvalResult) {
          await proceedSwapOpenOcean(swapQuoteData, routerAddress, swapType);
        }
      }
    }
  };

  const savePendingTxnsToDB = async () => {
    let pendingTxns: any = JSON.parse(
      localStorage.getItem("AllHistory") || "[]",
    );
    if (pendingTxns?.length > 0) {
      for (let i = 0; i <= pendingTxns.length; i++) {
        const addUserTransactionResult = await addUserTransaction(
          pendingTxns[i],
        );
        if (addUserTransactionResult?.status === 200) {
          localStorage.removeItem(pendingTxns[i]);
        }
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    savePendingTxnsToDB();
  }, []);

  return (
    <>
      <ButtonCustom
        fullWidth
        size={"large"}
        title={
          Number(input1Amount) > Number(userTokenOneBalance)
            ? "Insufficient Balance"
            : "Swap"
        }
        disabled={
          tradeDataLoading ||
          !input1Amount ||
          !input2Amount ||
          Number(input1Amount) > Number(userTokenOneBalance)
        }
        className={
          tradeDataLoading ||
          !input1Amount ||
          !input2Amount ||
          Number(input1Amount) > Number(userTokenOneBalance)
            ? `common-connect-btn GrayBtn ${className}`
            : `common-connect-btn ${className}`
        }
        onClick={executeSwapOpenOcean}
      />
    </>
  );
};

export default SwapButton;
