import { message } from "antd";
import { apiCallGet } from "../../../services/apiServices/ApiServices";
import { getMessageConfig } from "../../../utils";

export const getTransactions = async (address: any) => {
  try {
    const SITE_URL_HACK = "https://dev.api.shido.baboons.tech";
    const ENDPOINT = "/api/transactions/";
    const headersData = {
      "x-api-key": "9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc",
    };

    const result: any = await apiCallGet(
      SITE_URL_HACK,
      ENDPOINT,
      // {},
      { wallet_address: address },
      headersData,
    );
    if (result) {
      return result.results;
    }
  } catch (error: any) {
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};
