import { Card, Checkbox, Col, Input, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EthIcon } from "../../../../Constants/ImagesConstants";
import "./SelectTokenModal.scss";

import { SearchOutlined } from "@ant-design/icons";
import { colors } from "../../../../Assets/Theme/colors";
import { isNativeToken } from "../../../../services/contractServices/tokenServices";
import {
  getModifiedTokenName,
  getModifiedTokenSymbol,
} from "../../../../services/helpers/tokenListHelper";
import { PrimaryButton } from "../../../Button";
import { HeaderText, Text } from "../../../Text";

const SelectTokenModal = ({
  handleClose,
  show,
  tokenList,
  type,
  disableToken,
  allTokens,
  onSelectToken,
}: any) => {
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const { token1, token2 } = useSelector((state: any) => state?.swapData);
  const [searchMessage, setSearchMessage] = useState("");
  const [fetchedToken, setFetchedToken] = useState<any>();
  const [filteredTokenList, setFilteredTokenList] = useState<any>(tokenList);
  const [agreedTerms, setAgreedTerms] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (tokenList) {
      setFilteredTokenList(
        tokenList.concat(allTokens.slice(40, allTokens.length)),
      );
    }
  }, [tokenList]);

  useEffect(() => {
    setSelectedItem(type === "tk1" ? token1 : token2);
  }, [type, token1, token2]);

  const handleTokenSearch = async (userInput: any) => {
    setSearchMessage("");
    let tokensToSearch = tokenList;

    if (allTokens && allTokens.length > 0) {
      tokensToSearch = allTokens;
    }
    // 1. If user clear the input => clear searcMessage, setFetchedToken and reset setFilteredTokenList
    if (userInput?.length === 0) {
      setSearchMessage("");
      setFetchedToken("");
      setFilteredTokenList(tokenList);
    }
    // 2. If user inputs something then try to find that in the current list
    else if (userInput?.length > 0) {
      const filteredResults = tokensToSearch.filter((token: any) =>
        ["name", "address", "code", "symbol"].some((property) =>
          token[property]?.toLowerCase().includes(userInput?.toLowerCase()),
        ),
      );

      if (filteredResults) {
        setFilteredTokenList(
          filteredResults.filter(async (token: any) => {
            const res = await isNativeToken(token.address);
            return !res;
          }),
        );
      }
    }
  };

  const importToken = () => {
    // let tempCollection: any = tokenList;
    // if (tempCollection.find((e: any) => e.symbol === fetchedToken?.symbol)) {
    //   setSearchMessage("Token Already Added");
    // } else {
    //   let tempFetchedToken = fetchedToken;
    //   tempFetchedToken.isImported = true;
    //   // find current chain & according to that add token to it's importedList
    //   switch (currentNetwork) {
    //     case EHTEREUM_NETWORK:
    //       let ethCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.ETHimportedTokenList;
    //       const overallEthTokenList: any =
    //         store?.getState()?.tokenCollection?.overallEthTokenList;
    //       ethCurrentImportedTokenList = [
    //         ...ethCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(setEthImportedTokenList(ethCurrentImportedTokenList));
    //       tempCollection = [...overallEthTokenList, tempFetchedToken];
    //       dispatch(setOverallEthTokenList(tempCollection));
    //       break;
    //     case BNB_NETWORK:
    //       let bnbCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.BNBimportedTokenList;
    //       const overallBNBTokenList: any =
    //         store?.getState()?.tokenCollection?.overallBNBTokenList;
    //       bnbCurrentImportedTokenList = [
    //         ...bnbCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(setBnbImportedTokenList(bnbCurrentImportedTokenList));
    //       tempCollection = [...overallBNBTokenList, tempFetchedToken];
    //       dispatch(setOverallBNBTokenList(tempCollection));
    //       break;
    //     case POLYGON_NETWORK:
    //       let polygonCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.POLYGONimportedTokenList;
    //       const overallPOLYGONTokenList: any =
    //         store?.getState()?.tokenCollection?.overallPOLYGONTokenList;
    //       polygonCurrentImportedTokenList = [
    //         ...polygonCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(
    //         setPolygonImportedTokenList(polygonCurrentImportedTokenList)
    //       );
    //       tempCollection = [...overallPOLYGONTokenList, tempFetchedToken];
    //       dispatch(setOverallPOLYGONTokenList(tempCollection));
    //       break;
    //     case AVALANCHE_NETWORK:
    //       let avalancheCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.AVALANCHEimportedTokenList;
    //       const overallAVALANCHETokenList: any =
    //         store?.getState()?.tokenCollection?.overallAVALANCHETokenList;
    //       avalancheCurrentImportedTokenList = [
    //         ...avalancheCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(
    //         setAvalancheImportedTokenList(avalancheCurrentImportedTokenList)
    //       );
    //       tempCollection = [...overallAVALANCHETokenList, tempFetchedToken];
    //       dispatch(setOverallAVALANCHETokenList(tempCollection));
    //       break;
    //     case ARBITRUM_NETWORK:
    //       let arbitrumCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.ARBITRUMimportedTokenList;
    //       const overallARBITRUMTokenList: any =
    //         store?.getState()?.tokenCollection?.overallARBITRUMTokenList;
    //       arbitrumCurrentImportedTokenList = [
    //         ...arbitrumCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(
    //         setArbitrumImportedTokenList(arbitrumCurrentImportedTokenList)
    //       );
    //       tempCollection = [...overallARBITRUMTokenList, tempFetchedToken];
    //       dispatch(setOverallARBITRUMTokenList(tempCollection));
    //       break;
    //     case FANTOM_NETWORK:
    //       let fantomCurrentImportedTokenList =
    //         store.getState()?.tokenCollection?.FANTOMimportedTokenList;
    //       const overallFANTOMTokenList: any =
    //         store?.getState()?.tokenCollection?.overallFANTOMTokenList;
    //       fantomCurrentImportedTokenList = [
    //         ...fantomCurrentImportedTokenList,
    //         tempFetchedToken,
    //       ];
    //       dispatch(setFantomImportedTokenList(fantomCurrentImportedTokenList));
    //       tempCollection = [...overallFANTOMTokenList, tempFetchedToken];
    //       dispatch(setOverallFANTOMTokenList(tempCollection));
    //       break;
    //     default:
    //       break;
    //   }
    //   dispatch(setTokenCollection(tempCollection));
    //   setFetchedToken("");
    //   setAgreedTerms(false);
    //   handleClose();
    // }
  };

  const acceptTermsConditions = (e: any) => {
    setAgreedTerms(e.target.checked);
  };

  const handleTokenSelect = (item: any) => {
    if (item?.symbol === disableToken?.symbol) return;
    onSelectToken(item);
    handleClose();
  };

  return (
    <>
      <Modal
        centered
        open={show}
        onCancel={() => {
          handleClose();
          setFetchedToken("");
          setSearchMessage("");
        }}
        title={
          <Text fontSize={30} fontWeight={600}>
            Select a token
          </Text>
        }
        footer={null}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Input
              prefix={
                <SearchOutlined
                  style={{ color: colors.secondary400, marginRight: 8 }}
                />
              }
              placeholder={"Search by name or token address"}
              onChange={(e) => handleTokenSearch(e.target.value)}
            />
          </Col>

          <Card
            style={{
              backgroundColor: colors.secondary600,
              borderRadius: 12,
              maxHeight: "50vh",
              overflowY: "auto",
              width: "100%",
            }}
            className={`card-sm-padding scroll-container ${
              tokenList === undefined || !!searchMessage.length
                ? "loading-container"
                : ""
            }`}
          >
            {fetchedToken ? (
              <>
                <Col span={24}>
                  <Row
                    align={"middle"}
                    gutter={12}
                    style={{
                      padding: "0.5em",
                      margin: 0,
                      backgroundColor: colors.primary,
                      borderRadius: 8,
                    }}
                  >
                    <Col>
                      <img src={EthIcon} alt="img" style={{ height: 44 }} />
                    </Col>
                    <Col style={{ display: "grid" }}>
                      <HeaderText fontSize={16}>
                        {fetchedToken?.name}
                      </HeaderText>
                      <Text fontSize={12}>{fetchedToken?.symbol}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  style={{ display: "grid", padding: "1em 0.5em" }}
                >
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <HeaderText color={colors.error} fontSize={16}>
                        Trade at your own risk!
                      </HeaderText>
                    </Col>
                    <Col span={24}>
                      <Text>
                        Anyone can create a token, including creating fake
                        versions of existing tokens that claim to represent
                        projects.
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text style={{ marginTop: "0.5em" }}>
                        If you purchase this token, you may not be able to sell
                        it back.
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        onChange={(e) => acceptTermsConditions(e)}
                        style={{ margin: "0.5em 0" }}
                      >
                        I understand
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <PrimaryButton
                    fullWidth
                    disabled={!agreedTerms}
                    onClick={importToken}
                  >
                    Import
                  </PrimaryButton>
                </Col>
              </>
            ) : (
              <Row>
                {tokenList === undefined || !!searchMessage.length ? (
                  <Col span={24}>
                    {searchMessage ? (
                      <Text fontWeight={600}>{searchMessage}</Text>
                    ) : (
                      <Spin size={"large"} />
                    )}
                  </Col>
                ) : (
                  filteredTokenList?.map((item: any, idx: number) => (
                    <Col
                      key={idx}
                      span={24}
                      className={`clickable ${
                        item.symbol === disableToken?.symbol
                          ? "disabled"
                          : "hoverable-list-item"
                      }`}
                      onClick={() => handleTokenSelect(item)}
                    >
                      <Row
                        align={"middle"}
                        justify={"space-between"}
                        style={{
                          padding: "0.7em",
                          paddingRight: "1.2em",
                          borderRadius: 8,
                          backgroundColor:
                            selectedItem?.symbol === item.symbol
                              ? colors.primary500
                              : "transparent",
                        }}
                      >
                        <Col>
                          <Row align={"middle"} gutter={12}>
                            <Col>
                              <img
                                src={item?.logoURI}
                                alt="img"
                                style={{ height: 44 }}
                              />
                            </Col>
                            <Col style={{ display: "grid" }}>
                              <HeaderText fontSize={16}>
                                {getModifiedTokenName(
                                  item.name,
                                  item.isNative,
                                  currentPath,
                                )}
                              </HeaderText>
                              <Text
                                fontSize={12}
                                color={
                                  selectedItem?.symbol === item.symbol
                                    ? "white"
                                    : colors.secondary500
                                }
                              >
                                {getModifiedTokenSymbol(
                                  item?.symbol,
                                  item.isNative,
                                  currentPath,
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))
                )}
              </Row>
            )}
          </Card>
        </Row>
      </Modal>
    </>
  );
};
export default SelectTokenModal;
