import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Shido = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="43" height="43" rx="21.5" fill="#3B87F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1566 10.9983C25.1566 12.9303 23.5372 14.4966 21.5396 14.4966C19.542 14.4966 17.9226 12.9303 17.9226 10.9983C17.9226 9.06623 19.542 7.5 21.5396 7.5C23.5372 7.5 25.1566 9.06623 25.1566 10.9983ZM29.1555 26.0946V24.0444L33.75 21.4778V28.6597L21.5 35.5L9.25 28.6597V21.4027L13.8445 23.9678V26.0946L21.5 30.3698L29.1555 26.0946ZM25.7951 10.9983C25.7951 10.8359 25.7856 10.6765 25.765 10.5202L33.75 14.9792V19.3954L29.1555 21.9605V17.5443L23.7418 14.5211C24.9728 13.7993 25.7951 12.4908 25.7951 10.9983ZM17.3221 10.4727C17.2968 10.6443 17.2857 10.8205 17.2857 10.9983C17.2857 12.4754 18.0905 13.7718 19.2994 14.4981L13.8445 17.5443V21.8854L9.25 19.3203V14.9792L17.3221 10.4727ZM15.0882 22.3451V19.6543L21.5 23.2614L19.0823 24.6144L15.0882 22.3451ZM21.5 23.2614L27.9118 19.6543V22.3451L23.9177 24.6144L21.5 23.2614Z"
      fill="white"
    />
  </svg>
);

export const ShidoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Shido} {...props} />
);
