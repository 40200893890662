import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Strategies.scss";
import { cross_icon } from "../../../../Constants/ImagesConstants";
import StrateigiesTable from "./StrateigiesTable";

const Strategies = () => {
  return (
    <>
      <div className="strategies_page py-4">
        <Container>
          <Row>
            <Col xs={12} lg={12} xl={12}>
              <div className="strategies">
                <Row>
                  <Col xs={12} lg={6} xl={6}>
                    <div className="shido_img"></div>
                  </Col>
                  <Col xs={12} lg={6} xl={6}>
                    <div className="shido_contant">
                      <h4>
                        SHIDO <span>EARN</span>
                      </h4>
                      <p>
                        Shido Earn Offers Liquidity Providers Attractive Apys
                        Through Efficient Use Of Capital.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="liquidity_content">
                <h4>Derivatives Liquidity Pools</h4>
                <p>
                  Stakers Earn APR As Long As The SHIDO Price Stays Below The
                  Strike Price. Each Week The Pools Are Rebalanced And Strike
                  Price Might Be Updated.
                </p>
              </div>
              <div className="shido_earn d-flex align-items-center justify-content-between">
                <p>
                  Shido Earn is also available on Polygon. Switch the network to
                  get access to Turbo !
                </p>
                <div className="croess_icon">
                  <img src={cross_icon} alt="icon" />
                </div>
              </div>
              <StrateigiesTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Strategies;
