import { createSlice } from "@reduxjs/toolkit";

export const loadingData: any = createSlice({
  name: "loadingData",
  initialState: {
    loading: false,
    tradeDataLoading: false,
    routePathLoading: false,
    marketPriceLoading: false,
    searchTokenLoading: false,
    swapTransactionLoading: false,
    submitTransactionLoading: false,
    transactionHash: "",
    submittedTransactionHash: "",
    showTransactionModal: false,
    transactionErrorMessage: "",
    transactionSuccessMessage: "",
    limitPriceLoading: false,
  },
  reducers: {
    resetLoadingDataSlice: (state: any, action) => {
      state.loading = false;
      state.tradeDataLoading = false;
      state.routePathLoading = false;
      state.marketPriceLoading = false;
      state.searchTokenLoading = false;
      state.swapTransactionLoading = false;
      state.submitTransactionLoading = false;
      state.transactionHash = "";
      state.submittedTransactionHash = "";
      state.showTransactionModal = false;
      state.transactionErrorMessage = "";
      state.transactionSuccessMessage = "";
      state.limitPriceLoading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTradeDataLoading: (state, action) => {
      state.tradeDataLoading = action.payload;
    },
    setRoutePathsLoading: (state, action) => {
      state.routePathLoading = action.payload;
    },
    setMarketPriceLoading: (state, action) => {
      state.marketPriceLoading = action.payload;
    },
    setSearchTokenLoading: (state, action) => {
      state.searchTokenLoading = action.payload;
    },
    setSwapTransactionLoading: (state, action) => {
      state.swapTransactionLoading = action.payload;
    },
    setSubmitTransactionLoading: (state, action) => {
      state.submitTransactionLoading = action.payload;
    },
    setTransactionHash: (state, action) => {
      state.transactionHash = action.payload;
    },
    setSubmittedTransactionHash: (state, action) => {
      state.submittedTransactionHash = action.payload;
    },
    setShowTransactionModal: (state, action) => {
      state.showTransactionModal = action.payload;
    },
    setTransactionErrorMessage: (state, action) => {
      state.transactionErrorMessage = action.payload;
    },
    setTransactionSuccessMessage: (state, action) => {
      state.transactionSuccessMessage = action.payload;
    },
    setLimitPriceLoading: (state, action) => {
      state.limitPriceLoading = action.payload;
    },
  },
});
export const {
  setLoading,
  setSwapTransactionLoading,
  setTradeDataLoading,
  setRoutePathsLoading,
  setMarketPriceLoading,
  setSearchTokenLoading,
  resetLoadingDataSlice,
  setSubmitTransactionLoading,
  setTransactionHash,
  setSubmittedTransactionHash,
  setShowTransactionModal,
  setTransactionErrorMessage,
  setTransactionSuccessMessage,
  setLimitPriceLoading,
} = loadingData.actions;

export default loadingData.reducer;
