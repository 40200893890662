import "./TransactionLoaderModal.scss";
import { Oval } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../Constants/NetworkNames/NetworkNames";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import {
  setShowTransactionModal,
  setSubmittedTransactionHash,
  setSubmitTransactionLoading,
  setSwapTransactionLoading,
  setTransactionHash,
  setTransactionErrorMessage,
  setTransactionSuccessMessage,
} from "../../../redux/reducers/loadingData/loadingData";
import {
  ARBITRUM_SCAN,
  AVALANCHE_SCAN,
  BSC_SCAN,
  ETHER_SCAN,
  FANTOM_SCAN,
  POLYGON_SCAN,
} from "../../../Constants/AppVariables/appVariable";
import { Button, Col, Modal, Row, Spin } from "antd";
import { HeaderText, Text } from "../../Text";
import { useModalWidth } from "../../../hooks";

const TransactionLoaderModal = () => {
  const dispatch = useDispatch();
  const { width } = useModalWidth();

  const currentPath = window.location.pathname;

  const currentNetwork: any = useSelector(
    (state: any) => state.networkSlice.currentNetwork,
  );
  const swapTransactionLoading: any = useSelector(
    (state: any) => state.loadingData.swapTransactionLoading,
  );
  const transactionErrorMessage: any = useSelector(
    (state: any) => state.loadingData.transactionErrorMessage,
  );
  const transactionSuccessMessage: any = useSelector(
    (state: any) => state.loadingData.transactionSuccessMessage,
  );
  // This is when we submit the first swapData to ChaingePlatform
  const submitTransactionLoading: any = useSelector(
    (state: any) => state.loadingData.submitTransactionLoading,
  );
  const txnHash: any = useSelector(
    (state: any) => state.loadingData.transactionHash,
  );
  const submittedTxnHash: any = useSelector(
    (state: any) => state.loadingData.submittedTransactionHash,
  );
  const fromChainValue: string = useSelector(
    (state: any) => state.swapDataCrossChain.fromChainCross,
  );
  const toChainValue: string = useSelector(
    (state: any) => state.swapDataCrossChain.toChainCross,
  );

  const [fromNetworkScan, setFromNetworkScan] = useState<any>();
  const [toNetworkScan, setToNetworkScan] = useState<any>();

  const [currentNetworkScan, setCurrentNetworkScan] = useState<any>();

  const handleCurrentNetworkScan: any = () => {
    const selectedNtwk =
      currentNetwork === EHTEREUM_NETWORK
        ? ETHER_SCAN
        : currentNetwork === BNB_NETWORK
          ? BSC_SCAN
          : currentNetwork === POLYGON_NETWORK
            ? POLYGON_SCAN
            : currentNetwork === AVALANCHE_NETWORK
              ? AVALANCHE_SCAN
              : currentNetwork === ARBITRUM_NETWORK
                ? ARBITRUM_SCAN
                : currentNetwork === FANTOM_NETWORK
                  ? FANTOM_SCAN
                  : "";
    setCurrentNetworkScan(selectedNtwk);
  };

  const handleFromNetworkScan: any = () => {
    const selectedNtwk =
      fromChainValue === EHTEREUM_NETWORK
        ? ETHER_SCAN
        : fromChainValue === BNB_NETWORK
          ? BSC_SCAN
          : fromChainValue === POLYGON_NETWORK
            ? POLYGON_SCAN
            : fromChainValue === AVALANCHE_NETWORK
              ? AVALANCHE_SCAN
              : fromChainValue === ARBITRUM_NETWORK
                ? ARBITRUM_SCAN
                : fromChainValue === FANTOM_NETWORK
                  ? FANTOM_SCAN
                  : "";
    setFromNetworkScan(selectedNtwk);
  };

  const handleToNetworkScan: any = () => {
    const selectedNtwk =
      toChainValue === EHTEREUM_NETWORK
        ? ETHER_SCAN
        : toChainValue === BNB_NETWORK
          ? BSC_SCAN
          : toChainValue === POLYGON_NETWORK
            ? POLYGON_SCAN
            : toChainValue === AVALANCHE_NETWORK
              ? AVALANCHE_SCAN
              : toChainValue === ARBITRUM_NETWORK
                ? ARBITRUM_SCAN
                : toChainValue === FANTOM_NETWORK
                  ? FANTOM_SCAN
                  : "";
    setToNetworkScan(selectedNtwk);
  };

  useEffect(() => {
    handleFromNetworkScan();
    handleToNetworkScan();
    handleCurrentNetworkScan();
  }, [fromChainValue, toChainValue, currentNetwork]);

  const closeTransactionModal = () => {
    dispatch(setTransactionErrorMessage(""));
    dispatch(setShowTransactionModal(false));
    dispatch(setSwapTransactionLoading(false));
    dispatch(setSubmitTransactionLoading(false));
    dispatch(setTransactionHash(""));
    dispatch(setSubmittedTransactionHash(""));
    dispatch(setTransactionSuccessMessage(""));
  };

  const conditionalClose = () => {
    if (
      swapTransactionLoading === false &&
      submitTransactionLoading === false
    ) {
      closeTransactionModal();
    }
  };

  function clearLocalStorageAndReload() {
    // Clear the localStorage
    localStorage.clear();

    window.location.reload();
  }

  return (
    <Modal
      open
      centered
      onCancel={conditionalClose}
      closable={!swapTransactionLoading && !submitTransactionLoading}
      footer={null}
      width={width}
      bodyStyle={{ padding: "0 1.5em" }}
    >
      <Row gutter={[8, 12]} justify={"center"} style={{ textAlign: "center" }}>
        {swapTransactionLoading && (
          <>
            <Col span={24}>
              <HeaderText fontSize={16}>Transaction in progress</HeaderText>
            </Col>
            <Col span={24} style={{ margin: "1em 0" }}>
              <Spin size={"large"} />
            </Col>
          </>
        )}

        {!swapTransactionLoading &&
          !submitTransactionLoading &&
          transactionErrorMessage && (
            <Col span={24}>
              <HeaderText fontSize={16}>transactionErrorMessage</HeaderText>
            </Col>
          )}

        {txnHash && (
          <>
            <Col span={24}>
              <HeaderText fontSize={16}>
                Transaction completed successfully
              </HeaderText>
            </Col>
            <Col span={24}>
              <HeaderText fontSize={16}>
                View on Explorer{" "}
                <a
                  target="_blank"
                  href={`${
                    currentPath === "/cross-chain-swap"
                      ? fromNetworkScan
                      : currentNetworkScan
                  }${txnHash}`}
                >
                  {`${txnHash?.slice(0, 6)}.....${txnHash?.slice(
                    txnHash.length - 8,
                    txnHash.length,
                  )}`}
                </a>
              </HeaderText>
            </Col>
          </>
        )}

        {submitTransactionLoading && (
          <>
            <Col span={24}>
              <HeaderText fontSize={16}>Submitting transaction</HeaderText>
            </Col>
            <Col span={24}>
              <Text>This might take ~4 minutes</Text>
            </Col>
            <Col span={24} style={{ margin: "1em 0" }}>
              <Spin size={"large"} />
            </Col>
          </>
        )}

        {submittedTxnHash && (
          <Col span={24}>
            <HeaderText fontSize={16}>
              Submit Transaction Completed{" "}
              <a target="_blank" href={`${toNetworkScan}${submittedTxnHash}`}>
                {`${submittedTxnHash?.slice(
                  0,
                  6,
                )}.....${submittedTxnHash?.slice(
                  submittedTxnHash.length - 8,
                  submittedTxnHash.length,
                )}`}
              </a>
            </HeaderText>
          </Col>
        )}

        {transactionSuccessMessage && (
          <Col span={24}>
            <HeaderText fontSize={16}>{transactionSuccessMessage}</HeaderText>
          </Col>
        )}

        {transactionSuccessMessage ||
          (transactionErrorMessage && (
            <Col span={24}>
              <ButtonCustom
                fullWidth
                onClick={closeTransactionModal}
                title="Close"
              />
            </Col>
          ))}
      </Row>
      <Row>
        <Col span={24}>
          <Text>
            If this takes more than 5 minutes please click{" "}
            <Text
              fontSize={16}
              style={{
                cursor: "pointer",
                color: "red",
              }}
              onClick={clearLocalStorageAndReload}
            >
              here
            </Text>
          </Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default TransactionLoaderModal;
