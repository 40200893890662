import { gsap } from "gsap";

export function HomeTL(element) {
  // HomeTL----------------------
  let HomeTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.inOut" },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  HomeTL.from(
    element.querySelector(".trade_info-col"),
    {
      x: 22,
      opacity: 0,
      delay: window.innerWidth > 1200 ? 1.4 : 0, // Desktop|Mobile -------------
    },
    "<+=0.00",
  ).from(
    element.querySelectorAll(".trade_info-col .trade_info-item-col"),
    {
      opacity: 0,
      stagger: 0.31,
    },
    "<+=0.00",
  );
  if (window.innerWidth > 768) {
    HomeTL.pause();
  }
  // /HomeTL----------------------

  // JavaScript_ScrollTrigger----------------------
  let TopTriggerValue = window.innerWidth > 1200 ? 1 : 0.6; // Desktop|Mobile -------------
  if (window.innerWidth > 768) {
    function ScrollTrigger(e, tt) {
      return e.getBoundingClientRect().top <= tt * window.innerHeight; // %_TopValue
    }
    function OnScroll() {
      if (
        ScrollTrigger(element.querySelector(".trade_info-col"), TopTriggerValue)
      ) {
        window.removeEventListener("wheel", OnScroll);
        HomeTL.play();
      }
    }
    OnScroll();
    window.addEventListener("wheel", OnScroll);
  }
  // /JavaScript_ScrollTrigger----------------------
}
