import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row, ConfigProvider } from "antd";
import "./SwapSettingsModal.scss";
import info_icon_2 from "../../../../Assets/Images/info_icon_2.svg";
import Liquidity from "../Liquidity/Liquidity";
import CustomToken from "../CustomToken/CustomToken";
import { setSlippageTolerance } from "../../../../redux/reducers/swapData/swapData";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTokenIcon,
  LiquidIcon,
  SlippageIcon,
} from "../../../../Assets/Svg/SvgImages";
import { Text } from "../../../Text";
import { colors } from "../../../../Assets/Theme/colors";
import up from "../../../../Assets/Images/up.png";
import down from "../../../../Assets/Images/down.png";
import { PrimaryButton } from "../../../Button";

const TAB_AUTO = "TAB_AUTO";
const TAB_ONE = "TAB_ONE";

type SlippageTab = {
  tab: "TAB_AUTO" | "TAB_ONE" | "TAB_TWO" | "TAB_THREE";
  label: "Auto" | "0.1%" | "0.5%" | "1%";
  value: 0.5 | 0.1 | 1;
};

const SwapSettings = () => {
  const dispatch = useDispatch();
  const [showSlippage, setShowSlippage] = useState<boolean>(true);
  const [showLiquidity, setShowLiquidity] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);

  const slippageTabs: SlippageTab[] = [
    { tab: "TAB_AUTO", label: "Auto", value: 0.5 },
    { tab: "TAB_ONE", label: "0.1%", value: 0.1 },
    { tab: "TAB_TWO", label: "0.5%", value: 0.5 },
    { tab: "TAB_THREE", label: "1%", value: 1 },
  ];

  const liquiditySources: any = useSelector(
    (state: any) => state.swapData.liquiditySources,
  );

  const tokenCollection: any = useSelector(
    (state: any) => state.tokenCollection.tokenCollection,
  );

  const importedTokens = tokenCollection.filter(
    (token: any) => token?.isImported === true,
  );

  const slippageTolerance: any = useSelector(
    (state: any) => state.swapData.slippageTolerance,
  );

  const [inputSlippageTolerance, setInputSlippageTolerance] =
    useState<string>("");

  const [slippageActiveTab, setSlippageActiveTab] = useState<string>(TAB_AUTO);
  const [showLowSlippageWarning, setShowLowSlippageWarning] =
    useState<boolean>(false);

  //custom Token

  const handleSlippageTolerance = (value: number, type: string) => {
    setShowLowSlippageWarning(false);
    setSlippageActiveTab(type);
    setInputSlippageTolerance("");
    dispatch(setSlippageTolerance(value));
  };

  useEffect(() => {}, [inputSlippageTolerance]);

  const handleInputSlippageTolerance = (value: any) => {
    const maxSlippage = "49";
    const re = /^[0-9]\d{0,9}(\.\d{0,2})?%?$/;
    if (value === "") {
      setInputSlippageTolerance("");
    } else if (re.test(value) === false) {
      return;
    } else {
      if (Number(value) > Number(maxSlippage)) value = maxSlippage;
      else if (Number(value) === 0.1) {
        setShowLowSlippageWarning(true);
      } else {
        setShowLowSlippageWarning(false);
      }
      setSlippageActiveTab("");
      setInputSlippageTolerance(value);
    }
  };

  const showSave = !(
    slippageActiveTab === "" &&
    !!inputSlippageTolerance &&
    slippageTolerance === inputSlippageTolerance
  );

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24} style={{ textAlign: "left" }}>
          <Text fontSize={20} fontWeight={600}>
            Swap settings
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: colors.secondary600,
                  borderRadius: 12,
                }}
              >
                <Row
                  align={"middle"}
                  justify={"space-between"}
                  className={"clickable"}
                  onClick={() => setShowSlippage(!showSlippage)}
                >
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <SlippageIcon />
                      </Col>
                      <Col>
                        <Text fontWeight={600}>Slippage Tolerance</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <Text fontWeight={600}>
                          {slippageTolerance}%{" "}
                          {slippageActiveTab === TAB_AUTO ? "Auto" : ""}
                        </Text>
                      </Col>
                      <Col>
                        <img
                          src={showSlippage ? up : down}
                          style={{ height: 19 }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {showSlippage && (
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <Row
                        align={"middle"}
                        justify={"space-between"}
                        gutter={[8, 8]}
                        style={{ marginTop: "1em" }}
                      >
                        {slippageTabs.map(({ tab, label, value }) => (
                          <Col key={tab} span={6}>
                            <PrimaryButton
                              fullWidth
                              onClick={() =>
                                handleSlippageTolerance(value, tab)
                              }
                              style={
                                slippageActiveTab === tab
                                  ? {}
                                  : { backgroundColor: "transparent" }
                              }
                            >
                              {label}
                            </PrimaryButton>
                          </Col>
                        ))}
                        <Col span={24} style={{ paddingLeft: 8 }}>
                          <Row align={"middle"} gutter={8}>
                            <Col xs={16} md={showSave ? 18 : 24}>
                              <ConfigProvider
                                theme={{
                                  components: {
                                    Input: {
                                      colorBgContainer: "#505055",
                                      colorBorder: "#505055",
                                      activeBorderColor: "#505055",
                                      colorPrimaryHover: "#505055",
                                      colorPrimary: "#505055",
                                      colorTextPlaceholder: colors.secondary400,
                                      colorIcon: colors.secondary400,
                                    },
                                  },
                                }}
                              >
                                <Input
                                  style={{
                                    width: "100%",
                                    padding: "0.4em 1em",
                                    backgroundColor: "#505055",
                                    border:
                                      slippageActiveTab === ""
                                        ? `1px solid ${colors.primary}`
                                        : "none",
                                    height: "auto",
                                  }}
                                  onChange={(e) => {
                                    handleInputSlippageTolerance(
                                      e.target.value,
                                    );
                                  }}
                                  onFocus={() => setSlippageActiveTab("")}
                                  inputMode="decimal"
                                  placeholder="Or set custom tolerance"
                                  suffix={<Text fontWeight={600}>%</Text>}
                                  value={inputSlippageTolerance}
                                />
                              </ConfigProvider>
                            </Col>
                            <Col xs={showSave ? 8 : 0} md={showSave ? 6 : 0}>
                              <PrimaryButton
                                fullWidth
                                disabled={!inputSlippageTolerance}
                                onClick={() =>
                                  dispatch(
                                    setSlippageTolerance(
                                      inputSlippageTolerance,
                                    ),
                                  )
                                }
                              >
                                Save
                              </PrimaryButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    {(slippageActiveTab === TAB_ONE ||
                      showLowSlippageWarning) && (
                      <Col span={24}>
                        <Card
                          className={"card-md-padding"}
                          style={{
                            backgroundColor: `${colors.primary900}65`,
                            borderRadius: 8,
                          }}
                        >
                          <Row align={"middle"} justify={"space-between"}>
                            <Col span={2}>
                              <img src={info_icon_2} alt="info-icon" />
                            </Col>
                            <Col span={22}>
                              <Text>
                                Transaction might be reverted because of low
                                slippage tolerance.
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )}
                  </Row>
                )}
              </Card>
            </Col>
            {/* <Col span={24}>
              <Card style={{backgroundColor: colors.secondary600, borderRadius: 12}}>
                <Row align={"middle"} justify={"space-between"} className={"clickable"} onClick={() => setShowLiquidity(!showLiquidity)}>
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <LiquidIcon />
                      </Col>
                      <Col>
                        <Text fontWeight={600}>Liquidity Sources</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <Text fontWeight={600}>
                          {liquiditySources.length}
                        </Text>
                      </Col>
                      <Col>
                        <img src={showLiquidity ? up : down} style={{height: 19}}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {showLiquidity && <Liquidity />}
              </Card>
            </Col> */}
            {/* <Col span={24}>
              <Card style={{backgroundColor: colors.secondary600, borderRadius: 12}}>
                <Row align={"middle"} justify={"space-between"} className={"clickable"} onClick={() => setShowToken(!showToken)}>
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <CustomTokenIcon />
                      </Col>
                      <Col>
                        <Text fontWeight={600}>Custom Tokens</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row align={"middle"} gutter={12}>
                      <Col>
                        <Text fontWeight={600}>
                          {importedTokens.length}
                        </Text>
                      </Col>
                      <Col>
                        <img src={showToken ? up : down} style={{height: 19}}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {showToken && <CustomToken />}
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default SwapSettings;

// Backup Regexes
// const re = /^(10|\d)(\.\d{1,2})?$/;
// const re = /^[0-9]\d{0,9}(\.\d{1,2})?%?$/;
