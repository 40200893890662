// // @ts-nocheck
// import { Card, Col, message, Row } from "antd";
// import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
// import { ethers } from "ethers";
// import { useEffect, useState } from "react";
// import { Form } from "react-bootstrap";
// import Countdown from "react-countdown";
// import { useDispatch, useSelector } from "react-redux";
// import { useAccount } from "wagmi";
// import shidoIcon from "../../../Assets/Images/logo.png";
// import shidoWhite from "../../../Assets/Images/shidoWhite.svg";
// import stakingCoins from "../../../Assets/Images/stakingCoins.svg";
// import stakingHero from "../../../Assets/Images/stakingHero.svg";
// import { colors } from "../../../Assets/Theme/colors";
// import { STAKING_TOKEN_VERSIONS } from "../../../Constants/TYPES/stakingTokenTypes";
// import {
//   setShowTransactionModal,
//   setSwapTransactionLoading,
//   setTransactionSuccessMessage,
// } from "../../../redux/reducers/loadingData/loadingData";
// import store from "../../../redux/store";
// import {
//   claimRewards,
//   emergencyExit,
//   getAllowanceStakingContract,
//   GetApprovalStakingContract,
//   getEmergencyExitFees,
//   getRewardRate,
//   getTotalStakings,
//   getUserLockedTokens,
//   getUserLockedTokensInDollars,
//   getUserRewards,
//   getUserRewardValueInDollars,
//   getUserShidoValueInDollars,
//   getUserStakedValueInDollars,
//   getUserStakes,
//   stakeShidoRewards,
//   stakeTokens,
//   withdrawUserStakings,
// } from "../../../services/contractServices/stakingServices";
// import {
//   getDecimals,
//   getRawBalanceWithoutRoundOff,
//   getTokenBalanceWithoutRoundOff,
// } from "../../../services/contractServices/tokenServices";
// import {
//   bigNumberToFixed,
//   decimalFormatWithoutRoundOffCrypto,
//   exponentialToDecimal,
// } from "../../../services/helpers/swapHelpers";
// import { getMessageConfig } from "../../../utils";
// import { PrimaryButton } from "../../Button";
// import { ButtonCustom } from "../../Common";
// import CustomShimmer from "../../Common/CustomShimmer/CustomShimmer";
// import { HeaderText, Text } from "../../Text";
// import "./Staking.scss";
// type Tab = "Deposit" | "Withdraw" | "Emergency withdraw";
//
// const StakingTest = () => {
//   const dispatch = useDispatch();
//   const { sm, lg } = useBreakpoint();
//   const { address: waddress } = useAccount();
//   const chainID: any = useSelector((state: any) =>
//     state.networkSlice.ChainID.toString(),
//   );
//   const wrongNetworSelected = useSelector(
//     (state: any) => state.loginSlice.wrongNetworkSelected,
//   );
//   const [rateAndTotalStakesLoading, setRateAndTotalStakesLoading] =
//     useState<boolean>(false);
//   const [shidoBalanceLoading, setShidoBalanceLoading] = useState<boolean>(true);
//   const [amountStakedLoading, setAmountStakedLoading] = useState<boolean>(true);
//   const [totalStaked, setTotalStaked] = useState<any>(null);
//   const [userStakings, setUserStakings] = useState<any>("");
//   const [userRewards, setUserRewards] = useState<any>("");
//   const [rewardRate, setRewardRate] = useState("");
//   const [userRewardInDollars, setUserRewardInDollars] = useState("");
//   const [userStakingsInDollars, setUserStakingsInDollars] = useState("");
//   const tabs: Tab[] = ["Deposit", "Withdraw", "Emergency withdraw"];
//   const [selectedTab, setSelectedTab] = useState<Tab>("Deposit");
//
//   // USER DEPOSIT DATA
//   const [depositInput, setDepositInput] = useState<any>("");
//   const [userShidoBalanceV2, setUserShidoBalanceV2] = useState(0);
//   const [userShidoBalanceRawV2, setUserShidoBalanceRawV2] = useState(0);
//   const [useMaxDeposit, setUseMaxDeposit] = useState<boolean>(false);
//   const [depositButtonDisabled, setDepositButtonDisabled] = useState(true);
//
//   // USER WITHDRAW DATA
//   const [withdrawInput, setWithdrawInput] = useState<any>("");
//   const [unlockTime, setUnlockTime] = useState<any>();
//
//   const [emergencyExitInput, setEmergencyExitInput] = useState<any>("");
//   const [emergencyExitInputInDollars, setEmergencyExitInputInDollars] =
//     useState<any>("");
//   const [emergencyExitButtonDisabled, setEmergencyExitButtonDisabled] =
//     useState(true);
//   const [emergencyExitFee, setEmergencyExitFee] = useState<any>("");
//
//   const [withdrawInputInDollars, setWithdrawInputInDollars] = useState<any>("");
//   const [withdrawButtonDisabled, setWithdrawButtonDisabled] = useState(true);
//
//   useEffect(() => {
//     const callInit = async () => {
//       if (waddress) {
//         init();
//       } else {
//         resetValues();
//       }
//     };
//     callInit();
//   }, [waddress, chainID, wrongNetworSelected]);
//
//   const emergencyExitFunction = async (e: any) => {
//     e.preventDefault();
//     dispatch(setShowTransactionModal(true));
//     dispatch(setSwapTransactionLoading(true));
//
//     const calculatedAmount: any = ethers.utils.parseUnits(
//       String(emergencyExitInput),
//       18,
//     );
//
//     const result = await emergencyExit(
//       waddress,
//       calculatedAmount,
//       "STAKING_V2",
//     );
//
//     if (result?.status) {
//       dispatch(setSwapTransactionLoading(false));
//       dispatch(
//         setTransactionSuccessMessage(
//           "Withdraw of the SHIDO tokens has been completed successfully.",
//         ),
//       );
//       message.success(
//         getMessageConfig({
//           type: "success",
//           title: "Withdraw successful!",
//           body: "SHIDO tokens have been withdrawn successfully",
//         }),
//       );
//
//       setEmergencyExitInput("");
//       setEmergencyExitInputInDollars("");
//       dispatch(setShowTransactionModal(false));
//       dispatch(setSwapTransactionLoading(false));
//
//       await init();
//     }
//     // else there was some error in executing swap
//     else if (result.status === false) {
//       dispatch(setSwapTransactionLoading(false));
//     }
//   };
//
//   const handleEmergencyExitInput = async (e: any) => {
//     let values: any;
//
//     if (typeof e === "object") {
//       values = e.target.value;
//     } else values = e;
//
//     setEmergencyExitInput(values);
//
//     if (Number(values) <= 0 || values === "") {
//       setEmergencyExitButtonDisabled(true);
//     } else {
//       const resultVestingTime = await fetchVestingTime();
//
//       if (
//         resultVestingTime > Date.now() &&
//         Number(values) <= Number(userStakings)
//       ) {
//         setEmergencyExitButtonDisabled(false);
//       } else {
//         setEmergencyExitButtonDisabled(true);
//       }
//     }
//   };
//
//   function truncateToTwoDecimalPlaces(inputString) {
//     const match = inputString.match(/^(\d+\.\d{0,2})/);
//
//     if (match) {
//       return match[1];
//     } else {
//       return inputString;
//     }
//   }
//
//   const fetchUserShidoBalance = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       setShidoBalanceLoading(true);
//       const contractCollection =
//         store.getState().contractCollection.contractCollection;
//       const tokenData: any = contractCollection.filter(
//         (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//       );
//       const tokenAddress = tokenData[0]?.address;
//
//       const shortTokenBalance: any = truncateToTwoDecimalPlaces(
//         String(
//           ethers.utils.formatUnits(
//             String(await getRawBalanceWithoutRoundOff(waddress, tokenAddress)),
//             18,
//           ),
//         ),
//       );
//
//       const rawTokenBalance: any = await getRawBalanceWithoutRoundOff(
//         waddress,
//         tokenAddress,
//       );
//
//       setUserShidoBalanceRawV2(bigNumberToFixed(rawTokenBalance));
//       setUserShidoBalanceV2(shortTokenBalance);
//       setShidoBalanceLoading(false);
//       return shortTokenBalance;
//     }
//   };
//
//   const fetchUserShidoBalanceV1 = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const contractCollection =
//         store.getState().contractCollection.contractCollection;
//       const tokenData: any = contractCollection.filter(
//         (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//       );
//       const tokenAddress = tokenData[0]?.address;
//       const rawTokenBalance: any = await getTokenBalanceWithoutRoundOff(
//         waddress,
//         tokenAddress,
//       );
//       return rawTokenBalance;
//     }
//   };
//
//   const fetchRawUserShidoBalanceV1 = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const contractCollection =
//         store.getState().contractCollection.contractCollection;
//       const tokenData: any = contractCollection.filter(
//         (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//       );
//       const tokenAddress = tokenData[0]?.address;
//       const rawTokenBalance: any = await getRawBalanceWithoutRoundOff(
//         waddress,
//         tokenAddress,
//       );
//       return rawTokenBalance;
//     }
//   };
//
//   const disabledLetters = ["e", "E", "+", "-"];
//
//   const handleDepositInput = async (e: any, useMax: boolean) => {
//     setUseMaxDeposit(useMax);
//
//     let values: any;
//     // const maxLength = 25;
//     if (typeof e === "object") {
//       values = e.target.value;
//     } else values = e;
//     const regexHere = /^(\d+)?([.]?\d{0,10})?$/;
//     const isInputValid = regexHere.test(values);
//
//     if (isInputValid) {
//       values = values;
//     } else {
//       return;
//     }
//
//     if (
//       values.length > 1 &&
//       Array.from(values)[0] === "0" &&
//       Array.from(values)[1] !== "."
//     ) {
//       values = values.slice(1);
//     }
//
//     setDepositInput(values);
//
//     if (Number(values) <= 0 || values === "") {
//       setDepositButtonDisabled(true);
//     } else {
//       if (Number(values) > Number(userShidoBalanceV2)) {
//         setDepositButtonDisabled(true);
//       } else {
//         setDepositButtonDisabled(false);
//       }
//     }
//   };
//
//   const fetchVestingTime = async () => {
//     if (!waddress) {
//       setUnlockTime(Date.now());
//       return;
//     } else {
//       const result = await getUserStakes(waddress, "STAKING_V2");
//       if (result) {
//         let inMilliSec: any;
//         if (Number(result.unlockTime) > 0) {
//           inMilliSec = Number(result.unlockTime) * 1000;
//         } else {
//           inMilliSec = Date.now();
//         }
//         return inMilliSec;
//       }
//     }
//   };
//
//   const handleWithdrawInput = async (e: any) => {
//     let values: any;
//     if (typeof e === "object") {
//       values = e.target.value;
//     } else values = e;
//     if (
//       values.length > 1 &&
//       Array.from(values)[0] === "0" &&
//       Array.from(values)[1] !== "."
//     ) {
//       values = values.slice(1);
//     }
//     setWithdrawInput(values);
//     if (Number(values) <= 0 || values === "") {
//       setWithdrawButtonDisabled(true);
//     } else {
//       const resultVestingTime = await fetchVestingTime();
//       if (
//         resultVestingTime < Date.now() &&
//         Number(values) <= Number(userStakings)
//       ) {
//         setWithdrawButtonDisabled(false);
//       }
//       // const result = await getTokenValueInDollars(values, "STAKING_TOKEN_V2");
//     }
//   };
//
//   const fetchUserRewardsInDollars = async (amount: any) => {
//     const result = await getUserRewardValueInDollars(
//       amount,
//       "STAKING_TOKEN_V2",
//     );
//     if (result) {
//       setUserRewardInDollars(result);
//     }
//   };
//
//   const fetchUserLockedTokensInDollars = async (amount: any) => {
//     const result = await getUserLockedTokensInDollars(
//       amount,
//       "STAKING_TOKEN_V1",
//     );
//     if (result) {
//       setUserLockedAmountInDollarsV1(result);
//     }
//   };
//
//   const fetchStakedValueInDollars = async (amount: any) => {
//     const result = await getUserStakedValueInDollars(
//       amount,
//       "STAKING_TOKEN_V2",
//     );
//     if (result) {
//       setUserStakingsInDollars(result);
//     }
//   };
//   const fetchStakedValueInDollarsV1Contract = async (amount: any) => {
//     const result = await getUserStakedValueInDollars(
//       amount,
//       "STAKING_TOKEN_V1",
//     );
//   };
//
//   const fetchUserShidoValueInDollarsV2 = async (amount: any) => {
//     const result = await getUserShidoValueInDollars(amount, "STAKING_TOKEN_V2");
//   };
//
//   const fetchUserShidoValueInDollarsV1 = async (amount: any) => {
//     const result = await getUserShidoValueInDollars(amount, "STAKING_TOKEN_V1");
//   };
//
//   const fetchAllUserStakes = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       setAmountStakedLoading(true);
//       const result = await getUserStakes(waddress, "STAKING_V2");
//       if (result) {
//         const contractCollection =
//           store.getState().contractCollection.contractCollection;
//         const tokenData: any = contractCollection.filter(
//           (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//         );
//         const tokenAddress = tokenData[0]?.address;
//         const tokenDecimals = await getDecimals(tokenAddress);
//         const calculatedResult: any =
//           Number(result?.amount) / 10 ** Number(tokenDecimals);
//         setUserStakings(calculatedResult);
//         setAmountStakedLoading(false);
//         return calculatedResult;
//       } else {
//         setUserStakings("0");
//         setAmountStakedLoading(false);
//         return "0";
//       }
//     }
//   };
//
//   // This is fetch user stakes on initial contract (V1)
//   const fetchAllUserStakesV1Contract = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const result = await getUserStakes(waddress, "STAKING_V1");
//       if (result) {
//         const contractCollection =
//           store.getState().contractCollection.contractCollection;
//         const tokenData: any = contractCollection.filter(
//           (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//         );
//         const tokenAddress = tokenData[0]?.address;
//         const tokenDecimals = await getDecimals(tokenAddress);
//         const calculatedResult: any =
//           Number(result?.amount) / 10 ** Number(tokenDecimals);
//         return calculatedResult;
//       } else {
//         return "0";
//       }
//     }
//   };
//
//   const fetchUserRewardsV1Contract = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const result = await getUserRewards(waddress, "STAKING_V1");
//       if (result) {
//         const contractCollection =
//           store.getState().contractCollection.contractCollection;
//         const tokenData: any = contractCollection.filter(
//           (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//         );
//         const tokenAddress = tokenData[0]?.address;
//         const tokenDecimals = await getDecimals(tokenAddress);
//         const calculatedResult: any =
//           Number(result) / 10 ** Number(tokenDecimals);
//
//         return calculatedResult;
//       } else {
//         setUserRewards("0");
//         return "0";
//       }
//     }
//   };
//
//   const fetchUserLockedTokensV1Contract = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const result = await getUserLockedTokens(waddress, "LOCK_V1");
//       if (result) {
//         const contractCollection =
//           store.getState().contractCollection.contractCollection;
//         const tokenData: any = contractCollection.filter(
//           (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//         );
//         const tokenAddress = tokenData[0]?.address;
//         const tokenDecimals = await getDecimals(tokenAddress);
//         const calculatedResult: any =
//           Number(result) / 10 ** Number(tokenDecimals);
//         if (calculatedResult > 1) {
//           // setUserLockedAmountV1(
//           //   decimalFormatWithoutRoundOffCrypto(calculatedResult)
//           // );
//         } else if (calculatedResult > 0 && calculatedResult < 1) {
//           // setUserLockedAmountV1(exponentialToDecimal(calculatedResult));
//         } else {
//           // setUserLockedAmountV1(calculatedResult);
//         }
//         return calculatedResult;
//       } else {
//         setUserRewards("0");
//         return "0";
//       }
//     }
//   };
//
//   const fetchUserRewards = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     if (!waddress) {
//       return;
//     } else {
//       const result = await getUserRewards(waddress, "STAKING_V2");
//       if (result) {
//         const contractCollection =
//           store.getState().contractCollection.contractCollection;
//         const tokenData: any = contractCollection.filter(
//           (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//         );
//         const tokenAddress = tokenData[0]?.address;
//         const tokenDecimals = await getDecimals(tokenAddress);
//         const calculatedResult: any =
//           Number(result) / 10 ** Number(tokenDecimals);
//
//         if (calculatedResult > 1) {
//           setUserRewards(decimalFormatWithoutRoundOffCrypto(calculatedResult));
//         } else if (calculatedResult > 0 && calculatedResult < 1) {
//           setUserRewards(exponentialToDecimal(calculatedResult));
//         } else {
//           setUserRewards(calculatedResult);
//         }
//         return calculatedResult;
//       } else {
//         setUserRewards("0");
//         return "0";
//       }
//     }
//   };
//
//   const fetchVestingTimeInitial = async () => {
//     if (!waddress) {
//       setUnlockTime(Date.now());
//       return;
//     } else {
//       const result = await getUserStakes(waddress, "STAKING_V2");
//       if (result) {
//         let inMilliSec: any;
//         if (Number(result.unlockTime) > 0) {
//           inMilliSec = Number(result.unlockTime) * 1000;
//         } else {
//           inMilliSec = Date.now();
//         }
//         setUnlockTime(inMilliSec);
//         if (inMilliSec < Date.now()) {
//           // Disable EmergencyExit
//           setEmergencyExitButtonDisabled(true);
//         } else if (inMilliSec > Date.now()) {
//           // Disable Withdraw
//           setWithdrawButtonDisabled(true);
//         }
//       }
//     }
//   };
//
//   const fetchStakingDetails = async (
//     STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
//   ) => {
//     setRateAndTotalStakesLoading(true);
//     try {
//       const result = await getTotalStakings("STAKING_V2");
//       const rewardRateResult = await getRewardRate("STAKING_V2");
//       const emergencyExitFeesResult = await getEmergencyExitFees("STAKING_V2");
//
//       const contractCollection =
//         store.getState().contractCollection.contractCollection;
//       const tokenData: any = contractCollection.filter(
//         (a: any) => a.symbol == STAKING_TOKEN_VERSION,
//       );
//       let tokenDecimals = tokenData?.decmals || 18;
//       const calculatedResult: any =
//         Number(result) / 10 ** Number(tokenDecimals);
//
//       setTotalStaked(calculatedResult);
//       setRewardRate(rewardRateResult / 100);
//       setEmergencyExitFee(emergencyExitFeesResult);
//     } catch (e) {
//       setTotalStaked(0);
//       setRewardRate(0);
//       setEmergencyExitFee(0);
//     }
//     setRateAndTotalStakesLoading(false);
//   };
//
//   const init = async () => {
//     if (!wrongNetworSelected) {
//       await fetchStakingDetails("STAKING_TOKEN_V2");
//       const userShidoBalanceV2Result: any = await fetchUserShidoBalance(
//         "STAKING_TOKEN_V2",
//       );
//       await fetchUserRewardsV1Contract("STAKING_TOKEN_V1");
//       const allUserStakeV1Result: any = await fetchAllUserStakesV1Contract(
//         "STAKING_TOKEN_V1",
//       );
//       await fetchVestingTimeInitial();
//       const userLockedTokensV1Result = await fetchUserLockedTokensV1Contract(
//         "STAKING_TOKEN_V1",
//       );
//       const userRewardsResult = await fetchUserRewards("STAKING_TOKEN_V2");
//       const allUserStakeResult: any = await fetchAllUserStakes(
//         "STAKING_TOKEN_V2",
//       );
//
//       const userShidoBalanceV1Result: any = await fetchUserShidoBalanceV1(
//         "STAKING_TOKEN_V1",
//       );
//       await fetchRawUserShidoBalanceV1("STAKING_TOKEN_V1");
//       if (allUserStakeResult) {
//         await fetchStakedValueInDollars(allUserStakeResult);
//       }
//       if (allUserStakeV1Result) {
//         await fetchStakedValueInDollarsV1Contract(allUserStakeV1Result);
//       }
//       if (userShidoBalanceV2Result) {
//         await fetchUserShidoValueInDollarsV2(userShidoBalanceV2Result);
//       }
//       if (userShidoBalanceV1Result) {
//         await fetchUserShidoValueInDollarsV1(userShidoBalanceV1Result);
//       }
//       if (userRewardsResult) {
//         await fetchUserRewardsInDollars(userRewardsResult);
//       }
//       if (userLockedTokensV1Result) {
//         await fetchUserLockedTokensInDollars(userLockedTokensV1Result);
//       }
//     }
//   };
//
//   const resetValues = async () => {
//     if (!wrongNetworSelected) {
//       setShidoBalanceLoading(false);
//       setAmountStakedLoading(false);
//       setTotalStaked("0");
//       setUserRewards("0");
//       setUserStakings("0");
//       setUserShidoBalanceV2(0);
//       // setUserLockedAmountV1(0);
//       setUserStakingsInDollars("0");
//       setUserRewardInDollars("0");
//       await fetchStakingDetails("STAKING_TOKEN_V2");
//     }
//   };
//
//   const depositTokensFunction = async (e: any) => {
//     dispatch(setShowTransactionModal(true));
//     dispatch(setSwapTransactionLoading(true));
//     e.preventDefault();
//
//     try {
//       const allowanceResult = await getAllowanceStakingContract(
//         waddress,
//         "STAKING_V2",
//         "STAKING_TOKEN_V2",
//       );
//
//       const depositInputEther = ethers.utils.parseUnits(
//         String(depositInput),
//         18,
//       );
//
//       let approvalResult: any = false;
//
//       if (allowanceResult.gte(depositInputEther)) {
//         const result = await stakeTokens(
//           waddress,
//           depositInputEther,
//           "STAKING_V2",
//         );
//         if (result?.status) {
//           dispatch(setSwapTransactionLoading(false));
//           dispatch(
//             setTransactionSuccessMessage(
//               "You have successfully staked your SHIDO tokens",
//             ),
//           );
//           message.success(
//             getMessageConfig({
//               type: "success",
//               title: "Staking successful!",
//               body: "SHIDO tokens staked successfully",
//             }),
//           );
//           setDepositInput("");
//           // reload staking page values
//           await init();
//         }
//         // else there was some error in executing swap
//         else if (result?.status === false) {
//           dispatch(setSwapTransactionLoading(false));
//         }
//         // Else if user is trying to stake more amount than current approval get new approval
//       } else if (allowanceResult.lt(depositInputEther)) {
//         approvalResult = await GetApprovalStakingContract(
//           waddress,
//           depositInputEther,
//           "STAKING_V2",
//           "STAKING_TOKEN_V2",
//         );
//
//         if (approvalResult?.status == true) {
//           const result = await stakeTokens(
//             waddress,
//             depositInputEther,
//             "STAKING_V2",
//           );
//
//           if (result?.status) {
//             dispatch(setSwapTransactionLoading(false));
//             dispatch(
//               setTransactionSuccessMessage(
//                 "You have successfully staked your SHIDO tokens",
//               ),
//             );
//             message.success(
//               getMessageConfig({
//                 type: "success",
//                 title: "Staking successful!",
//                 body: "SHIDO tokens staked successfully",
//               }),
//             );
//             setDepositInput("");
//
//             // reload staking page values
//             await init();
//           }
//           // else there was some error in executing swap
//           else if (result?.status === false) {
//             dispatch(setSwapTransactionLoading(false));
//           }
//         }
//       }
//     } catch (e) {
//       dispatch(setShowTransactionModal(false));
//       dispatch(setSwapTransactionLoading(false));
//     }
//   };
//
//   const collectShidoFunction = async (e: any) => {
//     e.preventDefault();
//     dispatch(setShowTransactionModal(true));
//     dispatch(setSwapTransactionLoading(true));
//
//     const result = await claimRewards(waddress, "STAKING_V2");
//
//     if (result?.status) {
//       dispatch(setSwapTransactionLoading(false));
//       dispatch(
//         setTransactionSuccessMessage(
//           "You have successfully claimed your rewards",
//         ),
//       );
//       message.success(
//         getMessageConfig({
//           type: "success",
//           title: "Rewards claimed!",
//           body: "You have successfully claimed your rewards",
//         }),
//       );
//       await init();
//     }
//     // else there was some error in executing swap
//     else if (result?.status === false) {
//       dispatch(setSwapTransactionLoading(false));
//     }
//   };
//
//   const stakeShidoRewardsFunction = async (e: any) => {
//     e.preventDefault();
//     dispatch(setShowTransactionModal(true));
//     dispatch(setSwapTransactionLoading(true));
//
//     const result = await stakeShidoRewards(waddress, "STAKING_V2");
//
//     if (result?.status) {
//       dispatch(setSwapTransactionLoading(false));
//       dispatch(
//         setTransactionSuccessMessage(
//           "You have successfully staked your rewarded SHIDO tokens",
//         ),
//       );
//       message.success(
//         getMessageConfig({
//           type: "success",
//           title: "Staking successful!",
//           body: "SHIDO tokens staked successfully",
//         }),
//       );
//       await init();
//     }
//     // else there was some error in executing swap
//     else if (result.status === false) {
//       dispatch(setSwapTransactionLoading(false));
//     }
//   };
//
//   // This useEffect will enable Deposit or Withdraw button if the
//   // user has entered some amount (in Deposit or Withdraw field) without connecting metamask/walletConnect
//   useEffect(() => {
//     handleDepositInput(depositInput, false);
//     handleWithdrawInput(withdrawInput);
//   }, [userShidoBalanceV2, userStakings]);
//
//   useEffect(() => {
//     const currentTimeStamp = Date.now();
//   });
//
//   const withdrawStakingsFunction = async (e: any) => {
//     e.preventDefault();
//     dispatch(setShowTransactionModal(true));
//     dispatch(setSwapTransactionLoading(true));
//
//     const bigNumberConverted = ethers.utils.parseUnits(
//       String(withdrawInput),
//       18,
//     );
//
//     const result = await withdrawUserStakings(
//       waddress,
//       bigNumberConverted,
//       "STAKING_V2",
//     );
//
//     if (result.status) {
//       dispatch(setSwapTransactionLoading(false));
//       dispatch(
//         setTransactionSuccessMessage(
//           "Withdrawal of the SHIDO tokens has been completed successfully.",
//         ),
//       );
//       message.success(
//         getMessageConfig({
//           type: "success",
//           title: "Withdrawal successful!",
//           body: "SHIDO tokens withdrawn successfully",
//         }),
//       );
//       setWithdrawInput("");
//       setWithdrawInputInDollars("");
//       await init();
//     }
//     // else there was some error in executing swap
//     else if (result.status === false) {
//       dispatch(setSwapTransactionLoading(false));
//     }
//   };
//
//   return (
//     <Row
//       gutter={[20, 20]}
//       className={"staking_page staking_page_tabs staking_info"}
//     >
//       <Col span={24}>
//         <Card
//           className={"card-no-padding"}
//           style={{
//             background: "linear-gradient(91deg, #2B66F6 2.17%, #4CA8F8 98.2%)",
//             padding: sm ? "2em 3em" : "1em 1.5em",
//           }}
//         >
//           <Row align={"middle"}>
//             {lg && (
//               <img
//                 src={stakingHero}
//                 style={{ position: "absolute", height: "100%" }}
//               />
//             )}
//             <Col xs={24} lg={0}>
//               <img src={stakingCoins} />
//             </Col>
//             <Col xs={24} lg={12} xl={14}>
//               <Row align={"middle"} gutter={[24, 12]}>
//                 <Col xs={0} xl={6}>
//                   <img src={shidoWhite} />
//                 </Col>
//                 <Col
//                   xs={24}
//                   xl={18}
//                   style={{
//                     textAlign: "left",
//                     ...(!lg && sm && { paddingLeft: "2em" }),
//                   }}
//                 >
//                   <HeaderText
//                     fontSize={lg ? 50 : sm ? 40 : 36}
//                     fontWeight={700}
//                     style={{ lineHeight: "normal" }}
//                   >
//                     SHIDO Staking
//                   </HeaderText>
//                   <Row gutter={8} style={{ marginTop: "1em" }}>
//                     <Col span={24} style={{ marginBottom: "0.5em" }}>
//                       <Text fontWeight={600}>
//                         Stake SHIDO to receive rewards
//                       </Text>
//                     </Col>
//                     <Col span={24} style={{ marginBottom: "0.5em" }}>
//                       <Row align={"middle"} justify={"space-between"}>
//                         <Col>
//                           <Text fontWeight={600}>Current reward:</Text>
//                         </Col>
//                         <Col>
//                           <div
//                             style={{
//                               borderRadius: 4,
//                               border: "1px solid #FFF",
//                               background: "rgba(255, 255, 255, 0.10)",
//                               padding: "0.3em 1em",
//                               width: "fit-content",
//                             }}
//                           >
//                             <Text fontWeight={600}>
//                               {rewardRate || "0"}% APR
//                             </Text>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                     <Col span={24}>
//                       <Row>
//                         <Col span={24}>
//                           <Text fontWeight={600}>
//                             Total SHIDO currently staked:
//                           </Text>
//                         </Col>
//                         <Col span={24}>
//                           <div
//                             style={{
//                               borderRadius: 4,
//                               border: "1px solid #FFF",
//                               background: "rgba(255, 255, 255, 0.10)",
//                               padding: "0.3em 1em",
//                               width: "100%",
//                               marginTop: "0.25em",
//                               textAlign: "center",
//                             }}
//                           >
//                             <Text fontWeight={600}>
//                               {totalStaked
//                                 ? Number(
//                                     Number(totalStaked).toFixed(4),
//                                   ).toLocaleString("en-US")
//                                 : 0}
//                             </Text>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                   <Row gutter={12}>
//                     <Col></Col>
//                     <Col></Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </Col>
//             <Col xs={0} lg={12} xl={10}>
//               <img src={stakingCoins} />
//             </Col>
//           </Row>
//         </Card>
//       </Col>
//       <Col xs={24} lg={16} style={{ height: "auto" }}>
//         <Card style={{ height: "100%" }}>
//           <Row
//             gutter={[8, 8]}
//             style={{ flexWrap: "nowrap", overflow: "scroll" }}
//             className={"scroll-container"}
//           >
//             {tabs.map((tab, idx: number) => (
//               <Col key={idx}>
//                 <PrimaryButton
//                   onClick={() => {
//                     setSelectedTab(tab);
//                     // navigate(`/${tab.link}`);
//                   }}
//                   style={{
//                     ...(tab !== selectedTab && {
//                       backgroundColor: "transparent",
//                     }),
//                   }}
//                 >
//                   {tab}
//                 </PrimaryButton>
//               </Col>
//             ))}
//           </Row>
//           <Row
//             style={{ marginTop: "2em" }}
//             className={"staking_page staking_page_tabs staking_info"}
//           >
//             {selectedTab === "Deposit" && (
//               <div className="staking_info">
//                 <form>
//                   <Form.Group>
//                     <Row
//                       className="form-group-inner"
//                       align={"middle"}
//                       justify={"space-between"}
//                       gutter={8}
//                     >
//                       <Col xs={18} md={14}>
//                         <Row align={"middle"} gutter={18}>
//                           <Col xs={24} sm={12}>
//                             <div className="form-group-inner__left">
//                               <span className="icon">
//                                 <img src={shidoIcon} alt="" />
//                               </span>
//                               <span className="text">
//                                 <small className="d-block">Amount</small>
//                                 <span>SHIDO</span>
//                               </span>
//                             </div>
//                           </Col>
//                           <Col xs={0} sm={12}>
//                             <input
//                               onKeyDown={(evt) => {
//                                 evt.code === "ArrowDown" &&
//                                   evt.preventDefault();
//                                 evt.code === "ArrowUp" && evt.preventDefault();
//                                 disabledLetters.includes(evt.key) &&
//                                   evt.preventDefault();
//                               }}
//                               onWheel={(e: any) => e.target.blur()}
//                               type="number"
//                               onChange={(e) => handleDepositInput(e, false)}
//                               value={depositInput}
//                               className="form-control"
//                               placeholder="0.000"
//                             />
//                           </Col>
//                         </Row>
//                       </Col>
//                       <Col xs={6} md={10}>
//                         <div
//                           className="form-group-inner__right"
//                           style={{ float: "right" }}
//                         >
//                           <span
//                             onClick={() =>
//                               handleDepositInput(
//                                 // subtractOne(
//                                 Number(
//                                   // decimalFormatWithoutRoundOffCrypto(
//                                   Number(userShidoBalanceV2),
//                                   // ),
//                                 ),
//                                 // ),
//                                 true,
//                               )
//                             }
//                             className="use_max_button"
//                           >
//                             MAX
//                           </span>
//                         </div>
//                       </Col>
//                       <Col xs={24} sm={0} style={{ marginTop: "1em" }}>
//                         <input
//                           onKeyDown={(evt) => {
//                             evt.code === "ArrowDown" && evt.preventDefault();
//                             evt.code === "ArrowUp" && evt.preventDefault();
//                             disabledLetters.includes(evt.key) &&
//                               evt.preventDefault();
//                           }}
//                           onWheel={(e: any) => e.target.blur()}
//                           type="number"
//                           onChange={(e) => handleDepositInput(e, false)}
//                           value={depositInput}
//                           className="form-control"
//                           placeholder="0.000"
//                         />
//                       </Col>
//                     </Row>
//                   </Form.Group>
//                   <Row style={{ marginTop: "1em" }} justify={"space-between"}>
//                     <Col xs={24} sm={0} style={{ marginBottom: "1em" }}>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                     </Col>
//                     <Col xs={24} sm={5}>
//                       <ButtonCustom
//                         fullWidth
//                         onClick={(e: any) => depositTokensFunction(e)}
//                         disabled={depositButtonDisabled || !waddress}
//                         className="action-btn"
//                         title="Deposit"
//                       />
//                     </Col>
//                     <Col xs={0} sm={19}>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </form>
//               </div>
//             )}
//
//             {/* withdraw tab */}
//             {selectedTab === "Withdraw" && (
//               <div className="staking_info">
//                 <form>
//                   <Form.Group>
//                     <div>
//                       {unlockTime && (
//                         <Form.Label className="text-end d-block">
//                           <span className="timer_text">
//                             Time until vesting:{" "}
//                             {unlockTime ? <Countdown date={unlockTime} /> : "0"}
//                           </span>
//                         </Form.Label>
//                       )}
//                     </div>
//                     <Row
//                       className="form-group-inner"
//                       align={"middle"}
//                       justify={"space-between"}
//                       gutter={8}
//                     >
//                       <Col xs={18} md={14}>
//                         <Row align={"middle"} gutter={18}>
//                           <Col xs={24} sm={12}>
//                             <div className="form-group-inner__left">
//                               <span className="icon">
//                                 <img src={shidoIcon} alt="" />
//                               </span>
//                               <span className="text">
//                                 <small className="d-block">Amount</small>
//                                 <span>SHIDO</span>
//                               </span>
//                             </div>
//                           </Col>
//                           <Col xs={0} sm={12}>
//                             <input
//                               onKeyDown={(evt) => {
//                                 evt.code === "ArrowDown" &&
//                                   evt.preventDefault();
//                                 evt.code === "ArrowUp" && evt.preventDefault();
//                                 disabledLetters.includes(evt.key) &&
//                                   evt.preventDefault();
//                               }}
//                               onWheel={(e: any) => e.target.blur()}
//                               type="number"
//                               onChange={(e) => handleWithdrawInput(e)}
//                               value={withdrawInput}
//                               className="form-control"
//                               placeholder="0.000"
//                             />
//                           </Col>
//                         </Row>
//                       </Col>
//                       <Col xs={6} md={10}>
//                         <div
//                           className="form-group-inner__right"
//                           style={{ float: "right" }}
//                         >
//                           <span
//                             style={{
//                               pointerEvents:
//                                 userStakings <= 1 ? "none" : "auto",
//                               opacity: userStakings <= 1 ? "0.5" : "1",
//                             }}
//                             onClick={() => {
//                               if (userStakings <= 1) {
//                                 return;
//                               }
//                               handleWithdrawInput(
//                                 // subtractOne(
//                                 Number(
//                                   // decimalFormatWithoutRoundOffCrypto(
//                                   Number(userStakings),
//                                   // ),
//                                 ),
//                                 // ),
//                               );
//                             }}
//                             className="use_max_button"
//                           >
//                             MAX
//                           </span>
//                         </div>
//                       </Col>
//                       <Col xs={24} sm={0} style={{ marginTop: "1em" }}>
//                         <input
//                           onKeyDown={(evt) => {
//                             evt.code === "ArrowDown" && evt.preventDefault();
//                             evt.code === "ArrowUp" && evt.preventDefault();
//                             disabledLetters.includes(evt.key) &&
//                               evt.preventDefault();
//                           }}
//                           onWheel={(e: any) => e.target.blur()}
//                           type="number"
//                           onChange={(e) => handleWithdrawInput(e)}
//                           value={withdrawInput}
//                           className="form-control"
//                           placeholder="0.000"
//                         />
//                       </Col>
//                     </Row>
//                   </Form.Group>
//                   <Row style={{ marginTop: "1em" }} justify={"space-between"}>
//                     <Col xs={24} sm={0} style={{ marginBottom: "1em" }}>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                     </Col>
//                     <Col xs={24} sm={8} style={{ textAlign: "left" }}>
//                       <ButtonCustom
//                         fullWidth={!sm}
//                         onClick={(e: any) => withdrawStakingsFunction(e)}
//                         className="action-btn"
//                         // disabled={
//                         // withdrawButtonDisabled || !waddress
//                         // BigInt(bigNumberToFixed(withdrawInput)) >
//                         //   BigInt(bigNumberToFixed(userStakings))
//                         // }
//                         title="Withdraw"
//                       />
//                     </Col>
//                     <Col xs={0} sm={16}>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </form>
//               </div>
//             )}
//
//             {/* emergency withdraw tab */}
//             {selectedTab === "Emergency withdraw" && (
//               <div className="staking_info">
//                 <form>
//                   <Form.Group>
//                     <Row
//                       className="form-group-inner"
//                       align={"middle"}
//                       justify={"space-between"}
//                       gutter={8}
//                     >
//                       <Col xs={18} md={14}>
//                         <Row align={"middle"} gutter={18}>
//                           <Col xs={24} sm={12}>
//                             <div className="form-group-inner__left">
//                               <span className="icon">
//                                 <img src={shidoIcon} alt="" />
//                               </span>
//                               <span className="text">
//                                 <small className="d-block">Amount</small>
//                                 <span>SHIDO</span>
//                               </span>
//                             </div>
//                           </Col>
//                           <Col xs={0} sm={12}>
//                             <input
//                               onKeyDown={(evt) => {
//                                 evt.code === "ArrowDown" &&
//                                   evt.preventDefault();
//                                 evt.code === "ArrowUp" && evt.preventDefault();
//                                 disabledLetters.includes(evt.key) &&
//                                   evt.preventDefault();
//                               }}
//                               onWheel={(e: any) => e.target.blur()}
//                               type="number"
//                               onChange={(e) => handleEmergencyExitInput(e)}
//                               value={emergencyExitInput}
//                               className="form-control"
//                               placeholder="0.000"
//                             />
//                           </Col>
//                         </Row>
//                       </Col>
//                       <Col xs={6} md={10}>
//                         <div
//                           className="form-group-inner__right"
//                           style={{ float: "right" }}
//                         >
//                           <span
//                             style={{
//                               pointerEvents:
//                                 userStakings <= 1 ? "none" : "auto",
//                               opacity: userStakings <= 1 ? "0.5" : "1",
//                             }}
//                             onClick={() => {
//                               if (userStakings <= 1) {
//                                 return;
//                               }
//                               handleEmergencyExitInput(
//                                 // subtractOne(
//                                 // decimalFormatWithoutRoundOffCrypto(
//                                 userStakings,
//                                 // ),
//                                 // ),
//                               );
//                             }}
//                             className="use_max_button"
//                           >
//                             MAX
//                           </span>
//                         </div>
//                       </Col>
//                       <Col xs={24} sm={0} style={{ marginTop: "1em" }}>
//                         <input
//                           onKeyDown={(evt) => {
//                             evt.code === "ArrowDown" && evt.preventDefault();
//                             evt.code === "ArrowUp" && evt.preventDefault();
//                             disabledLetters.includes(evt.key) &&
//                               evt.preventDefault();
//                           }}
//                           onWheel={(e: any) => e.target.blur()}
//                           type="number"
//                           onChange={(e) => handleDepositInput(e, false)}
//                           value={depositInput}
//                           className="form-control"
//                           placeholder="0.000"
//                         />
//                       </Col>
//                     </Row>
//                   </Form.Group>
//                   <Row style={{ marginTop: "1em" }} justify={"space-between"}>
//                     <Col xs={24} sm={0} style={{ marginBottom: "1em" }}>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row
//                         align={"middle"}
//                         gutter={12}
//                         justify={"space-between"}
//                       >
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             Emergency withdraw fee
//                           </Text>
//                         </Col>
//                         <Col>{emergencyExitFee}%</Col>
//                       </Row>
//                     </Col>
//                     <Col xs={24} sm={8} style={{ textAlign: "left" }}>
//                       <ButtonCustom
//                         fullWidth={!sm}
//                         onClick={(e: any) => emergencyExitFunction(e)}
//                         // disabled={emergencyExitButtonDisabled || !waddress}
//                         className="action-btn"
//                         title="Emergency withdraw"
//                       />
//                     </Col>
//                     <Col xs={0} sm={16}>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             SHIDO in wallet
//                           </Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || shidoBalanceLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userShidoBalanceV2 ? userShidoBalanceV2 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>Amount staked</Text>
//                         </Col>
//                         <Col>
//                           {rateAndTotalStakesLoading || amountStakedLoading ? (
//                             <CustomShimmer
//                               className="ms-1"
//                               width={46}
//                               height={13}
//                             />
//                           ) : (
//                             <Text fontWeight={500}>
//                               {userStakings != 0
//                                 ? decimalFormatWithoutRoundOffCrypto(
//                                     userStakings,
//                                   )
//                                 : 0}
//                             </Text>
//                           )}
//                         </Col>
//                       </Row>
//                       <Row align={"middle"} gutter={12} justify={"end"}>
//                         <Col>
//                           <Text color={colors.secondary500}>
//                             Emergency withdraw fee
//                           </Text>
//                         </Col>
//                         <Col>{emergencyExitFee}%</Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </form>
//               </div>
//             )}
//           </Row>
//         </Card>
//       </Col>
//       <Col xs={24} lg={8}>
//         <Card
//           style={{
//             border: "1px solid var(--Gradient, #4CA8F8)",
//             height: "100%",
//           }}
//         >
//           <Row align={"middle"}>
//             <Col span={12}>
//               <img
//                 src={require("../../../Assets/Images/rewards.png")}
//                 style={{ height: 115 }}
//               />
//             </Col>
//             <Col span={12} style={lg ? { display: "grid" } : {}}>
//               {lg ? (
//                 <>
//                   <HeaderText
//                     fontSize={30}
//                     fontWeight={700}
//                     color={colors.primary500}
//                     style={{ height: "fit-content", lineHeight: "normal" }}
//                   >
//                     SHIDO
//                   </HeaderText>
//                   <HeaderText
//                     fontSize={30}
//                     fontWeight={700}
//                     style={{ height: "fit-content", lineHeight: "normal" }}
//                   >
//                     rewards
//                   </HeaderText>
//                 </>
//               ) : (
//                 <Row gutter={8}>
//                   <Col>
//                     <HeaderText
//                       fontSize={30}
//                       fontWeight={700}
//                       color={colors.primary500}
//                       style={{ height: "fit-content", lineHeight: "normal" }}
//                     >
//                       SHIDO
//                     </HeaderText>
//                   </Col>
//                   <Col>
//                     <HeaderText
//                       fontSize={30}
//                       fontWeight={700}
//                       style={{ height: "fit-content", lineHeight: "normal" }}
//                     >
//                       rewards
//                     </HeaderText>
//                   </Col>
//                 </Row>
//               )}
//             </Col>
//           </Row>
//           <Row
//             className={"staking-migration_tabs_content"}
//             style={{ margin: "1em 0" }}
//           >
//             <div className={"staking_info"}>
//               <div className="form-group-inner">
//                 <Row
//                   justify={"space-between"}
//                   align={"middle"}
//                   style={{ width: "100%" }}
//                 >
//                   <Col>
//                     <div className="form-group-inner__left">
//                       <span className="icon">
//                         <img src={shidoIcon} alt="" />
//                       </span>
//                       <span className="text">
//                         <span>SHIDO</span>
//                       </span>
//                     </div>
//                   </Col>
//                   <Col>
//                     <HeaderText fontSize={20}>{userRewards}</HeaderText>
//                     {/*<input*/}
//                     {/*  onKeyDown={(evt) => {*/}
//                     {/*    evt.code === "ArrowDown" && evt.preventDefault();*/}
//                     {/*    evt.code === "ArrowUp" && evt.preventDefault();*/}
//                     {/*    disabledLetters.includes(evt.key) &&*/}
//                     {/*    evt.preventDefault();*/}
//                     {/*  }}*/}
//                     {/*  onWheel={(e: any) => e.target.blur()}*/}
//                     {/*  type="number"*/}
//                     {/*  value={userRewards}*/}
//                     {/*  className="form-control"*/}
//                     {/*  placeholder="0.000"*/}
//                     {/*  disabled={true}*/}
//                     {/*/>*/}
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//           </Row>
//           <Row gutter={[8, 8]}>
//             <Col span={12}>
//               <PrimaryButton
//                 fullWidth
//                 onClick={(e: any) => stakeShidoRewardsFunction(e)}
//                 disabled={userRewards === 0}
//               >
//                 Stake reward
//               </PrimaryButton>
//             </Col>
//             <Col span={12}>
//               <PrimaryButton
//                 fullWidth
//                 style={{ backgroundColor: "transparent" }}
//                 onClick={(e: any) => collectShidoFunction(e)}
//                 disabled={userRewards === 0}
//               >
//                 Collect reward
//               </PrimaryButton>
//             </Col>
//           </Row>
//         </Card>
//       </Col>
//     </Row>
//   );
// };
//
// export default StakingTest;

// @ts-nocheck
import { Card, Col, Row } from 'antd';
import { Text } from '../../Text';
import './Staking.scss';
import { colors } from '../../../Assets/Theme/colors';

const StakingModal = () => {
  return (
    <Row
      gutter={[20, 20]}
      className={'staking_page staking_page_tabs staking_info'}
      align={'middle'}
      justify={'center'}
    >
      <Col sm={24} lg={10}>
        <Card style={{ border: `1px solid ${colors.primary}`, padding: '4em 2em' }}>
          <Text fontSize={22} fontWeight={500} color={colors.secondary200}>Staking is currently unavailable</Text>
        </Card>
      </Col>
    </Row>
  );
};

export default StakingModal;
