import React from "react";
import "./VoteTable.scss";
import { Table } from "react-bootstrap";

const VoteTable = () => {
  const Votedata = [
    {
      id: "1",
      classIcon: "primaryClr",
      textIcon: "A",
      name: "A0X215   ",
      votesPre: "0.3%",
      price: "102,820",
      diffbtn: "blueText",
    },
    {
      id: "2",
      classIcon: "SeconeryClr",
      textIcon: "B",
      name: "B0X215   ",
      votesPre: "0.3%",
      price: "102,820",
      diffbtn: "blueText",
    },
    {
      id: "3",
      classIcon: "primaryClr",
      textIcon: "A",
      name: "C0X215   ",
      votesPre: "0.3%",
      price: "102,820",
      diffbtn: "blueText",
    },
    {
      id: "4",
      classIcon: "SeconeryClr",
      textIcon: "B",
      name: "D0X215   ",
      votesPre: "0.3%",
      price: "102,820",
      diffbtn: "blueText",
    },
    {
      id: "5",
      classIcon: "primaryClr",
      textIcon: "A",
      name: "E0X215   ",
      votesPre: "0.3%",
      price: "102,820",
      diffbtn: "blueText",
    },
  ];
  return (
    <>
      <div className="Commom_Table">
        <Table responsive>
          <thead>
            <tr>
              <th>Address</th>
              <th>Vote for</th>
              <th>Diff</th>
            </tr>
          </thead>
          <tbody>
            {Votedata.map((data) => {
              return (
                <tr>
                  <td>
                    <div className="name_Data d-flex align-items-center">
                      <h4 className={data.classIcon}>{data.textIcon}</h4>
                      <span>{data.name}</span>
                    </div>
                  </td>
                  <td>{data.votesPre}</td>
                  <td>{data.price}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default VoteTable;
