import React from "react";
import "./TooltipCustom.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import { infoIcon1 } from "../../../Constants/ImagesConstants";
import infoIcon2 from "../../../Assets/Images/about.svg";
import infoIcon from "../../../Assets/Images/info-white.svg";
import { Button } from "react-bootstrap";

const TooltipCustom = ({
  text,
  iconBlue,
  customIcon,
  className,
  noIcon,
  children,
}: any) => {
  return (
    <>
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => <Tooltip {...props}>{text}</Tooltip>}
        placement="top"
      >
        {customIcon ? (
          customIcon
        ) : (
          <span className={className}>
            {iconBlue ? (
              <img src={infoIcon2} alt="img" />
            ) : (
              <img src={infoIcon} alt="img" />
            )}
          </span>
        )}
      </OverlayTrigger>
    </>
  );
};

export default TooltipCustom;
