import React from "react";
import { Button, ButtonProps, ConfigProvider } from "antd";
import { colors } from "../Assets/Theme/colors";

export const PrimaryButton = (props: ButtonProps & { fullWidth?: boolean }) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: colors.primary500,
            colorPrimary: colors.primary500,
            colorPrimaryHover: colors.primary600,
            colorPrimaryActive: colors.primary600,
            borderRadius: 8,
            controlHeightLG: 45,
            paddingContentHorizontal: 22,
            controlHeight: 36,
            borderColorDisabled: colors.primary700,
            colorBgContainerDisabled: colors.primary600,
            colorTextDisabled: colors.secondary200,
          },
        },
      }}
    >
      <Button
        type={"primary"}
        {...props}
        style={{
          fontWeight: 600,
          boxShadow: "none",
          ...(props.fullWidth && { width: "100%" }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const SecondaryButton = (props: ButtonProps) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: colors.secondary600,
            colorPrimaryHover: colors.secondary700,
            colorPrimaryActive: colors.secondary700,
            borderRadius: 8,
            paddingContentHorizontal: 22,
            controlHeight: 36,
          },
        },
      }}
    >
      <Button
        type={"primary"}
        {...props}
        style={{
          fontWeight: 500,
          boxShadow: "none",
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};
