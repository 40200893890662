import { APIURL_OPEN_OCEAN } from "../../../Constants/APIconstants/apiConstants";
import {
  SITE_URL,
  SITE_URL_OPEN_OCEAN_FREE,
} from "../../../Constants/AppVariables/appVariable";
import { OPEN_OCEAN_CHAIN_NAMES } from "../../../Constants/TYPES/openOceanChainNames";
import {
  apiCallGet,
  apiCallPost,
} from "../../../services/apiServices/ApiServices";
import { setTradeDataLoading } from "../../reducers/loadingData/loadingData";
import store from "../../store";

/** Get Token List function gives the list of the tokens
 * @param chainName : "eth" | "bsc"
 * @returns list of tokens listed on openOcean
 */
export const getTokenListFunction = async (
  chainName: OPEN_OCEAN_CHAIN_NAMES,
) => {
  try {
    const ENDPOINT = chainName + APIURL_OPEN_OCEAN.TOKEN_LIST;
    const result: any = await apiCallGet(
      SITE_URL_OPEN_OCEAN_FREE,
      ENDPOINT,
      {},
      {},
    );
    if (result?.data) {
      const tokenList = result?.data;
      return {
        status: 200,
        tokenList: tokenList,
      };
    } else
      return {
        status: 400,
        tokenList: null,
      };
  } catch (error: any) {
    // store.dispatch(setSearchTokenLoading(false)); // need to fix later add stop loading of tokenList
    console.log("getTokenListFunction Error => ", error);
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error.message ?? error.mesage,
    // }));
  }
};
export const getCrossTokenListFunction = async (chain: string) => {
  try {
    const ENDPOINT = `https://market-api.openocean.finance/v2/${chain}/token`;
    const result: any = await apiCallGet(SITE_URL, ENDPOINT, {});
    if (result?.data) {
      const tokenList = result?.data;
      return {
        status: 200,
        tokenList: tokenList,
      };
    } else
      return {
        status: 400,
        tokenList: null,
      };
  } catch (error: any) {
    // store.dispatch(setSearchTokenLoading(false)); // need to fix later add stop loading of tokenList
    console.log("getTokenListFunction Error => ", error);
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error.message ?? error.mesage,
    // }));
  }
};
export const getTokenDetails = async (chain: string, token: string) => {
  try {
    const ENDPOINT = `https://open-api.openocean.finance/v3/${chain.toLowerCase()}/specify_tokenList`;
    const result: any = await apiCallPost(SITE_URL, ENDPOINT, {
      tokens: [token],
    });
    if (result?.data) {
      return result?.data?.data[0];
    } else return null;
  } catch (error: any) {
    // store.dispatch(setSearchTokenLoading(false)); // need to fix later add stop loading of tokenList
    console.log("getTokenListFunction Error => ", error);
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error.message ?? error.mesage,
    // }));
  }
};
/** Get Best Value function gives best output value for a combination
 * @param chainName : "eth" | "bsc"
 * @param chainName : "data : {inTokenAddress, outTokenAddress, amount, gasPrice, slippage}"
 * @returns best value for combination
 */
export const bestValuesFunctionOpenOcean = async (
  chainName: OPEN_OCEAN_CHAIN_NAMES,
  data: any,
) => {
  try {
    store.dispatch(setTradeDataLoading(true));

    const ENDPOINT = chainName + APIURL_OPEN_OCEAN.QUOTE;
    const result: any = await apiCallGet(
      SITE_URL_OPEN_OCEAN_FREE,
      ENDPOINT,
      data,
      {},
    );

    if (result?.data) {
      return result?.data;
    }
  } catch (error: any) {
    // store.dispatch(setSearchTokenLoading(false)); // need to fix later add stop loading of tokenList
    console.log("bestValuesFunctionOpenOcean Error => ", error);
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: error.message ?? error.mesage,
    // }));
  }
};

export const priceImpactOpenOcean = async (
  chainName: OPEN_OCEAN_CHAIN_NAMES,
  data: any,
) => {
  try {
    const ENDPOINT = chainName + APIURL_OPEN_OCEAN.QUOTE;
    const result: any = await apiCallGet(
      SITE_URL_OPEN_OCEAN_FREE,
      ENDPOINT,
      data,
      {},
    );

    if (result?.data) {
      return result?.data;
    }
  } catch (error) {
    console.log("Error in => priceImpactOpenOcean", error);
  }
};
