import { gsap } from "gsap";

export function HomeOverviewTL(element) {
  // HomeOverviewTL----------------------
  let HomeOverviewTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.inOut" },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  HomeOverviewTL.from(
    element.querySelector(".overview_card"),
    {
      x: 22,
      opacity: 0,
    },
    "<+=0.00",
  )
    .from(
      element.querySelectorAll(".overview_card .overview_card-item"),
      {
        opacity: 0,
        stagger: 0.31,
      },
      "<+=0.22",
    )
    .from(
      element.querySelectorAll(".overview_card .overview_card-item-xl"),
      {
        x: window.innerWidth > 1200 ? -22 : 22, // Desktop|Mobile -------------
        opacity: 0,
        stagger: 0.31,
      },
      "<+=0.00",
    )
    .from(
      element.querySelectorAll(".overview_card .overview_card-item-xr"),
      {
        x: 22,
        opacity: 0,
        stagger: 0.31,
      },
      "<+=0.00",
    );
  if (window.innerWidth > 768) {
    HomeOverviewTL.pause();
  }
  // /HomeOverviewTL----------------------

  // JavaScript_ScrollTrigger----------------------
  let TopTriggerValue = 0.85;
  if (window.innerWidth > 768) {
    function ScrollTrigger(e, tt) {
      return e.getBoundingClientRect().top <= tt * window.innerHeight; // %_TopValue
    }
    function OnScroll() {
      if (ScrollTrigger(element, TopTriggerValue)) {
        window.removeEventListener("wheel", OnScroll);
        HomeOverviewTL.play();
      }
    }
    OnScroll();
    window.addEventListener("wheel", OnScroll);
  }
  // /JavaScript_ScrollTrigger----------------------
}
