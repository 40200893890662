import { Col, Row, Space, Tooltip } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { RotateIcon } from "../../../Assets/Svg/SvgImages";
import { ChartIcon } from "../../../Assets/icons/Chart";
import { setUpdateTradeData } from "../../../redux/reducers/tradeData/tradeData";
import { PrimaryButton } from "../../Button";
import useCookieReset from "../../Pages/Trade/useCookieReset";
import "./TradeCard.scss";

type TabKey = "swap" | "limit" | "cross-chain";
type TabLabel = "Swap" | "Limit Order" | "Cross Chain";
type TabLink = "simple-mode" | "limit-order" | "cross-chain-swap";

const TradeCardTabs = () => {
  useCookieReset(); //this is a function we made so that when cookies are cleared we can reinitialize the things
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isClassic = useMatch("/classic-mode");
  const isLimit = useMatch("/limit-order");
  const { md, sm } = useBreakpoint();
  const currentPath = window.location.pathname;

  const updateTradeData: any = useSelector(
    (state: any) => state.tradeData.updateData,
  );
  const tabs: {
    key: TabKey;
    label: TabLabel;
    link: TabLink;
    disabled?: boolean;
  }[] = [
      {
        key: "swap",
        label: "Swap",
        link: "simple-mode",
      },
      {
        key: "limit",
        label: "Limit Order",
        link: "limit-order",
      },
      {
        key: "cross-chain",
        label: "Cross Chain",
        link: "cross-chain-swap",
        disabled: false,
      },
    ];
  const [selectedTab, setSelectedTab] = useState<TabKey>("swap");

  useEffect(() => {
    if (currentPath === "/limit-order") {
      setSelectedTab("limit");
    } else if (currentPath === "/simple-mode") {
      setSelectedTab("swap");
    } else if (currentPath === "/cross-chain-swap") {
      setSelectedTab("cross-chain");
    }
  }, [currentPath]);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const switchModes = () => {
    if (currentPath === "/" || currentPath === "/simple-mode") {
      // navigate to classic mode
      // navigate("/classic-mode", { replace: true });
    } else {
      // navigate to simple mode
      navigate("/simple-mode", { replace: true });
    }
  };

  return (
    <>
      <Row
        style={{
          marginBottom: sm ? "36px" : "0",
          display: "flex",
          alignItems: "center",
          gap: sm ? "0" : "36px",
        }}
        gutter={[0, sm ? 8 : 0]}
      >
        <Col
          className="tab-head"
          xs={24}
          sm={18}
          xxl={isClassic || isLimit ? 24 : 18}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row style={{ gap: sm ? "8px" : "5px" }}>
            {tabs.map((tab, idx) => (
              <Col key={idx}>
                <Tooltip title={tab.disabled ? "Coming soon" : undefined}>
                  <PrimaryButton
                    disabled={tab.disabled ?? false}
                    onClick={() => {
                      setSelectedTab(tab.key);
                      navigate(`/${tab.link}`);
                    }}
                    style={{
                      height: "auto",
                      padding: md
                        ? "10px 16px"
                        : sm
                          ? "12px 23px"
                          : "12px 14px",
                      fontSize: sm ? "16px" : "14px",
                      fontWeight: "600",
                      ...(tab.key !== selectedTab && {
                        backgroundColor: "rgba(255, 255, 255, 0.04)",
                        borderColor: "transparant",
                      }),
                    }}
                  >
                    {tab.label}
                  </PrimaryButton>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={6}
          xxl={isClassic || isLimit ? 24 : 6}
          style={{
            gap: 8,
            display: "flex",
            justifyContent: sm ? "end" : "start",
            alignItems: "center",
          }}
        >
          {selectedTab === "swap" && (
            <Space
              className={"clickable"}
              style={{ marginTop: 0 }}
              onClick={switchModes}
            >
              <ChartIcon />
            </Space>
          )}
          {/* <Space
            className={"clickable"}
            style={{ marginTop: 0 }}
            onClick={handleShow}
          >
            <PlusCircleIcon />
          </Space> */}
          <Space
            className={"clickable"}
            style={{ marginTop: 0 }}
            onClick={() => dispatch(setUpdateTradeData(!updateTradeData))}
          >
            <RotateIcon />
          </Space>
        </Col>
      </Row>
      {/*
      <AddTokenModal show={show} handleClose={() => setShow(false)} /> */}
    </>
  );
};

export default TradeCardTabs;
