import Form from "react-bootstrap/Form";
import { Col, Row, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./ShidoToken.scss";
import {
  token_icon,
  direction_arrow,
  info_icon,
} from "../../../../Constants/ImagesConstants";
import ConnectWallet from "../../../Common/ConnectWallet/ConnectWallet";

const ShidoToken = () => {
  return (
    <>
      <div className="Trade_Simple_Card shido_token mt-4">
        <div className="Trade_Tabs_card">
          <Tab.Container id="left-tabs-example" defaultActiveKey="Outbound">
            <Row>
              <Col xs={12}>
                <div className="Trade_Tabs">
                  <Nav variant="pills" className="Bottomline_tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="Outbound">Stake</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Inbound">Withdraw</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xs={12}>
                <Tab.Content className="Trade_Tabs_content token_tab_content">
                  <Tab.Pane eventKey="Outbound">
                    <div className="token_box">
                      <div className="token_amount d-flex align-content-center justify-content-between">
                        <div className="amount_content d-flex">
                          <span>
                            <img src={token_icon} alt="icon" />
                          </span>
                          <div className="token_data">
                            <h6>Amount</h6>
                            <h5>Shido Token</h5>
                          </div>
                        </div>
                        <div className="amount_value">
                          <Form.Control
                            type="number"
                            placeholder="1.00"
                            className="text-end p-1"
                          />
                          <h5 className="amount_value__conversion">~$0.5634</h5>
                        </div>
                      </div>
                    </div>
                    <div className="stake_value d-flex align-content-center justify-content-between">
                      <div className="stake_content">
                        <span>25%</span>
                      </div>
                      <div className="stake_content">
                        <span>50%</span>
                      </div>
                      <div className="stake_content">
                        <span>75%</span>
                      </div>
                      <div className="stake_content">
                        <span>100%</span>
                      </div>
                    </div>
                    <div className="stake_swap_card">
                      <div className="stake_tite">
                        <p>Your active stake</p>
                        <h6>
                          <span>0 </span>
                          <img src={direction_arrow} alt="icon" /> 0 Shido
                        </h6>
                      </div>
                      <div className="stake_tite">
                        <p>Your pending stake</p>
                        <h6>
                          {" "}
                          <span>0</span>{" "}
                          <img src={direction_arrow} alt="icon" /> 1 Shido{" "}
                        </h6>
                      </div>
                      <div className="stake_tite">
                        <p>
                          Utilization starts
                          <span>
                            <img src={info_icon} alt="icon" />{" "}
                          </span>
                        </p>
                        <h6>14 Oct 13:30 UTC+5.5</h6>
                      </div>
                      <div className="stake_tite">
                        <p>You receive </p>
                        <h6> 0.95250306 LP </h6>
                      </div>
                    </div>
                    <div className="stake_fund_content">
                      <div className="fund_content">
                        <p>
                          A fee will apply to staked funds on withdrawal: 0.25%
                          or ~ 0.0025 1INCH
                        </p>
                      </div>
                      <p className="fixed_fee">
                        Fixed fee of 5 1INCH applies for scheduling your stake
                        by Opium relayer
                      </p>
                    </div>
                    <ConnectWallet className="mt-4 w-100" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Inbound">
                    <div className="NotYet_Box p-4">
                      <p className="text-center mt-4">
                        You haven't received any orders yet Inbound
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default ShidoToken;
