import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Wallet } from "../../Constants";

type AppState = {
  connectWalletModal: boolean;
  wallet: {
    connected: boolean;
    provider?: Wallet;
  };
  menuVisible: boolean;
};

const initialState: AppState = {
  connectWalletModal: false,
  wallet: {
    connected: false,
  },
  menuVisible: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleConnectWalletModal: (state) => {
      state.connectWalletModal = !state.connectWalletModal;
      return state;
    },
    toggleMenu: (state) => {
      state.menuVisible = !state.menuVisible;
      return state;
    },
    setWallet: (
      state,
      action: PayloadAction<{ connected: boolean; provider?: Wallet }>,
    ) => {
      state.wallet = action.payload;
      return state;
    },
  },
});

export const { toggleConnectWalletModal, toggleMenu, setWallet } =
  appSlice.actions;

export const appState = (state: RootState) => state.app;

export default appSlice.reducer;
