import { Col } from "antd";
import { Text } from "../../../Text";
import "./stats.scss";

interface StatisticItemProps {
  text: string;
  amount: string;
}

export const Stats = () => {
  return (
    <Col className="stats" xs={24} sm={24} md={24} lg={11} xl={16} xxl={16}>
      <Text fontSize={24} fontWeight={600} color="#FFFFFF">
        Stats
      </Text>
      <div className="statistic-items">
        <StatisticItem text="Tvl" amount="$1.1B" />
        <StatisticItem text="Market Cap" amount="$402.2B" />
        <StatisticItem text="FDV" amount="$402.2B" />
        <StatisticItem text="1 day volume" amount="$256.1M" />
      </div>

      <div className="statistic-items-mobile">
        <div className="item-row1">
          <StatisticItem text="Tvl" amount="$1.1B" />
          <StatisticItem text="Market Cap" amount="$402.2B" />
        </div>
        <div className="item-row2">
          <StatisticItem text="FDV" amount="$402.2B" />
          <StatisticItem text="1 day volume" amount="$256.1M" />
        </div>
      </div>
    </Col>
  );
};

const StatisticItem = (props: StatisticItemProps) => {
  const { text, amount } = props;
  return (
    <div className="statistic-item">
      <Text fontSize={14} fontWeight={500} color="#7C7C82" className="text">
        {text}
      </Text>
      <Text fontSize={24} fontWeight={500} color="#FFFFFF" className="text">
        {amount}
      </Text>
    </div>
  );
};
