import { useState } from "react";
import { useDispatch } from "react-redux";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import "./SwapButtonCrossChain.scss";

import { Button, Card, Modal, Row, message } from "antd";
import { BigNumber } from "ethers";
import { erc20ABI, useAccount } from "wagmi";
import Web3 from "web3";
import { colors } from "../../../Assets/Theme/colors";
import {
  setShowTransactionModal,
  setSwapTransactionLoading,
} from "../../../redux/reducers/loadingData/loadingData";
import { decimalFormatWithRoundOffDollar } from "../../../services/helpers/swapHelpers";
import { getMessageConfig } from "../../../utils";
import { Text } from "../../Text";
const SwapButtonCrossChain = ({
  className,
  tokenSelect1,
  tokenSelect2,
  input1Amount,
  input2Amount,
  userTokenOneBalance,
  receiverAddressInvalid,
  receiverAddressEmpty,
  selectedRoute,
  token1DollarValue,
  token2DollarValue,
  routeError,
  minAmountError,
  loading,
}: any) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successHash, setSuccessHash] = useState<string>("");
  const { address, connector } = useAccount();

  const executeCrossChainSwap = async () => {
    dispatch(setShowTransactionModal(true));
    dispatch(setSwapTransactionLoading(true));
    setSuccessHash("");

    try {
      const web3 = new Web3(await connector?.getProvider());

      const transactionRequest = selectedRoute.transactionRequest;

      //  get router allowance
      if (
        String(tokenSelect1.address).toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        try {
          const fromTokenContract = new web3.eth.Contract(
            // @ts-ignore
            erc20ABI,
            tokenSelect1.address,
          );

          // Check current allowance
          const currentAllowance = await fromTokenContract.methods
            .allowance(address, transactionRequest.targetAddress)
            .call();

          // If the current allowance is sufficient, no need to approve again
          if (
            BigNumber.from(currentAllowance).lt(
              BigNumber.from(selectedRoute.estimate.fromAmount),
            )
          ) {
            const isUSDT =
              tokenSelect1.address ===
              "0xdac17f958d2ee523a2206206994597c13d831ec7";

            if (isUSDT && Number(currentAllowance.toString()) !== 0) {
              // If current allowance is non-zero, set it to zero first
              const zeroEstimateGas = await fromTokenContract.methods
                .approve(transactionRequest.targetAddress, 0)
                .estimateGas({ from: address });

              if (!zeroEstimateGas) {
                throw new Error(
                  "Failed to estimate gas for zero approval transaction.",
                );
              }

              // Approve the spending with the estimated gas
              await fromTokenContract.methods
                .approve(transactionRequest.targetAddress, 0)
                .send({
                  from: address,
                  gas: zeroEstimateGas,
                });
            }

            // Estimate gas for the final approval transaction
            const gasEstimate = await fromTokenContract.methods
              .approve(
                transactionRequest.targetAddress,
                selectedRoute.estimate.fromAmount,
              )
              .estimateGas({ from: address });

            if (!gasEstimate) {
              throw new Error(
                "Failed to estimate gas for approval transaction.",
              );
            }

            // Approve the spending with the estimated gas
            await fromTokenContract.methods
              .approve(
                transactionRequest.targetAddress,
                selectedRoute.estimate.fromAmount,
              )
              .send({
                from: address,
                gas: gasEstimate,
              });
          }
        } catch (error: any) {
          if (error?.message) message.error(error?.message);
          throw new Error("Failed to approve Router spend.");
        }
      }
      //

      const transactionData: any = {
        from: address,
        to: transactionRequest.targetAddress,
        data: transactionRequest.data,
        value: transactionRequest.value,
      };

      // Estimate the gas for the transaction
      const gasEstimate = await web3.eth.estimateGas(transactionData);

      transactionData["gas"] = gasEstimate;

      // Execute the swap transaction with gas estimate
      const tx = await web3.eth.sendTransaction(transactionData);

      setSuccessHash(tx.transactionHash);

      message.success(
        getMessageConfig({
          type: "success",
          title: "Transaction successfull",
        }),
      );
    } catch (error) {
      console.error("Transaction failed:", error);
      // Handle error (show error message, etc.)
    }

    dispatch(setShowTransactionModal(false));
    dispatch(setSwapTransactionLoading(false));
  };

  return (
    <>
      <ButtonCustom
        fullWidth
        size={"large"}
        title={
          Number(input1Amount) > Number(userTokenOneBalance)
            ? "Insufficient Balance"
            : "Swap"
        }
        disabled={
          // tradeDataLoading ||
          loading ||
          minAmountError.length > 0 ||
          routeError.length > 0 ||
          receiverAddressEmpty ||
          receiverAddressInvalid ||
          Number(input1Amount) > Number(userTokenOneBalance) ||
          !input1Amount ||
          !input2Amount
        }
        className={className}
        onClick={() => setShowModal(true)}
      />

      {selectedRoute && (
        <Modal
          title="Confirm"
          okText={"Confirm"}
          open={showModal}
          onOk={() => executeCrossChainSwap()}
          onCancel={() => {
            setSuccessHash("");
            setShowModal(false);
          }}
        >
          <div className={"cross-confirm-modal"}>
            {successHash.length > 0 && (
              <Row>
                <Card
                  style={{
                    backgroundColor: `${colors.success}15`,
                    borderRadius: 8,
                    marginBottom: "1em",
                    width: "100%",
                  }}
                  className={"card-sm-padding"}
                >
                  <Text color={colors.success}>
                    <a
                      style={{ color: "inherit" }}
                      href={`https://axelarscan.io/gmp/${successHash}`}
                      target="_blank"
                    >
                      Transaction successfull! Click here to view status
                    </a>
                  </Text>
                </Card>
              </Row>
            )}
            <div className={"panel"}>
              <div className={"panel-title"}>
                <span>You Pay</span>

                <span>
                  $
                  {decimalFormatWithRoundOffDollar(
                    Number(selectedRoute.estimate.fromAmountUSD),
                  )}
                </span>
              </div>
              <div className={"panel-box"}>
                <div className={"coin-box"}>
                  <img src={tokenSelect1.logoURI} className="coin-img" />
                  <div>
                    <div className={"chain"}>{tokenSelect1.name}</div>
                    <div className={"amount"}>{tokenSelect1.symbol}</div>
                  </div>
                </div>
                <div className={"amount large-text"}>
                  {Number(selectedRoute.estimate.fromAmount) /
                    10 ** tokenSelect1.decimals}
                </div>
              </div>
            </div>
            <div className={"panel"}>
              <div className={"panel-title"}>
                <span>You Receive</span>
                <span>
                  {" "}
                  $
                  {decimalFormatWithRoundOffDollar(
                    Number(selectedRoute.estimate.toAmountUSD),
                  )}
                </span>
              </div>
              <div className={"panel-box"}>
                <div className={"coin-box"}>
                  <img src={tokenSelect2.logoURI} className="coin-img" />
                  <div>
                    <div className={"chain"}>{tokenSelect2.name}</div>
                    <div className={"amount"}>{tokenSelect2?.symbol}</div>
                  </div>
                </div>
                <div className={"amount large-text"}>
                  {Number(selectedRoute.estimate.toAmount) /
                    10 ** tokenSelect2.decimals}
                </div>
              </div>
            </div>
            <div className={"summary"}>
              <div className={"summary-box"}>
                <span>Minimum received</span>
                <span>
                  {Number(selectedRoute.estimate.toAmountMin) /
                    10 ** tokenSelect2.decimals}{" "}
                  {tokenSelect2?.symbol}
                </span>
              </div>
              <div className={"summary-box"}>
                <span>Est. Cost</span>
                <span>
                  {selectedRoute?.estimate?.feeCosts[0]?.amountUSD} USD
                </span>
              </div>
              <div className={"summary-box"}>
                <span>Est. Arrival Time</span>
                <span>
                  ~ {selectedRoute.estimate.estimatedRouteDuration} secs
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SwapButtonCrossChain;
