import React from "react";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import "./LiquidityCommon.scss";

const LiquidityCommon = ({
  classBar,
  classText,
  now,
  formLabel,
  placeholder,
  valueTotle,
  TotleAm,
  Address,
  AddressValue,
}: any) => {
  return (
    <div className="Containt">
      <Row className="align-items-center">
        <Col lg={6} md={12}>
          <div className="BarProgress">
            <Form.Label>{formLabel}</Form.Label>
            <div className="d-flex align-items-center">
              <ProgressBar className={`prog ${classBar}`} now={now} />
              <Form.Control
                type="text"
                placeholder={placeholder}
                className={`TextConrol ${classText}`}
              />
            </div>
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div className="TotleValues">
            <div className="ValueList">
              <label className="labelHed">{valueTotle}</label>
              <h6 className="SubTotle">{TotleAm}</h6>
            </div>
            <div className="ValueList">
              <label className="labelHed">{Address}</label>
              <h6 className="SubTotle">{AddressValue}</h6>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LiquidityCommon;
