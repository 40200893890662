import { Card, Col, Row } from "antd";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { HomeHeaderTL } from "../../../../Assets/animation/HomeHeader";
import arrowDown from "../../../../Assets/Images/arrow-down.svg";
import ethereumIcon from "../../../../Assets/Images/ethereum-icon.svg";
import shidoIcon from "../../../../Assets/Images/shido-icon.svg";
import { HeaderText, Text } from "../../../Text";
import { Swapper } from "../Swapper/Swapper";
import "./header.scss";

export const HomeHeader = () => {
  const HomeHeaderRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      HomeHeaderTL(HomeHeaderRef.current);
    }, HomeHeaderRef);

    return () => ctx.revert();
  }, [HomeHeaderRef]);

  return (
    <div className="HomeHeader" ref={HomeHeaderRef}>
      <Col span={24}>
        <Card
          className="card"
          style={{
            background: "linear-gradient(91deg, #2B66F6 2.17%, #4CA8F8 98.2%)",
          }}
        >
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={10}
              xxl={8}
              className="img-container card-item-left"
            >
              <div className="swappers">
                <Swapper
                  isHeader={true}
                  icon={ethereumIcon}
                  currency={"ETH"}
                  text={"Swap from"}
                />
                <div className="arrow">
                  <img src={arrowDown} />
                </div>
                <Swapper
                  isHeader={true}
                  icon={shidoIcon}
                  currency={"SHIDO"}
                  text={"Swap to"}
                />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={1} xl={1} xxl={2}></Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={13}
              xxl={10}
              className="text-section card-item-right"
            >
              <Row>
                <Col className="header card-col" span={24}>
                  <HeaderText
                    className="heading"
                    fontWeight={700}
                    style={{ lineHeight: "normal" }}
                  >
                    Unlock your crypto potential with{" "}
                    <span style={{ color: "#071B33" }}>SHIDO</span>
                  </HeaderText>
                </Col>
                <Col span={24} className="text card-col">
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    style={{ lineHeight: "normal" }}
                  >
                    Embark on your crypto adventure – connect your wallet now
                    and experience seamless swaps, secure stakes, and a world of
                    financial empowerment on SHIDO.
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
};
