import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Chart = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9.5C18.5523 9.5 19 9.94772 19 10.5V20.5C19 21.0523 18.5523 21.5 18 21.5C17.4477 21.5 17 21.0523 17 20.5V10.5C17 9.94772 17.4477 9.5 18 9.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.5C12.5523 3.5 13 3.94772 13 4.5V20.5C13 21.0523 12.5523 21.5 12 21.5C11.4477 21.5 11 21.0523 11 20.5V4.5C11 3.94772 11.4477 3.5 12 3.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13.5C6.55228 13.5 7 13.9477 7 14.5V20.5C7 21.0523 6.55228 21.5 6 21.5C5.44772 21.5 5 21.0523 5 20.5V14.5C5 13.9477 5.44772 13.5 6 13.5Z"
      fill="white"
    />
  </svg>
);

export const ChartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Chart} {...props} />
);
