import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { supportedChains } from "../../../App";
import "./CrossChainRoute.scss";

const { Column, ColumnGroup } = Table;
const SwapButtonCrossChainRoute = ({
  selectedRoute,
  fromChainValue,
  toChainValue,
  setSelectedRoute,
  fromToken,
  toToken,
}: any) => {
  const fromChain = () => {
    return supportedChains.find((chain) => chain.value === fromChainValue);
  };
  const toChain = () => {
    return supportedChains.find((chain) => chain.value === toChainValue);
  };

  const [feeCosts, setFeeCosts] = useState(0);
  const [gasCosts, setGasCosts] = useState(0);

  const selectedtoken1 = useSelector(
    (state: any) => state.swapDataCrossChain.token1Cross,
  );
  const getCost = (route: any) => {
    return (selectedtoken1?.usd * route?.fees?.bridgeFee?.amount).toString();
  };

  useEffect(() => {
    if (selectedRoute?.estimate?.feeCosts) {
      let total = 0;
      for (const fee of selectedRoute?.estimate?.feeCosts) {
        total += Number(fee.amountUSD);
      }
      setFeeCosts(Number(total.toFixed(3)));
    }

    if (selectedRoute?.estimate?.gasCosts) {
      let total = 0;
      for (const fee of selectedRoute?.estimate?.gasCosts) {
        total += Number(fee.amountUSD);
      }
      setGasCosts(Number(total.toFixed(3)));
    }
  }, [selectedRoute]);

  return (
    <>
      <Card className="route-card">
        {selectedRoute ? (
          <>
            <div style={{ textAlign: "left" }}>
              <h6>Cross-chain Swap Route</h6>
              {selectedRoute && (
                <>
                  {selectedRoute ? (
                    <div className={"selected-route"}>
                      <div className={"middleware-top"}>
                        <div className="coin-box">
                          <div className="img-box">
                            <img
                              src={selectedRoute?.params?.fromToken?.logoURI}
                              className="asset-img"
                            />
                            <img
                              src={"/chains/" + fromChainValue + ".svg"}
                              className="coin-img"
                            />
                          </div>
                          <div>
                            <div className={"asset"}>
                              {Number(selectedRoute?.estimate?.fromAmount) /
                                10 **
                                  Number(
                                    selectedRoute?.params?.fromToken?.decimals,
                                  )}
                              <span>
                                {selectedRoute?.params?.fromToken?.symbol}
                              </span>
                            </div>
                            <span className={"chain"}>
                              on {fromChain().name}
                            </span>
                          </div>
                        </div>
                        <div className="coin-box">
                          <div>
                            <div className={"asset"}>
                              {Number(selectedRoute?.estimate?.toAmount) /
                                10 **
                                  Number(
                                    selectedRoute?.params?.toToken?.decimals,
                                  )}
                              <span>
                                {selectedRoute?.params?.toToken?.symbol}
                              </span>
                            </div>
                            <span className={"chain"}>on {toChain().name}</span>
                          </div>
                          <div className="img-box">
                            <img
                              src={selectedRoute?.params?.toToken?.logoURI}
                              className="asset-img"
                            />
                            <img
                              src={"/chains/" + toChainValue + ".svg"}
                              className="coin-img"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cross-current__middleware">
                        <div className="path_line" />
                        <div
                          className="path_swap"
                          style={{ marginLeft: "20px" }}
                        >
                          <div className={"dt"}>
                            <span>
                              {
                                selectedRoute?.estimate?.route?.fromChain[0]
                                  ?.dex?.dexName
                              }
                            </span>
                          </div>
                          <div>
                            <span>Time:</span>
                            <span>
                              ~{" "}
                              {selectedRoute?.estimate?.estimatedRouteDuration}{" "}
                              seconds
                            </span>
                          </div>
                          <div>
                            <span>Transaction Cost:</span>
                            <span className="amount">~${gasCosts}</span>
                          </div>
                        </div>
                        <div className="path_swap token">
                          <div className="img-box">
                            <img
                              src={selectedRoute?.params?.fromToken?.logoURI}
                              className="asset-img"
                            />
                            <img
                              src={"/chains/" + toChainValue + ".svg"}
                              className="coin-img"
                            />
                          </div>
                          <div className="text-center">
                            <div>
                              <span className="amount">
                                {Number(selectedRoute?.estimate?.toAmount) /
                                  10 **
                                    Number(
                                      selectedRoute?.params?.toToken?.decimals,
                                    )}
                              </span>
                              <span>
                                {selectedRoute?.params?.toToken?.symbol}
                              </span>
                            </div>
                            <div className="chain">
                              <span>on </span>
                              <span>{toChain().name}</span>
                            </div>
                          </div>
                        </div>
                        <div className="path_swap">
                          <div className={"dt"}>
                            <img
                              src={selectedRoute?.params?.fromToken?.logoURI}
                              className="icon-asset"
                            />
                            <img
                              src={selectedRoute?.params?.toToken?.logoURI}
                              className="icon-asset"
                            />
                          </div>
                          <div className="text-center">
                            <div>
                              <span>
                                {
                                  selectedRoute?.estimate?.route?.toChain[0]
                                    ?.dex?.dexName
                                }
                              </span>
                            </div>
                            <div>
                              <span>Transaction Cost:</span>
                              <span className="amount">~$ {feeCosts}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={"selected-route no-middleware"}>
                      <div className="coin-box">
                        <div className="img-box">
                          <img
                            src={selectedRoute?.bridgeRoute?.fromAsset?.icon}
                            className="asset-img"
                          />
                          <img
                            src={"/chains/" + fromChainValue + ".svg"}
                            className="coin-img"
                          />
                        </div>
                        <div>
                          <div className={"asset"}>
                            {Number(selectedRoute?.estimate?.fromAmount) /
                              10 **
                                Number(
                                  selectedRoute?.params?.fromToken?.decimals,
                                )}
                            <span>
                              {selectedRoute?.params?.fromToken?.symbol}
                            </span>
                          </div>
                          <span className={"chain"}>on {fromChain().name}</span>
                        </div>
                      </div>
                      <div className={"from path_line"} />
                      <div className={"asset"}>
                        <div style={{ textAlign: "center" }}>
                          {
                            selectedRoute?.estimate?.route?.toChain[0]?.dex
                              ?.dexName
                          }
                        </div>
                        <div>
                          Transaction Cost: ~ ${" "}
                          {selectedRoute?.estimate?.gasCosts[0]?.amountUSD}
                        </div>
                      </div>
                      <div className={"to path_line"} />
                      <div className="coin-box">
                        <div className="img-box">
                          <img
                            src={"/chains/" + toChainValue + ".svg"}
                            className="coin-img"
                          />
                        </div>
                        <div>
                          <div className={"asset"}>
                            {Number(selectedRoute?.estimate?.toAmount) /
                              10 **
                                Number(
                                  selectedRoute?.params?.toToken?.decimals,
                                )}
                            <span>
                              {selectedRoute?.params?.toToken?.symbol}
                            </span>
                          </div>
                          <span className={"chain"}>on {toChain().name}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>No Route Found</>
        )}
      </Card>
    </>
  );
};

export default SwapButtonCrossChainRoute;
