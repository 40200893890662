interface Token {
  symbol: string;
  name: string;
}

interface TokensByChain {
  [key: number]: Token[];
}


export const TOKENS_BY_CHAIN: TokensByChain = {
  56: [ // Binance Smart Chain
    { symbol: 'BNB', name: 'BNB' },
    { symbol: 'DOGE', name: 'Dogecoin' },
    { symbol: 'TONCOIN', name: 'Toncoin' },
    { symbol: 'LINK', name: 'Chain Link' },
    { symbol: 'MATIC', name: 'Matic Token' },
    { symbol: 'UNI', name: 'Uniswap' },
    { symbol: 'DAI', name: 'Dai' },
    { symbol: 'USDC', name: 'USD Coin' },
    { symbol: 'ATOM', name: 'Cosmos Hub' },
    { symbol: 'FTM', name: 'Fantom' }
  ],
  1: [ // Ethereum Ecosystem
    { symbol: 'ETH', name: 'Ethereum' },
    { symbol: 'USDT', name: 'Tether' },
    { symbol: 'USDC', name: 'USD Coin' },
    { symbol: 'DAI', name: 'Dai Stablecoin' },
    { symbol: 'TONCOIN', name: 'Wrapped Ton Coin' },
    { symbol: 'SHIB', name: 'Shiba Inu' },
    { symbol: 'WBTC', name: 'Wrapped BTC' },
    { symbol: 'LINK', name: 'Chain Link' },
    { symbol: 'MATIC', name: 'Matic Token' },
    { symbol: 'UNI', name: 'Uniswap' }
  ],
  137: [ // Polygon
    { symbol: 'USDC', name: 'USD Coin' },
    { symbol: 'WBTC', name: 'Wrapped Bitcoin' },
    { symbol: 'LINK', name: 'ChainLink Token' },
    { symbol: 'MATIC', name: 'MATIC' },
    { symbol: 'UNI', name: 'Uniswap' },
    { symbol: 'DAI', name: '(PoS) Dai Stablecoin' },
    { symbol: 'GRT', name: 'Graph Token (PoS)' },
    { symbol: 'LDO', name: 'Lido DAO Token (PoS)' },
    { symbol: 'AAVE', name: 'Aave' },
    { symbol: 'SAND', name: 'SAND' }
  ],
  43114: [ // Avalanche
    { symbol: 'USDT', name: 'TetherToken' },
    { symbol: 'BNB', name: 'BNB' },
    { symbol: 'USDC', name: 'USD Coin' },
    { symbol: 'AVAX', name: 'Avalanche' },
    { symbol: 'WBTC.e', name: 'Wrapped BTC' },
    { symbol: 'LINK.e', name: 'Chainlink' },
    { symbol: 'DAI.e', name: 'Dai Stablecoin' },
    { symbol: 'AAVE.e', name: 'Aave Token' },
    { symbol: 'FRAX', name: 'Frax' },
    { symbol: 'PENDLE', name: 'Pendle' }
  ],
  42161: [ // Arbitrum One
    { symbol: 'USDC', name: 'USDC' },
    { symbol: 'WBTC', name: 'Wrapped Bitcoin' },
    { symbol: 'LINK', name: 'Chainlink' },
    { symbol: 'UNI', name: 'Uniswap' },
    { symbol: 'DAI', name: 'Dai' },
    { symbol: 'EZETH', name: 'Renzo Restaked ETH' },
    { symbol: 'ARB', name: 'Arbitrum' },
    { symbol: 'GRT', name: 'The Graph' },
    { symbol: 'MKR', name: 'Maker' },
    { symbol: 'LDO', name: 'Lido DAO' }
  ]
};
