import { CloseOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNetwork } from 'wagmi';
import { colors } from "../../../Assets/Theme/colors";
import { PrimaryButton } from "../../Button";
import { Text } from "../../Text";
// import { openoceanLimitOrderSdk } from "@openocean.finance/limitorder-sdk";


export default function TradingViewWidget(props:any) {
    const limitOrderAction = useSelector(
        (state: any) => state.limitOrderData.action
    );
    const {chain, chains} = useNetwork()
    const selectedtoken1 = useSelector((state: any) => state.swapData.token1);
    const selectedtoken2 = useSelector((state: any) => state.swapData.token2);
    const [showAlert, setShowAlert] = useState(true);
    const [showInfo, setShowinfo] = useState(false);
    const [finalAddress, setFinalAddress] = useState("");
    const [chainName, setChainName] = useState("");
    const onLoadScriptRef = useRef();

    const selectedToken2 = useSelector((state:any) => state.swapData.token2);

    useEffect(() => {
        if (chain && chain.id === 1 && selectedtoken1 && selectedtoken1.symbol === 'ETH') {
            setFinalAddress('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2')
        } else if(!chain && selectedtoken1 && selectedtoken1.symbol === 'ETH'){
            setFinalAddress('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2')
        } else{
            setFinalAddress(selectedtoken1?.address);
        }
    }, [chain, limitOrderAction, selectedtoken1, selectedtoken2]);
    useEffect(() => {
        if(chain) {
            if (chain.id === 1) {
                setChainName('ethereum')
            } else if (chain.id === 56) {
                setChainName('bsc')
            } else if (chain.id === 137) {
                setChainName('polygon')
            } else if (chain.id === 42161) {
                setChainName('arbitrum')
            } else if (chain.id === 43114) {
                setChainName('avalanche')
            } else if (chain.id === 250) {
                setChainName('fantom')
            } else {
                setChainName(chain.name.toLowerCase())
            }
        }else{
            setChainName('ethereum')
        }
    }, [chain]);


    return (
        <Row gutter={[20, 20]}>
            <Col span={24}>
                {finalAddress && chainName && <>
                        <div style={{textAlign: "right"}}>
                            <PrimaryButton
                                onClick={() => {
                                    setShowinfo(!showInfo);
                                }}
                            >
                                {showInfo ? "Hide" : "Show"} info
                            </PrimaryButton>
                        </div>
                        <iframe
                            src={`https://dexscreener.com/${chainName}/${finalAddress}?embed=1&theme=dark&trades=0&info=${
                                showInfo ? 1 : 0
                            }`}
                            style={{
                                height: "710px",
                                width: "100%",
                                marginTop: "10px",
                                borderRadius: "10px",
                            }}
                        />
                        {showAlert &&
                            <Col span={24}>
                                <div
                                    className={"clickable"}
                                    onClick={() => setShowAlert(false)}
                                    style={{
                                        top: -8,
                                        right: 2,
                                        width: 20,
                                        zIndex: 10,
                                        height: 20,
                                        display: "flex",
                                        padding: "0 4px",
                                        position: "absolute",
                                        alignItems: "center",
                                        borderRadius: "100%",
                                        justifyContent: "center",
                                        backgroundColor: colors.secondary600,
                                    }}
                                >
                                    <CloseOutlined style={{fontSize: 10}}/>
                                </div>
                                <Card
                                    style={{
                                        borderRadius: 8,
                                        backgroundColor: colors.secondary600,
                                        textAlign: "left",
                                    }}
                                >
                                    <Text>
                                        Please note even though the current market price shown on the
                                        chart matches your limit price, there’s a possibility that your
                                        limit order will not get filled instantly.
                                    </Text>
                                </Card>
                            </Col>
                        }
                    </>
                }
            </Col>
        </Row>
    );
}
