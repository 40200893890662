import { createSlice } from "@reduxjs/toolkit";
import { SwapDataCrossChain } from "../../../Constants/Interfaces/SwapDataCrossChain";
import {
  BNB_NETWORK,
  EHTEREUM_NETWORK,
} from "../../../Constants/NetworkNames/NetworkNames";

const initialState: SwapDataCrossChain = {
  slippageToleranceCross: 0.5,
  basePriceCross: 0,
  lowPriceCross: 0,
  avgPriceCross: 0,
  fastPriceCross: 0,
  lowWaitCross: 0,
  avgWaitCross: 0,
  fastWaitCross: 0,
  userSelectedGasCross: "",
  chosenSouresCross: "",
  token1Cross: "",
  token2Cross: "",
  token1CrossDetail: {},
  token2CrossDetail: {},
  userSelectedGasTypeCross: "MARKET",
  input1ValueReduxCross: 1,
  input2ValueReduxCross: "",
  calculatedGasPriceDollarsCross: "",
  convertedInput1ReduxCross: "",
  convertedInput2ReduxCross: "",
  buyPriceCross: "",
  sellPriceCross: "",
  buyPriceInDollarsCross: "",
  sellPriceInDollarsCross: "",
  fromChainCross: 1,
  toChainCross: 56,
  fromChainIdCross: 1,
  toChainIdCross: 56,
  priceImpactCross: "",
  gasFromChaingeCross: "",
  lowInputErrorCross: false,
  allToChainTokens: [],
  allFromChainTokens: [],
};

export const swapDataCrossChain = createSlice({
  name: "swapDataCrossChain",
  initialState,
  reducers: {
    resetSwapDataCrossSlice: (state: any, action) => {
      state.slippageToleranceCross = 0.5;
      state.basePriceCross = 0;
      state.lowPriceCross = 0;
      state.avgPriceCross = 0;
      state.fastPriceCross = 0;
      state.lowWaitCross = 0;
      state.avgWaitCross = 0;
      state.fastWaitCross = 0;
      state.userSelectedGasCross = "";
      state.chosenSouresCross = "";
      state.token1Cross = "";
      state.token2Cross = "";
      state.userSelectedGasTypeCross = "MARKET";
      state.input1ValueReduxCross = 1;
      state.input2ValueReduxCross = "";
      state.calculatedGasPriceDollarsCross = "";
      state.convertedInput1ReduxCross = "";
      state.convertedInput2ReduxCross = "";
      state.buyPriceCross = "";
      state.sellPriceCross = "";
      state.buyPriceInDollarsCross = "";
      state.sellPriceInDollarsCross = "";
      state.fromChainCross = 1;
      state.toChainCross = 56;
      state.fromChainIdCross = 1;
      state.toChainIdCross = 56;
      state.priceImpactCross = "";
      state.gasFromChaingeCross = "";
      state.lowInputErrorCross = false;
      state.token1CrossDetail = null;
      state.token2CrossDetail = null;
    },
    setSlippageToleranceCross: (state, action) => {
      state.slippageToleranceCross = action.payload;
    },
    setBaseGasPriceCross: (state, action) => {
      state.basePriceCross = action.payload;
    },
    setLowGasPriceCross: (state, action) => {
      state.lowPriceCross = action.payload;
    },
    setAverageGasPriceCross: (state, action) => {
      state.avgPriceCross = action.payload;
    },
    setFastGasPriceCross: (state, action) => {
      state.fastPriceCross = action.payload;
    },
    setLowGasWaitCross: (state, action) => {
      state.lowWaitCross = action.payload;
    },
    setAverageGasWaitCross: (state, action) => {
      state.avgWaitCross = action.payload;
    },
    setFastGasWaitCross: (state, action) => {
      state.fastWaitCross = action.payload;
    },
    setUserSelectedGasCross: (state, action) => {
      state.userSelectedGasCross = action.payload;
    },
    setUserSelectedGasTypeCross: (state, action) => {
      state.userSelectedGasTypeCross = action.payload;
    },
    setChosenSourcesCross: (state, action) => {
      state.chosenSouresCross = action.payload;
    },
    setToken1Cross: (state, action) => {
      state.token1Cross = action.payload;
    },
    setToken1CrossDetail: (state, action) => {
      state.token1CrossDetail = action.payload;
    },
    setToken2CrossDetail: (state, action) => {
      state.token2CrossDetail = action.payload;
    },
    setToken2Cross: (state, action) => {
      state.token2Cross = action.payload;
    },
    setInput1ValueReduxCross: (state, action) => {
      state.input1ValueReduxCross = action.payload;
    },
    setInput2ValueReduxCross: (state, action) => {
      state.input2ValueReduxCross = action.payload;
    },
    setCalculatedGasPriceDollarsReduxCross: (state, action) => {
      state.calculatedGasPriceDollarsCross = action.payload;
    },
    setConvertedInput1ReduxCross: (state, action) => {
      state.convertedInput1ReduxCross = action.payload;
    },
    setConvertedInput2ReduxCross: (state, action) => {
      state.convertedInput2ReduxCross = action.payload;
    },
    setBuyPriceCross: (state, action) => {
      state.buyPriceCross = action.payload;
    },
    setSellPriceCross: (state, action) => {
      state.sellPriceCross = action.payload;
    },
    setBuyPriceInDollarsCross: (state, action) => {
      state.buyPriceInDollarsCross = action.payload;
    },
    setSellPriceInDollarsCross: (state, action) => {
      state.sellPriceInDollarsCross = action.payload;
    },
    setFromChainCross: (state, action) => {
      state.fromChainCross = action.payload;
    },
    setToChainCross: (state, action) => {
      state.toChainCross = action.payload;
    },
    setFromChainIdCross: (state, action) => {
      state.fromChainIdCross = action.payload;
    },
    setToChainIdCross: (state, action) => {
      state.toChainIdCross = action.payload;
    },
    setPriceImpactCross: (state, action) => {
      state.priceImpactCross = action.payload;
    },
    setGasFromChaingeCross: (state, action) => {
      state.gasFromChaingeCross = action.payload;
    },
    setLowInputErrorCross: (state, action) => {
      state.lowInputErrorCross = action.payload;
    },
    setAllFromChainTokens: (state, action) => {
      state.allFromChainTokens = action.payload;
    },
    setAllToChainTokens: (state, action) => {
      state.allToChainTokens = action.payload;
    },
  },
});
export const {
  setSlippageToleranceCross,
  setBaseGasPriceCross,
  setLowGasPriceCross,
  setAverageGasPriceCross,
  setFastGasPriceCross,
  setLowGasWaitCross,
  setAverageGasWaitCross,
  setFastGasWaitCross,
  setUserSelectedGasCross,
  setChosenSourcesCross,
  setUserSelectedGasTypeCross,
  setToken1Cross,
  setToken1CrossDetail,
  setToken2CrossDetail,
  setToken2Cross,
  setInput1ValueReduxCross,
  setInput2ValueReduxCross,
  setConvertedInput1ReduxCross,
  setConvertedInput2ReduxCross,
  setCalculatedGasPriceDollarsReduxCross,
  resetSwapDataCrossSlice,
  setBuyPriceCross,
  setSellPriceCross,
  setBuyPriceInDollarsCross,
  setSellPriceInDollarsCross,
  setFromChainCross,
  setToChainCross,
  setFromChainIdCross,
  setToChainIdCross,
  setPriceImpactCross,
  setGasFromChaingeCross,
  setLowInputErrorCross,
  setAllFromChainTokens,
  setAllToChainTokens,
} = swapDataCrossChain.actions;

export default swapDataCrossChain.reducer;
