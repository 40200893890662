import { openoceanLimitOrderSdk } from "@openocean.finance/limitorder-sdk";
import { message } from "antd";
import {
  APIURL,
  APIURL_OPEN_OCEAN,
} from "../../../Constants/APIconstants/apiConstants";
import { SITE_URL } from "../../../Constants/AppVariables/appVariable";
import {
  LIMIT_ORDER_EXPIRE_DATE,
  OPEN_OCEAN_CHAIN_NAMES,
} from "../../../Constants/TYPES/openOceanChainNames";
import {
  apiCallGet,
  apiCallPost,
} from "../../../services/apiServices/ApiServices";
import { bigNumberToFixed } from "../../../services/helpers/swapHelpers";
import { callWeb3 } from "../../../services/walletServices";
import { getMessageConfig } from "../../../utils";
import {
  setShowTransactionModal,
  setSwapTransactionLoading,
  setTransactionErrorMessage,
  setTransactionSuccessMessage,
} from "../../reducers/loadingData/loadingData";
import store from "../../store";

const REACT_APP_ONEINCH_API_BASE_URL =
  process.env.REACT_APP_ONEINCH_API_BASE_URL ||
  "https://dev.api.shido.baboons.tech/api/swap";

/** Exchange Token function gives the best value for the particular pair
 * @param {tokenFromAddress, tokenToAddress,chainId}
 * @param dispatch
 * @returns
 */
const one_inch_url = "https://limit-orders.1inch.io";
export const bestValueCmcExchangeFunction = async (data: any) => {
  try {
    const result: any = await apiCallPost(SITE_URL, APIURL.CMC_EXCHANGE, data);
    if (result?.data?.status) {
      return result.data.data;
    }
  } catch (error: any) {
    console.log("bestValueCmcExchangeFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};

export const getActiveLimitOrders = async (
  chainId: Number,
  Address: String,
) => {
  try {
    const statuses = [1];
    const limit = 50;

    const headersData = {};

    const ENDPOINT =
      "/api/swap" +
      APIURL_OPEN_OCEAN.GET_LIMIT_ORDER_BY_ADDRESS +
      chainId +
      `?statuses=${JSON.stringify(
        statuses,
      )}&limit=${limit}&wallet_address=${Address}`;

    const result: any = await apiCallGet(SITE_URL, ENDPOINT, {}, headersData);

    if (result) {
      return result;
    }
  } catch (error: any) {
    console.log("getLimitOrderFunction ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};

// Fetch user limit order history
export const limitOrderHistoryOpenOcean = async (
  chainId: Number,
  address: String,
) => {
  const statuses = [3, 4, 7];
  const limit = 100;

  try {
    const headersData = {};

    const ENDPOINT =
      "/api/swap" +
      APIURL_OPEN_OCEAN.GET_LIMIT_ORDER_BY_ADDRESS +
      chainId +
      `?statuses=${JSON.stringify(
        statuses,
      )}&limit=${limit}&wallet_address=${address}`;

    const result: any = await apiCallGet(SITE_URL, ENDPOINT, {}, headersData);

    if (result) {
      return result;
    }
  } catch (error: any) {
    console.log("Error in limitOrderHistoryOpenOcean ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};

// Cancel Limit Order Function
export const cancelLimitOrderOpenOcean = async (
  chainId: number,
  orderHash: any,
) => {
  try {
    const payload = {
      orderHash: orderHash,
    };

    const headersData = {
      // apikey: OPEN_OCEAN_API_KEY,
    };
    const ENDPOINT =
      "/api/swap" + APIURL_OPEN_OCEAN.CANCEL_LIMIT_ORDER + chainId + "/";

    const result: any = await apiCallPost(
      SITE_URL,
      ENDPOINT,
      payload,
      {},
      headersData,
    );
    if (result?.status === 200) {
      message.success(
        getMessageConfig({
          type: "success",
          title: "Order cancelled successfully",
        }),
      );
      console.log("Order cancelled successfully:", result);
      return result;
    }
  } catch (error: any) {
    console.log("CreateLimitOrderFunction ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: "Failed to cancel order, please try again later",
      }),
    );
  }
};

// CreateLimitOrderOpenOcean Function
export const createLimitOrderOpenOcean = async (
  address: string,
  chainKey: OPEN_OCEAN_CHAIN_NAMES,
  chainID: number,
  makerTokenAddress: string,
  makerTokenDecimals: number,
  makerAmount: number,
  takerTokenAddress: string,
  takerTokenDecimals: number,
  takerAmount: number,
  expireTime: LIMIT_ORDER_EXPIRE_DATE,
) => {
  try {
    const web3 = await callWeb3();

    const gasPriceResult = await web3.eth.getGasPrice();
    const estimatedGasPrice = web3.utils.fromWei(gasPriceResult, "wei");

    const walletData = {
      provider: web3,
      chainKey: chainKey,
      chainId: chainID,
      account: address,
    };

    const makerAmountWithoutDecimals = makerAmount;
    const takerAmountWithoutDecimals = takerAmount;

    const OrderData = {
      makerTokenAddress: makerTokenAddress,
      makerTokenDecimals: makerTokenDecimals,
      takerTokenAddress: takerTokenAddress,
      takerTokenDecimals: takerTokenDecimals,
      makerAmount: bigNumberToFixed(
        makerAmountWithoutDecimals * 10 ** makerTokenDecimals,
      )?.toString(),
      takerAmount: bigNumberToFixed(
        takerAmountWithoutDecimals * 10 ** takerTokenDecimals,
      )?.toString(),
      gasPrice: estimatedGasPrice,
      expire: expireTime,
    };
    await putOrder(walletData, OrderData, chainID);
  } catch (error) {
    console.log("Error in createLimitOrderOpenOcean =>", error);
  }
};

const putOrder = async (wallet: any, order: any, chainID: number) => {
  try {
    let limitOrderSdkResult = await openoceanLimitOrderSdk.createLimitOrder(
      wallet,
      order,
    );
    if (limitOrderSdkResult) {
      await submitLimitOrderToAPI(chainID, limitOrderSdkResult);
    }
  } catch (error: any) {
    console.log("putOrder error =>", error);
    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      store.dispatch(
        setTransactionErrorMessage("Please try once with more gas fee"),
      );
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      store.dispatch(setTransactionErrorMessage("User rejected the request"));
      message.error(
        getMessageConfig({
          type: "error",
          title: "You have rejected this transaction!",
          body: "Please place a new order and accept the transaction in your wallet.",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      store.dispatch(
        setTransactionErrorMessage(
          "Something went wrong. Please try again later",
        ),
      );
      message.error(
        getMessageConfig({
          type: "error",
          title: "Your order could not get executed!",
          body: "The blockchain could not process your order right now. Please try again later.",
        }),
      );
    } else if (
      error?.message?.includes("gas required exceeds") ||
      error.code === -32000
    ) {
      store.dispatch(
        setTransactionErrorMessage(
          "Not enough gas balance to complete the transaction",
        ),
      );
      message.error(
        getMessageConfig({
          type: "error",
          title: "You are not using enough gas to complete this transaction!",
          body: "Please increase your gas to the required gas needed to complete this order.",
        }),
      );
    } else {
      store.dispatch(setTransactionErrorMessage("Something went wrong"));
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please reach out in our support chat so we can check your issue.",
        }),
      );
    }
    console.log("Error in => SwapByChaingeNative", error);
    return error;
  }
};

/** This is the 3rd & last step for creating the order i.e submitLimitOrderToAPI
 * @param chainId
 * @param dispatch
 * @returns
 */
export const submitLimitOrderToAPI = async (
  chainId: number,
  limitOrderSdkData: any,
) => {
  try {
    const ENDPOINT = chainId + APIURL_OPEN_OCEAN.LIMIT_ORDER;

    const result: any = await apiCallPost(
      "https://open-api.openocean.finance/v1/",
      ENDPOINT,
      limitOrderSdkData,
      {},
      {},
    );

    if (result) {
      store.dispatch(setSwapTransactionLoading(false));
      store.dispatch(setTransactionSuccessMessage(""));

      // message.success(
      //   getMessageConfig({
      //     type: "success",
      //     title: "Order created successfully",
      //   }),
      // );
    }
  } catch (error: any) {
    console.log("submitLimitOrderToAPI => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: "Failed to create order, please try again later",
      }),
    );
  }
  store.dispatch(setSwapTransactionLoading(false));
  store.dispatch(setShowTransactionModal(false));
};

//one inch
export const getActiveLimitOrders1nch = async (
  chainId: number,
  Address: string,
  Page?: number,
) => {
  try {
    const limit = 500;

    const headersData = {};

    const ENDPOINT =
      "/v4.0/" +
      chainId +
      `/address/${Address}?statuses=1&limit=${limit}&page=${Page ? Page : 1}`;

    const result: any = await apiCallGet(
      one_inch_url,
      ENDPOINT,
      {},
      headersData,
    );

    if (result) {
      return result;
    }
  } catch (error: any) {
    console.log("getLimitOrderFunction ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};

export const getLimitOrdersHistory1nch = async (
  chainId: number,
  Address: string,
  Page?: number,
) => {
  try {
    const limit = 500;

    const headersData = {};

    const ENDPOINT =
      "/v4.0/" +
      chainId +
      `/address/${Address}?statuses=1,2,3&limit=${limit}&page=${
        Page ? Page : 1
      }`;

    const result: any = await apiCallGet(
      one_inch_url,
      ENDPOINT,
      {},
      headersData,
    );

    if (result) {
      return result;
    }
  } catch (error: any) {
    console.log("getLimitOrderFunction ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};

export const submitLimitOrderToAPI1nch = async (
  chainId: number,
  payload: any,
) => {
  try {
    const ENDPOINT = "/v4.0/" + chainId;

    const result: any = await apiCallPost(one_inch_url, ENDPOINT, payload, {});
    console.log(result);

    if (result.status !== 400) {
      store.dispatch(setSwapTransactionLoading(false));

      store.dispatch(setTransactionSuccessMessage(""));

      // message.success(
      //   getMessageConfig({
      //     type: "success",
      //     title: "Order created successfully",
      //   }),
      // );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Failed to create order, please try again later",
        }),
      );
    }
  } catch (error: any) {
    console.log("submitLimitOrderToAPI => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: "Failed to create order, please try again later",
      }),
    );
  }
  store.dispatch(setSwapTransactionLoading(false));
  store.dispatch(setShowTransactionModal(false));
};

export const getEventsForOrderHash = async (
  chainId: number,
  orderHash: string,
) => {
  try {
    const ENDPOINT = `/order-events/?chain_id=${chainId}&order_hash=${orderHash}`;

    const result: any = await apiCallGet(
      REACT_APP_ONEINCH_API_BASE_URL,
      ENDPOINT,
      {},
    );
    return result;
  } catch (error: any) {
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: "Failed to get events",
      }),
    );
  }
  store.dispatch(setSwapTransactionLoading(false));
  store.dispatch(setShowTransactionModal(false));
};
