import { Empty, Spin } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ethers } from "ethers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { erc20ABI, useAccount } from "wagmi";
import Web3 from "web3";
import { colors } from "../../../../../Assets/Theme/colors";
import store from "../../../../../redux/store";
import { PrimaryButton } from "../../../../Button";
import { Text } from "../../../../Text";
import LimitOrderConfirmationModal from "./LimitOrderConfirmationModal";
import "./ReviewLimitOderTable.scss";

const ReviewLimitOrderTable = ({
  type,
  limitorderdata,
  orderHistoryData,
  cancelLimitOrderByHash,
}: any) => {
  const { lg } = useBreakpoint();
  const loading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );
  const { address, connector } = useAccount();

  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [orderTimeType, setOrderTimeType] = useState("EXPIRED_AT");
  const [formattedData, setFormattedData] = useState<any>([]);

  const [formattedHistory, setFormattedHistory] = useState<any>([]);

  const handleShowModal = (data: any) => {
    setSelectedOrder(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = async () => {
    if (selectedOrder) {
      const result = await cancelLimitOrderByHash(selectedOrder);
      console.log("cancel order result =>", result);
      handleCloseModal();
    }
  };
  const allTokens = store.getState().tokenCollection.swapTokens;

  const getTokenSymbol = async (address: string) => {
    const web3 = new Web3(await connector?.getProvider());
    // @ts-ignore
    const contract = new web3.eth.Contract(erc20ABI, address);
    let symbol = await contract.methods.symbol().call();

    switch (symbol) {
      case "WETH":
        return "ETH";
      case "WBNB":
        return "BNB";
      case "WAVAX":
        return "AVAX";
      case "WMATIC":
        return "MATIC";
      case "WETH_ARBITRUM":
        return "ETH";
      case "WMATIC_POLYGON":
        return "MATIC";
      case "WAVAX_AVALANCHE":
        return "AVAX";
      default:
        break;
    }
    return symbol;
  };

  const getTokenDecimals = async (tokenAddress: string) => {
    try {
      const web3 = new Web3(await connector?.getProvider());

      // @ts-ignore
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
      let decimals = await contract.methods.decimals().call();
      return decimals;
    } catch (error) {
      console.error("Error getting token decimals:", error);
      throw error;
    }
  };

  useEffect(() => {
    const formatData = async () => {
      if (limitorderdata && connector) {
        const promises = limitorderdata.map(async (data: any) => {
          const [makerDecimals, takerDecimals, makerSymbol, takerSymbol] =
            await Promise.all([
              getTokenDecimals(data.data.makerAsset),
              getTokenDecimals(data.data.takerAsset),
              getTokenSymbol(data.data.makerAsset),
              getTokenSymbol(data.data.takerAsset),
            ]);

          const makerAmountFormatted = ethers.utils.formatUnits(
            data.data.makingAmount,
            makerDecimals,
          );
          const takerAmountFormatted = ethers.utils.formatUnits(
            data.data.takingAmount,
            takerDecimals,
          );
          return {
            ...data,
            makerAmountFormatted,
            takerAmountFormatted,
            makerSymbol,
            takerSymbol,
          };
        });
        const results = await Promise.all(promises);

        setFormattedData(results);
      }
    };

    formatData();
  }, [limitorderdata, connector]);

  useEffect(() => {
    const formatData = async () => {
      if (orderHistoryData && connector) {
        const promises = orderHistoryData.map(async (data: any) => {
          try {
            const [makerDecimals, takerDecimals, makerSymbol, takerSymbol] =
              await Promise.all([
                getTokenDecimals(data.data.makerAsset),
                getTokenDecimals(data.data.takerAsset),
                getTokenSymbol(data.data.makerAsset),
                getTokenSymbol(data.data.takerAsset),
              ]);

            const makerAmountFormatted = ethers.utils.formatUnits(
              data.data.makingAmount,
              makerDecimals,
            );
            const takerAmountFormatted = ethers.utils.formatUnits(
              data.data.takingAmount,
              takerDecimals,
            );

            return {
              ...data,
              makerAmountFormatted,
              takerAmountFormatted,
              makerSymbol,
              takerSymbol,
            };
          } catch (error) {
            console.error("Error formatting order data:", error);
            return null;
          }
        });

        const results = await Promise.all(promises.filter(Boolean));

        setFormattedHistory(results);
      }
    };

    formatData();
  }, [orderHistoryData, connector]);

  return (
    <div>
      {loading && (
        <div>
          <Spin />
        </div>
      )}
      {type === "Exchange" && limitorderdata?.length > 0 ? (
        <div className={"newc-table my-4"}>
          <table className={" "}>
            <thead className="p-[10px] bg-[#F5F5F5]">
              <tr>
                <td className={"w-[100px]"}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    You Sell
                  </Text>
                </td>
                <td className={""}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    You Buy
                  </Text>
                </td>
                <td className={""}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Rate
                  </Text>
                </td>
                <td className={""} style={{ whiteSpace: "nowrap" }}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Expired/Created At
                  </Text>
                </td>

                <td className={"flex-1 text-black text-right"}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Status
                  </Text>
                </td>
                <td className={"flex-1 text-black text-right"}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Status
                  </Text>
                </td>
              </tr>
            </thead>

            <tbody>
              {formattedData &&
                formattedData.map((data: any, idx: number) => {
                  const makerAmount = data?.makerAmountFormatted;
                  const takerAmount = data?.takerAmountFormatted;

                  const rate = data?.takerRate;
                  return (
                    <tr key={idx}>
                      <td>
                        {makerAmount} {data.makerSymbol}
                      </td>
                      <td>
                        {takerAmount} {data.takerSymbol}
                      </td>
                      <td>{parseFloat(rate).toFixed(8)}</td>
                      <td>
                        {moment(data.createDateTime).format("DD-MM-YY hh:mm A")}
                      </td>
                      <td>
                        {data?.orderInvalidReason
                          ? data?.orderInvalidReason
                          : "Active"}
                      </td>
                      <td>
                        <PrimaryButton onClick={() => handleShowModal(data)}>
                          Cancel
                        </PrimaryButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : type === "TradeHistory" && orderHistoryData?.length > 0 ? (
        <div className={"newc-table my-4"}>
          <table className={" "}>
            <thead className="p-[10px] bg-[#F5F5F5]">
              <tr>
                <td className={"w-[100px]"}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    You Sell
                  </Text>
                </td>
                <td className={""}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    You Buy
                  </Text>
                </td>
                <td className={""}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Rate
                  </Text>
                </td>
                <td className={""} style={{ whiteSpace: "nowrap" }}>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Expired/Created At
                  </Text>
                </td>

                <td>
                  <Text fontWeight={500} color={colors.secondary500}>
                    Status
                  </Text>
                </td>
              </tr>
            </thead>

            <tbody>
              {formattedHistory &&
                formattedHistory.map((data: any, idx: number) => {
                  console.log(data)
                  if (!data) return null;

                  const makerAmount = data?.makerAmountFormatted;
                  const takerAmount = data?.takerAmountFormatted;

                  const rate = data?.takerRate;
                  return (
                    <tr key={idx}>
                      <td>
                        {makerAmount} {data.makerSymbol}
                      </td>
                      <td>
                        {takerAmount} {data.takerSymbol}
                      </td>
                      <td>{parseFloat(rate).toFixed(8)}</td>
                      <td>
                        {moment(data.createDateTime).format("DD-MM-YY hh:mm A")}
                      </td>
                      <td>
                        {data?.orderInvalidReason
                          ? data?.orderInvalidReason
                          : "Not enough balance"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <Empty />
      )}

      <LimitOrderConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        onConfirm={() => handleConfirm()}
      />
    </div>
  );
};

export default ReviewLimitOrderTable;
