import { Col, Row } from "antd";
import { GraphicalView } from "./Graph/GraphicalView";
import { Stats } from "./Stats/Stats";
import Swap from "./Swap/Swap";

import { Transactions } from "./Transactions/TransactionTable";

const SingleTokenHolder = () => {
  return (
    <Row gutter={[0, 20]}>
      <GraphicalView />
      <Swap />
      <Stats />
      <Col xs={0} sm={0} md={0} lg={1} xl={0} xxl={0}></Col>
      <Swap fromMain={true} />
      <Transactions />
    </Row>
  );
};

export default SingleTokenHolder;
