import OneInchSwap from "./OneInchSwap";
import "./SwapCard.scss";
import YouSellBuyCard from "./YouSellBuyCard";

const SwapCard = () => {
  return (
    <>
      <div className="SwapCards">
        <OneInchSwap />
      </div>
    </>
  );
};

export default SwapCard;
