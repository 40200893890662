import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Ethereum = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="#3B84F7" />
    <path
      d="M26.2143 19.4966C26.1775 19.4966 17.9873 24.3484 17.8797 24.3895C17.8557 24.3753 9.59332 19.4853 9.55368 19.491C9.5735 19.5207 17.8741 31.2418 17.8797 31.2418C17.8854 31.2418 26.2143 19.4966 26.2143 19.4966Z"
      fill="white"
    />
    <path
      d="M17.8797 4L9.57632 17.8872L17.8797 22.8055L26.1831 17.8872L17.8797 4Z"
      fill="white"
    />
  </svg>
);

export const EthereumIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Ethereum} {...props} />
);
