import { Card, Col, Input, Modal, Row, Spin } from "antd";
import _debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SelectTokenModal.scss";

import { SearchOutlined } from "@ant-design/icons";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { colors } from "../../../../Assets/Theme/colors";
import shidoTokenIcon from "../../../../Assets/icons/shido_token_img.png";
import tokenPlaceholder from "../../../../Assets/icons/token_img_placeholder.png";
import { searchTokens } from "../../../../redux/api/swap/swapAPIs";
import { addImportedToken } from "../../../../redux/reducers/tokenCollection/tokenCollection";
import { getTokenBalanceFull } from "../../../../services/contractServices/tokenServices";
import { PrimaryButton } from "../../../Button";
import { Text } from "../../../Text";

const SelectTokenModalSimple = ({
  handleClose,
  show,
  type,
  disableToken,
  selectedToken,
  onSelectToken,
}: any) => {
  const dispatch = useDispatch();
  const tokens = useSelector((state: any) => state.tokenCollection.swapTokens);
  const [searchMessage, setSearchMessage] = useState("");
  const [allTokensWithBalances, setAllTokensWithBalances] = useState<any>([]);
  const [filteredTokenList, setFilteredTokenList] = useState<any>([]);
  const currentPath = window.location.pathname;
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [searchedToken, setSearchedToken] = useState<any>(null);
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    if (searchMessage) {
      setLoading(true);
      searchTokens(searchMessage)
        .then((results) => {
          setFilteredTokenList(Array.isArray(results) ? results : []);
          setLoading(false);
          if (results.length === 0) {
            // No tokens found, clear previous search results
            setSearchedToken(null);
          }
        })
        .catch(() => {
          setLoading(false);
          setFilteredTokenList([]);
        });
    } else {
      setFilteredTokenList(tokens);
    }
  }, [searchMessage, tokens]);

  useEffect(() => {
    const fetchBalances = async () => {
      const tokensWithBalances = await Promise.all(
        tokens.map(async (token: any) => {
          const balance = await getTokenBalanceFull(
            address,
            token.address,
            web3,
          );
          return { ...token, balance: prettyFyBalance(parseFloat(balance)) };
        }),
      );
      setAllTokensWithBalances(tokensWithBalances);
      setFilteredTokenList(tokensWithBalances);
    };

    fetchBalances();
  }, [tokens, web3, address]);

  useEffect(() => {
    if (allTokensWithBalances) {
      if (searchMessage?.length > 0) {
        const filteredResults = allTokensWithBalances.filter((token: any) =>
          ["name", "address", "code", "symbol"].some((property) =>
            token[property]
              ?.toLowerCase()
              .includes(searchMessage?.toLowerCase()),
          ),
        );

        if (filteredResults) {
          setFilteredTokenList(filteredResults.slice(0, 40));
        }
      } else {
        setFilteredTokenList(
          [selectedToken].concat(
            [
              ...allTokensWithBalances.filter(
                (t: any) => t.symbol !== selectedToken.symbol,
              ),
            ].slice(0, 40),
          ),
        );
      }
    }
  }, [tokens, selectedToken, searchMessage]);

  const handleTokenSearch = async (userInput: any) => {
    setSearchMessage(userInput);
  };
  const debounceFn = useCallback(_debounce(handleTokenSearch, 1000), []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchMessage(value);

    if (value && !tokens.find((token: any) => token.address === value)) {
      setLoading(true);
      searchTokens(value)
        .then((results) => {
          setLoading(false);
          if (results.length > 0) {
            setSearchedToken(results[0]);
          } else {
            setSearchedToken(null);
          }
        })
        .catch(() => {
          setLoading(false);
          setSearchedToken(null);
        });
    } else {
      setSearchedToken(null);
    }
  };

  const handleTokenSelect = (item: any) => {
    if (item?.symbol === disableToken?.symbol) return;
    onSelectToken(item);
    handleClose();
  };

  const prettyFyBalance = (balance: any) => {
    if (!balance) return "0";
    const string = balance.toString();
    if (string.includes(".")) {
      const [whole, decimal] = string.split(".");
      if (decimal.length === 0) {
        return `${whole}`;
      }
      if (decimal.length === 1) {
        return balance.toFixed(2);
      }
      if (decimal.length === 2) {
        return balance.toFixed(2);
      }
      if (decimal.length === 3) {
        return balance.toFixed(3);
      }
      if (decimal.length === 4) {
        return balance.toFixed(4);
      }
      if (decimal.length > 4) {
        return balance.toFixed(6);
      }
    }
  };

  const handleImportToken = (item: any) => {
    setSearchedToken(item);

    setShowWarningModal(true);
  };

  const saveImportedTokenToLocalStorage = (token: any) => {
    const tokens = localStorage.getItem("importedTokens");
    const importedTokens = tokens ? JSON.parse(tokens) : [];
    const updatedTokens = [...importedTokens, token];
    localStorage.setItem("importedTokens", JSON.stringify(updatedTokens));
  };

  const handleAcceptWarning = () => {
    if (searchedToken) {
      dispatch(addImportedToken(searchedToken));
      saveImportedTokenToLocalStorage(searchedToken);
      handleTokenSelect(searchedToken);
    }
    setShowWarningModal(false);
  };

  const handleDenyWarning = () => {
    setShowWarningModal(false);
    setSearchedToken(null);
  };

  return (
    <>
      <Modal
        centered
        open={show}
        onCancel={() => {
          handleClose();
          setSearchMessage("");
        }}
        title={
          <Text fontSize={30} fontWeight={600}>
            Select a token
          </Text>
        }
        footer={null}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Input
              prefix={
                <SearchOutlined
                  style={{ color: colors.secondary400, marginRight: 8 }}
                />
              }
              placeholder={"Search by name or token address"}
              onChange={handleSearchChange}
            />
          </Col>

          <Card
            style={{
              backgroundColor: colors.secondary600,
              borderRadius: 12,
              maxHeight: "50vh",
              overflowY: "auto",
              width: "100%",
            }}
            className={`card-sm-padding scroll-container ${
              tokens === undefined ? "loading-container" : ""
            }`}
          >
            <Row>
              {loading ? (
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "24px",
                  }}
                >
                  <Spin size="large" />
                </Col>
              ) : (
                filteredTokenList &&
                filteredTokenList.map((item: any, idx: number) => (
                  <Col
                    key={idx}
                    span={24}
                    className={`clickable ${
                      item.symbol === disableToken?.symbol ? "disabled" : ""
                    }`}
                    onClick={() => handleTokenSelect(item)}
                  >
                    <Row
                      align="middle"
                      justify="space-between"
                      style={{ padding: "0.7em", borderRadius: 8 }}
                    >
                      <Col>
                        <Row align="middle" gutter={12}>
                          <Col>
                            <img
                              src={
                                item?.logoURI
                                  ? item?.logoURI
                                  : item?.address ===
                                    "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b"
                                  ? shidoTokenIcon
                                  : tokenPlaceholder
                              }
                              alt="img"
                              style={{ height: 44 }}
                            />
                          </Col>
                          <Col>
                            <div>{item.name}</div>
                            <div>{item.symbol}</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>{item.balance || "0"}</Col>
                    </Row>
                  </Col>
                ))
              )}
              {searchedToken &&
                !filteredTokenList.find(
                  (token: any) => token.address === searchedToken.address,
                ) && (
                  <Col span={24} className="clickable">
                    <Row
                      align="middle"
                      justify="space-between"
                      style={{ padding: "0.7em", borderRadius: 8 }}
                    >
                      <Col>
                        <Row align="middle" gutter={12}>
                          <Col>
                            <img
                              src={
                                searchedToken.logoURI
                                  ? searchedToken.logoURI
                                  : searchedToken.address ===
                                    "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b"
                                  ? shidoTokenIcon
                                  : tokenPlaceholder
                              }
                              alt="img"
                              style={{ height: 44 }}
                            />
                          </Col>
                          <Col>
                            <div>{searchedToken.name}</div>
                            <div>{searchedToken.symbol}</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <PrimaryButton
                          type="primary"
                          onClick={() => handleImportToken(searchedToken)}
                        >
                          Import
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Col>
                )}
            </Row>
          </Card>
        </Row>
      </Modal>

      <Modal
        centered
        open={showWarningModal}
        onCancel={handleDenyWarning}
        title={
          <Text fontSize={20} fontWeight={600}>
            Caution
          </Text>
        }
        footer={[
          <PrimaryButton key="deny" onClick={handleDenyWarning}>
            Deny
          </PrimaryButton>,
          <PrimaryButton
            key="accept"
            type="primary"
            onClick={handleAcceptWarning}
          >
            Accept
          </PrimaryButton>,
        ]}
      >
        <Text>
          Make sure you have checked the token address and it's correct.
          <br />
          <br />
          Anyone can create any token, including fake versions of the existing
          tokens. Caution required. Some tokens and their technical parameters
          may be incompatible with other protocols. By importing this custom
          token you acknowledge and accept the risks.
        </Text>
      </Modal>
    </>
  );
};

export default SelectTokenModalSimple;
