import { Card, Col, Input, Spin, Table } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import "chartjs-adapter-moment";
import { useState } from "react";
import { RxMagnifyingGlass } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { ArrowDownIconRed } from "../../../../Assets/icons/arrowDownRed";
import { ArrowUpIconGreen } from "../../../../Assets/icons/arrowUpGreen";
import { EthereumIcon } from "../../../../Assets/icons/Ethereum";
import { Text } from "../../../Text";
import "./shidoToken.scss";

const { Search } = Input;

export const ShidoTokens = () => {
  const dataSource = [
    {
      key: "1",
      name: "abc",
      price: "$4,028.52",
      hour: "77%",
      day: 12,
      fdv: "1.234",
      volume: "656454",
    },
    {
      key: "2",
      name: "def",
      price: "$4,028.52",
      hour: "000%",
      day: 12,
      fdv: "1.76",
      volume: "878",
    },
    {
      key: "3",
      name: "ghi",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
    {
      key: "4",
      name: "ghi",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
    {
      key: "5",
      name: "lmn",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
    {
      key: "6",
      name: "pqr",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
    {
      key: "7",
      name: "stu",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
    {
      key: "8",
      name: "abc",
      price: 32,
      hour: "0.08800%",
      day: 12,
      fdv: "3.356",
      volume: "966",
    },
  ];
  const { xs, sm, md, lg, xxl } = useBreakpoint();
  const [filterValue, setFilterValue] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const navigate = useNavigate();

  const handleSearch = (value: any) => {
    const filteredData = dataSource.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setFilteredDataSource(filteredData);
    setFilterValue(value);
  };

  const columns = [
    {
      title: "Token name",
      dataIndex: "name",
      className: "name",
      onCell: (name: any, rowIndex: any) => {
        return {
          onClick: () => {
            navigate("/singleTokenHolder");
            console.log(name, rowIndex);
          },
        };
      },
      render: (name: any, record: any) => (
        <>
          <div className="name-text">
            <EthereumIcon />
            <Text fontWeight={600} color="white" fontSize={"14px"}>
              {name}
            </Text>
            <Text fontWeight={500} color="#7C7C82" fontSize={"14px"}>
              ETH
            </Text>
          </div>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (amount: any, record: any) => (
        <>
          <Text fontWeight={500} color="white" fontSize={"14px"}>
            {amount}
          </Text>
        </>
      ),
    },
    {
      title: "1 hour",
      dataIndex: "hour",
      key: "hour",
      render: (hour: any, record: any) => (
        <>
          {true ? (
            <>
              <ArrowDownIconRed />
              <Text fontWeight={500} color="#EA332D" fontSize={"14px"}>
                {hour}
              </Text>
            </>
          ) : (
            <>
              <ArrowUpIconGreen />
              <Text fontWeight={500} color="#39CA7F" fontSize={"14px"}>
                {hour}
              </Text>
            </>
          )}
        </>
      ),
    },
    {
      title: "1 day",
      dataIndex: "day",
      key: "day",
      render: (day: any, record: any) => (
        <>
          {false ? (
            <>
              <ArrowDownIconRed />
              <Text fontWeight={500} color="#EA332D" fontSize={"14px"}>
                {day}
              </Text>
            </>
          ) : (
            <>
              <ArrowUpIconGreen />
              <Text fontWeight={500} color="#39CA7F" fontSize={"14px"}>
                {day}
              </Text>
            </>
          )}
        </>
      ),
    },
    {
      title: "FDV",
      dataIndex: "fdv",
      key: "fdv",
      render: (fdv: any, record: any) => (
        <>
          <Text fontWeight={500} color="white" fontSize={"14px"}>
            {fdv}
          </Text>
        </>
      ),
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
      className: "volume",
      render: (volume: any, record: any) => (
        <>
          <Text fontWeight={500} color="white" fontSize={"14px"}>
            {volume}
          </Text>
        </>
      ),
    },
  ];

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      style={{ paddingRight: "unset" }}
    >
      <Card className="token-card">
        <div className="heading-row">
          <Text fontWeight={600} color="white" fontSize={"16px"}>
            Tokens on SHIDO
          </Text>
        </div>
        {false ? (
          <Spin size={"large"} className="spinner" />
        ) : (
          <div className="staking-table">
            <Input
              value={filterValue}
              onChange={(e) => handleSearch(e.target.value)}
              style={{
                width: "400px",
                height: "auto",
                border: "none",
                marginTop: "16px",
              }}
              className="input"
              placeholder="Find a token"
              prefix={<RxMagnifyingGlass color="#9B9CA3" size={"24px"} />}
            />
            <Table
              dataSource={filteredDataSource}
              columns={columns}
              scroll={{ x: "max-content" }}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: false,
                // pageSizeOptions: ["5", "20", "30"],
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    console.log("evv", event);
                    navigate("/singleTokenHolder");
                  }, // click row
                };
              }}
              style={{
                marginTop: "16px",
              }}
            />
          </div>
        )}
      </Card>
    </Col>
  );
};
