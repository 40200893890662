export const APIURL = {
  BEST_VALUE: "/bestValue",
  HAVE_POOL: "/havePool",
  LIQUIDITY_SOURCES: "/getLiqSrc",
  DOLLAR_WORTH: "/dollar-worth",
  LIQUIDITY_RATIOS: "/liqRatio",
  TOKEN_DETAILS: "/tokenDetails",
  ETH_GAS_PRICES: "/gasFee",
  STAKING_DETAILS: "/stakingDetails",
  ADD_USER_TRANSACTION: "/history/create",
  GET_USER_TRANSACTIONS: "/history",
  GET_CHART: "/getChart",
  GET_TOKEN_BALANCE: "/getBalance",
  CMC_EXCHANGE: "/exchange",
  SAVE_STAKING: "/api/staking/",
};

export const APIURL_OPEN_OCEAN = {
  TOKEN_LIST: "/tokenList",
  SWAP_QUOTE: "/swap-quote",
  QUOTE: "/quote",
  IMPACT: "/1/quote",
  DEX_LIST: "/dexList",
  LIMIT_ORDER: "/limit-order/",
  GET_LIMIT_ORDER_BY_ADDRESS: "/get-limit-orders/",
  CANCEL_LIMIT_ORDER: "/cancel-limit-order/",
};

export const APIURL_1INCH_SWAP = {
  TOKEN_LIST: "/tokens",
  SWAP_QUOTE: "/swap_quote",
  IMPACT: "/1/quote",
  DEX_LIST: "/dexList",
  LIMIT_ORDER: "/limit-order/",
  GET_LIMIT_ORDER_BY_ADDRESS: "/get-limit-orders/",
  CANCEL_LIMIT_ORDER: "/cancel-limit-order/",
};

export const APIURL_CHAINGE = {
  CROSS_CHAIN_QUOTE: "/open/v1/order/getCrossChainQuote",
  AGGREGATOR_QUOTE: "/open/v1/order/getAggregateQuote",
  TRANSACTION_RAW: "/open/v1/order/getTransferToMinterRaw",
  SUBMIT_TRANSACTION: "/open/v1/order/submitCrossChain",
  SUBMIT_AGGREGATOR_TRANSACTION: "/open/v1/order/submitAggregate",
  GET_ORDER_DETAIL: "/open/v1/order/getOrderDetail",
  ADD_RAW_TRANSACTION_CHAINGE: "/txn/create",
  UPDATE_RAW_TRANSACTION_CHAINGE: "/txn/update",
};

export const CHAINGE_ADMIN_FEE = 0;
