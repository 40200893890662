import { Col, Row } from "antd";
import { SuccessMessageIcon } from "../Assets/icons/SuccessMessageIcon";
import { HeaderText, Text } from ".";
import { colors } from "../Assets/Theme/colors";
import { ErrorMessageIcon } from "../Assets/icons/ErrorMessageIcon";

type Props = {
  type: "success" | "error";
  title: string;
  body?: string;
};

const MessageContent = ({ type, title, body }: Props) => {
  return (
    <Row align={"middle"} gutter={16}>
      <Col>
        {type === "success" ? <SuccessMessageIcon /> : <ErrorMessageIcon />}
      </Col>
      <Col style={{ display: "grid", textAlign: "left" }}>
        <HeaderText fontSize={16}>{title}</HeaderText>
        <Text color={colors.secondary500}>{body}</Text>
      </Col>
    </Row>
  );
};

export default MessageContent;
