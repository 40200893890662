import {
  ADDRESS_LOCK_V1_ETH,
  ADDRESS_STAKING_TOKEN_V1_ETH,
  ADDRESS_STAKING_TOKEN_V2_ETH,
  ADDRESS_STAKING_V1_ETH,
  ADDRESS_STAKING_V2_ETH,
  ADDRESS_WETH_ETH,
} from "../../../../Constants/AppVariables/appVariable";
import Routerabi from "../../../abis/RouterAbi/Router.json";
import StakingABI from "../../../abis/StakingABI/Staking.json";
import LockABI from "../../../abis/StakingABI/Lock.json";
import StakingTokenABI from "../../../abis/StakingABI/StakingToken.json";

export const EthMainnetContracts = [
  {
    symbol: "WETH",
    address: ADDRESS_WETH_ETH,
    abi: Routerabi,
  },
  {
    symbol: "LOCK_V1",
    address: ADDRESS_LOCK_V1_ETH,
    abi: LockABI,
  },
  {
    symbol: "STAKING_V1",
    address: ADDRESS_STAKING_V1_ETH,
    abi: StakingABI,
  },
  {
    symbol: "STAKING_V2",
    address: ADDRESS_STAKING_V2_ETH,
    abi: StakingABI,
  },
  {
    symbol: "STAKING_TOKEN_V1",
    address: ADDRESS_STAKING_TOKEN_V1_ETH,
    abi: StakingTokenABI,
  },
  {
    symbol: "STAKING_TOKEN_V2",
    address: ADDRESS_STAKING_TOKEN_V2_ETH,
    abi: StakingTokenABI,
  },
];
