import { switchNetwork } from "@wagmi/core";
import { ConfigProvider, Select } from "antd";
import { useNetwork } from "wagmi";
import { supportedChains } from "../../../App";
import { colors } from "../../../Assets/Theme/colors";

import { useDispatch } from "react-redux";
import {
  ArbitrumMainnetData,
  AvalancheMainnetData,
  BnbMainnetData,
  EthereumMainnetData,
  PolygonMainnetData,
} from "../../../Constants/NetworkConstants";
import { setCurrentNetworkData } from "../../../redux/reducers/network/network";

type OptionType = {
  value: number;
  label: JSX.Element;
  disabled?: boolean;
};

const SelectBlockchain = ({
  useAsButton,
  useAsSelect,
  switchToNetwork,
  className,
}: any) => {
  const { chain } = useNetwork();
  const dispatch = useDispatch();
  // const [showPopup, setShowPopup] = useState<boolean>(false);
  // const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);
  // const wrongNetworSelected = useSelector((state: any) => state.loginSlice.wrongNetworkSelected);

  // const waddress: string = useSelector((state: any) => state.addressSlice.walletAddress);
  // const { connector } = useAccount();
  // const showWarning = () => {
  //     if (
  //         (waddress?.length > 0 && connector && connector.name === 'WalletConnect') ||
  //         (wrongNetworSelected && connector && connector.name === 'WalletConnect')
  //     ) {
  //         setShowPopup(true);
  //         setTimeout(() => {
  //             setShowPopup(false);
  //         }, 3500);
  //     }
  // };

  // useEffect(() => {
  //     if (!wrongNetworSelected) {
  //         setShowPopup(false);
  //     }
  // }, [isLoggedIn, wrongNetworSelected]);

  return (
    useAsSelect && (
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionActiveBg: "#43434c",
              optionSelectedBg: colors.primary,
              controlItemBgHover: "#43434c",
              colorPrimaryHover: "#43434c",
              selectorBg: colors.secondary600,
              colorBorder: "#333",
              optionSelectedColor: "white",
              controlHeight: 38,
              colorBgElevated: colors.secondary600,
            },
          },
        }}
      >
        <Select
          defaultValue={
            supportedChains &&
            chain &&
            supportedChains.find((op: any) => op.value === chain?.id)
          }
          value={
            supportedChains &&
            chain &&
            supportedChains.find((op: any) => op.value === chain?.id)
          }
          onChange={(value: number) => {
            if (supportedChains.find((chain) => chain.id === value)) {
              switchNetwork({ chainId: value });

              let chainData = EthereumMainnetData;
              if (value === 56) {
                chainData = BnbMainnetData;
              } else if (value === 137) {
                chainData = PolygonMainnetData;
              } else if (value === 43114) {
                chainData = AvalancheMainnetData;
              } else if (value === 42161) {
                chainData = ArbitrumMainnetData;
              }

              dispatch(setCurrentNetworkData(chainData));
            }
          }}
          style={{ width: "100%", minWidth: "210px" }}
        >
          {supportedChains.map((option: OptionType) => (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </ConfigProvider>
    )
  );
};

export default SelectBlockchain;
