import { Card, Col, Spin, Table, TableColumnsType } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAccount } from "wagmi";
import { DownLoadIcon } from "../../../../Assets/icons/download";
import { EmergencyExitIcon } from "../../../../Assets/icons/emergencyExit";
import { DataType } from "../../../../Constants/Interfaces/ProfilePage";
import { getTransactions } from "../../../../redux/api/profileInfo/profileInfo";
import { Text } from "../../../Text";
import "./stakings.scss";

const ExpandedRow = (record: any) => {
  return (
    <div className="expandable-row">
      <div className="heading">
        <Text fontWeight={500} color="#7C7C82" fontSize={"14px"}>
          Transaction Hash
        </Text>
      </div>
      <div className="transaction-hash">
        <Text fontWeight={500} color="#9B9CA3" fontSize={"14px"}>
          {record.record.slice(0, 8) + "..." + record.record.slice(-8)}
        </Text>
        <DownLoadIcon style={{ marginLeft: "12px" }} />
      </div>
    </div>
  );
};

export const MyStakings = () => {
  const { xs, sm, md, lg, xxl } = useBreakpoint();
  const { address, isConnected, connector } = useAccount();
  const [transactions, setTransactions] = useState<DataType[]>();
  const [expandedRowKey, setExpandedRowKey] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("address", address);
  const columns: TableColumnsType<DataType> = [
    {
      title: "Amount (SHIDO)",
      dataIndex: "amount",
      className: "amount",
      render: (amount, record) => (
        <span>{Math.round(amount).toLocaleString()} SHIDO</span>
      ),
    },
    {
      title: "Transaction Hash",
      dataIndex: "transaction_hash",
      className: "txhash",
      responsive: ["md", "lg", "xxl"],
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log(record, rowIndex);
            window.open(
              ` https://etherscan.io/tx/${record.transaction_hash}`,
              "_blank",
            );
          },
        };
      },
      render: (text, record) => (
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {text.slice(0, 8) + "..." + text.slice(-8)}
          <CopyToClipboard
            text={text}
            onCopy={(text: any, result: any) => {
              console.log(result, text);
            }}
          >
            <DownLoadIcon style={{ marginLeft: "12px" }} />
          </CopyToClipboard>
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "transaction_type",
      className: "type",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {text.replace(/^./, text[0].toUpperCase()).replace(/_/g, " ")}
          <EmergencyExitIcon style={{ marginLeft: "12px" }} />
        </span>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  useEffect(() => {
    (async function () {
      await myTransactions(address);
    })();
  }, []);

  const myTransactions = async (address: any) => {
    try {
      setLoading(true);
      const result = await getTransactions(address);
      console.log("result", result);
      if (result) {
        setTransactions(result);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys: any = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.id as row key. Check the documentation for more details.
    }
    setExpandedRowKey(keys);
  };

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      style={{ paddingRight: "unset" }}
    >
      <Card className="staking-card">
        <div className="heading-row">
          <Text fontWeight={600} color="white" fontSize={"16px"}>
            Staking History
          </Text>
        </div>
        {loading ? (
          <Spin size={"large"} className="spinner" />
        ) : (
          <div className="staking-table">
            <Table
              dataSource={transactions}
              columns={columns}
              scroll={{ x: "max-content" }}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: false,
                // pageSizeOptions: ["5", "20", "30"],
              }}
              rowClassName={() => "rowClassName1"}
              expandable={
                md || lg || xxl
                  ? undefined
                  : {
                      expandedRowRender: (record) => (
                        <ExpandedRow record={record.transaction_hash} />
                      ),
                      onExpand: onTableRowExpand,
                      expandedRowKeys: expandedRowKey,
                    }
              }
            />
          </div>
        )}
      </Card>
    </Col>
  );
};
