import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AggregationPage from "./Components/Pages/AggregationPage/AggregationPage";
import Pools from "./Components/Pages/Earn/Pools/Pools";
import ShidoTurbo from "./Components/Pages/Earn/ShidoTurbo/ShidoTurbo";
import Strategies from "./Components/Pages/Earn/Strategies/Strateigies";
import Farming from "./Components/Pages/Farming/Farming";
import Liquidity from "./Components/Pages/Liquidity/Liquidity";
import Voting from "./Components/Pages/Liquidity/Voting";
import StakingPage from "./Components/Pages/Staking/Staking";
import CrossChainSwap from "./Components/Pages/Trade/CrossChainSwap";
import LimitOrder from "./Components/Pages/Trade/LimitOrder/LimitOrder";
import P2PDeal from "./Components/Pages/Trade/P2PDeal";
import SimpleMode from "./Components/Pages/Trade/SimpleMode";
import store from "./redux/store";

import { ConfigProvider, Layout, message } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ErrorBoundary } from "react-error-boundary";
import { useChainId } from "wagmi";
import { colors } from "./Assets/Theme/colors";
import { Header, Sidebar } from "./Components";
import PrimaryLayout from "./Components/Layouts/PrimaryLayout";
import Explore from "./Components/Pages/Explore/Explore";
import Home from "./Components/Pages/Home/Home";
import Profile from "./Components/Pages/Profile/Profile";
import SingleTokenHolder from "./Components/Pages/SingleToken/SingleToken";
import TotalSupply from "./Components/Pages/TotalSupply";
import ErrorFallback from "./ErrorFallBack";
import InternetConnectionStatus from "./InternetConnectionStatus/InternetConnectionStatus";
import { TOKENS_BY_CHAIN } from "./redux/api/swap/chainTokens";
import { fetchTokens } from "./redux/api/swap/swapAPIs";
import {
  appState,
  toggleConnectWalletModal,
  toggleMenu,
} from "./redux/reducers";
import { setSwapToken } from "./redux/reducers/tokenCollection/tokenCollection";

const { Content } = Layout;

export default function Main() {
  const { md } = useBreakpoint();
  const dispatch = useDispatch();
  const chainId = store.getState().networkSlice.ChainID.toString();
  const { menuVisible, connectWalletModal } = useSelector(appState);
  const wagmiChainId = useChainId();

  // useEffect(() => {
  //   console.log(wagmiChainId, "wagmi--------------");
  // }, [wagmiChainId]);

  useEffect(() => {
    if (!md && menuVisible) {
      dispatch(toggleMenu());
    }
  }, [md]);

  useEffect(() => {
    return () => {
      if (connectWalletModal) dispatch(toggleConnectWalletModal());
    };
  }, []);

  const getBodyLeftMargin = () => {
    if (!md) {
      return 0;
    } else {
      return 320;
    }
  };

  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);

  useEffect(() => {
    // Listen for changes in the URL and update the currentRoute state accordingly
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    // Attach the event listener
    window.addEventListener("popstate", handleRouteChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const getImportedTokensForCurrentChain = (chainId: number) => {
    const tokens = localStorage.getItem("importedTokens");
    if (!tokens) return [];
    const importedTokens = JSON.parse(tokens);
    return importedTokens.filter((token: any) => token.chainId === chainId);
  };

  const fetchAndDispatchTokens = async (chainId: number) => {
    try {
      const fetchedTokens = await fetchTokens(chainId);
      const filteredTokens = fetchedTokens.filter((token: any) =>
        TOKENS_BY_CHAIN[chainId].some((t) => t.symbol === token.symbol),
      );

      const importedTokens = getImportedTokensForCurrentChain(chainId);
      const allTokens = [...filteredTokens, ...importedTokens];

      dispatch(setSwapToken(allTokens));
    } catch (error) {
      console.error("Failed to fetch tokens:", error);
      message.error("Failed to load tokens.");
    }
  };

  useEffect(() => {
    fetchAndDispatchTokens(wagmiChainId);
  }, [wagmiChainId]);

  useEffect(() => {
    fetchTokens(wagmiChainId)
      .then((fetchedTokens) => {
        const filteredTokens = fetchedTokens.filter((token: any) =>
          TOKENS_BY_CHAIN[wagmiChainId].some((t) => t.symbol === token.symbol),
        );
        dispatch(setSwapToken(filteredTokens));
      })
      .catch((error: any) => {
        console.error("Failed to fetch tokens:", error);
        message.error("Failed to load tokens.");
      });
  }, [wagmiChainId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary50,
          colorText: "white",
          colorSuccess: colors.success,
          colorError: colors.error,
          colorWarning: colors.warning,
          colorTextDisabled: colors.secondary400,
          colorIcon: "white",
        },
        components: {
          Card: {
            actionsBg: colors.secondary700,
            colorBgContainer: colors.secondary700,
            colorBorderSecondary: "transparent",
            borderRadiusLG: 20,
            borderRadius: 20,
            borderRadiusSM: 20,
            borderRadiusXS: 20,
            paddingLG: 20,
          },
          Table: {
            headerBg: "#37373C",
            rowHoverBg: "#252527",
            // rowSelectedBg: "yellow",
            // rowSelectedHoverBg: "red",
            // headerBorderRadius: 12,
            borderColor: "#37373C",
            // headerSplitColor: '#fff',
            bodySortBg: "pink",
            headerSortActiveBg: "blue",
            colorBgContainer: "#252527",
            controlItemBgHover: "purple",
            headerColor: "#9B9CA3",
            colorText: "#9B9CA3",
            // borderRadius: 24,
            rowExpandedBg: "#252527",
            // cellPaddingBlock: 18,
            cellPaddingInline: 30,
            cellPaddingBlockMD: 10,
            cellPaddingBlockSM: 10,
            cellPaddingInlineSM: 10,
            cellPaddingInlineMD: 10,
            expandIconBg: "none",
            // headerBorderRadius: 20,
            headerSplitColor: "none",
            fontSize: 14,
            // fontSizeIcon: 18,
            // borderColor: 'red',
          },

          Button: {
            colorPrimary: colors.primary50,
            colorText: "black",
            colorTextBase: "black",
            colorPrimaryHover: colors.primary50,
          },
          Input: {
            colorBgContainer: colors.secondary600,
            colorBorder: colors.secondary600,
            activeBorderColor: colors.secondary600,
            colorPrimaryHover: colors.secondary600,
            colorPrimary: colors.secondary600,
            colorTextPlaceholder: colors.secondary400,
            colorIcon: colors.secondary400,
            controlHeight: 36,
            paddingBlock: 12,
            borderRadius: 8,
          },
          Menu: {
            darkItemBg: colors.secondary700,
            darkItemHoverColor: "white",
            darkItemHoverBg: colors.secondary600,
            itemHoverColor: "#3B87F7",
            darkItemSelectedBg: "transparent",
            darkSubMenuItemBg: colors.secondary700,
          },
          Empty: {
            colorText: colors.secondary200,
          },
          Select: {
            colorBgContainer: colors.secondary600,
            colorBorder: colors.secondary600,
            colorPrimaryHover: colors.secondary600,
            colorPrimary: colors.secondary600,
            colorTextPlaceholder: colors.secondary400,
            controlHeight: 36,
            borderRadius: 8,
            optionSelectedBg: colors.secondary600,
            optionSelectedColor: "white",
            colorIcon: "white",
            colorText: "white",
          },
          Modal: {
            contentBg: colors.secondary700,
            headerBg: colors.secondary700,
            footerBg: colors.secondary700,
            colorIcon: "white",
            titleFontSize: 30,
            borderRadiusLG: 20,
          },
          Spin: {
            colorPrimary: colors.primary,
            dotSizeLG: 28,
          },
          Alert: {
            colorWarningBg: `${colors.warning}15`,
            colorText: colors.warning,
            colorWarningBorder: `${colors.warning}15`,
          },
        },
      }}
    >
      <BrowserRouter>
        {currentRoute === "/total-supply" ? (
          <Routes>
            <Route path="/total-supply" element={<TotalSupply />} />
          </Routes>
        ) : (
          <Layout
            hasSider
            style={{
              padding: 20,
              minHeight: "100vh",
              backgroundColor: colors.secondary800,
            }}
          >
            <Sidebar />
            <Layout
              style={{
                marginLeft: getBodyLeftMargin(),
                backgroundColor: colors.secondary800,
              }}
            >
              <Header />
              {!md && menuVisible ? (
                <Sidebar mobile />
              ) : (
                <Content
                  style={{
                    backgroundColor: colors.secondary800,
                    minHeight: "80vh",
                  }}
                >
                  <PrimaryLayout>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <div className="no_internet_connection">
                        <InternetConnectionStatus />
                      </div>
                      <Routes>
                        <Route index path="/" element={<Home />} />
                        <Route path="/simple-mode" element={<SimpleMode />} />
                        {/*<Route path="/classic-mode" element={<ClassicMode />} />*/}
                        <Route path="/limit-order" element={<LimitOrder />} />
                        <Route path="/p2p-deal" element={<P2PDeal />} />
                        <Route path="/pools" element={<Pools />} />
                        <Route path="/strategies" element={<Strategies />} />
                        <Route path="/shidoturbo" element={<ShidoTurbo />} />
                        <Route path="/liquidity" element={<Liquidity />} />
                        <Route path="/voting" element={<Voting />} />
                        <Route path="/staking" element={<StakingPage />} />
                        <Route path="/farming" element={<Farming />} />
                        <Route path="/total-supply" element={<TotalSupply />} />

                        <Route
                          path="/aggregation"
                          element={<AggregationPage />}
                        />
                        <Route
                          path="/cross-chain-swap"
                          element={<CrossChainSwap />}
                        />
                        <Route path="/home" element={<Home />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/explore" element={<Explore />} />
                        <Route
                          path="/singleTokenHolder"
                          element={<SingleTokenHolder />}
                        />
                      </Routes>
                    </ErrorBoundary>
                  </PrimaryLayout>
                </Content>
              )}
            </Layout>
          </Layout>
        )}
      </BrowserRouter>
    </ConfigProvider>
  );
}
