import React, { useState } from "react";
import "./ShidoTurbo.scss";
import { Container, Row, Col } from "react-bootstrap";
import { shido_img } from "../../../../Constants/ImagesConstants";
import BalanceCard from "./BalanceCard";
import {
  risk_dis,
  banchmark_icon,
  graph_img,
  right_arrow,
} from "../../../../Constants/ImagesConstants";
import ShidoToken from "./ShidoToken";
import { Link } from "react-router-dom";
import { DownIcon } from "../../../../Assets/Svg/SvgImages";
// import ShidoToken './ShidoToken.tsx';

const ShidoTurbo = () => {
  const [showBottom, setShowBottom] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(isActive);
  };

  const shidolistdata = [
    {
      id: "1",
      tokentitle: "1 714 556.51",
      token: "Shido",
      shidoinfo: "Total staked",
    },
    {
      id: "2",
      tokentitle: "1 714 556.51",
      token: "USD",
      shidoinfo: "Strike price",
    },
    {
      id: "3",
      tokentitle: "1 714 556.51",
      shidoinfo: "Next rebalancing",
    },
    {
      id: "4",
      tokentitle: "10%",
      shidoinfo: "Pool utilization",
    },
    {
      id: "5",
      tokentitle: "6.2%",
      shidoinfo: "Pool APR",
    },
  ];

  return (
    <div className="shidoturbo Voting py-70">
      <Container>
        <div className="shidoturbo_main">
          <div className="shido">
            <div className="shido_content d-flex align-items-center justify-content-between">
              <div className="shido_left d-flex align-items-center">
                <div className="right_arrow">
                  <Link to="/strategies">
                    <img src={right_arrow} alt="img" />
                  </Link>
                </div>
                <img src={shido_img} alt="img" />
                <h4>Shido Turbo</h4>
              </div>
              <div className="shido_right HeadingsList d-flex align-items-center">
                <button
                  className={showBottom ? "BtnDrop" : "activeBtnDrop"}
                  onClick={() => {
                    setShowBottom(!showBottom);
                    ToggleClass();
                  }}
                >
                  <img src={risk_dis} alt="img" />
                  <p>Risk Disclosure</p>
                  <DownIcon />
                </button>
              </div>
            </div>
            <div className={!showBottom ? "Rishshow" : "RiskHide"}>
              <h5>Risk Disclosure</h5>
              <p>
                Acting as a liquidity provider (LP) for staking pools is an
                inherently risky undertaking. Please do not transact with more
                funds than you can afford to lose. Once you stake any amount
                into a pool, you become a liquidity provider.
              </p>
            </div>
          </div>
          <Row>
            <Col xs={12} lg={7} xl={7}>
              <div className="shido_left">
                <BalanceCard />
                <div className="shido_stake">
                  <ul className="shido_unstyle">
                    {shidolistdata.map((item) => {
                      return (
                        <li>
                          <div className="list_data">
                            <h4>
                              {item.tokentitle}
                              <span>{item.token}</span>
                            </h4>
                            <p>{item.shidoinfo}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="shido_graph">
                  <h4>Pool performance per week</h4>
                  <div className="benchmark_return d-flex align-item-center justify-content-between">
                    <div className="benchmark_left d-flex align-item-center">
                      <p>
                        Benchmark Return :<span>0.05% Weekly (APR: 2.6%)</span>
                        <img src={banchmark_icon} alt="img" />
                      </p>
                    </div>
                    <div className="benchmark_right d-flex align-item-center">
                      <div className="pool_data d-flex">
                        <div className="blue_dot"></div>
                        <p>Pool Performance</p>
                      </div>
                      <div className="return_data d-flex align-item-center">
                        <div className="green_dot"></div>
                        <p>Return Since Inception</p>
                      </div>
                    </div>
                  </div>
                  <div className="graph_img">
                    <img src={graph_img} alt="img" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={5} xl={5}>
              <ShidoToken />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ShidoTurbo;
