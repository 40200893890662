import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Hamburger = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 8.75H3.75C3.3375 8.75 3 8.4125 3 8C3 7.5875 3.3375 7.25 3.75 7.25H20.25C20.6625 7.25 21 7.5875 21 8C21 8.4125 20.6625 8.75 20.25 8.75ZM20.25 13.25H3.75C3.3375 13.25 3 12.9125 3 12.5C3 12.0875 3.3375 11.75 3.75 11.75H20.25C20.6625 11.75 21 12.0875 21 12.5C21 12.9125 20.6625 13.25 20.25 13.25ZM20.25 17.75H3.75C3.3375 17.75 3 17.4125 3 17C3 16.5875 3.3375 16.25 3.75 16.25H20.25C20.6625 16.25 21 16.5875 21 17C21 17.4125 20.6625 17.75 20.25 17.75Z"
      fill="white"
    />
  </svg>
);

export const HamburgerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Hamburger} {...props} />
);
