import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import shidoCoinDark from "../../../../Assets/Images/shidoCoinDark.svg";
import { PrimaryButton } from "../../../Button";
import { HeaderText, Text } from "../../../Text";
import "./hero.scss";

export const Hero = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Col span={24}>
        <Card
          className="card"
          style={{
            background: "linear-gradient(91deg, #2B66F6 2.17%, #4CA8F8 98.2%)",
          }}
        >
          <Row>
            <Col
              className="text-section card-item-right"
              xs={{ span: 24, order: 3 }}
              sm={{ span: 24, order: 3 }}
              md={{ span: 24, order: 3 }}
              lg={{ span: 12, order: 1 }}
              xl={{ span: 13, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Row>
                <Col className="header card-col" span={24}>
                  <HeaderText
                    className="heading"
                    fontWeight={700}
                    style={{ lineHeight: "normal" }}
                  >
                    Maximize your{" "}
                    <span style={{ color: "#071B33" }}>SHIDO</span> holdings
                    with staking rewards
                  </HeaderText>
                </Col>
                <Col span={24} className="text card-col">
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    style={{ lineHeight: "normal" }}
                  >
                    Grow your SHIDO holdings with ease. Stake and earn – it's
                    that simple. Welcome to the future of effortless crypto
                    rewards.
                  </Text>
                </Col>
                <Col span={24} className="btn-col">
                  <PrimaryButton
                    style={{
                      backgroundColor: "#181819",
                      fontSize: "16px",
                      height: "43px",
                    }}
                    onClick={() => navigate("/simple-mode")}
                  >
                    Trade Now
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 24, order: 2 }}
              lg={{ span: 1, order: 2 }}
              xl={{ span: 1, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            ></Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 11, order: 3 }}
              xl={{ span: 10, order: 3 }}
              xxl={{ span: 6, order: 3 }}
              className="img-container card-item-left"
            >
              <img src={shidoCoinDark} />
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
};
