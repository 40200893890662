import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

const TextScroller = ({ text }: { text: string | React.JSX.Element }) => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(0%,0)" },
    to: { transform: "translate(-100%,0)" },
    config: { duration: 30000 },
    reset: true,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div className="TextScroller" key={key} style={{ overflow: "hidden" }}>
      <animated.div style={scrolling}>{text}</animated.div>
    </div>
  );
};

export default TextScroller;
// const TextScroller = ({ text }: { text: string | JSX.Element }) => {
//   return <div>{text}</div>;
// };
// export default TextScroller;
