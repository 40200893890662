import React from "react";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Lottie from "lottie-react";
import NotYet from "../../../Assets/animation/notfound.json";
// import "../TradeCards/TradeCard.scss";

const P2PTradeCards = () => {
  return (
    <>
      <div className="Trade_Simple_Card P2PTrade_Cards d-flex">
        <div className="Trade_Tabs_card w-100">
          <Tab.Container id="left-tabs-example" defaultActiveKey="Outbound">
            <Row>
              <Col xs={12}>
                <div className="Trade_Tabs">
                  <Nav variant="pills" className="Bottomline_tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="Outbound">Outbound</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Inbound">Inbound</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="History">History</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xs={12}>
                <Tab.Content className="Trade_Tabs_content">
                  <Tab.Pane eventKey="Outbound">
                    <div className="NotYet_Box p-4">
                      <Lottie animationData={NotYet} loop={true} />
                      <p className="text-center mt-4">
                        You haven't received any orders yet Outbound
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Inbound">
                    <div className="NotYet_Box p-4">
                      <Lottie animationData={NotYet} loop={true} />
                      <p className="text-center mt-4">
                        You haven't received any orders yet Inbound
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="History">
                    <div className="NotYet_Box p-4">
                      <Lottie animationData={NotYet} loop={true} />
                      <p className="text-center mt-4">
                        You haven't received any orders yet History
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default P2PTradeCards;
