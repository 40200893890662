import Web3 from "web3";
import store from "../../redux/store";
import { callWeb3 } from "../walletServices";
import { GAS_MULTIPLIER } from "../../Constants/swapConstants";

let _web3Instance: any;

// common ABI instances for .send() functions
// export const commonAbiInstancesForSendFunctions = async (address: string, abi: any) => {
//   let _web3Instance: any = await callWeb3();
//   if (_web3Instance)  {
//     let Instance = new _web3Instance.eth.Contract(abi, address);
//     return Instance;
//   }
// };

// common ABI instances for .send() functions with custom gasPrice
export const commonAbiInstancesForSendFunctions = async (
  address: string,
  abi: any,
) => {
  let _web3Instance: any = await callWeb3();
  if (_web3Instance) {
    const userSelectedGas = store.getState().swapData.userSelectedGas;
    // We will be using userSelectedGas, if user hasn't selected any gas then average gasPrice coming from api
    // is set as default useSelectedGas
    const gasPrice = Number(userSelectedGas) * GAS_MULTIPLIER;
    let Instance = new _web3Instance.eth.Contract(abi, address, { gasPrice });
    return Instance;
  }
};

// common ABI instances for .send() functions but no custom gasPrice
export const commonAbiInstancesForSendFunctionsAutoGasPrice = async (
  address: string,
  abi: any,
) => {
  let _web3Instance: any = await callWeb3();
  if (_web3Instance) {
    let Instance = new _web3Instance.eth.Contract(abi, address);
    return Instance;
  }
};

// common ABI instances for .call() functions
export const commonAbiInstancesForCallFunctions = async (
  address: string,
  abi: any,
) => {
  let _web3Instance: any = await callWeb3();
  if (_web3Instance) {
    let Instance = new _web3Instance.eth.Contract(abi, address);
    return Instance;
  }
};

// export const commonAbiInstances = async (address: string, abi: any) => {
//   try {
//     const { ethereum } = window;
//     const account = storeInstance.getState().addressSlice.walletAddress;
// const { RPCURL }  = store.getState().networkSlice;
//     if (ethereum && !!account) {
//       console.log("Entered .. 1");
//       let _web3Instance: any = await callWeb3();
//       // _web3Instance = new Web3(RPCURL);
//       console.log("Entered .. 1.1", _web3Instance);
//       let Instance = new _web3Instance.eth.Contract(abi, address);
//       console.log("Entered .. 1.2", _web3Instance);
//       return Instance;
//     } else if (provider && !!account) {
//       console.log("Entered .. 2");
//       _web3Instance = await callWeb3();
//       let Instance = new _web3Instance.eth.Contract(abi, address);
//       return Instance;
//     } else {
//       console.log("Entered .. 3");
//       const { ethereum } = window;
//       let rpc: any = RPCURL;
//       _web3Instance = new Web3(rpc);
//       let Instance = new _web3Instance.eth.Contract(abi, address);
//       return Instance;
//     }
//   } catch (error: any) {
//     console.log("e_web3Instancerror", error);
//   }
// };
