import { createSlice } from "@reduxjs/toolkit";
import { SwapData } from "../../../Constants/Interfaces/SwapData";

const initialState: SwapData = {
  slippageTolerance: 4,
  basePrice: 0,
  lowPrice: 0,
  avgPrice: 0,
  fastPrice: 0,
  lowWait: 0,
  avgWait: 0,
  fastWait: 0,
  userSelectedGas: 5, // we need some initial value as openOceanQuote needs some value of gasPrice every time
  partialFill: false,
  chosenSoures: "",
  liquiditySources: "",
  liquidityRatios: "",
  token1: "",
  token2: "",
  userSelectedGasType: "MARKET",
  input1Value: 1,
  input2Value: "",
  calculatedGasPriceDollars: "",
  convertedInput1Redux: "",
  convertedInput2Redux: "",
  buyPrice: "",
  sellPrice: "",
  buyPriceInDollars: "",
  sellPriceInDollars: "",
  priceImpact: null,
  recentTxnHashOpenOcean: "",
  outTokenValueLimitOrder: "",
};

export const swapData = createSlice({
  name: "swapData",
  initialState,
  reducers: {
    resetSwapDataSlice: (state: any, action) => {
      state.slippageTolerance = 4;
      state.basePrice = 0;
      state.lowPrice = 0;
      state.avgPrice = 0;
      state.fastPrice = 0;
      state.lowWait = 0;
      state.avgWait = 0;
      state.fastWait = 0;
      state.userSelectedGas = "";
      state.partialFill = false;
      state.chosenSoures = "";
      state.liquiditySources = "";
      state.liquidityRatios = "";
      state.token1 = "";
      state.token2 = "";
      state.userSelectedGasType = "MARKET";
      state.input1Value = 1;
      state.input2Value = "";
      state.calculatedGasPriceDollars = "";
      state.convertedInput1Redux = "";
      state.convertedInput2Redux = "";
      state.buyPrice = "";
      state.sellPrice = "";
      state.buyPriceInDollars = "";
      state.sellPriceInDollars = "";
      state.priceImpact = "";
      state.recentTxnHashOpenOcean = "";
      state.outTokenValueLimitOrder = "";
    },
    setSlippageTolerance: (state, action) => {
      state.slippageTolerance = action.payload;
    },
    setBaseGasPrice: (state, action) => {
      state.basePrice = action.payload;
    },
    setLowGasPrice: (state, action) => {
      state.lowPrice = action.payload;
    },
    setAverageGasPrice: (state, action) => {
      state.avgPrice = action.payload;
    },
    setFastGasPrice: (state, action) => {
      state.fastPrice = action.payload;
    },
    setLowGasWait: (state, action) => {
      state.lowWait = action.payload;
    },
    setAverageGasWait: (state, action) => {
      state.avgWait = action.payload;
    },
    setFastGasWait: (state, action) => {
      state.fastWait = action.payload;
    },
    setUserSelectedGas: (state, action) => {
      state.userSelectedGas = action.payload;
    },
    setUserSelectedGasType: (state, action) => {
      state.userSelectedGasType = action.payload;
    },
    setPartialFill: (state, action) => {
      state.partialFill = action.payload;
    },
    setChosenSources: (state, action) => {
      state.chosenSoures = action.payload;
    },
    setLiquiditySources: (state, action) => {
      state.liquiditySources = action.payload;
    },
    setLiquidityRatio: (state, action) => {
      state.liquidityRatios = action.payload;
    },
    setToken1: (state, action) => {
      state.token1 = action.payload;
    },
    setToken2: (state, action) => {
      state.token2 = action.payload;
    },
    setInput1ValueRedux: (state, action) => {
      state.input1Value = action.payload;
    },
    setInput2ValueRedux: (state, action) => {
      state.input2Value = action.payload;
    },
    setCalculatedGasPriceDollarsRedux: (state, action) => {
      state.calculatedGasPriceDollars = action.payload;
    },
    setConvertedInput1Redux: (state, action) => {
      state.convertedInput1Redux = action.payload;
    },
    setConvertedInput2Redux: (state, action) => {
      state.convertedInput2Redux = action.payload;
    },
    setBuyPrice: (state, action) => {
      state.buyPrice = action.payload;
    },
    setSellPrice: (state, action) => {
      state.sellPrice = action.payload;
    },
    setBuyPriceInDollars: (state, action) => {
      state.buyPriceInDollars = action.payload;
    },
    setSellPriceInDollars: (state, action) => {
      state.sellPriceInDollars = action.payload;
    },
    setPriceImpact: (state, action) => {
      state.priceImpact = action.payload;
    },
    setRecentTxnHashOpenOcean: (state, action) => {
      state.recentTxnHashOpenOcean = action.payload;
    },
    setOutTokenValueLimitOrder: (state, action) => {
      state.outTokenValueLimitOrder = action.payload;
    },
  },
});
export const {
  setSlippageTolerance,
  setBaseGasPrice,
  setLowGasPrice,
  setAverageGasPrice,
  setFastGasPrice,
  setLowGasWait,
  setAverageGasWait,
  setFastGasWait,
  setUserSelectedGas,
  setPartialFill,
  setChosenSources,
  setUserSelectedGasType,
  setLiquiditySources,
  setLiquidityRatio,
  setToken1,
  setToken2,
  setInput1ValueRedux,
  setInput2ValueRedux,
  setConvertedInput1Redux,
  setConvertedInput2Redux,
  setCalculatedGasPriceDollarsRedux,
  resetSwapDataSlice,
  setBuyPrice,
  setSellPrice,
  setBuyPriceInDollars,
  setSellPriceInDollars,
  setPriceImpact,
  setRecentTxnHashOpenOcean,
  setOutTokenValueLimitOrder,
} = swapData.actions;

export default swapData.reducer;
