import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

let initialTokenCollection: any = [];
const currentEnvironment = process.env.REACT_APP_NODE_ENV;
switch (currentEnvironment) {
  case "development":
    initialTokenCollection = [];
    break;
  case "production":
    initialTokenCollection = [];
    break;
  case "qa":
    initialTokenCollection = [];
    break;
  default:
    break;
}

export const tokenCollectionSlice: any = createSlice({
  name: "tokenCollectionSlice",
  initialState: {
    tokenCollection: initialTokenCollection,
    tokenCollectionChaing: [],
    allOpenOceantokens: [],

    ETHimportedTokenList: [],
    overallEthTokenList: [],

    overallBNBTokenList: [],
    BNBimportedTokenList: [],

    overallPOLYGONTokenList: [],
    POLYGONimportedTokenList: [],

    overallAVALANCHETokenList: [],
    AVALANCHEimportedTokenList: [],

    overallARBITRUMTokenList: [],
    ARBITRUMimportedTokenList: [],

    overallFANTOMTokenList: [],
    FANTOMimportedTokenList: [],

    toTokenCollection: [],
    fromTokenCollection: [],
    crossTokenCollection: {},
    swapTokens: [],
    tokenRemoved: false,
  },
  reducers: {
    resetTokenCollectionSlice: (state: any, action) => {
      state.tokenCollection = [];
      state.allOpenOceantokens = [];

      state.overallEthTokenList = [];
      state.ETHimportedTokenList = [];

      state.openOceanBNBTokenList = [];
      state.overallBNBTokenList = [];
      state.BNBimportedTokenList = [];

      state.openOceanPOLYGONTokenList = [];
      state.overallPOLYGONTokenList = [];
      state.POLYGONimportedTokenList = [];

      state.openOceanAVALANCHETokenList = [];
      state.overallAVALANCHETokenList = [];
      state.AVALANCHEimportedTokenList = [];

      state.openOceanARBITRUMTokenList = [];
      state.overallARBITRUMTokenList = [];
      state.ARBITRUMimportedTokenList = [];

      state.openOceanFANTOMTokenList = [];
      state.overallFANTOMTokenList = [];
      state.FANTOMimportedTokenList = [];

      state.toTokenCollection = [];
      state.fromTokenCollection = [];
      state.crossTokenCollection = {};
    },
    setTokenCollection: (state: any, action: any) => {
      state.tokenCollection = action.payload;
    },
    setAllOpenoceanTokens: (state: any, action: any) => {
      state.allOpenOceantokens = action.payload;
    },
    setCrossTokens: (state: any, action: any) => {
      state.crossTokenCollection = {
        ...state.crossTokenCollection,
        ...action.payload,
      };
    },
    updateTokenCollection: (state: any, action: any) => {
      const tokenList = current(state.tokenCollection);
      const index = tokenList
        ?.map((i: any) => i?.address)
        .indexOf(action?.payload?.address);
      if (index >= 0) {
        tokenList[index].balance = action.payload.balance;
      }
    },
    updateTokenCollectionOpenOcean: (state: any, action: any) => {
      state.tokenCollection = action.payload;
    },

    // OverallTokenList will have default, Imported and OpenOcean Tokens

    setOverallEthTokenList: (state: any, action: any) => {
      state.overallEthTokenList = action.payload;
    },
    setEthImportedTokenList: (state: any, action: any) => {
      state.ETHimportedTokenList = action.payload;
    },

    setOverallBNBTokenList: (state: any, action: any) => {
      state.overallBNBTokenList = action.payload;
    },
    setBnbImportedTokenList: (state: any, action: any) => {
      state.BNBimportedTokenList = action.payload;
    },

    setOverallPOLYGONTokenList: (state: any, action: any) => {
      state.overallPOLYGONTokenList = action.payload;
    },
    setPolygonImportedTokenList: (state: any, action: any) => {
      state.POLYGONimportedTokenList = action.payload;
    },

    setOverallAVALANCHETokenList: (state: any, action: any) => {
      state.overallAVALANCHETokenList = action.payload;
    },
    setAvalancheImportedTokenList: (state: any, action: any) => {
      state.AVALANCHEimportedTokenList = action.payload;
    },

    setOverallARBITRUMTokenList: (state: any, action: any) => {
      state.overallARBITRUMTokenList = action.payload;
    },
    setArbitrumImportedTokenList: (state: any, action: any) => {
      state.ARBITRUMimportedTokenList = action.payload;
    },

    setOverallFANTOMTokenList: (state: any, action: any) => {
      state.overallFANTOMTokenList = action.payload;
    },
    setFantomImportedTokenList: (state: any, action: any) => {
      state.FANTOMimportedTokenList = action.payload;
    },

    setFromTokenCollection: (state: any, action: any) => {
      state.fromTokenCollection = action.payload;
    },
    setToTokenCollection: (state: any, action: any) => {
      state.toTokenCollection = action.payload;
    },
    setTokenCollectionChaing: (state: any, action: any) => {
      state.tokenCollectionChaing = action.payload;
    },
    setSwapToken: (state: any, action: PayloadAction<[]>) => {
      state.swapTokens = action.payload;
    },
    addImportedToken: (state: any, action: any) => {
      state.swapTokens = [...state.swapTokens, action.payload];
    },
    removeImportedToken: (state: any, action: any) => {
      state.swapTokens = state.swapTokens.filter(
        (token: any) => token.address !== action.payload.address,
      );
      state.tokenRemoved = !state.tokenRemoved; // Add this line
    },
  },
});

export const {
  setTokenCollection,
  resetTokenCollectionSlice,
  setAllOpenoceanTokens,

  setOverallEthTokenList,
  setEthImportedTokenList,

  setOverallBNBTokenList,
  setBnbImportedTokenList,

  setOverallPOLYGONTokenList,
  setPolygonImportedTokenList,

  setOverallAVALANCHETokenList,
  setAvalancheImportedTokenList,

  setOverallARBITRUMTokenList,
  setArbitrumImportedTokenList,

  setOverallFANTOMTokenList,
  setFantomImportedTokenList,

  updateTokenCollection,

  setFromTokenCollection,
  setToTokenCollection,
  setTokenCollectionChaing,
  setCrossTokens,
  updateTokenCollectionOpenOcean,
  setSwapToken,
  addImportedToken,
  removeImportedToken,
} = tokenCollectionSlice.actions;

export default tokenCollectionSlice.reducer;
