import { Col, Row } from "antd";
import SwapSettings from "../../Common/CommonModals/SwapSettingsModal/SwapSettings";
import TradeCard from "../../Common/TradeCards/TradeCard";

const SimpleMode = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} lg={14}>
        <TradeCard />
      </Col>
      <Col xs={24} lg={10}>
        <SwapSettings />
      </Col>
    </Row>
  );
};

export default SimpleMode;
