import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { DownIcon } from "../../../../Assets/Svg/SvgImages";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../../Constants/NetworkNames/NetworkNames";
import {
  NATIVE_WITH_TOKEN,
  TOKEN_WITH_TOKEN,
} from "../../../../Constants/SwapTypes/SwapTypes";
import {
  OPEN_OCEAN_CHAIN_ARBITRUM,
  OPEN_OCEAN_CHAIN_AVALANCHE,
  OPEN_OCEAN_CHAIN_BSC,
  OPEN_OCEAN_CHAIN_ETH,
  OPEN_OCEAN_CHAIN_FANTOM,
  OPEN_OCEAN_CHAIN_POLYGON,
} from "../../../../Constants/TYPES/openOceanChainNames";
import { bestValuesFunctionOpenOcean } from "../../../../redux/api/openOcean/tokenAPIs";
import { getLiquiditySourcesFunctionOpenSea } from "../../../../redux/api/swap/swapAPIs";
import {
  setRoutePathsLoading,
  setTradeDataLoading,
} from "../../../../redux/reducers/loadingData/loadingData";
import {
  setBuyPrice,
  setBuyPriceInDollars,
  setConvertedInput1Redux,
  setConvertedInput2Redux,
  setInput1ValueRedux,
  setInput2ValueRedux,
  setLiquiditySources,
  setOutTokenValueLimitOrder,
  setSellPrice,
  setSellPriceInDollars,
  setToken1,
  setToken2,
} from "../../../../redux/reducers/swapData/swapData";
import {
  setBestValues,
  updateRouteStepV2,
  updateRouteSteps,
  updateRouterPath,
  updateToken1DollarValue,
  updateToken1Name,
  updateToken1Price,
  updateToken2DollarValue,
  updateToken2Name,
  updateToken2Price,
} from "../../../../redux/reducers/tradeData/tradeData";
import store from "../../../../redux/store";
import { cancelPendingPostReq } from "../../../../services/apiServices/ApiServices";
import { nativeTokenBalance } from "../../../../services/contractServices/SwapServices";
import {
  getDecimals,
  getTokenBalanceBigList,
  getTokenBalanceFull,
  isNativeToken,
} from "../../../../services/contractServices/tokenServices";
import {
  decimalFormatWithoutRoundOffCrypto,
  exponentialToDecimal,
} from "../../../../services/helpers/swapHelpers";
import ConnectWallet from "../../ConnectWallet/ConnectWallet";
import CustomShimmer from "../../CustomShimmer/CustomShimmer";

import { CloseCircleFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { colors } from "../../../../Assets/Theme/colors";
import {
  setLimitPrice,
  setShowOrderExecutionWarning,
  setTokenOutAmount,
} from "../../../../redux/reducers/limitOrderData/limitOrderData";
import {
  bigNumberToFixed,
  uptoFourDecimal,
} from "../../../../services/helpers/swapHelpers";
import { getModifiedTokenSymbol } from "../../../../services/helpers/tokenListHelper";
import { callWeb3 } from "../../../../services/walletServices";
import { HeaderText, Text } from "../../../Text";
import SelectTokenModalSimple from "../../CommonModals/SelectTokenModal/SelectTokenModalSimple";
import ReviewOrderButton from "../../ReviewOrderButton/ReviewOrderButton";
import { disabledLetters } from "../../../../Constants/inputConstants";

const YouSellBuyCard = ({
  isCoversionHide,
  children,
  crossChainSelect,
  showHalfPriceButton,
  showReviewModal,
  minDollarWorthSatisfied,
  showLimitPriceBelowMarketWarning,
  setShowLimitPriceBelowMarketWarning,
  handleLimitPriceChange,
}: any) => {
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  const currentPath = window.location.pathname;
  const updateTradeData: any = useSelector(
    (state: any) => state.tradeData.updateData,
  );

  const limitPriceLoading = useSelector(
    (state: any) => state.loadingData.limitPriceLoading,
  );

  const limitPrice = useSelector(
    (state: any) => state.limitOrderData.limitPrice,
  );

  const chainID: any = useSelector((state: any) =>
    state.networkSlice.ChainID?.toString(),
  );

  const tokenCollection: any = useSelector(
    (state: any) => state?.tokenCollection?.swapTokens,
  );

  const initialLimitPrice = useSelector(
    (state: any) => state.limitOrderData.initialLimitPrice,
  );

  const limitAction = useSelector((state: any) => state.limitOrderData.action);

  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);

  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );

  const [userTokenOneBalance, setUserTokenOneBalance] = useState(0);
  const [tokenOneDisplayBalance, setTokenOneDisplayBalance] = useState<any>(0);
  const [tokenOneHoverBalance, setTokenOneHoverBalance] = useState<any>(0);
  const [tokenBalanceWithDecimals, setTokenBalanceWithDecimals] = useState(0);

  const selectedToken1 = useSelector((state: any) => state.swapData.token1);
  const selectedToken2 = useSelector((state: any) => state.swapData.token2);

  const [tokenListWithBalance, setTokenListWithBalance] = useState();
  // const [tokenListWithBalance, setTokenListWithBalance] = useState<any>([]);

  const input1 = useSelector((state: any) => state.swapData.input1Value);

  const swapDataInput2Value = useSelector(
    (state: any) => state.swapData.input2Value,
  );

  const input2 = swapDataInput2Value;

  const showOrderExecutionWarning = useSelector(
    (state: any) => state.limitOrderData.showOrderExecutionWarning,
  );

  // convertedInput1 & convertedInput2 is used while exectuing swap (swap button)
  const convertedInput1 = useSelector(
    (state: any) => state.swapData.convertedInput1Redux,
  );
  const convertedInput2 = useSelector(
    (state: any) => state.swapData.convertedInput2Redux,
  );

  const [bestValuePerToken, setBestValuePerToken] = useState<any>("");

  const [swapType, setSwapType] = useState<any>();

  const [token1DollarValue, setToken1DollarValue] = useState<any>("");
  const [token2DollarValue, setToken2DollarValue] = useState<any>("");

  const [token1Price, setToken1Price] = useState<any>("");
  const [token2Price, setToken2Price] = useState<any>("");

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [opData, setOpData] = useState<any>();
  const [intervalId, setIntervalId] = useState();

  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);

  const handleShow1 = () => setShowModal1(true);
  const handleShow2 = () => setShowModal2(true);

  interface Token {
    address: string;
    symbol: string;
    decimals: number;
    name: string;
    logoURI: string;
    price?: number;
  }

  const calculatebestValueForOneInputToken = () => {
    const output = Number(input2) / Number(input1);
    const toDecimal = exponentialToDecimal(output);
    setBestValuePerToken(toDecimal);
  };

  const handleSwapType = () => {
    if (selectedToken1?.isNative) setSwapType(NATIVE_WITH_TOKEN);
    else setSwapType(TOKEN_WITH_TOKEN);
  };

  const fetchLiquiditySources = async () => {
    const result = await getLiquiditySourcesFunctionOpenSea(chainID, dispatch);

    const dexArray: any = [];
    if (result) {
      await result.data.map(async (dexlist: any) => {
        await dexArray.push({ Name: dexlist.name });
      });

      if (result?.data?.length) dispatch(setLiquiditySources(dexArray));
    }
  };

  const fetchTokenOneBalance = async () => {
    if (!address) return;
    let tokenOneAddress: any;
    let tknOneDecimals: any;
    let tokenBalance: any;

    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.find(
      (a: any) => a?.symbol === "WETH",
    );

    if (selectedToken1?.isNative && currentPath !== "/limit-order") {
      tokenOneAddress = wethData?.address;

      tknOneDecimals = await getDecimals(tokenOneAddress);
      const nativeTokenBal: any = await nativeTokenBalance(address);

      tokenBalance = nativeTokenBal;
      setTokenBalanceWithDecimals(tokenBalance);

      setTokenOneDisplayBalance(
        Number(nativeTokenBal) / 10 ** Number(tknOneDecimals),
      );

      setTokenOneHoverBalance(
        Number(nativeTokenBal) / 10 ** Number(tknOneDecimals),
      );
      dispatch(setToken1(wethData));
    } else {
      tokenOneAddress = selectedToken1?.address;
      tknOneDecimals = await getDecimals(tokenOneAddress);

      const rawTokenBalance = await getTokenBalanceFull(
        address,
        tokenOneAddress,
      );

      tokenBalance = rawTokenBalance;

      setUserTokenOneBalance(tokenBalance ** (10 ** Number(tknOneDecimals)));
      setTokenOneDisplayBalance(tokenBalance);

      return;
    }
    setUserTokenOneBalance(tokenBalance);
  };

  const fetchTokenBalance = async (
    isNative: any,
    tokenAddress: any,
    tokenDecimals: any,
  ) => {
    if (!address) return;
    let tokenOneAddress;
    let tokenBalance;
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.filter(
      (a: any) => a?.symbol === "WETH",
    );

    if (isNative && currentPath !== "/limit-order") {
      tokenOneAddress = wethData[0]?.address;
      const tokenDecimals: any = await getDecimals(tokenOneAddress);
      const nativeTokenBal: any = await nativeTokenBalance(address);
      tokenBalance = Number(nativeTokenBal) / 10 ** Number(tokenDecimals);
    } else {
      tokenOneAddress = tokenAddress;
      tokenBalance = await getTokenBalanceBigList(
        address,
        tokenOneAddress,
        tokenDecimals,
      );
    }
    return tokenBalance;
  };

  const prepareTokenListWithBalance = async () => {
    let tempList: any = [];

    for (const token of tokenCollection) {
      // need to fix later : fetch token balances of long list
      let result = await fetchTokenBalance(
        token?.isNative,
        token?.address,
        token?.decimals,
      );
      const res = await isNativeToken(token?.address);
      if (!res) {
        const newObj = Object.assign({ balance: Number(result) }, token);
        tempList.push(newObj);
      }
    }
    setTokenListWithBalance(tempList);
  };

  const updateTradeValuesFunction = async () => {
    const latestSelectedtoken1 = store.getState().swapData.token1;
    const latestSelectedtoken2 = store.getState().swapData.token2;
    dispatch(updateToken1Name(latestSelectedtoken1?.symbol));
    dispatch(updateToken2Name(latestSelectedtoken2?.symbol));
    dispatch(updateToken1DollarValue(token1DollarValue));
    dispatch(updateToken2DollarValue(token2DollarValue));

    dispatch(updateToken1Price(token1Price));
    dispatch(updateToken2Price(token2Price));
  };

  const handleChangeOne = async (inputValue: any) => {
    const value = String(inputValue);

    let values: any;
    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;
    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (values?.length <= maxLength) {
    } else if (values?.length > maxLength) {
      return;
    }

    if (values?.toString().charAt(0) === ".") {
      values = "0" + values;
    }

    dispatch(setInput1ValueRedux(values));
    const latestSelectedtoken1 = store.getState().swapData.token1;
    const latestSelectedtoken2 = store.getState().swapData.token2;
    dispatch(updateToken1Name(latestSelectedtoken1?.symbol));
    dispatch(updateToken2Name(latestSelectedtoken2?.symbol));
    if (Number(values) <= 0 || values === "") {
      dispatch(setConvertedInput1Redux(""));
      dispatch(setInput2ValueRedux(""));
      dispatch(setConvertedInput2Redux(""));
    } else {
      dispatch(setRoutePathsLoading(true));
      fetchInput2Value(values);
    }
  };

  const getTokenDetails = async (address: any) => {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const data = {
      tokenAddress: address,
      chainId: chainId,
    };
    try {
      // TODO get token details from data above
      const res: any = false;
      if (res?.status === 200) {
        const tokenData: any = {
          symbol: res?.token?.symbol,
          icon: res?.token?.logoURI,
        };
        return tokenData;
      } else if (res?.status === 400) {
        return "";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInput2Value = async (values: any) => {
    let tokenAddressOne;
    let tokenAddressTwo;
    let tokenSymbolOne;
    let tokenSymbolTwo;

    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.filter(
      (a: any) => a?.symbol == "WETH",
    );
    // we are fetching latest state of selectedToken1 as interval will
    // pick initial provided value every time
    const latestSelectedtoken1 = store.getState().swapData.token1;
    // const isNative = await isNativeToken(latestSelectedtoken1.address);

    tokenAddressOne = latestSelectedtoken1?.address;
    tokenSymbolOne = latestSelectedtoken1?.symbol;
    // }

    // const tokenInDecimals = await getDecimals(tokenAddressOne);
    const tokenInDecimals = latestSelectedtoken1?.decimals;

    const cv1: any = values * 10 ** Number(tokenInDecimals);

    // we are fetching latest state of selectedToken2 as interval will
    // pick initial provided value every time
    const latestSelectedtoken2 = store.getState().swapData.token2;

    if (latestSelectedtoken2?.isNative) {
      tokenAddressTwo = wethData[0]?.address;
      tokenSymbolTwo = "WETH";
    } else {
      tokenAddressTwo = latestSelectedtoken2?.address;
      tokenSymbolTwo = latestSelectedtoken2?.symbol;
    }

    dispatch(setConvertedInput1Redux(cv1));

    if (tokenAddressOne && tokenAddressTwo) {
      // we will pass this data to swap
      const data = {
        inputAmount: cv1,
        tokens: [tokenAddressOne, tokenAddressTwo],
      };

      setOpData(data); // we are using this data later at the time of executing swap

      const slippageTolerance = store.getState().swapData.slippageTolerance;
      const web3 = await callWeb3();

      const gasPriceResult = await web3.eth.getGasPrice();

      const estimatedGasPrice = web3.utils.fromWei(gasPriceResult, "Gwei"); // need to check later :::: "Gwei" or "ether"

      const payload = {
        inTokenAddress: tokenAddressOne,
        outTokenAddress: tokenAddressTwo,
        amount: Number(values),
        gasPrice: estimatedGasPrice,
        slippage: slippageTolerance,
      };

      const currentNetwork = store.getState().networkSlice.currentNetwork;

      let result: any;
      switch (currentNetwork) {
        case EHTEREUM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_ETH,
            payload,
          );
          break;

        case BNB_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_BSC,
            payload,
          );
          break;

        case POLYGON_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_POLYGON,
            payload,
          );
          break;

        case AVALANCHE_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_AVALANCHE,
            payload,
          );
          break;

        case ARBITRUM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_ARBITRUM,
            payload,
          );
          break;

        case FANTOM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_FANTOM,
            payload,
          );
          break;

        default:
          break;
      }

      const latestInput1Val = store.getState().swapData.input1Value;

      const dollarWorthToken1 = selectedToken1?.usd;

      if (result) {
        const allRoutes = result?.path?.routes;
        const tempRoutes: any = [];
        for (let index = 0; index < allRoutes?.length; index++) {
          const element = allRoutes[index];

          for (let index2 = 0; index2 < element?.subRoutes?.length; index2++) {
            const element2 = element?.subRoutes[index2];
            const tokenData: any = await getTokenDetails(element2.to);
            element2.toName = tokenData?.symbol;
            element2.toIcon = tokenData?.logoURI;
          }
          tempRoutes.push(element);
        }

        dispatch(updateRouteStepV2(tempRoutes));

        dispatch(updateRouteSteps(result?.path?.routes?.length));
        dispatch(setRoutePathsLoading(false));

        const dollarWorthToken2 = selectedToken2?.usd;

        // Fetch the best output value for token 2
        const rawValue = result.outAmount;
        const tokenOutDecimals = result.outToken.decimals;

        dispatch(setConvertedInput2Redux(rawValue));

        const resultoutput = rawValue / 10 ** Number(tokenOutDecimals);

        const convertedResultOutput = exponentialToDecimal(resultoutput);

        const latestInput2Val = Number(convertedResultOutput);

        // value that is making it 0
        setToken2DollarValue(
          Number(latestInput2Val) * Number(dollarWorthToken2),
        );
        dispatch(
          setOutTokenValueLimitOrder(
            Number(latestInput2Val) * Number(dollarWorthToken2),
          ),
        );

        dispatch(
          setBuyPrice(
            exponentialToDecimal(
              Number(latestInput1Val) / Number(latestInput2Val),
            ),
          ),
        );
        dispatch(
          setSellPrice(
            exponentialToDecimal(
              Number(latestInput2Val) / Number(latestInput1Val),
            ),
          ),
        );

        dispatch(
          setBuyPriceInDollars(
            exponentialToDecimal(
              (Number(latestInput1Val) / Number(latestInput2Val)) *
                Number(dollarWorthToken1),
            ),
          ),
        );
        dispatch(
          setSellPriceInDollars(
            exponentialToDecimal(
              (Number(latestInput2Val) / Number(latestInput1Val)) *
                Number(dollarWorthToken2),
            ),
          ),
        );

        const truncatedResultOutput = Number(convertedResultOutput)?.toFixed(4);

        dispatch(setInput2ValueRedux(truncatedResultOutput));

        dispatch(setTradeDataLoading(false));
      } else {
        dispatch(setBestValues(""));
        setToken2DollarValue("");
        dispatch(setOutTokenValueLimitOrder(""));
        setToken1Price("");
        setToken2Price("");
        dispatch(updateRouterPath([]));
        dispatch(updateRouteSteps(0));
        dispatch(setTradeDataLoading(false));
      }
    }
  };

  const switchTokens = async () => {
    dispatch(setToken1(selectedToken2));
    dispatch(setToken2(selectedToken1));
    dispatch(setInput1ValueRedux(input2));
  };

  useEffect(() => {
    if (currentPath !== "/limit-order") {
      const id: any = setInterval(async () => {
        const latestInput1Val = store.getState().swapData.input1Value;
        await handleChangeOne(latestInput1Val);
      }, 20000);
      setIntervalId(id);
      return () => {
        clearInterval(id);
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    fetchLiquiditySources();
  }, [chainID]);

  useEffect(() => {
    if (input1 === 0) {
      setToken1DollarValue("0");
      setToken2DollarValue("0");
      dispatch(setOutTokenValueLimitOrder(""));
    }
    setToken1DollarValue(Number(selectedToken1?.usd) * Number(input1));
  }, [input1, selectedToken1]);

  useEffect(() => {
    dispatch(setTradeDataLoading(true));
    setToken2DollarValue(Number(selectedToken2?.usd) * Number(input2));
    dispatch(setTradeDataLoading(false));
  }, [input2, selectedToken2]);

  useEffect(() => {
    fetchTokenOneBalance();
    prepareTokenListWithBalance();
  }, [address]);

  useEffect(() => {
    updateTradeValuesFunction();
    fetchTokenOneBalance();
    calculatebestValueForOneInputToken();
  }, [
    token1DollarValue,
    input2,
    updateTradeData,
    tokenCollection,
    selectedToken1,
  ]);

  // To update trade data on handleSelecToken
  useEffect(() => {
    handleSwapType();
    handleChangeOne(input1);
  }, [selectedToken1, selectedToken2]);

  // Fetching input2 value(bestvalue) might take some time, so incase while fetching
  // input2 value if user clears the input field we should cancel the ongoing request,
  // set other values to 0 and show loading as true
  useEffect(() => {
    if (Number(input1) <= 0 || (input1 === "" && tradeDataLoading)) {
      cancelPendingPostReq();
      dispatch(setConvertedInput1Redux(""));
      dispatch(setInput2ValueRedux(""));
      dispatch(setConvertedInput2Redux(""));
      dispatch(setRoutePathsLoading(true));
    }
  }, [tradeDataLoading]);

  useEffect(() => {
    const latestInput1Val = store.getState().swapData.input1Value;
    handleChangeOne(latestInput1Val);
  }, [updateTradeData]);

  useEffect(() => {
    const latestInput1Val = store.getState().swapData.input1Value;
    handleChangeOne(latestInput1Val);
  }, [updateTradeData]);

  useEffect(() => {
    const validTokens = tokenCollection.filter(
      (token: Token) => token.symbol.length <= 6,
    );

    if (validTokens.length > 1) {
      let randomIndex1 = Math.floor(Math.random() * validTokens.length);
      let randomIndex2 = Math.floor(Math.random() * validTokens.length);

      while (randomIndex1 === randomIndex2) {
        randomIndex2 = Math.floor(Math.random() * validTokens.length);
      }

      dispatch(setToken1(tokenCollection[randomIndex1]));
      dispatch(setToken2(tokenCollection[randomIndex2]));
    }
  }, [tokenCollection]);

  const handleLimitPrice = async (value: any) => {
    let values: any;
    const maxLength = 45;
    const validRegex = /^(\d+)?([.]?\d{0,45})?$/;
    const isInputValid = validRegex.test(value);
    if (isInputValid) {
      values = value;
    } else {
      return;
    }
    if (value?.length <= maxLength) {
      values = value;
    } else if (value?.length > maxLength) {
      return;
    }
    if (
      values?.length > 1 &&
      Array.from(values)[0] === "0" &&
      Array.from(values)[1] !== "."
    ) {
      values = values?.slice(1);
    }

    if (values?.toString().charAt(0) == ".") {
      values = "0" + values;
    }
    if (Number(values) <= 0 || values === "") {
      dispatch(setTokenOutAmount(0));
      dispatch(setLimitPrice(values));
      if (Number(values) < Number(initialLimitPrice)) {
        setShowLimitPriceBelowMarketWarning(true);
      } else {
        setShowLimitPriceBelowMarketWarning(false);
      }
    } else {
      dispatch(setLimitPrice(values));
      if (Number(values) < Number(initialLimitPrice)) {
        setShowLimitPriceBelowMarketWarning(true);
      } else {
        setShowLimitPriceBelowMarketWarning(false);
      }

      // dispatch(setTokenOutAmount((Number(input1 * limitPrice)).toFixed(4)));
      const calculatedValue: any = await bigNumberToFixed(
        Number(input1 * limitPrice),
      );
      const fourDecimalPlaceValue = await uptoFourDecimal(calculatedValue);
      dispatch(setTokenOutAmount(fourDecimalPlaceValue));
    }
  };

  function formatNumber(amount: any) {
    const amountString = String(amount);

    const decimalPlaces = amountString.split(".")[1];
    if (decimalPlaces && decimalPlaces.length > 5) {
      return Number(amount).toFixed(5);
    } else {
      return Number(amount);
    }
  }

  const handleSelect1Token = (token: any) => {
    dispatch(setToken1(token));
  };
  const handleSelect2Token = (token: any) => {
    dispatch(setToken2(token));
  };

  const limitOrder = (
    <>
      <div className="Swap_You_Cards" style={{ marginBottom: 20 }}>
        <Row
          align={"middle"}
          gutter={8}
          className={`swap_from_content ${
            limitAction === "Buy" ? "sellOrder" : ""
          }`}
        >
          <Col span={12}>
            <Row align={"middle"} gutter={4}>
              <Col>
                <img
                  className="SwapIcon"
                  src={selectedToken1?.logoURI}
                  alt="icons"
                />
              </Col>
              <Col style={{ display: "grid", justifyItems: "start" }}>
                <Text fontSize={12} color={colors.secondary500}>
                  {limitAction === "Buy" ? "To" : "From"}
                </Text>
                <Row align={"middle"} gutter={8}>
                  <Col>
                    <HeaderText
                      fontSize={18}
                      className={"clickable"}
                      onClick={handleShow1}
                    >
                      {getModifiedTokenSymbol(
                        selectedToken1?.symbol,
                        selectedToken1?.isNative,
                        currentPath,
                      )}
                      {"  "}
                    </HeaderText>
                  </Col>
                  <Col className={"swap-svg"}>
                    <DownIcon />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align={"middle"} gutter={4} className={"swap_from_content"}>
              <Col>
                <img
                  className="SwapIcon"
                  src={selectedToken2?.logoURI}
                  alt="icons"
                />
              </Col>
              <Col style={{ display: "grid", justifyItems: "start" }}>
                <Text fontSize={12} color={colors.secondary500}>
                  {limitAction === "Buy" ? "From" : "To"}
                </Text>
                <Row align={"middle"} gutter={8}>
                  <Col>
                    <HeaderText
                      fontSize={18}
                      className={"clickable"}
                      onClick={handleShow2}
                    >
                      {getModifiedTokenSymbol(
                        selectedToken2?.symbol,
                        selectedToken2?.isNative,
                        currentPath,
                      )}
                      {"  "}
                    </HeaderText>
                  </Col>
                  <Col className={"swap-svg"}>
                    <DownIcon />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="Swap_You_Cards" style={{ marginBottom: 20 }}>
        <div className="">
          <div
            className="swap_from_price align-items-center"
            style={{ width: "100%" }}
          >
            <div className="swap_from_content">
              <Row>
                <Col span={24} style={{ textAlign: "left" }}>
                  <Text fontSize={12} color={colors.secondary500}>
                    Limit price
                  </Text>
                </Col>
                <Col span={24} style={{ textAlign: "left" }}>
                  <Row align={"middle"} justify={"space-between"}>
                    <Col span={10}>
                      {limitPriceLoading ? (
                        <div className="height_adjust">
                          <CustomShimmer width={160} height={15} light />
                        </div>
                      ) : (
                        <input
                          onKeyDown={(evt) => {
                            evt.code === "ArrowDown" && evt.preventDefault();
                            evt.code === "ArrowUp" && evt.preventDefault();
                            disabledLetters.includes(evt.key) &&
                              evt.preventDefault();
                          }}
                          type="text"
                          inputMode="decimal"
                          onWheel={(e: any) => e.target.blur()}
                          value={limitPrice}
                          onChange={(e) =>
                            handleLimitPriceChange?.(e.target.value)
                          }
                          style={{ textAlign: "left" }}
                          className="form-control"
                          placeholder="0.00"
                        />
                      )}
                    </Col>
                    <Col span={14} style={{ textAlign: "right" }}>
                      <Text fontSize={18} fontWeight={600}>
                        {selectedToken2?.symbol}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {tokenCollection ? (
        <>
          <div className="YouSellBuyCard">
            {currentPath === "/limit-order" && limitOrder}
            {!isCoversionHide && (
              <div className="text-center conversionText">
                1{" "}
                <strong className="ms-2 me-1">{selectedToken1?.symbol}</strong>{" "}
                ={" "}
                <span
                  className="token_buyPrice mx-2 tooltip_container"
                  // style={{ color: "white" }}
                >
                  <span className="shimmer_insert">
                    {tradeDataLoading || Number(input1) === 0 ? (
                      <CustomShimmer width={45} height={15} />
                    ) : Number(bestValuePerToken) < 0.01 ? (
                      `${bestValuePerToken
                        ?.toString()
                        .slice(0, 3)}..${bestValuePerToken
                        ?.toString()
                        .slice(
                          bestValuePerToken - 4,
                          bestValuePerToken.length,
                        )}`
                    ) : (
                      decimalFormatWithoutRoundOffCrypto(
                        Number(bestValuePerToken),
                      )
                    )}
                  </span>
                  {Number(input1) !== 0 && (
                    <p className="hover_tooltip">{bestValuePerToken}</p>
                  )}
                </span>
                <strong>{selectedToken2?.symbol} </strong>
              </div>
            )}
          </div>{" "}
          <>{children}</>
        </>
      ) : (
        <div
          className={"text-center"}
          style={{ marginBottom: "30px", fontSize: "20px" }}
        >
          Token list empty
        </div>
      )}

      <Row gutter={[0, 12]} style={{ marginBottom: "1em" }}>
        {isConnected &&
          currentPath === "/limit-order" &&
          showOrderExecutionWarning && (
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: `${colors.warning}15`,
                  borderRadius: 8,
                }}
                className={"card-sm-padding"}
              >
                <Row align={"middle"}>
                  <Col span={22}>
                    <Text color={colors.warning}>
                      Note: If the profit is less than the transaction gas fees
                      then your order will not be executed. Gas fees varies as
                      per network congestion.
                    </Text>
                  </Col>
                  <Col
                    span={2}
                    className={"clickable"}
                    onClick={() =>
                      dispatch(setShowOrderExecutionWarning(false))
                    }
                  >
                    <CloseCircleFilled style={{ color: colors.warning }} />
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        {isConnected &&
          currentPath === "/limit-order" &&
          showLimitPriceBelowMarketWarning && (
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: `${colors.warning}15`,
                  borderRadius: 8,
                }}
                className={"card-sm-padding"}
              >
                <Text color={colors.warning}>
                  Please be aware that the limit price you entered is below the
                  market price, which means your{" "}
                  {getModifiedTokenSymbol(
                    selectedToken1?.symbol,
                    selectedToken1?.isNative,
                    currentPath,
                  )}{" "}
                  token will be sold at a lower market price.
                </Text>
              </Card>
            </Col>
          )}

        {/* TODO : please fix min dollar worth satisfied */}
        {/* {isLoggedIn &&
          currentPath === "/limit-order" &&
          !minDollarWorthSatisfied && (
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: `${colors.error}15`,
                  borderRadius: 8,
                }}
                className={"card-sm-padding"}
              >
                <Text color={colors.error}>
                  * Please increase input amount to place the order
                </Text>
              </Card>
            </Col>
          )} */}
      </Row>

      {isConnected ? (
        <ReviewOrderButton
          minDollarWorthSatisfied={true}
          showReviewModal={showReviewModal}
          userTokenOneBalance={userTokenOneBalance}
          tokenSelect1={selectedToken1}
          tokenSelect2={selectedToken2}
          swapType={swapType}
          className=" w-100"
          opData={opData}
          input1Amount={convertedInput1}
          input2Amount={convertedInput2}
          fetchTokenOneBalance={fetchTokenOneBalance}
        />
      ) : (
        <ConnectWallet className="w-100" />
      )}

      <SelectTokenModalSimple
        show={showModal1}
        handleClose={handleClose1}
        onSelectToken={handleSelect1Token}
        disableToken={selectedToken2}
        selectedToken={selectedToken1}
      />

      <SelectTokenModalSimple
        show={showModal2}
        handleClose={handleClose2}
        onSelectToken={handleSelect2Token}
        disableToken={selectedToken1}
        selectedToken={selectedToken2}
      />
    </>
  );
};

export default YouSellBuyCard;
