import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LiquidityCommon from "../../Common/Liquidity/LiquidityCommon";

const Liquidity = () => {
  const LiquidityContent = [
    {
      formLabel: "Swap Fee",
      classBar: "SwapBar",
      classText: "TextSwap",
      placeholder: "0.0%",
      now: "30",
      valueTotle: "Total Votes",
      TotleAm: "1,181,782.99",
      Address: "Address",
      AddressValue: "175",
    },
    {
      formLabel: "Decay Time",
      classBar: "DecayBar",
      classText: "TextSwap",
      placeholder: "0M 00S",
      now: "40",
      valueTotle: "Total Votes",
      TotleAm: "1,181,782.99",
      Address: "Address",
      AddressValue: "175",
    },
    {
      formLabel: "Referral Reward",
      classBar: "ReferralBar",
      classText: "TextSwap",
      placeholder: "0.0%",
      now: "65",
      valueTotle: "Total Votes",
      TotleAm: "1,181,782.99",
      Address: "Address",
      AddressValue: "175",
    },
    {
      formLabel: "Treasury",
      classBar: "TreasuryBar",
      classText: "TextSwap",
      placeholder: "0.0%",
      now: "47",
      valueTotle: "Total Votes",
      TotleAm: "1,181,782.99",
      Address: "Address",
      AddressValue: "175",
    },
    {
      formLabel: "Price Impact Fee",
      classBar: "PriceBar",
      classText: "TextSwap",
      placeholder: "0.0%",
      now: "55",
      valueTotle: "Total Votes",
      TotleAm: "1,181,782.99",
      Address: "Address",
      AddressValue: "175",
    },
  ];
  return (
    <div className="Liquidity py-70">
      <Container className="inner-cont">
        <Row className="align-items-center">
          <Col md={12}>
            <div className="Headings">
              <h4 className="mb-4">Liquidity protocol governance</h4>
              <p className="SubHeading">
                Instant Governance Is A Community Tool For Setting Optimal Pool
                Configuration Parameters. Each Liquidity Provider Can Vote On
                Parameter Values Using Their Staked LP Tokens. Pool Governance
                Enables The Configuration Of Parameters That Are Specific To
                Each Pool. Users Can Vote To Change Shido’s Core Parameters. The
                Weight Of A Vote Depends On The Amount Of Shido Tokens Staked By
                A User. Parameters With Default Prefixes Are Used As Default
                Vote Results For Liquidity Providers Who Do Not Participate In
                Pool Governance.
              </p>
            </div>
          </Col>
          <Col md={12}>
            {LiquidityContent.map((item, idx) => (
              <LiquidityCommon
                formLabel={item?.formLabel}
                classBar={item?.classBar}
                classText={item?.classText}
                placeholder={item?.placeholder}
                now={item?.now}
                valueTotle={item?.valueTotle}
                TotleAm={item?.TotleAm}
                Address={item?.Address}
                AddressValue={item?.AddressValue}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Liquidity;
