import {
  prepareSendTransaction,
  sendTransaction,
  waitForTransaction,
} from "@wagmi/core";
import { message } from "antd";
import { OPEN_OCEAN_CONTRACT_ROUTER } from "../../Constants/AppVariables/appVariable";
import { VOUCHER_MESSAGE } from "../../Constants/WalletTypes/WalletTypes";
import {
  setShowTransactionModal,
  setSubmitTransactionLoading,
  setSubmittedTransactionHash,
  setSwapTransactionLoading,
  setTransactionErrorMessage,
  setTransactionHash,
  setTransactionSuccessMessage,
} from "../../redux/reducers/loadingData/loadingData";
import store from "../../redux/store";
import { getMessageConfig } from "../../utils";
import {
  callContractMethod,
  callContractReadMethod,
  callWeb3,
} from "../walletServices";
import { commonAbiInstancesForCallFunctions } from "./GlobalCall";

export const SwapByOpenOceanNative = async (
  walletAddress: any,
  swapParams: any,
) => {
  try {
    const convertedInput1 = store.getState().swapData.convertedInput1Redux;

    // Define common network properties
    const commonNetworkProps = {
      to: swapParams?.to,
      from: walletAddress,
      data: swapParams?.data,
      value: BigInt(convertedInput1),
      account: walletAddress,
    };

    console.log(swapParams, "all params");
    // Send the transaction using the selected network configuration
    const config = await prepareSendTransaction(commonNetworkProps);

    const { hash } = await sendTransaction(config);

    const swapResult = await waitForTransaction({
      hash,
    });

    return swapResult;
  } catch (error: any) {
    console.log("Error in => SwapByOpenOceanNative", error);
    handleTransactionError(error);
    return error;
  }
};

// Handle transaction error
export const handleTransactionError = (error: any) => {
  store.dispatch(setSwapTransactionLoading(false));

  let errorMessage = "";

  if (
    error?.message?.includes("gas less") ||
    error?.message?.includes("cap less")
  ) {
    errorMessage = "Please try again with more gas fee";
  } else if (error?.message?.includes("rejected") || error.code === 4001) {
    errorMessage = "User rejected the request";
  } else if (error?.message?.includes("Invalid msg.value")) {
    errorMessage = "Please try decreasing input amount";
  } else if (
    error?.message?.includes("execution reverted") ||
    error.code === -32603
  ) {
    errorMessage = "Please try again with higher slippage tolerance";
  } else if (
    error?.message?.includes("gas required exceeds") ||
    error.code === -32000
  ) {
    errorMessage =
      "There is not enough gas balance to complete the transaction";
  } else if (error?.message.includes("insufficient funds")) {
    errorMessage = "You have insufficient funds to complete the transaction.";
  }

  store.dispatch(setTransactionErrorMessage(""));
  store.dispatch(setShowTransactionModal(false));
  store.dispatch(setSwapTransactionLoading(false));
  store.dispatch(setSubmitTransactionLoading(false));
  store.dispatch(setTransactionHash(""));
  store.dispatch(setSubmittedTransactionHash(""));
  store.dispatch(setTransactionSuccessMessage(""));

  message.error(
    getMessageConfig({
      type: "error",
      title: "Something went wrong",
      body: errorMessage,
    }),
  );
};
// -------------------------------------END--------------------------------------//

export const SwapByOpenOceanCustom = async (
  walletAddress: any,
  swapParams: any,
) => {
  try {
    const data = {
      to: swapParams?.to,
      account: walletAddress,
      data: swapParams?.data,
    };

    const config = await prepareSendTransaction(data);

    const { hash } = await sendTransaction(config);

    const swapResult = await waitForTransaction({
      hash,
    });

    return swapResult;
  } catch (error: any) {
    console.log("Error in => SwapByOpenOceanCustom", error);

    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again with more gas fee",
        }),
      );
    } else if (error?.message?.includes("rejected") || error.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "User rejected the request",
        }),
      );
    } else if (error?.message?.includes("Invalid msg.value")) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try decreasing the input amount",
        }),
      );
    } else if (
      error?.message?.includes("execution reverted") ||
      error.code === -32603
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please increase your slippage tolerance and try again",
        }),
      );
    } else if (
      error?.message?.includes("gas required exceeds") ||
      error.code === -32000
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "You need more gas to complete this transaction",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
        }),
      );
    }
    return error;
  }
};

export const getAllowance = async (walletAddress: any, tokenName: string) => {
  try {
    let tokenCollection = store.getState().tokenCollection.tokenCollection;

    if (tokenName !== "SHIDO") {
      tokenCollection = store.getState().tokenCollection.allOpenOceantokens;
    }
    const routerAddress = OPEN_OCEAN_CONTRACT_ROUTER;

    const TOKENdata: any = tokenCollection.filter(
      (a: any) => a.symbol == tokenName,
    );

    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;

    const address = TOKENdata[0]?.address;
    const abi: any[] = JSON.parse(JSON.stringify(currentChainToken[0]?.abi));
    const functionName = "allowance";
    const args: any[] = [walletAddress, routerAddress];
    const fromAddress = walletAddress;

    const result = await callContractReadMethod({
      address,
      abi,
      functionName,
      args,
      fromAddress,
      // gasPrice,
    });

    return result;
  } catch (error) {
    console.log("Error in getAllowance => ", error);
    return error;
  }
};

export const tokenBalanceFunction = async (
  waddress: string,
  selectedTokenName: string,
  isNative: string,
  tokenName: string,
) => {
  try {
    const tokenCollection = store.getState().tokenCollection.tokenCollection;

    const tokenNamedata: any = tokenCollection.filter(
      (a: any) => a.symbol == tokenName,
    );

    const contract = await commonAbiInstancesForCallFunctions(
      tokenNamedata[0]?.address,
      JSON.parse(JSON.stringify(tokenNamedata[0]?.abi)),
    );

    if (contract) {
      let result: any;
      // if is Native Token call nativeTokenBalance else balanceOf
      if (isNative) {
        result = await nativeTokenBalance(waddress);
        // result = Number(res);
      } else {
        result = await contract.methods.balanceOf(waddress).call();
      }

      return result;
    }
  } catch (error) {
    console.log("Error in => tokenBalanceFunction", error);
  }
};

export const nativeTokenBalance = async (waddress: string) => {
  try {
    let _web3Instance: any = await callWeb3();
    const balance = await _web3Instance.eth.getBalance(waddress);
    const etherBal = await balance;
    return etherBal;
  } catch (error) {
    console.log("Error in => nativeTokenBalance", error);
  }
};

export const GetRouterApprovalOpenOcean = async (
  walletAddress: any,
  tokenName: any,
  inputAmout: any,
  contractAddress: any,
) => {
  try {
    let tokenCollection = store.getState().tokenCollection.tokenCollection;

    if (tokenName !== "SHIDO") {
      tokenCollection = store.getState().tokenCollection.allOpenOceantokens;
    }

    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;

    const tokenNamedata: any = tokenCollection.filter(
      (a: any) => a.symbol == tokenName,
    );

    const contractABI = JSON.parse(JSON.stringify(currentChainToken[0]?.abi));

    const functionName = "approve";
    const args: any[] = [contractAddress, inputAmout];
    const fromAddress = walletAddress;

    const result = await callContractMethod({
      contractAddress: tokenNamedata[0]?.address,
      contractABI,
      functionName,
      args,
      fromAddress,
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    return data;
  } catch (error: any) {
    console.log("Error in => GetRouterApprovalOpenOcean", error);

    store.dispatch(setTransactionErrorMessage(""));
    store.dispatch(setShowTransactionModal(false));
    store.dispatch(setSwapTransactionLoading(false));
    store.dispatch(setSubmitTransactionLoading(false));
    store.dispatch(setTransactionHash(""));
    store.dispatch(setSubmittedTransactionHash(""));
    store.dispatch(setTransactionSuccessMessage(""));

    store.dispatch(setSwapTransactionLoading(false));
    if (
      error?.message?.includes("gas less") ||
      error?.message?.includes("cap less")
    ) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "You need more gas to complete this transaction",
        }),
      );
    } else if (error?.message?.includes("execution reverted")) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Please try again later",
        }),
      );
    } else if (error?.code === 4001) {
      message.error(
        getMessageConfig({
          type: "error",
          title: "You have rejected this transaction!",
          body: "User denied transaction",
        }),
      );
    } else {
      message.error(
        getMessageConfig({
          type: "error",
          title: "Something went wrong",
          body: "Approval failed, please try again",
        }),
      );
    }

    return error;
  }
};

// Sign Swap Contract Function
export const signSwapContract = async (walletAddress: any) => {
  const contractCollection =
    store.getState().contractCollection.contractCollection;
  const ROUTER_TYPE = store.getState().swapData.router_type;
  const ROUTERdata: any = contractCollection.filter(
    (a: any) => a.symbol == ROUTER_TYPE,
  );
  const chainId = store.getState().networkSlice.ChainID;

  try {
    const owner = walletAddress;
    const web3: any = await callWeb3();

    const EIP712Domain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];

    const domain = {
      name: "Shido_Router",
      version: "1",
      chainId: chainId,
      verifyingContract: ROUTERdata[0]?.address,
    };

    const validateUser = [
      { name: "message", type: "string" },
      { name: "userAddress", type: "address" },
      { name: "chainId", type: "uint160" },
    ];

    const message = {
      message: VOUCHER_MESSAGE,
      userAddress: walletAddress,
      chainId: chainId,
    };

    const data = JSON.stringify({
      types: {
        EIP712Domain,
        validateUser,
      },
      domain,
      primaryType: "validateUser",
      message,
    });

    const from = owner;
    const params = [from, data];
    const method = "eth_signTypedData_v4";

    const res = await web3?.currentProvider?.request({
      method,
      params,
      from,
    });

    if (res) {
      return {
        status: 200,
        signature: res,
      };
    }
    // return res;
  } catch (err) {
    console.log("Error in signSwapContract", err);
    // return err;
  }
};
