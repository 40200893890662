import React from "react";
import "./CustomShimmer.scss";

type propTypes = {
  className?: string;
  height?: number;
  width?: number;
  light?: boolean;
};

const CustomShimmer = (props: propTypes) => {
  const { className, height, width, light = false } = props;

  return (
    <div
      style={{
        width: width ? `${width}px` : "100%",
        height: `${height}px` ? height : "4px",
      }}
      className={`${className ? `${className} ` : ""} ${light ? "light_shimmer" : "custom_shimmer"}`}
    />
  );
};

export default CustomShimmer;
