import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { GasRefund } from "../../../../Constants/ImagesConstants";
import "./GasRefundModal.scss";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { Link } from "react-router-dom";

const GasRefundModal = ({ handleClose, show }: any) => {
  const TokenData = [
    {
      id: "1",
      refund: "5%",
      inch: "0",
    },
    {
      id: "2",
      refund: "10%",
      inch: "1-100",
    },
    {
      id: "3",
      refund: "25%",
      inch: "100+",
    },
    {
      id: "4",
      refund: "50%",
      inch: "1,000+",
    },
    {
      id: "5",
      refund: "75%",
      inch: "10,000+",
    },
    {
      id: "6",
      refund: "95%",
      inch: "1,00,000+",
    },
  ];
  return (
    <>
      <Modal
        className="Common_Modal gas_refund_modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Gas Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Common_Modal_Content">
          <div className="d-flex flex-wrap">
            <div className="gas_title">
              <h5>Shido refunds gas costs!</h5>
              <p>
                SHIDO Foundation compensates gas cost in Shido tokens depending
                on your stake size.
              </p>
            </div>
            <span className="GasRefund_logo">
              <img src={GasRefund} alt="Gas refund" />
            </span>
          </div>
          <div className="gas_refund_Box">
            <div className="refund_data">
              <span>Gas refund</span>
              <span>Staked 1INCH</span>
            </div>
            <div className="gas_refund_data">
              {TokenData.map((data, idx) => {
                return (
                  <div
                    className="Token_icon_Name d-flex align-items-center justify-content-around"
                    key={idx}
                  >
                    <span className="border_right">{data.refund}</span>
                    <span>{data.inch}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <ButtonCustom className="mt-5 w-100" title="Go to staking" />
          <Link to="/" className="text-center mt-4 mb-0 aboutgas d-block">
            Learn more about gas cost refund
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GasRefundModal;
