import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ButtonCustom, MultiSelect, PoolsAccordian } from "../../../Common";
import "./Pools.scss";

const Pools = () => {
  return (
    <div className="pools">
      <Container className="inner-cont">
        <Row className="align-items-center">
          <Col md={1} xs={12} className="mb-3 mb-md-0">
            <h4 className="mb-0">Pools</h4>
          </Col>
          <Col md={9} sm={8} className="mb-3 mb-sm-0">
            <MultiSelect />
          </Col>
          <Col md={2} sm={4}>
            <ButtonCustom title="Create Pool" className="gray-btn w-100" />
          </Col>
        </Row>
        <div className="pool-table">
          <div className="pool-table__header commn-grid d-none d-lg-grid">
            <div>Pool</div>
            <div></div>
            <div>Liquidity</div>
            <div>Volume</div>
            <div>APY</div>
            <div></div>
          </div>
          <PoolsAccordian />
        </div>
      </Container>
    </div>
  );
};

export default Pools;
