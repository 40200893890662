import { ethers } from "ethers";
import { MessageContent } from "../Components";

type MessageProps = {
  type: "success" | "error";
  title: string;
  body?: string;
  duration?: number;
};

export const getMessageConfig = ({
  type,
  title,
  body,
  duration,
}: MessageProps) => ({
  type,
  content: <MessageContent type={type} title={title} body={body} />,
  duration: duration ?? 3,
  icon: <></>,
  className: type === "success" ? "success-message" : "error-message",
});

export const subtractOne = (number: number) => {
  const decimalCount = (number?.toString().split(".")[1] || "").length;

  const result = number - Math.pow(10, -decimalCount);

  return result;
};

export const roundToFiveDecimalPlaces = (number: any) => {
  const decimalPlaces = (Number(number).toString().split(".")[1] || []).length;

  if (decimalPlaces > 5) {
    return parseFloat(Number(number).toFixed(5));
  } else {
    return number;
  }
};

const MAX_DECIMALS = 4;

export function fromReadableAmount(
  amount: any,
  decimals: number,
): ethers.BigNumber {
  return ethers.utils.parseUnits(amount.toString(), decimals);
}

export function toReadableAmount(rawAmount: number, decimals: number): string {
  return ethers.utils.formatUnits(rawAmount, decimals).slice(0, MAX_DECIMALS);
}

const CHAIN_WRAPPED_NATIVE: any = {
  1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  56: "0xbb4cdb5dbf16e6cec1dedee27a2a1a3980f3388a",
  137: "0x0d500b2d5d8af5bf4af8e8288b2c5e88a783e2c9",
  250: "0x1de55e7de2dcfaa29fee7e14feadbefb6c9b44e8",
  43114: "0x8dFfEaB7E161BD98e3C689bC940f9A6C02aefc28",
};

export function getWrappedTokenAddress(chainId: number): string {
  if (!CHAIN_WRAPPED_NATIVE[chainId]) {
    throw new Error(`Unsupported chain ID: ${chainId}`);
  }

  return CHAIN_WRAPPED_NATIVE[chainId];
}
