import { Card, Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { EmptyLogoForWallet } from "../../../../Assets/icons/emptyLogo";
import { Text } from "../../../Text";
import { MyStakings } from "../Stakings/Stakings";
import "./info.scss";

interface WalletInformationProps {
  icon: any;
  walletType?: string;
  walletName?: string;
  walletAmount?: string;
  number?: string;
  dollarAmount?: string;
}

interface ERC20TokenBalance {
  token_address: string | null;
  symbol: string;
  name: string;
  logo: string;
  thumbnail: string;
  decimals: number;
  balance: string;
  possible_spam: boolean;
  verified_contract: boolean | null;
  balance_formatted: number | null;
  usd_price: number | null;
  usd_price_24hr_percent_change: number | null;
  usd_price_24hr_usd_change: number | null;
  usd_value: number | null;
  usd_value_24hr_usd_change: number | null;
  native_token: boolean | null;
  portfolio_percentage: number | null;
}

const WalletInformation = (props: WalletInformationProps) => {
  const { icon, walletType, walletName, walletAmount, number, dollarAmount } =
    props;
  return (
    <div className="wallet-info">
      <div className="text">
        {icon}
        <div className="information">
          <Text fontWeight={500} color="white" fontSize={"14px"}>
            {walletName}
          </Text>
          <Text fontWeight={500} color="#9B9CA3" fontSize={"14px"}>
            {walletAmount}
          </Text>
        </div>
        <div style={{ color: "#7C7C82" }}>{walletType}</div>
      </div>
      <div className="number">
        <Text fontWeight={500} color="white" fontSize={"14px"}>
          {number}
        </Text>
        <Text
          fontWeight={500}
          color="#9B9CA3"
          fontSize={"14px"}
          style={{ textAlign: "end" }}
        >
          {dollarAmount}
        </Text>
      </div>
    </div>
  );
};

const MyWallet = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const [tokens, setTokens] = useState<ERC20TokenBalance[]>([]);
  const [totalUSD, setTotalUSD] = useState<number>(0);
  const [totalETH, setTotalETH] = useState<number>(0);

  useEffect(() => {
    const fetchTokens = async () => {
      if (!address || !chain?.id) return;
      const moralisChain = mapChainIdToMoralisChain(chain?.id);
      if (!moralisChain) {
        console.error("Unsupported chain ID:", chain?.id);
        return;
      }
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-Key":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjYwNjk0NTIxLTkwZDItNGFmNC04MmM5LWJkN2Y3NGIyMjBjMCIsIm9yZ0lkIjoiMzc5OTc1IiwidXNlcklkIjoiMzkwNDQ2IiwidHlwZUlkIjoiODJmNjJhMGYtMmRiYS00YTg1LTgzNGYtNjM4YjJlY2E5NWIyIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDg5NTkzMjQsImV4cCI6NDg2NDcxOTMyNH0.cWxUqneGoZhBAuL5rfWKyjCIvorL9xkcNLUAWbQOblw",
        },
      };

      try {
        const response = await fetch(
          `https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=${moralisChain}&exclude_spam=true&exclude_unverified_contracts=true`,
          options,
        );
        const data = await response.json();
        const fetchedTokens = data.result;

        setTokens(fetchedTokens);

        const total = fetchedTokens.reduce(
          (acc: any, token: any) => acc + (token.usd_value || 0),
          0,
        );
        setTotalUSD(total);
      } catch (error) {
        console.error("Failed to fetch tokens:", error);
      }
    };

    fetchTokens();
  }, [address, chain?.id]);

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd",
        );
        const data = await response.json();

        const totalInETH = totalUSD / data.ethereum.usd;
        setTotalETH(totalInETH);
      } catch (error) {
        console.error("Failed to fetch ETH price:", error);
      }
    };

    fetchEthPrice();
  }, [chain?.id]);

  function mapChainIdToMoralisChain(
    chainId?: number | string,
  ): string | undefined {
    const chainMapping: { [key: string]: string } = {
      // Ethereum Mainnet
      "1": "eth",
      // Binance Smart Chain Mainnet
      "56": "bsc",
      // Polygon (Matic) Mainnet
      "137": "polygon",
      // Avalanche C-Chain Mainnet
      "43114": "avalanche",
      // Arbitrum One Mainnet
      "42161": "arbitrum",
      // Binance Smart Chain Testnet
      "97": "bsc testnet",
    };

    return chainId ? chainMapping[chainId.toString()] : undefined;
  }

  console.log("token", tokens);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
      <Card className="info-card" style={{ overflowY: "auto" }}>
        <Text
          fontWeight={600}
          color="white"
          fontSize={"20px"}
          style={{ textAlign: "left" }}
        >
          My Wallet
        </Text>

        <div className="info">
          <div className="textual-info">
            <Text fontWeight={400} color="#7C7C82" fontSize={"16px"}>
              Balance
            </Text>
            <div className="network-info">
              <Text fontWeight={400} color="#7C7C82" fontSize={"16px"}>
                Network:
              </Text>
              <div className="network">
                <Text fontWeight={400} color="#3B87F7" fontSize={"14px"}>
                  {chain?.name}
                </Text>
              </div>
            </div>
          </div>
          <div className="number-info" style={{ marginTop: "9px" }}>
            <Text fontWeight={500} color="white" fontSize={"32px"}>
              {isNaN(totalETH) ? 0 : totalETH.toFixed(6)}
            </Text>
            <div style={{ color: "#7C7C82" }}>ETH</div>
          </div>
        </div>
        {tokens.map((token, index) => (
          <div key={index}>
            <Divider style={{ backgroundColor: "#37373C" }} />
            <WalletInformation
              icon={
                token.logo ? (
                  <img
                    src={token.logo}
                    alt={token.symbol}
                    width={36}
                    height={36}
                  />
                ) : (
                  <EmptyLogoForWallet />
                )
              }
              walletType={token.symbol}
              walletName={token.name}
              walletAmount={`$${token.usd_value?.toFixed(2)}`}
              number={`${token.balance_formatted}`}
              dollarAmount={`$${token?.usd_value?.toFixed(2)}`}
            />
          </div>
        ))}
      </Card>
    </Col>
  );
};

export const Info = () => {
  return (
    <Row
      style={{ width: "100%" }}
      gutter={[
        { xs: 0, sm: 0, md: 0, lg: 0, xl: 20 },
        { xs: 10, sm: 10, md: 10, lg: 10, xl: 10 },
      ]}
    >
      <MyWallet />
      <MyStakings />
    </Row>
  );
};
