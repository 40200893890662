export const FROM_CHAIN = "FROM_CHAIN";
export const TO_CHAIN = "TO_CHAIN";

export const CHAINGE_CHAIN_ETH = "ETH";
export const CHAINGE_CHAIN_BSC = "BSC";
export const CHAINGE_CHAIN_POLYGON = "polygon";
export const CHAINGE_CHAIN_AVALANCHE = "AVAX";
export const CHAINGE_CHAIN_ARBITRUM = "ARB";
export const CHAINGE_CHAIN_FANTOM = "FTM";

export type CHAINGE_CHAIN_NAMES =
  | "ETH"
  | "BSC"
  | "polygon"
  | "AVAX"
  | "ARB"
  | "FTM"
  | undefined;
