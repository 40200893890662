import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ethicon, bnb, polygon_icon } from "../../../Constants/ImagesConstants";
import "./MultiSelect.scss";

const animatedComponents = makeAnimated();

const options = [
  {
    value: "eth",
    label: (
      <div className="multi-options">
        <img src={ethicon} alt="icon" />
        ETH
      </div>
    ),
  },
  {
    value: "bnb",
    label: (
      <div className="multi-options">
        <img src={bnb} alt="icon" />
        BNB
      </div>
    ),
  },
  {
    value: "polygen",
    label: (
      <div className="multi-options">
        <img src={polygon_icon} alt="icon" />
        Polygen
      </div>
    ),
  },
];

const MultiSelect = () => {
  return (
    <div className="multi-wrap">
      <Select
        closeMenuOnSelect={true}
        components={animatedComponents}
        placeholder="Enter token name..."
        classNamePrefix="multi-select"
        isMulti
        options={options}
        // menuIsOpen={true}
      />
    </div>
  );
};

export default MultiSelect;
