import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    walletAddress: "",
    walletType: "",
    chainId: "",
    userWalletChainId: "",
  },
  reducers: {
    resetAddressSlice: (state: any, action) => {
      state.walletAddress = "";
      state.walletType = "";
      state.chainId = "";
      state.userWalletChainId = "";
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    setWalletType: (state, action) => {
      state.walletType = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    setUserWalletCurrentNetworkId: (state, action) => {
      state.chainId = action.payload;
    },
  },
});
export const {
  setWalletAddress,
  setWalletType,
  setChainId,
  setUserWalletCurrentNetworkId,
  resetAddressSlice,
} = addressSlice.actions;

export default addressSlice.reducer;
