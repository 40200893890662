import { createSlice } from "@reduxjs/toolkit";

export const tradeDataCrossChain: any = createSlice({
  name: "tradeDataCrossChain",
  initialState: {
    fromTokenNameCross: "",
    toTokenNameCross: "",
    token1DollarValueCross: "",
    token2DollarValueCross: "",
    token1PriceCross: "",
    token2PriceCross: "",
    updateDataCross: false,
    bestValueCross: "",
  },
  reducers: {
    resetTradeDataCrossSlice: (state: any, action) => {
      state.fromTokenNameCross = "";
      state.toTokenNameCross = "";
      state.token1DollarValueCross = "";
      state.token2DollarValueCross = "";
      state.token1PriceCross = "";
      state.token2PriceCross = "";
      state.updateDataCross = false;
      state.bestValueCross = "";
    },
    setToken1NameCross: (state, action) => {
      state.fromTokenNameCross = action.payload;
    },
    setToken2NameCross: (state, action) => {
      state.toTokenNameCross = action.payload;
    },
    updateToken1DollarValueCross: (state, action) => {
      state.token1DollarValueCross = action.payload;
    },
    updateToken2DollarValueCross: (state, action) => {
      state.token2DollarValueCross = action.payload;
    },
    updateToken1PriceCross: (state, action) => {
      state.token1PriceCross = action.payload;
    },
    updateToken2PriceCross: (state, action) => {
      state.token2PriceCross = action.payload;
    },
    setUpdateTradeDataCross: (state, action) => {
      state.updateDataCross = action.payload;
    },
    setBestValuesCross: (state, action) => {
      state.bestValueCross = action.payload;
    },
  },
});
export const {
  setToken1NameCross,
  setToken2NameCross,
  updateToken1DollarValueCross,
  updateToken2DollarValueCross,
  updateToken1PriceCross,
  updateToken2PriceCross,
  setUpdateTradeDataCross,
  setBestValuesCross,
  resetTradeDataCrossSlice,
} = tradeDataCrossChain.actions;

export default tradeDataCrossChain.reducer;
