import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const EmptyLogo = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#37373C" />
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#7C7C82" />
    <g opacity="0.2" clip-path="url(#clip0_2527_41814)">
      <path
        d="M23.6244 19.8174V21.2818L18 24.3355L12.3756 21.2818V19.7627L9 17.9304V23.114L18 27.9999L27 23.114V17.9841L23.6244 19.8174ZM21.1335 10.1572C21.1486 10.2689 21.1556 10.3827 21.1556 10.4987C21.1556 11.5648 20.5515 12.4995 19.6471 13.015L23.6244 15.1745V18.3288L27 16.4966V13.3422L21.1335 10.1572ZM14.9038 10.4987C14.9038 10.3717 14.9119 10.2459 14.9305 10.1233L9 13.3422V16.443L12.3756 18.2752V15.1745L16.3832 12.9986C15.4951 12.4798 14.9038 11.5538 14.9038 10.4987Z"
        fill="white"
      />
      <path
        d="M13.2891 16.6816V18.6036L16.2235 20.2246L17.9997 19.2581L13.2891 16.6816Z"
        fill="white"
      />
      <path
        d="M22.7107 16.6816V18.6036L19.7763 20.2246L18 19.2581L22.7107 16.6816Z"
        fill="white"
      />
      <path
        d="M18.0285 12.9975C19.4961 12.9975 20.6859 11.8788 20.6859 10.4988C20.6859 9.11874 19.4961 8 18.0285 8C16.5609 8 15.3711 9.11874 15.3711 10.4988C15.3711 11.8788 16.5609 12.9975 18.0285 12.9975Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2527_41814">
        <rect width="18" height="20" fill="white" transform="translate(9 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const EmptyLogoForWallet = (
  props: Partial<CustomIconComponentProps>,
) => <Icon component={EmptyLogo} {...props} />;
