import React from "react";
import { Container } from "react-bootstrap";
import "./farming.scss";
import { table_icon } from "../../../Constants/ImagesConstants";
// import { Link } from 'react-router-dom';

const Farming = () => {
  const farmingdata = [
    {
      id: "1",
      table_icon: table_icon,
      title: "Total Staked",
      value: "$23,029",
      endedvalue: "24 Mar 2022 9:00 UTC",
      totalvalue: "$0.00",
      stakevalue: "0,00",
      strikevalue: "$0.00",
      poolvalue: "0%",
    },
    {
      id: "2",
      table_icon: table_icon,
      title: "Total Staked",
      value: "$23,029",
      endedvalue: "24 Mar 2022 9:00 UTC",
      totalvalue: "$0.00",
      stakevalue: "0,00",
      strikevalue: "$0.00",
      poolvalue: "0%",
    },
    {
      id: "3",
      table_icon: table_icon,
      title: "Total Staked",
      value: "$23,029",
      endedvalue: "24 Mar 2022 9:00 UTC",
      totalvalue: "$0.00",
      stakevalue: "0,00",
      strikevalue: "$0.00",
      poolvalue: "0%",
    },
    {
      id: "4",
      table_icon: table_icon,
      title: "Total Staked",
      value: "$23,029",
      endedvalue: "24 Mar 2022 9:00 UTC",
      totalvalue: "$0.00",
      stakevalue: "0,00",
      strikevalue: "$0.00",
      poolvalue: "0%",
    },
    {
      id: "5",
      table_icon: table_icon,
      title: "Total Staked",
      value: "$23,029",
      endedvalue: "24 Mar 2022 9:00 UTC",
      totalvalue: "$0.00",
      stakevalue: "0,00",
      strikevalue: "$0.00",
      poolvalue: "0%",
    },
  ];

  return (
    <div className="farming py-4">
      <Container>
        <div className="farming_main">
          <div className="farming_title">
            <h4>Farming</h4>
          </div>
          <div className="farming_table Commom_Table">
            <div className="farming_tableHeading">
              <ul>
                <li>Name</li>
                <li>Ended at</li>
                <li>Your stake</li>
                <li>Shiba reward</li>
                <li>LDO reward</li>
                <li>Farming APY</li>
              </ul>
            </div>
            <div className="farming_tableData">
              {farmingdata.map((item) => {
                return (
                  <ul>
                    <li>
                      <div className="Mobile_Table table_data">
                        <h6>Name</h6>
                        <div className="d-flex align-items-center">
                          <div className="table_imgs">
                            <img src={item.table_icon} alt="img" />
                            <img
                              src={item.table_icon}
                              alt="img"
                              className="right_img"
                            />
                          </div>
                          <div className="table_contant">
                            <h5>{item.title}</h5>
                            <p>{item.value}</p>
                          </div>
                          <div className="ended">
                            <span>Ended</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="Mobile_Table">
                        <h6>Ended at</h6>
                        {item.endedvalue}
                      </div>
                    </li>
                    <li>
                      <div className="Mobile_Table">
                        <h6>Your stake</h6>
                        {item.totalvalue}
                      </div>
                    </li>
                    <li>
                      <div className="Mobile_Table">
                        <h6>Shiba reward</h6>
                        {item.stakevalue}
                      </div>
                    </li>
                    <li>
                      <div className="Mobile_Table">
                        <h6>LDO reward</h6>
                        {item.strikevalue}
                      </div>
                    </li>
                    <li>
                      <div className="Mobile_Table">
                        <h6>Farming APY</h6>
                        {item.poolvalue}
                      </div>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Farming;
