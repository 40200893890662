import { createSlice } from "@reduxjs/toolkit";

export const tradeData: any = createSlice({
  name: "tradeData",
  initialState: {
    token1Name: "",
    token2Name: "",
    routeSteps: "",
    token1DollarValue: "",
    token2DollarValue: "",
    token1Price: "",
    token2Price: "",
    updateData: false,
    routePath: [],
    bestValue: "",
    classicRoutes: "",
    routeStepsV2: [],
  },
  reducers: {
    resetTradeDataSlice: (state: any, action) => {
      state.token1Name = "";
      state.token2Name = "";
      state.routeSteps = "";
      state.token1DollarValue = "";
      state.token2DollarValue = "";
      state.token1Price = "";
      state.token2Price = "";
      state.updateData = false;
      state.routePath = [];
      state.bestValue = "";
      state.classicRoutes = "";
      state.routeStepsV2 = [];
    },
    updateToken1Name: (state, action) => {
      state.token1Name = action.payload;
    },
    updateToken2Name: (state, action) => {
      state.token2Name = action.payload;
    },
    updateRouteSteps: (state, action) => {
      state.routeSteps = action.payload;
    },
    updateToken1DollarValue: (state, action) => {
      state.token1DollarValue = action.payload;
    },
    updateToken2DollarValue: (state, action) => {
      state.token2DollarValue = action.payload;
    },
    updateToken1Price: (state, action) => {
      state.token1Price = action.payload;
    },
    updateToken2Price: (state, action) => {
      state.token2Price = action.payload;
    },
    setUpdateTradeData: (state, action) => {
      state.updateData = action.payload;
    },
    updateRouterPath: (state, action) => {
      state.routePath = action.payload;
    },
    setBestValues: (state, action) => {
      state.bestValue = action.payload;
    },
    setRoutesClassic: (state, action) => {
      state.classicRoutes = action.payload;
    },
    updateRouteStepV2: (state, action) => {
      state.routeStepsV2 = action.payload;
    },
  },
});
export const {
  updateToken1Name,
  updateToken2Name,
  updateRouteSteps,
  updateToken1DollarValue,
  updateToken2DollarValue,
  updateToken1Price,
  updateToken2Price,
  setUpdateTradeData,
  updateRouterPath,
  setBestValues,
  setRoutesClassic,
  resetTradeDataSlice,
  updateRouteStepV2,
} = tradeData.actions;

export default tradeData.reducer;
