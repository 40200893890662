import { Card, Col } from "antd";
import { Chart, ChartOptions, registerables } from "chart.js";
import "chartjs-adapter-moment";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { ArrowUpIconGreen } from "../../../../Assets/icons/arrowUpGreen";
import { EthereumIcon } from "../../../../Assets/icons/Ethereum";
import { Text } from "../../../Text";
import "./graphicalView.scss";

interface SelectOption {
  option: string;
  key: number;
}

Chart.register(...registerables);

export const GraphicalView = () => {
  const [isSelected, setIsSelected] = useState<number>(2);
  function getGradient(ctx: any, chartArea: any) {
    let gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom,
    );

    gradient.addColorStop(0, "rgba(59, 135, 247, 0.50)");
    gradient.addColorStop(1, "rgba(59, 135, 247, 0.00)");

    return gradient;
  }

  const data = {
    labels: ["24 Oct", "25 Oct", "26 Oct", "27 Oct", "28 Oct", "29 Oct"],
    datasets: [
      {
        label: "Transaction volume",
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: "#3B87F7",
        borderWidth: 2,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        data: [200, 800, 150, 500, 100, 600],
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: "right",
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const selectOptions = [
    { option: "1H", key: 1 },
    { option: "1D", key: 2 },
    { option: "1W", key: 3 },
    { option: "1M", key: 4 },
    { option: "1Y", key: 5 },
  ];

  const onOptionClick = (item: SelectOption) => {
    console.log("item", item);
    setIsSelected(item?.key);
  };

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
      <Card className="token-graphical-view" style={{ overflowY: "auto" }}>
        <div className="token-info">
          <EthereumIcon />
          <Text
            className="heading"
            fontWeight={600}
            color="#FFF"
            fontSize={"18px"}
            style={{ textAlign: "left" }}
          >
            Ethereum
          </Text>
          <Text
            className="heading"
            fontWeight={400}
            color="#7C7C82"
            fontSize={"18px"}
            style={{ textAlign: "left" }}
          >
            ETH
          </Text>
          <div className="wallet-address">
            <Text fontWeight={500} color="#FFF" fontSize={"12px"}>
              0x31Ab...73bF
            </Text>
          </div>
        </div>
        <div className="textual-info">
          <Text fontWeight={600} color="#FFF" fontSize={"36px"}>
            $3,346.73
          </Text>
        </div>
        <div className="percentage-info">
          <ArrowUpIconGreen />
          <Text fontWeight={500} color="#39CA7F" fontSize={"14px"}>
            11.23%
          </Text>
        </div>
        <div className="line-chart-container">
          <Line data={data as any} options={options} />
        </div>
        <div className="select-options">
          {selectOptions.map((item) => (
            <div
              onClick={() => onOptionClick(item)}
              className={
                item?.key === isSelected ? "selected-option" : "option"
              }
            >
              <Text
                fontWeight={500}
                color={item?.key === isSelected ? "#FFF" : "#9B9CA3"}
                fontSize={"14px"}
              >
                {item?.option}
              </Text>
            </div>
          ))}
        </div>
      </Card>
    </Col>
  );
};
