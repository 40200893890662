import Accordion from "react-bootstrap/Accordion";
import "./TxCostMarketCard.scss";
// import { horsIcon1,horsIcon2,horsIcon3 } from '../../../Constants/ImagesConstants'
import market_bull_icon from "../../../Assets/Images/market_bull_icon.svg";
import aggresive_bull_icon from "../../../Assets/Images/aggresive_bull_icon.svg";
import custom_pen_icon from "../../../Assets/Images/custom_pen_icon.svg";
import info_icon_2 from "../../../Assets/Images/info_icon_2.svg";
import { Col, Row } from "react-bootstrap";
import TooltipCustom from "../TooltipCustom/TooltipCustom";
import { useDispatch, useSelector } from "react-redux";
import { SwapData } from "../../../Constants/Interfaces/SwapData";
import {
  setAverageGasPrice,
  setAverageGasWait,
  setBaseGasPrice,
  setFastGasPrice,
  setFastGasWait,
  setLowGasPrice,
  setLowGasWait,
  setUserSelectedGas,
  setUserSelectedGasType,
} from "../../../redux/reducers/swapData/swapData";
import { useEffect, useState } from "react";
import {
  currentEthereumGasPricesFunction,
  currentBNBGasPricesFunction,
} from "../../../redux/api/swap/swapAPIs";

const MARKET = "MARKET";
const AGGRESSIVE = "AGGRESSIVE";
const CUSTOM = "CUSTOM";

const TxCostMarketCard = ({ defaultSelectedGasType }: any) => {
  const dispatch = useDispatch();
  const swapData: SwapData = useSelector((state: any) => state.swapData);
  const chainID: any = useSelector((state: any) =>
    state.networkSlice.ChainID?.toString(),
  );
  const [customGasPrice, setCustomGasPrice] = useState<string>();
  const [showLowGasPriceWarning, setShowLowGasPriceWarning] =
    useState<boolean>(false);
  // console.log('swapData =>', swapData);

  useEffect(() => {
    const fetchInitialCustomValue = async () => {
      let result: any;
      if (chainID === "1" || chainID === "5") {
        result = await currentEthereumGasPricesFunction();
      } else if (chainID === "56" || chainID === "97") {
        result = currentBNBGasPricesFunction();
      }
      setCustomGasPrice(result?.base);
    };
    fetchInitialCustomValue();
  }, [chainID]);

  const costData = [
    {
      id: "0",
      eventkey: "0",
      icon: market_bull_icon,
      gasType: MARKET,
      market: (
        <>
          <span>Market</span> ~12 sec
        </>
      ),
      // price: `${swapData?.avgPrice ? swapData?.avgPrice : "loading.."} Gwei`,
      price: (
        <>
          {swapData?.avgPrice ? swapData?.avgPrice : "loading.."}
          <span>Gwei</span>
        </>
      ),
    },
    {
      id: "1",
      eventkey: "1",
      icon: aggresive_bull_icon,
      gasType: AGGRESSIVE,
      market: (
        <>
          <span>Aggressive</span> &lt; 10 sec
        </>
      ),

      // price: `${swapData?.fastPrice ? swapData?.fastPrice : "loading.."} Gwei`,
      price: (
        <>
          {swapData?.fastPrice ? swapData?.fastPrice : "loading.."}
          <span>Gwei</span>
        </>
      ),
    },
    {
      id: "2",
      eventkey: "2",
      icon: custom_pen_icon,
      gasType: CUSTOM,
      market: (
        <>
          <span>Custom</span> ~12 sec
        </>
      ),
      // price: `${
      //   Number(customGasPrice) > Number(swapData?.basePrice)
      //     ? customGasPrice
      //     : swapData?.basePrice
      // } Gwei`,

      price: (
        <>
          {Number(customGasPrice) > Number(swapData?.basePrice)
            ? customGasPrice
            : swapData?.basePrice}
          <span>Gwei</span>
        </>
      ),
    },
  ];

  const setGasPriceFunction = async (item: any) => {
    switch (item.eventkey) {
      case "0":
        dispatch(setUserSelectedGas(swapData.avgPrice));
        dispatch(setUserSelectedGasType(MARKET));
        break;
      case "1":
        dispatch(setUserSelectedGas(swapData.fastPrice));
        dispatch(setUserSelectedGasType(AGGRESSIVE));
        break;
      case "2":
        if (Number(customGasPrice) > swapData?.basePrice)
          dispatch(setUserSelectedGas(customGasPrice));
        else dispatch(setUserSelectedGas(swapData?.basePrice));
        dispatch(setUserSelectedGasType(CUSTOM));
        break;
      default:
        break;
    }
  };

  const setCustomGasPriceFunction = (value: any) => {
    const maxGasPrice = "9999";
    const re = /^[0-9]\d{0,9}(\.\d{0,2})?%?$/;
    if (value === "" || Number(value) < swapData?.basePrice) {
      setShowLowGasPriceWarning(true);
      setCustomGasPrice(value);
      dispatch(setUserSelectedGas(swapData?.basePrice));
    }
    if (re.test(value) === false) {
      return;
    } else {
      if (Number(value) > Number(maxGasPrice)) {
        value = maxGasPrice;
        setShowLowGasPriceWarning(false);
        setCustomGasPrice(value);
        dispatch(setUserSelectedGas(value));
      } else if (Number(value) < swapData?.basePrice) {
        setShowLowGasPriceWarning(true);
        setCustomGasPrice(value);
        dispatch(setUserSelectedGas(swapData?.basePrice));
      } else {
        setShowLowGasPriceWarning(false);
        setCustomGasPrice(value);
        dispatch(setUserSelectedGas(value));
      }
    }
  };

  const dispatchCurrentGasPriceFunction = async () => {
    let result: any;
    if (chainID === "1" || chainID === "5") {
      // for_more_chains : fetch gas prices for all chains
      result = await currentEthereumGasPricesFunction();
    } else if (chainID === "56" || chainID === "97") {
      result = currentBNBGasPricesFunction();
    }
    if (result) {
      dispatch(setBaseGasPrice(result.base));
      dispatch(setLowGasPrice(result.low));
      dispatch(setAverageGasPrice(result.medium));
      dispatch(setFastGasPrice(result.fast));
      dispatch(setLowGasWait(result.safeLowWait));
      dispatch(setAverageGasWait(result.avgWait));
      dispatch(setFastGasWait(result.fastWait));
      dispatch(setUserSelectedGas(result.medium));
    }
  };

  useEffect(() => {
    switch (defaultSelectedGasType) {
      case MARKET:
        setGasPriceFunction(costData[0]);
        break;
      case AGGRESSIVE:
        setGasPriceFunction(costData[1]);
        break;
      case CUSTOM:
        setGasPriceFunction(costData[2]);
        break;

      default:
        break;
    }
    dispatchCurrentGasPriceFunction();
  }, [chainID]);

  return (
    <>
      <div className="Txcost_Market_Card">
        <Accordion>
          {costData.map((item: any, idx) => {
            return (
              <Accordion.Item eventKey={item.eventkey} key={idx}>
                <Accordion.Header>
                  <div
                    aria-expanded={
                      item.gasType === swapData.userSelectedGasType
                        ? true
                        : false
                    }
                    onClick={() => setGasPriceFunction(item)}
                    className={`d-flex align-items-center w-100 ${
                      item.gasType === swapData.userSelectedGasType
                        ? `accordion-button`
                        : ""
                    }`}
                  >
                    <span className="Co_ratioBtn"></span>
                    <div className="Txcost_market_list d-flex justify-content-between align-items-center">
                      <div className="Txcost_market_L d-flex">
                        <img src={item.icon} alt="img" />
                        <p>{item.market}</p>
                      </div>
                      <p className="price">{item.price}</p>
                    </div>
                  </div>
                </Accordion.Header>
                {item.eventkey == "2" && (
                  <Accordion.Body>
                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="Txcost_Changes">
                          <p className="d-flex align-items-center">
                            Max base fee{" "}
                            <TooltipCustom
                              iconBlue
                              text="A base gas fee is automatically calculated 
                            by the network and varies from block to block. 
                            Set the max amount of the base fee you wish to pay."
                            />
                          </p>

                          <div className="inputGroup">
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setCustomGasPriceFunction(e.target.value)
                              }
                              value={customGasPrice}
                            />
                            <span>Gwei</span>
                          </div>
                          <h6>
                            <span
                              onClick={() =>
                                setCustomGasPriceFunction(
                                  swapData?.basePrice?.toString(),
                                )
                              }
                              className="blueText blueText_with_pointer"
                            >
                              Current
                            </span>{" "}
                            {swapData?.basePrice} Gwei
                          </h6>
                        </div>
                      </Col>
                      {/* <Col xs={12} sm={6}>
                      <div className='Txcost_Changes text-md-end'>
                        <p>Miner priority fee <TooltipCustom text="This fee goes directly to miners as an incentive to prioritize your transaction." /></p>
                        <div className='inputGroup'>
                          <input type="text" className="form-control" placeholder="1.61" />
                          <span>Gwei</span>
                        </div>
                        <h6><span className='blueText'>Current</span> 1.61 Gwei</h6>
                      </div>
                    </Col> */}
                      {showLowGasPriceWarning && (
                        <Col sm={12}>
                          <div className="Low_Gas_Warning">
                            <span>
                              <img src={info_icon_2} alt="info-icon" />
                            </span>
                            Max base fee can not be lower than current base fee
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            );
          })}

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className='d-flex align-items-center w-100'>
                <span className='Co_ratioBtn'></span>
                <div className='Txcost_market_list d-flex justify-content-between align-items-center'>
                  <div className='Txcost_market_L d-flex'>
                    <img src={horsIcon1} alt='img' />
                    <p>Market ~12 sec</p>
                  </div>
                  <p>22.09 - 24.27 Gwei</p>
                </div>
              </div>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className='d-flex align-items-center w-100'>
                <span className='Co_ratioBtn'></span>
                <div className='Txcost_market_list d-flex justify-content-between align-items-center'>
                  <div className='Txcost_market_L d-flex'>
                    <img src={horsIcon1} alt='img' />
                    <p>Market ~12 sec</p>
                  </div>
                  <p>22.09 - 24.27 Gwei</p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
    </>
  );
};

export default TxCostMarketCard;

// backup of costData with gas range (example: 10.12 - 14.3 Gwei)

// const costData = [
//   {
//     id: "0",
//     eventkey: "0",
//     horsIcon1: horsIcon1,
//     gasType: MARKET,
//     market: "Market ~12 sec",
//     price: `${swapData?.basePrice ? swapData?.basePrice : "loading.."} -
//      ${swapData?.avgPrice ? swapData?.avgPrice : "loading.."} Gwei`,
//   },
//   {
//     id: "1",
//     eventkey: "1",
//     horsIcon1: horsIcon2,
//     gasType: AGGRESSIVE,
//     market: "Aggressive < 10 sec",
//     price: `${swapData?.basePrice ? swapData?.basePrice : "loading.."} -
//     ${swapData?.fastPrice ? swapData?.fastPrice : "loading.."} Gwei`,
//   },
//   {
//     id: "2",
//     eventkey: "2",
//     horsIcon1: horsIcon3,
//     gasType: CUSTOM,
//     market: "Custom ~12 sec",
//     price: `${swapData?.basePrice ? swapData?.basePrice : "loading.."} -
//      ${Number(customGasPrice) > swapData?.basePrice  ? customGasPrice : swapData?.avgPrice} Gwei`,
//   },
// ];
