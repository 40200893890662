import { BigNumber, ethers } from "ethers";
import packageJson from "../../../package.json";
import { resetAddressSlice } from "../../redux/reducers/address/address";
import { resetContractCollectionSlice } from "../../redux/reducers/contractCollection/contractCollection";
import { resetCurrentChainTokenSlice } from "../../redux/reducers/currentChainToken/currentChainToken";
import { resetLoadingDataSlice } from "../../redux/reducers/loadingData/loadingData";
import { resetLoginSlice } from "../../redux/reducers/login/login";
import { resetNetworkSlice } from "../../redux/reducers/network/network";
import { resetSwapDataSlice } from "../../redux/reducers/swapData/swapData";
import { resetSwapDataCrossSlice } from "../../redux/reducers/swapDataCrossChain/swapDataCrossChain";
import { resetTokenCollectionSlice } from "../../redux/reducers/tokenCollection/tokenCollection";
import { resetTradeDataSlice } from "../../redux/reducers/tradeData/tradeData";
import { resetTradeDataCrossSlice } from "../../redux/reducers/tradeDataCrossChain/tradeDataCrossChain";
import { resetWalletConnectivitySlice } from "../../redux/reducers/walletConnectivity/walletConnectivity";
import store from "../../redux/store";

export const exponentialToDecimal = (exponential: any) => {
  if (exponential) {
    let decimal = exponential?.toString().toLowerCase();
    if (decimal.includes("e+")) {
      const exponentialSplitted = decimal.split("e+");
      let postfix = "";
      for (
        let i = 0;
        i <
        +exponentialSplitted[1] -
          (exponentialSplitted[0].includes(".")
            ? exponentialSplitted[0].split(".")[1].length
            : 0);
        i++
      ) {
        postfix += "0";
      }
      const addCommas = (text: any) => {
        let j = 3;
        let textLength = text.length;
        while (j < textLength) {
          text = `${text.slice(0, textLength - j)},${text.slice(
            textLength - j,
            textLength,
          )}`;
          textLength++;
          j += 3 + 1;
        }
        return text;
      };
      decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
    }
    if (decimal.toLowerCase().includes("e-")) {
      const exponentialSplitted = decimal.split("e-");
      let prefix = "0.";
      for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
        prefix += "0";
      }
      decimal = prefix + exponentialSplitted[0].replace(".", "");
    }
    return decimal;
  }
};

const toCustomFixed = (num: any, fixed: any) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num?.toString().match(re)[0];
};

export const decimalFormatWithoutRoundOffCrypto = (value: number) => {
  if (value === 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return value?.toFixed();
  }
};

export const balanceFormatWithoutRoundOffCrypto = (value: number) => {
  if (value === 0 || value < 0.00000005) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return value?.toFixed();
  }
};

export const balanceFormatWithoutRoundOffCryptoForMax = (value: number) => {
  if (value === 0 || value < 0.00000005) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 6);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 6);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 6);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 6);
  } else if (value > 9999 || value < -9999) {
    return value?.toFixed();
  }
};
export const decimalFormatWithRoundOffDollar = (value: any) => {
  let formatter = new Intl.NumberFormat("en-US", {
    // style: "currency",
    currency: "USD",
  });

  if (value == 0 || value == "" || value == undefined) {
    return "0.0";
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    const decimalPart = String(value).split(".")[1];

    if (decimalPart && decimalPart.length >= 2) {
      const firstTwoDigits = decimalPart.substring(0, 2);
      if (parseInt(firstTwoDigits) > 0) {
        return value.toFixed(2);
      }
    }

    return value;
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return value.toFixed(1);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return value.toFixed(0);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return value.toFixed(0);
  } else if (value > 9999 || value < -9999) {
    let data;
    data = Number(value.toFixed());
    data = formatter.format(data);
    return data;
  } else {
    // Check for numbers less than 1 (e.g., 0.0000058)
    if (Math.abs(value) < 1) {
      const formattedValue = value.toString();
      return formattedValue;
    }

    return value.toFixed(2);
  }
};

export const bigNumberToFixed = (x: any) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x?.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x?.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }

  return Math.trunc(Number(x));
};

export function uptoFourDecimal(value: any, decimals = 4) {
  const stringValue = value?.toString();
  const result: any = stringValue?.slice(
    0,
    stringValue?.indexOf(".") + decimals + 1,
  );
  return result;
}

const resetRedux = () => {
  store.dispatch(resetAddressSlice(""));
  store.dispatch(resetContractCollectionSlice(""));
  store.dispatch(resetCurrentChainTokenSlice(""));
  store.dispatch(resetLoadingDataSlice(""));
  store.dispatch(resetLoginSlice(""));
  store.dispatch(resetNetworkSlice(""));
  store.dispatch(resetSwapDataSlice(""));
  store.dispatch(resetTokenCollectionSlice(""));
  store.dispatch(resetTradeDataSlice(""));
  store.dispatch(resetWalletConnectivitySlice(""));
  store.dispatch(resetTradeDataCrossSlice(""));
  store.dispatch(resetSwapDataCrossSlice(""));
};

export const versionManager = async () => {
  try {
    const version = packageJson.version;
    const react_version = localStorage.getItem("react_version");
    if (react_version && version !== react_version) {
      resetRedux();
      localStorage.clear();
      window.location.reload();
    }
    if (!react_version) {
      resetRedux();
      localStorage.clear();
      window.location.reload();
    }
    localStorage.setItem("react_version", version);
  } catch (error) {
    console.log("Error in versionManager =>", error);
  }
};

export const truncateWithDots = (value: string) => {
  const firstfiveCharcters = value?.substring(0, 5);
  return firstfiveCharcters + "...";
};
export const amountTOBN = (value: number, mul: number = 18) => {
  const balanceWeiBN = ethers.utils.parseUnits(value.toString(), mul);
  return balanceWeiBN;
};
export const BNTOAmount = (value: any) => {
  const bigNumberValue = BigNumber.from(
    exponentialToDecimal(value).replaceAll(",", ""),
  );

  if (bigNumberValue) {
    return Number(ethers.utils.formatEther(bigNumberValue)).toFixed(4);
  }
  return 0;
};
