import { Chart, ChartOptions, registerables } from "chart.js";
import "chartjs-adapter-moment";
import { format } from "date-fns";
import { uniq } from "lodash";
import { Line } from "react-chartjs-2";

Chart.register(...registerables);

const LineChart = (props: any) => {
  const { weeklyStats } = props;

  const getData = () => {
    return (
      weeklyStats &&
      weeklyStats?.map(([timestamp, value]: any) => ({
        x: timestamp,
        y: value,
      }))
    );
  };

  const formatDate = (timestamp: number) => {
    return format(new Date(timestamp), "PP");
  };

  const getDate = (): string[] => {
    const dateArray: string[] = [];
    weeklyStats &&
      weeklyStats?.forEach(([timestamp, value]: [number, number]) => {
        const updatedDate = formatDate(timestamp);
        if (!dateArray.includes(updatedDate)) dateArray.push(updatedDate);
      });
    return dateArray;
  };

  // fill: linear-gradient(180deg, rgba(59, 135, 247, 0.50) 0%, rgba(59, 135, 247, 0.00) 100%);

  function getGradient(ctx: any, chartArea: any) {
    let gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom,
    );

    gradient.addColorStop(0, "rgba(59, 135, 247, 0.50)");
    gradient.addColorStop(1, "rgba(59, 135, 247, 0.00)");

    return gradient;
  }

  const data = {
    labels: uniq(getDate()),
    datasets: [
      {
        label: "Transaction volume",
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: "#3B87F7",
        borderWidth: 2,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        data: getData(),
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        // scaleShowVerticalLines: false,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM DD",
          },
        },
        min: weeklyStats && weeklyStats[0][0],
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="bar-chart-container">
      <Line data={data as any} options={options} />
    </div>
  );
};

export default LineChart;
