import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const ArrowUp = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.20132 5.16982L12.1213 9.49838C12.612 10.2259 12.1961 11.3334 11.432 11.3334L5.23445 11.3334C4.47045 11.3334 4.05445 10.2251 4.54519 9.49838L7.46519 5.16982C7.91792 4.49896 8.74859 4.49896 9.20132 5.16982Z"
      fill="#39CA7F"
    />
  </svg>
);

export const ArrowUpIconGreen = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowUp} {...props} />
);
