import { prepareWriteContract, readContract, writeContract } from "@wagmi/core";
import { UniversalProvider } from "@walletconnect/universal-provider";
import Web3 from "web3";
import { METAMASK } from "../../Constants/WalletTypes/WalletTypes";
import store from "../../redux/store";

let web3Object;

const projectId = "06cd73c01555f937a6963abee3431312";

export const getProvider = async () => {
  try {
    const provider: any = await UniversalProvider.init({
      logger: "info",
      // relayUrl: "ws://<relay-url>",
      projectId: projectId,
      metadata: {
        name: "React App",
        description: "React App for WalletConnect",
        url: "https://walletconnect.com/",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
      },
      client: undefined, // optional instance of @walletconnect/sign-client
    });

    return provider;
  } catch (error) {
    console.log("error in getProvider", error);
  }
};

export const callWeb3 = async () => {
  const { ethereum, web3, BinanceChain } = window;
  const { walletType } = store.getState().addressSlice;
  const CUSTOM_RPC_URL = store.getState().networkSlice.CUSTOM_RPC_URL;

  if (ethereum && ethereum.isMetaMask && walletType == "MetaMask") {
    // @ts-ignore
    web3Object = new Web3(ethereum);
  } else if (ethereum && walletType == METAMASK) {
    // @ts-ignore
    web3Object = new Web3(ethereum);
  } else if (BinanceChain && walletType == "Binance Chain") {
    web3Object = new Web3(BinanceChain);
    // } else if (provider) {
  } else if (walletType == "WalletConnect" || walletType == "TrustWallet") {
    const provider = await getProvider();
    if (!provider.connected) {
      provider.enable();
    }
    web3Object = new Web3(provider);
  } else if (web3) {
    let rpc: any = CUSTOM_RPC_URL;
    web3Object = new Web3(rpc);
    // web3Object = new Web3(web3.currentProvider);
  } else {
    // message.error(getMessageConfig({
    //   type: 'error',
    //   title: 'Something went wrong',
    //   body: 'You need to install MetaMask',
    // }));
    let rpc: any = CUSTOM_RPC_URL;
    web3Object = new Web3(rpc);
  }
  return web3Object;
};

interface ContractMethodParams {
  contractAddress: any;
  contractABI: any[];
  functionName: string;
  args?: any[];
  fromAddress: any;
  gas?: bigint;
  gasPrice?: bigint;
  value?: bigint;
}

export async function callContractMethod({
  contractAddress,
  contractABI,
  functionName,
  args = [],
  fromAddress,
  gas,
  gasPrice,
  value,
}: ContractMethodParams) {
  const data = {
    address: contractAddress,
    abi: contractABI,
    functionName,
    args,
    account: fromAddress,
    gas,
    gasPrice,
    value,
  };

  if (gas) {
    data["gas"] = gas;
  }

  if (gasPrice) {
    data["gasPrice"] = gasPrice;
  }

  if (value) {
    data["value"] = value;
  }

  const config = await prepareWriteContract(data);

  const response = await writeContract(config);
  return response;
}

interface ContractReadParams {
  address: any;
  abi: any[];
  functionName: string;
  args?: any[];
  fromAddress: any;
}

export async function callContractReadMethod({
  address,
  abi,
  functionName,
  args = [],
  fromAddress,
}: ContractReadParams) {
  const result = await readContract({
    address,
    abi,
    functionName,
    args,
    account: fromAddress,
  });
  return result;
}
