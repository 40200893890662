import { ADDRESS_WETH_ETH } from "../../../../Constants/AppVariables/appVariable";
import TOKEN_ABI from "../../../abis/TokenABIs/TOKEN.json";

// Ethereum Mainnet Current Token
export const EthMainnetCurrentToken = [
  {
    symbol: "WETH",
    address: ADDRESS_WETH_ETH,
    abi: TOKEN_ABI,
  },
];
