export const ErrorFallback = () => {
  function clearLocalStorage() {
    localStorage.clear();
    location.reload();
  }
  return (
    <div className="error_fallback h-100 d-flex justify-content-center align-items-center">
      <div className="error_fallback__inner text-center">
        <h2>Something went wrong</h2>
        <p>Please try reloading the page</p>
        <a onClick={clearLocalStorage} className="button-style d-inline-block">
          Reload
        </a>
      </div>
    </div>
  );
};

export default ErrorFallback;
