import {
  CUSTOM_RPC_URL_ARBITRUM,
  CUSTOM_RPC_URL_AVALANCHE,
  CUSTOM_RPC_URL_BSC,
  CUSTOM_RPC_URL_BSC_TESTNET,
  CUSTOM_RPC_URL_ETHEREUM,
  CUSTOM_RPC_URL_FANTOM,
  CUSTOM_RPC_URL_POLYGON,
  CUSTOM_RPC_URL_SEPOLIA,
} from "../AppVariables/appVariable";

import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  BNB_TEST_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
  SEPOLIA_NETWORK,
} from "../NetworkNames/NetworkNames";

// TESTNETS
export const SepoliaData = {
  currentNetwork: SEPOLIA_NETWORK,
  RPCURL: "https://rpc.sepolia.org",
  ChainID: 11155111,
  CHAINNAME: "Sepolia test network",
  BLOCKEXPLORER: "https://sepolia.etherscan.io/",
  SYMBOL: "SepoliaETH",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_SEPOLIA,
};
export const BnbTestNetData = {
  currentNetwork: BNB_TEST_NETWORK,
  RPCURL: "https://bsc-dataseed.binance.org",
  ChainID: 97,
  CHAINNAME: "Smart Chain",
  BLOCKEXPLORER: "https://bscscan.com",
  SYMBOL: "BNB",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_BSC_TESTNET,
};

// MAINNETS
export const EthereumMainnetData = {
  currentNetwork: EHTEREUM_NETWORK,
  RPCURL: "https://mainnet.infura.io/v3/",
  ChainID: 1,
  CHAINNAME: "Ethereum Mainnet",
  BLOCKEXPLORER: "https://etherscan.io/",
  SYMBOL: "ETH",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_ETHEREUM,
};
export const BnbMainnetData = {
  currentNetwork: BNB_NETWORK,
  RPCURL: "https://bsc-dataseed.binance.org",
  ChainID: 56,
  CHAINNAME: "Smart Chain",
  BLOCKEXPLORER: "https://bscscan.com",
  SYMBOL: "BNB",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_BSC,
};
export const PolygonMainnetData = {
  currentNetwork: POLYGON_NETWORK,
  RPCURL: "https://polygon-rpc.com/",
  ChainID: 137,
  CHAINNAME: "Polygon",
  BLOCKEXPLORER: "https://polygonscan.com/",
  SYMBOL: "MATIC",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_POLYGON,
};
export const AvalancheMainnetData = {
  currentNetwork: AVALANCHE_NETWORK,
  RPCURL: "https://api.avax.network/ext/bc/C/rpc",
  ChainID: 43114,
  CHAINNAME: "Avalanche Network C-Chain",
  BLOCKEXPLORER: "https://snowtrace.io/",
  SYMBOL: "AVAX",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_AVALANCHE,
};
export const ArbitrumMainnetData = {
  currentNetwork: ARBITRUM_NETWORK,
  RPCURL: "https://arb1.arbitrum.io/rpc",
  ChainID: 42161,
  CHAINNAME: "Arbitrum One",
  BLOCKEXPLORER: "https://explorer.arbitrum.io/",
  SYMBOL: "ETH",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_ARBITRUM,
};
export const FantomMainnetData = {
  currentNetwork: FANTOM_NETWORK,
  RPCURL: "https://rpc.ftm.tools/",
  ChainID: 250,
  CHAINNAME: "Fantom",
  BLOCKEXPLORER: "https://ftmscan.com/",
  SYMBOL: "FTM",
  CUSTOM_RPC_URL: CUSTOM_RPC_URL_FANTOM,
};
