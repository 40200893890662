import React from "react";
import { Row, Col } from "react-bootstrap";
import ProgressCustom from "../../../Common/ProgressCustom/ProgressCustom";
import {
  balance_icon,
  right_icon,
  clock_icon,
} from "../../../../Constants/ImagesConstants";

const BalanceCard = () => {
  const balancedata = [
    {
      id: "1",
      balance_icon: balance_icon,
      title: "Rebalancing",
      date: "7 Oct 13:30 - 7 Oct 17:29 UTC+5.5",
      right_icon: right_icon,
      satke_name: "stake",
      withdraw_name: "Withdraw",
    },
    {
      id: "2",
      balance_icon: balance_icon,
      title: "Active",
      date: "7 Oct 13:30 - 7 Oct 17:29 UTC+5.5",
      right_icon: clock_icon,
      satke_name: "stake",
      withdraw_name: "Withdraw",
    },
    {
      id: "3",
      balance_icon: balance_icon,
      title: "Pending",
      date: "7 Oct 13:30 - 7 Oct 17:29 UTC+5.5",
      right_icon: right_icon,
      satke_name: "stake",
      withdraw_name: "Withdraw",
    },
  ];

  return (
    <div className="balance_card">
      <Row>
        {balancedata.map((item) => {
          return (
            <Col xs={12} md={4} lg={12} xl={4} className="mb-4 mb-xl-0">
              <div className="balance_box">
                <div className="balance_img">
                  <img src={item.balance_icon} alt="img" />
                </div>
                <h4>{item.title}</h4>
                <p>{item.date}</p>
                <div className="balance_stake d-flex align-items-center">
                  <div className="stake_data d-flex align-items-center">
                    <img src={item.right_icon} alt="img" />
                    <p>{item.satke_name}</p>
                  </div>
                  <div className="stake_data d-flex align-items-center">
                    <img src={item.right_icon} alt="img" />
                    <p>{item.withdraw_name}</p>
                  </div>
                </div>
                <ProgressCustom
                  token_progressBar="balance_progress"
                  completed="100"
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default BalanceCard;
