import store from "../../redux/store";
import { decimalFormatWithoutRoundOffCrypto } from "../helpers/swapHelpers";
import { callWeb3 } from "../walletServices";
import { commonAbiInstancesForCallFunctions } from "./GlobalCall";

// const CommonAbi = async () => {
//   const contractCollection = store.getState().contractCollection.contractCollection;
//   const data: any = contractCollection.filter(
//     (a) => a.symbol == "REFERRAL"
//   );
//   const contract = await commonAbiInstances(data[0]?.address, JSON.parse(JSON.stringify(data[0]?.abi)));
//   return contract;
// };

export const isNativeToken = async (tokenAddress: string) => {
  if (tokenAddress) {
    try {
      const web3 = await callWeb3();

      const code = await web3.eth.getCode(tokenAddress);

      return code === "0x";
    } catch (error) {
      // console.error("Error while checking token:", error);
      return false;
    }
  }
  return false;
};

export const getTokenBalance = async (
  wallet_address: any,
  tokenAddress: any,
) => {
  try {
    const isNative = await isNativeToken(tokenAddress);

    if (isNative) {
      const web3 = await callWeb3();
      const balance = await web3.eth.getBalance(wallet_address);

      return balance;
    } else {
      const currentChainToken =
        store.getState().currentChainToken.currentChainToken;

      const data: any = currentChainToken;

      if (data && data.length > 0) {
        const contract = await commonAbiInstancesForCallFunctions(
          tokenAddress,
          JSON.parse(JSON.stringify(data[0].abi)),
        );

        let result = await contract.methods.balanceOf(wallet_address).call();

        return Number(result);
      }
    }
  } catch (error) {
    console.log("Error in getTokenBalance =>", error);
    return error;
  }
};

export const getTokenBalanceBigList = async (
  wallet_address: any,
  tokenAddress: any,
  tokenDecimals: any,
) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    // const data: any = currentChainToken?.filter((a) => a.symbol == "TBUSD");
    const data: any = currentChainToken;

    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    // if (contract) {
    // const decimals = await contract.methods.decimals().call();
    let result = await contract.methods.balanceOf(wallet_address).call();
    result = (Number(result) / 10 ** tokenDecimals)?.toFixed(5);
    // console.log("result on way =>" ,  result);
    return Number(result);
    // }
  } catch (error) {
    console.log("Error in getTokenBalanceBigList =>", error);
    return error;
  }
};

export const getTokenBalanceWithoutRoundOff = async (
  wallet_address: any,
  tokenAddress: any,
) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    // const data: any = currentChainToken?.filter((a) => a.symbol == "TBUSD");
    const data: any = currentChainToken;

    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    // if (contract) {
    const decimals = await contract.methods.decimals().call();
    let result = await contract.methods.balanceOf(wallet_address).call();

    result = decimalFormatWithoutRoundOffCrypto(
      Number(result) / 10 ** Number(decimals),
    );
    return Number(result);
    // }
  } catch (error) {
    console.log("Error in getTokenBalanceWithoutRoundOff =>", error);
    // return error;
  }
};

export const getRawBalanceWithoutRoundOff = async (
  wallet_address: any,
  tokenAddress: any,
) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    // const data: any = currentChainToken?.filter((a) => a.symbol == "TBUSD");
    const data: any = currentChainToken;

    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    // if (contract) {
    const decimals = await contract.methods.decimals().call();
    let result = await contract.methods.balanceOf(wallet_address).call();
    // result = decimalFormatWithoutRoundOffCrypto(Number(result) / 10 ** Number(decimals));
    return result;
    // }
  } catch (error) {
    console.log("Error in getRawBalanceWithoutRoundOff =>", error);
    // return error;
  }
};

export const getDecimals = async (tokenAddress: any) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;

    const data: any = currentChainToken;
    if (data && data.length > 0) {
      const contract = await commonAbiInstancesForCallFunctions(
        tokenAddress,
        JSON.parse(JSON.stringify(data[0].abi)),
      );

      if (contract) {
        const res = await contract?.methods?.decimals().call();
        return res;
      }
    }
  } catch (error) {
    console.log("Error in getDecimals =>", error);
    return error;
  }
};

export const getTokenName = async (tokenAddress: any) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    const data: any = currentChainToken;
    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    if (contract) {
      const res = await contract.methods.name().call();
      return await res;
    }
  } catch (error) {
    console.log("Error in getTokenName =>", error);
  }
};

export const getTokenSymbol = async (tokenAddress: any) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    const data: any = currentChainToken;
    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    if (contract) {
      const res = await contract.methods.symbol().call();
      return await res;
    }
  } catch (error) {
    console.log("Error in getTokenSymbol =>", error);
    return false;
  }
};

export const testRPC = async (tokenAddress: any) => {
  try {
    const currentChainToken =
      store.getState().currentChainToken.currentChainToken;
    const data: any = currentChainToken;
    const contract = await commonAbiInstancesForCallFunctions(
      tokenAddress,
      JSON.parse(JSON.stringify(data[0].abi)),
    );
    if (contract) {
      const res = await contract.methods.symbol().call();
      return await res;
    }
  } catch (error) {
    console.log("Error in getTokenSymbol =>", error);
    return false;
  }
};

export const getTokenBalanceFull = async (
  wallet_address: any,
  tokenAddress: any,
  web3?: any,
) => {
  try {
    const abi = [
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];
    if (!web3) {
      web3 = await callWeb3();
    }

    if (
      tokenAddress !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" &&
      tokenAddress !== "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
    ) {
      const tokenContract = new web3.eth.Contract(
        // @ts-ignore
        abi,
        tokenAddress,
      );

      // Get the balance of the user's address
      const decimals = await tokenContract.methods.decimals().call();

      const balance = await tokenContract.methods
        .balanceOf(wallet_address)
        .call();

      const result = balance / 10 ** decimals;
      return result;
    } else {
      console.log(wallet_address);
      const balance = await web3.eth.getBalance(wallet_address);
      const ethBalance = web3.utils.fromWei(balance, "ether");

      return ethBalance;
    }
  } catch (error) {
    console.log("Error in getTokenBalanceFull =>", error);
    return 0;
  }
};
