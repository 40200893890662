import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import P2PTradeCards from "../../Common/P2PTradeCards/P2PTradeCards";
import SimpleSwapCard from "../../Common/SimpleSwapCard/SimpleSwapCard";
import TradeCard from "../../Common/TradeCards/TradeCard";
import "./Trade.scss";

const P2PDeal = () => {
  return (
    <>
      <div className="py-4 p2p_deal_page">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} lg={6}>
              <TradeCard />
              <SimpleSwapCard />
            </Col>
            <Col xs={12} lg={6} className="d-flex mt-4 mt-lg-0">
              <P2PTradeCards />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default P2PDeal;
