import { useEffect } from "react";
import { useSelector } from "react-redux";
import { addUserTransaction } from "../../../redux/api/swap/swapAPIs";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import "./ReviewOrderButton.scss";

const ReviewOrderButton = ({
  className,
  tokenSelect1,
  tokenSelect2,
  swapType,
  input1Amount,
  input2Amount,
  userTokenOneBalance,
  fetchTokenOneBalance,
  showReviewModal,
  minDollarWorthSatisfied,
}: any) => {
  const wrongNetworSelected = useSelector(
    (state: any) => state.loginSlice.wrongNetworkSelected,
  );

  const input2Value = useSelector((state: any) => state.swapData.input2Value);

  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );

  const limitPriceLoading = useSelector(
    (state: any) => state.loadingData.limitPriceLoading,
  );
  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);

  const reviewOrder = async () => {
    showReviewModal();
  };

  const savePendingTxnsToDB = async () => {
    let pendingTxns: any = JSON.parse(
      localStorage.getItem("AllHistory") || "[]",
    );
    if (pendingTxns?.length > 0) {
      for (let i = 0; i <= pendingTxns.length; i++) {
        const addUserTransactionResult = await addUserTransaction(
          pendingTxns[i],
        );
        if (addUserTransactionResult?.status === 200) {
          localStorage.removeItem(pendingTxns[i]);
        }
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    savePendingTxnsToDB();
  }, []);

  return (
    <>
      <ButtonCustom
        fullWidth
        size={"large"}
        // title="Review order"
        title={
          isLoggedIn && wrongNetworSelected
            ? "Switch Network"
            : isLoggedIn && !wrongNetworSelected
            ? "Review Order"
            : ""
        }
        disabled={
          false
          // limitPriceLoading ||
          // !input1Amount ||
          // !input2Amount ||
          // !minDollarWorthSatisfied
          // wrongNetworSelected
        }
        className={
          limitPriceLoading || !input1Amount || !input2Amount
            ? `common-connect-btn GrayBtn ${className}`
            : `common-connect-btn ${className}`
        }
        onClick={() => reviewOrder()}
      />
    </>
  );
};

export default ReviewOrderButton;
