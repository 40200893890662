import { Card, Col, Layout, Menu, MenuProps, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { gsap } from "gsap";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { Text } from ".";
import logo from "../Assets/Images/logo.png";
import { colors } from "../Assets/Theme/colors";
import { SidebarTL } from "../Assets/animation/Sidebar";
import { BridgeIcon } from "../Assets/icons/Bridge";
import { EarnIcon } from "../Assets/icons/Earn";
import { ExploreIcon } from "../Assets/icons/Explore";
import { HomeIcon } from "../Assets/icons/Home";
import { MoreIcon } from "../Assets/icons/More";
import { MyProfileIcon } from "../Assets/icons/MyProfile";
import { PerpetualIcon } from "../Assets/icons/Perpetual";
import { ShidoIcon } from "../Assets/icons/Shido";
import { TradeIcon } from "../Assets/icons/Trade";
import { ProfileIcon } from "../Assets/icons/profile";
import {
  Aurora_Bridge,
  Avalanche_Bridge,
  Fantom_Bridge,
  Klaytn_Bridge,
  arbitrum,
  bnb_drop,
  gnosis,
  optimism,
  polygon_icon,
} from "../Constants/ImagesConstants";
import { useShidoPrice } from "../hooks";
import { toggleMenu } from "../redux/reducers";
import { PrimaryButton } from "./Button";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

type ItemType = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  disabled?: boolean;
};

type ToggleItem = {
  key: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
};

function getItem({
  label,
  key,
  icon,
  disabled,
  children,
}: ItemType): MenuItemType {
  return {
    key,
    icon,
    children,
    label,
    disabled,
  } as MenuItemType;
}

const Sidebar = ({ mobile }: { mobile?: boolean }) => {
  const { md } = useBreakpoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { address, isConnected, connector } = useAccount();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string[]>([]);
  const { shidoPrice } = useShidoPrice();

  const tradeItems: ToggleItem[] = [
    { key: "simple-mode", label: "Simple Mode" },
    // { key: "classic-mode", label: "Classic mode" },
    { key: "limit-order", label: "Limit Orders" },
    { key: "cross-chain-swap", label: "Cross Chain" },
  ];

  const earnItems: ToggleItem[] = [
    { key: "staking", label: "Staking", disabled: false },
    { key: "strategies", label: "Strategies", disabled: true },
    { key: "pools", label: "Pools", disabled: true },
    { key: "farming", label: "Farming", disabled: true },
  ];

  const bridgeItems: ToggleItem[] = [
    {
      key: "bnb-chain",
      label: "BNB Chain Bridge",
      icon: <img src={bnb_drop} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "polygon",
      label: "Polygon Bridge",
      icon: <img src={polygon_icon} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "optimism",
      label: "Optimism Bridge",
      icon: <img src={optimism} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "arbitrum",
      label: "Arbitrum Bridge",
      icon: <img src={arbitrum} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "gnosis",
      label: "Gnosis Chain Bridge",
      icon: <img src={gnosis} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "avalanche",
      label: "Avalanche Bridge",
      icon: (
        <img src={Avalanche_Bridge} style={{ width: 36, aspectRatio: 1 }} />
      ),
    },
    {
      key: "fantom",
      label: "Fantom Bridge",
      icon: <img src={Fantom_Bridge} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "klaytn",
      label: "Klaytn Bridge",
      icon: <img src={Klaytn_Bridge} style={{ width: 36, aspectRatio: 1 }} />,
    },
    {
      key: "aurora",
      label: "Aurora Bridge",
      icon: <img src={Aurora_Bridge} style={{ width: 36, aspectRatio: 1 }} />,
    },
  ];

  const moreItems: ToggleItem[] = [
    { key: "blog", label: "Blog" },
    { key: "about", label: "About" },
    { key: "help", label: "Help" },
    { key: "report", label: "Report a Bug" },
    { key: "suggest", label: "Suggest a Feature" },
  ];

  const items: MenuItem[] = [
    getItem({
      label: "Home",
      key: "home",
      icon: <HomeIcon />,
    }),
    getItem({
      label: "Trade",
      key: "trade",
      icon: <TradeIcon />,
      children: tradeItems.map(({ label, key, disabled }) =>
        getItem({ label, key, disabled }),
      ),
    }),
    getItem({
      label: "Perpetuals",
      key: "perpetuals",
      icon: <PerpetualIcon />,
    }),
    getItem({
      label: "Explore",
      key: "explore",
      icon: <ExploreIcon />,
    }),
    getItem({
      label: "Earn",
      key: "earn",
      icon: <EarnIcon />,
      children: earnItems.map(({ label, key, disabled }) =>
        getItem({ label, key, disabled }),
      ),
    }),
    getItem({
      label: "Bridge",
      key: "bridge",
      icon: <BridgeIcon />,
      children: bridgeItems.map(getItem),
    }),
    getItem({
      label: "More",
      key: "more",
      icon: <MoreIcon />,
      children: moreItems.map(({ label, key, disabled }) =>
        getItem({ label, key, disabled }),
      ),
    }),
    getItem({
      label: "My Profile",
      key: "profile",
      icon: <ProfileIcon />,
    }),
  ];

  const handleMenuClick = (item: { key: string; keyPath: string[] }) => {
    if (selectedKey.includes(item.keyPath[0])) {
      setSelectedKey((prevState) =>
        prevState.filter((key) => key !== item.keyPath[0]),
      );
    } else {
      setSelectedKey((prevState) => [...prevState, item.keyPath[0]]);
    }

    switch (item.key) {
      case "perpetuals":
        window.open("https://perp.shido.io/", "_blank");
        break;
      case "blog":
        window.open("https://www.shido.io/news", "_blank");
        break;
      case "about":
        window.open("https://www.shido.io/dex", "_blank");
        break;
      case "help":
        window.open("https://t.me/ShidoGlobal", "_blank");
        break;
      case "report":
        window.open("https://t.me/+JTRrG1s7E4ZlZDhk", "_blank");
        break;
      case "suggest":
        window.open("https://t.me/+JTRrG1s7E4ZlZDhk", "_blank");
        break;
      case "bnb-chain":
        window.open("https://cbridge.celer.network/", "_blank");
        break;
      case "polygon":
        window.open("https://wallet.matic.network/bridge/", "_blank");
        break;
      case "optimism":
        window.open("https://app.optimism.io/bridge", "_blank");
        break;
      case "arbitrum":
        window.open("https://bridge.arbitrum.io/", "_blank");
        break;
      case "gnosis":
        window.open("https://omni.gnosischain.com/bridge", "_blank");
        break;
      case "avalanche":
        window.open("https://bridge.avax.network/", "_blank");
        break;
      case "fantom":
        window.open("https://app.multichain.org/#/router/", "_blank");
        break;
      case "klaytn":
        window.open("https://scope.klaytn.com/bridge", "_blank");
        break;
      case "aurora":
        window.open("https://rainbowbridge.app/transfer", "_blank");
        break;
      default:
        navigate(`/${item.key}`);
        mobile && dispatch(toggleMenu());
        break;
    }
  };

  const renderMenu = () => {
    let modifiedItems;
    if (!mobile) {
      modifiedItems = items.slice(0, -1).map((item: any) => {
        // Check if the item has children
        const hasChildren = item && item.children && item.children.length > 0;

        // Add a custom className based on the presence of children
        const itemClassName = hasChildren
          ? "item-with-children"
          : "item-without-children";

        // Add custom styles based on the presence of children
        const itemStyle = hasChildren ? {} : { fontWeight: 600 }; // Add your desired styles

        // Return the modified item
        return {
          ...item,
          className: itemClassName,
          style: itemStyle,
        };
      });
    } else {
      modifiedItems = items.map((item: any) => {
        // Check if the item has children
        const hasChildren = item && item.children && item.children.length > 0;

        // Add a custom className based on the presence of children
        const itemClassName = hasChildren
          ? "item-with-children"
          : "item-without-children";

        // Add custom styles based on the presence of children
        const itemStyle = hasChildren ? {} : { fontWeight: 600 }; // Add your desired styles

        // Return the modified item
        return {
          ...item,
          className: itemClassName,
          style: itemStyle,
        };
      });
    }

    return (
      <Menu
        className={"shido-sider-menu scroll-container side-menu-wrap"}
        theme="dark"
        defaultSelectedKeys={[]}
        mode="inline"
        items={modifiedItems}
        style={{ maxHeight: "70vh", overflowY: "auto" }}
        onClick={handleMenuClick}
        // expandIcon={true ? <HamburgerIcon /> : <ExpandableIcon />}
      />
    );
  };

  const SiderBodyRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      SidebarTL(SiderBodyRef.current);
    }, SiderBodyRef);

    return () => ctx.revert();
  }, [SiderBodyRef]);

  return mobile ? (
    <Card>{renderMenu()}</Card>
  ) : (
    <Sider
      collapsed={collapsed}
      collapsible={false}
      onCollapse={(value) => setCollapsed(value)}
      width={340}
      style={{
        display: !md ? "none" : "unset",
        padding: 20,
        backgroundColor: colors.secondary800,
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 99,
        overflow: "auto",
        height: "100vh",
        alignItems: "center",
      }}
      trigger={null}
    >
      <div className="sider-body" ref={SiderBodyRef}>
        <div>
          <div className="logo clickable" onClick={() => navigate("/")}>
            <img src={logo} alt={"shido"} />
          </div>
          {renderMenu()}
        </div>

        <div>
          {isConnected && (
            <PrimaryButton
              style={{
                width: "100%",
                marginBottom: "10px",
                textAlign: "left",
                display: "flex",
              }}
              icon={<MyProfileIcon />}
              onClick={() => navigate("/profile")}
            >
              My Profile
            </PrimaryButton>
          )}

          <Card
            className={"card-md-padding shido-card"}
            style={{
              backgroundColor: colors.secondary600,
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <Row align={"middle"} gutter={10} style={{ width: "max-content" }}>
              <Col className="logo-icon">
                <ShidoIcon />
              </Col>
              <Col className="logo-name" style={{ textAlign: "left" }}>
                <Row>
                  <Col span={24}>
                    <Text fontSize={12} fontWeight={500}>
                      SHIDO
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Row gutter={10}>
                      <Col>
                        <Text fontSize={12}>
                          $
                          {Number(
                            shidoPrice?.coinInfo?.quote?.USD?.price,
                          ).toFixed(10)}
                        </Text>
                      </Col>
                      {shidoPrice?.coinInfo?.quote?.USD?.percent_change_24h && (
                        <Col>
                          <Text
                            fontSize={12}
                            color={
                              shidoPrice?.coinInfo?.quote?.USD
                                ?.percent_change_24h > 0
                                ? colors.success
                                : colors.error
                            }
                          >
                            {Number(
                              shidoPrice?.coinInfo?.quote?.USD
                                ?.percent_change_24h,
                            ).toFixed(2)}
                            %
                          </Text>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="btn-wrap">
              <PrimaryButton
                onClick={() =>
                  window.open(
                    "https://app.uniswap.org/tokens/ethereum/0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b",
                    "_blank",
                  )
                }
                style={{ width: "100%", marginTop: "1em" }}
              >
                Buy SHIDO
              </PrimaryButton>
            </div>
          </Card>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
