import { Col, Row } from "antd";
import { Text } from "../../../Text";
import "./swapper.scss";

interface SwapperProps {
  isHeader?: boolean;
  icon: string;
  currency: string;
  text: string;
}

export const Swapper = (props: SwapperProps) => {
  const { isHeader, icon, currency, text } = props;
  return (
    <Row
      className={isHeader ? "header-swapper" : "statistic-swapper"}
      justify={"center"}
    >
      <Col>
        <img src={icon} />
      </Col>
      <Col className="swapper-text">
        <Text fontSize={16} fontWeight={400} style={{ lineHeight: "normal" }}>
          <span className="swap-from" style={{ color: "#7C7C82" }}>
            {text}
          </span>
        </Text>
        <Col>
          <Text fontSize={16} fontWeight={400} style={{ lineHeight: "normal" }}>
            <span
              className="currency"
              style={{ fontSize: "24px", fontWeight: "600" }}
            >
              {currency}
            </span>
          </Text>
        </Col>
      </Col>
    </Row>
  );
};
