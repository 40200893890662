import { Card, Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import "./SimpleSwapCard.scss";
// import { editIcon } from "../../../Constants/ImagesConstants";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../Assets/Theme/colors";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../Constants/NetworkNames/NetworkNames";
import { priceImpactOpenOcean } from "../../../redux/api/openOcean/tokenAPIs";
import { setPriceImpact } from "../../../redux/reducers/swapData/swapData";
import store from "../../../redux/store";
import {
  decimalFormatWithRoundOffDollar,
  decimalFormatWithoutRoundOffCrypto,
} from "../../../services/helpers/swapHelpers";
import { callWeb3 } from "../../../services/walletServices";
import { Text } from "../../Text";
import GasRefundModal from "../CommonModals/GasRefundModal/GasRefundModal";
import CustomShimmer from "../CustomShimmer/CustomShimmer";
import TxCostMarketCard from "../TxCostMarketCard/TxCostMarketCard";

import {
  OPEN_OCEAN_CHAIN_ARBITRUM,
  OPEN_OCEAN_CHAIN_AVALANCHE,
  OPEN_OCEAN_CHAIN_BSC,
  OPEN_OCEAN_CHAIN_ETH,
  OPEN_OCEAN_CHAIN_FANTOM,
  OPEN_OCEAN_CHAIN_POLYGON,
} from "../../../Constants/TYPES/openOceanChainNames";

const BNB = "BNB";
const ETHEREUM = "ETHEREUM";

const SimpleSwapCard = () => {
  const { md, lg } = useBreakpoint();
  const dispatch = useDispatch();
  const userSelectedGasType = useSelector(
    (state: any) => state.swapData.userSelectedGasType,
  );

  const slippageTolerance: any = useSelector(
    (state: any) => state.swapData.slippageTolerance,
  );
  const input2Value = useSelector((state: any) => state.swapData.input2Value);

  const input1Value = useSelector((state: any) => state.swapData.input1Value);

  const token1 = useSelector((state: any) => state.swapData.token1);
  const token2 = useSelector((state: any) => state.swapData.token2);

  const CHAIN_ID: any = useSelector((state: any) => state.networkSlice.ChainID);

  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );
  const routePathLoading = useSelector(
    (state: any) => state.loadingData.routePathLoading,
  );
  const marketPriceLoading = useSelector(
    (state: any) => state.loadingData.marketPriceLoading,
  );

  const [network, setNetwork] = useState("");

  const tradeData: any = useSelector((state: any) => state.tradeData);

  const swapData: any = useSelector((state: any) => state.swapData);

  const userSelectedGasPrice: any = useSelector(
    (state: any) => state.swapData.userSelectedGas,
  );

  const [marketPrice, setMarketPrice] = useState<any>();

  // const calculateMarketPrice = async () => {
  //   const chainId = store.getState().networkSlice.ChainID.toString();
  //   const currentChainToken =
  //     store.getState().currentChainToken.currentChainToken;

  //   const userSelectedGasPrice = store.getState().swapData.userSelectedGas;
  //   // tokenAddress will be the address of Eth or BNB, depending on the network selected
  //   const tokenAddress: any = currentChainToken[0].address;
  //   const data = {
  //     tokenAddress: tokenAddress,
  //     chainId: chainId,
  //     // havePool: false,
  //   };
  //   dispatch(setMarketPriceLoading(true));
  //   const dollarWorth = await dollarWorthFunction(data);
  //   //we are assuming 200000 as the average gas fee
  //   const result = (userSelectedGasPrice / 10 ** 9) * dollarWorth * 200000;
  //   if (dollarWorth) {
  //     setMarketPrice(result);
  //     dispatch(setCalculatedGasPriceDollarsRedux(result));
  //     dispatch(setMarketPriceLoading(false));
  //   } else {
  //     setMarketPrice(0);
  //     dispatch(setCalculatedGasPriceDollarsRedux(0));
  //     dispatch(setMarketPriceLoading(false));
  //   }
  // };

  const [isActive, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [showRoutesModal, setShowRoutesModal] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const handleClose = () => setShow(false);

  const handleRoutesModal = () => setShowRoutesModal(!showRoutesModal);

  // useEffect(() => {
  //   calculateMarketPrice();
  // }, [userSelectedGasPrice]);

  useEffect(() => {
    if (Number(CHAIN_ID) === 56 || Number(CHAIN_ID) === 97) {
      setNetwork(BNB);
    } else if (Number(CHAIN_ID) === 1 || Number(CHAIN_ID) === 5) {
      setNetwork(ETHEREUM);
    }
  }, [CHAIN_ID]);

  const getTextSize = () => (lg ? 16 : 14);

  const getPriceImpact = async () => {
    const web3 = await callWeb3();

    const gasPriceResult = await web3.eth.getGasPrice();

    const estimatedGasPrice = web3.utils.fromWei(gasPriceResult, "Gwei");

    const data = {
      inTokenAddress: token1?.address,
      outTokenAddress: token2?.address,
      amount: input1Value,
      gasPrice: estimatedGasPrice,
      slippage: slippageTolerance,
    };

    const currentNetwork = store.getState().networkSlice.currentNetwork;

    let chain_to_use: any = OPEN_OCEAN_CHAIN_ETH;

    switch (currentNetwork) {
      case BNB_NETWORK:
        chain_to_use = OPEN_OCEAN_CHAIN_BSC;
        break;

      case POLYGON_NETWORK:
        chain_to_use = OPEN_OCEAN_CHAIN_POLYGON;

        break;

      case AVALANCHE_NETWORK:
        chain_to_use = OPEN_OCEAN_CHAIN_AVALANCHE;

        break;

      case ARBITRUM_NETWORK:
        chain_to_use = OPEN_OCEAN_CHAIN_ARBITRUM;

        break;

      case FANTOM_NETWORK:
        chain_to_use = OPEN_OCEAN_CHAIN_FANTOM;

        break;

      default:
        break;
    }

    priceImpactOpenOcean(chain_to_use, data).then((data: any) => {
      if (data?.price_impact) {
        dispatch(setPriceImpact(data.price_impact));
      } else {
        dispatch(setPriceImpact(null));
      }
    });
  };

  useEffect(() => {
    getPriceImpact();
  }, [token1, token2, slippageTolerance, input1Value, dispatch]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card>
            <Row gutter={[0, 6]}>
              <Col span={24}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <Text
                      fontSize={getTextSize()}
                      fontWeight={500}
                      color={colors.secondary500}
                    >
                      Minimum received
                    </Text>
                  </Col>
                  <Col>
                    {tradeDataLoading || Number(input1Value) === 0 ? (
                      <CustomShimmer width={100} height={15} />
                    ) : (
                      <Text fontSize={getTextSize()} fontWeight={500}>
                        {decimalFormatWithoutRoundOffCrypto(
                          Number(input2Value) -
                            (Number(input2Value) * Number(slippageTolerance)) /
                              100,
                        )}
                      </Text>
                    )}
                  </Col>
                </Row>
              </Col>
              {swapData.priceImpact && (
                <Col span={24}>
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <Text
                        fontSize={getTextSize()}
                        fontWeight={500}
                        color={colors.secondary500}
                      >
                        Price impact
                      </Text>
                    </Col>
                    <Col className={"tooltip_container"}>
                      {tradeDataLoading || Number(input1Value) === 0 ? (
                        <CustomShimmer width={70} height={15} />
                      ) : (
                        <Tooltip
                          title={
                            isNaN(swapData?.priceImpact)
                              ? undefined
                              : swapData?.priceImpact
                          }
                        >
                          <Text fontSize={getTextSize()} fontWeight={500}>
                            {swapData?.priceImpact}
                          </Text>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <Text
                      fontSize={getTextSize()}
                      fontWeight={500}
                      color={colors.secondary500}
                    >
                      {tradeData.token2Name} buy price
                    </Text>
                  </Col>
                  <Col>
                    {tradeDataLoading || Number(input1Value) === 0 ? (
                      <CustomShimmer width={170} height={15} />
                    ) : (
                      <Row align={"middle"} gutter={4}>
                        <Col>
                          <Tooltip title={swapData.buyPrice}>
                            <Text fontSize={getTextSize()} fontWeight={500}>
                              {swapData.buyPrice < 0.01
                                ? `${swapData.buyPrice
                                    ?.toString()
                                    .slice(0, 3)}..${swapData.buyPrice
                                    ?.toString()
                                    .slice(
                                      swapData.buyPrice - 4,
                                      swapData.buyPrice.length,
                                    )}`
                                : decimalFormatWithoutRoundOffCrypto(
                                    Number(swapData.buyPrice),
                                  )}
                            </Text>
                          </Tooltip>
                        </Col>
                        <Col>
                          <Text fontSize={getTextSize()} fontWeight={500}>
                            {tradeData.token1Name}
                          </Text>
                        </Col>
                        {!!Number(input1Value) &&
                          !!swapData.buyPriceInDollars && (
                            <Col>
                              <Tooltip title={swapData.buyPriceInDollars}>
                                <Text fontSize={getTextSize()} fontWeight={500}>
                                  {`~$ ${
                                    swapData.buyPriceInDollars < 0.01
                                      ? `${swapData.buyPriceInDollars
                                          ?.toString()
                                          .slice(
                                            0,
                                            3,
                                          )}..${swapData.buyPriceInDollars
                                          ?.toString()
                                          .slice(
                                            swapData.buyPriceInDollars - 4,
                                            swapData.buyPriceInDollars.length,
                                          )}`
                                      : decimalFormatWithRoundOffDollar(
                                          Number(swapData.buyPriceInDollars),
                                        )
                                  }`}
                                </Text>
                              </Tooltip>
                            </Col>
                          )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align={md ? "middle" : "top"} justify={"space-between"}>
                  <Col xs={8} md={6} style={{ textAlign: "left" }}>
                    <Text
                      fontSize={getTextSize()}
                      fontWeight={500}
                      color={colors.secondary500}
                    >
                      {tradeData.token1Name} sell price
                    </Text>
                  </Col>
                  <Col xs={16} md={18} style={{ placeContent: "end" }}>
                    {tradeDataLoading || Number(input1Value) === 0 ? (
                      <Row align={"middle"} gutter={4} justify={"end"}>
                        <Col>
                          <CustomShimmer width={190} height={15} />
                        </Col>
                      </Row>
                    ) : (
                      <Row align={"middle"} gutter={4} justify={"end"}>
                        <Col>
                          <Tooltip title={swapData.sellPrice}>
                            <Text fontSize={getTextSize()} fontWeight={500}>
                              {swapData.sellPrice < 0.01
                                ? `${swapData.sellPrice
                                    ?.toString()
                                    .slice(0, 3)}..${swapData.sellPrice
                                    ?.toString()
                                    .slice(
                                      swapData.sellPrice - 4,
                                      swapData.sellPrice.length,
                                    )}`
                                : decimalFormatWithoutRoundOffCrypto(
                                    Number(swapData.sellPrice),
                                  )}
                            </Text>
                          </Tooltip>
                        </Col>
                        <Col>
                          <Text fontSize={getTextSize()} fontWeight={500}>
                            {tradeData.token2Name}
                          </Text>
                        </Col>
                        {!!Number(input1Value) &&
                          !!swapData.buyPriceInDollars && (
                            <Col>
                              <Tooltip title={swapData.sellPriceInDollars}>
                                <Text fontSize={getTextSize()} fontWeight={500}>
                                  {`~$ ${
                                    swapData.sellPriceInDollars < 0.01
                                      ? `${swapData.sellPriceInDollars
                                          ?.toString()
                                          .slice(
                                            0,
                                            3,
                                          )}..${swapData.sellPriceInDollars
                                          ?.toString()
                                          .slice(
                                            swapData.sellPriceInDollars - 4,
                                            swapData.sellPriceInDollars.length,
                                          )}`
                                      : decimalFormatWithRoundOffDollar(
                                          Number(swapData.sellPriceInDollars),
                                        )
                                  }`}
                                </Text>
                              </Tooltip>
                            </Col>
                          )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <Text
                      fontSize={getTextSize()}
                      fontWeight={500}
                      color={colors.secondary500}
                    >
                      Route
                    </Text>
                  </Col>
                  <Col>
                    {routePathLoading || Number(input1Value) === 0 ? (
                      <CustomShimmer width={180} height={15} />
                    ) : (
                      <Text
                        fontSize={getTextSize()}
                        fontWeight={500}
                        className={"clickable"}
                        onClick={handleRoutesModal}
                      >
                        {tradeData.routeSteps} Step(s) In route
                      </Text>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div style={{ display: isActive ? "block" : "none" }}>
        <TxCostMarketCard defaultSelectedGasType={userSelectedGasPrice} />
      </div>
      <GasRefundModal show={show} handleClose={handleClose} />
      {/*<RouteInfoModal show={showRoutesModal} handleClose={handleRoutesModal} />*/}
    </>
  );
};

export default SimpleSwapCard;
