import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const ChevronDown = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.19037 6.56488C4.49953 6.25572 5.00079 6.25572 5.30996 6.56488L9.50016 10.7551L13.6904 6.56488C13.9995 6.25572 14.5008 6.25572 14.81 6.56488C15.1191 6.87405 15.1191 7.3753 14.81 7.68447L10.06 12.4345C9.75079 12.7436 9.24953 12.7436 8.94037 12.4345L4.19037 7.68447C3.8812 7.3753 3.8812 6.87405 4.19037 6.56488Z"
      fill="white"
    />
  </svg>
);

export const ChevronDownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChevronDown} {...props} />
);
