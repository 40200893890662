import { useDispatch, useSelector } from "react-redux";
import { FROM_CHAIN, TO_CHAIN } from "../../../Constants/TYPES/crossChainTypes";
import {
  setFromChainCross,
  setToChainCross,
} from "../../../redux/reducers/swapDataCrossChain/swapDataCrossChain";
import "./SelectBlockchain.scss";

import { ConfigProvider, Select } from "antd";
import { supportedChains } from "../../../App";
import { colors } from "../../../Assets/Theme/colors";

const SelectChain = ({ type }: { type: "FROM_CHAIN" | "TO_CHAIN" }) => {
  const dispatch = useDispatch();
  const fromChainValue: any = useSelector(
    (state: any) => state.swapDataCrossChain.fromChainCross,
  );
  const toChainValue: any = useSelector(
    (state: any) => state.swapDataCrossChain.toChainCross,
  );
  const tokenList = useSelector(
    (state: any) => state?.tokenCollection?.crossTokenCollection,
  );

  const changeNetwork = async (value: number) => {
    if (type === FROM_CHAIN && value !== toChainValue) {
      dispatch(setFromChainCross(value));
    } else if (type === TO_CHAIN && value !== fromChainValue) {
      dispatch(setToChainCross(value));
    }
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionActiveBg: "#43434c",
            optionSelectedBg: colors.primary,
            controlItemBgHover: "#43434c",
            colorPrimaryHover: "#43434c",
            selectorBg: colors.secondary600,
            colorBorder: "#333",
            controlHeight: 38,
            optionSelectedColor: "white",
            colorBgElevated: colors.secondary600,
          },
        },
      }}
    >
      <Select
        defaultValue={
          type === "FROM_CHAIN"
            ? fromChainValue
              ? fromChainValue
              : supportedChains[0].value
            : toChainValue
            ? toChainValue
            : supportedChains[1].value
        }
        value={
          type === "FROM_CHAIN"
            ? fromChainValue
              ? fromChainValue
              : supportedChains[0].value
            : toChainValue
            ? toChainValue
            : supportedChains[1].value
        }
        onChange={changeNetwork}
        style={{ width: "100%" }}
      >
        {supportedChains.map((option: any) => (
          <Select.Option
            value={option.value}
            key={option.value}
            disabled={
              type === "FROM_CHAIN"
                ? option.value === toChainValue
                : option.value === fromChainValue
            }
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </ConfigProvider>
  );
};

export default SelectChain;

// INFO:: When both chains are same, safeToken ensures we can't select same token on same chains
