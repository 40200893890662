export const OPEN_OCEAN_CHAIN_ETH = "eth";
export const OPEN_OCEAN_CHAIN_BSC = "bsc";
export const OPEN_OCEAN_CHAIN_POLYGON = "polygon"; // FOR_MORE_CHAINS
export const OPEN_OCEAN_CHAIN_AVALANCHE = "avax"; // FOR_MORE_CHAINS
export const OPEN_OCEAN_CHAIN_ARBITRUM = "arbitrum"; // FOR_MORE_CHAINS
export const OPEN_OCEAN_CHAIN_FANTOM = "fantom"; // FOR_MORE_CHAINS
export type OPEN_OCEAN_CHAIN_NAMES =
  | "eth"
  | "bsc"
  | "polygon"
  | "avax"
  | "arbitrum"
  | "fantom"
  | undefined;
export type LIMIT_ORDER_EXPIRE_DATE =
  | "10M"
  | "1H"
  | "1D"
  | "3D"
  | "7D"
  | "30D"
  | "1Month"
  | "3Month"
  | "6Month"
  | "1Y"
  | undefined;
