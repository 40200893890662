import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Home = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#3B87F7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58331 16.25H7.70831V11.0417H12.2916V16.25H15.4166V8.125L9.99998 4.0625L4.58331 8.125V16.25ZM4.58331 17.5C4.23609 17.5 3.94095 17.3785 3.6979 17.1354C3.45484 16.8924 3.33331 16.5972 3.33331 16.25V8.125C3.33331 7.93056 3.37845 7.74306 3.46873 7.5625C3.55901 7.38194 3.68054 7.23611 3.83331 7.125L9.24998 3.0625C9.36109 2.97917 9.47915 2.91667 9.60415 2.875C9.72915 2.83333 9.86109 2.8125 9.99998 2.8125C10.1389 2.8125 10.2708 2.83333 10.3958 2.875C10.5208 2.91667 10.6389 2.97917 10.75 3.0625L16.1666 7.125C16.3194 7.23611 16.441 7.38194 16.5312 7.5625C16.6215 7.74306 16.6666 7.93056 16.6666 8.125V16.25C16.6666 16.5972 16.5451 16.8924 16.3021 17.1354C16.059 17.3785 15.7639 17.5 15.4166 17.5H11.0416V12.2917H8.95831V17.5H4.58331Z"
      fill="#3B87F7"
    />
  </svg>
);

export const HomeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Home} {...props} />
);
