import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const ArrowDown2 = () => (
  <svg
    width="55"
    height="54"
    viewBox="0 0 55 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="54" height="54" rx="27" fill="#3B87F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 17C28.1904 17 28.75 17.5596 28.75 18.25V35.75C28.75 36.4404 28.1904 37 27.5 37C26.8096 37 26.25 36.4404 26.25 35.75V18.25C26.25 17.5596 26.8096 17 27.5 17Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8661 26.1161C18.3543 25.628 19.1457 25.628 19.6339 26.1161L27.5 33.9822L35.3661 26.1161C35.8543 25.628 36.6457 25.628 37.1339 26.1161C37.622 26.6043 37.622 27.3957 37.1339 27.8839L28.3839 36.6339C27.8957 37.122 27.1043 37.122 26.6161 36.6339L17.8661 27.8839C17.378 27.3957 17.378 26.6043 17.8661 26.1161Z"
      fill="white"
    />
  </svg>
);

export const ArrowDownIcon2 = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowDown2} {...props} />
);
