import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultBNBTokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/BnbMainnetDefaultTokens";
import { defaultEthTokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/EthMainnetDefaultTokens";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  FANTOM_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  POLYGON_NETWORK,
} from "../../../Constants/NetworkNames/NetworkNames";
import {
  setFromTokenCollection,
  setOverallARBITRUMTokenList,
  setOverallAVALANCHETokenList,
  setOverallBNBTokenList,
  setOverallEthTokenList,
  setOverallFANTOMTokenList,
  setOverallPOLYGONTokenList,
  setTokenCollection,
  setToTokenCollection,
} from "../../../redux/reducers/tokenCollection/tokenCollection";
import store from "../../../redux/store";
import { defaultPOLYGONTokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/PolygonMainnetDefaultTokens";
import { defaultAVALANCHETokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/AvalancheMainnetDefaultTokens";
import { defaultARBITRUMTokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/ArbitrumMainnetDefaultTokens";
import { defaultFANTOMTokenCollection } from "../../../Assets/Collections/TokenCollection/Mainnet/FantomMainnetDefaultTokens";

const useCookieReset = () => {
  const dispatch = useDispatch();
  const currentNetwork = useSelector(
    (state: any) => state.networkSlice.currentNetwork,
  );
  const fromChainValue: string = useSelector(
    (state: any) => state.swapDataCrossChain.fromChainCross,
  );
  const toChainValue: string = useSelector(
    (state: any) => state.swapDataCrossChain.toChainCross,
  );

  const fetchTokenList = async () => {
    switch (currentNetwork) {
      case EHTEREUM_NETWORK:
        // get all eth tokens..(from redux) as it will have importedTokens(if any) also
        // if eth tokens length is greater than 0 use that
        // otherwise prepare list
        const overallEthTokenList: any =
          store?.getState()?.tokenCollection?.overallEthTokenList;

        if (overallEthTokenList.length) {
          // need to check later : check when tokens are imported this length is increasing or not

          dispatch(setTokenCollection(overallEthTokenList));
        } else {
          dispatch(setOverallEthTokenList(defaultEthTokenCollection));
          dispatch(setTokenCollection(defaultEthTokenCollection));
        }
        break;

      case BNB_NETWORK:
        // get all BNB tokens..(from redux) as it will have importedTokens(if any) also
        // if BNB tokens length is greater than 0 use that
        // otherwise prepare new list
        const overallBNBTokenList: any =
          store?.getState()?.tokenCollection?.overallBNBTokenList;
        if (overallBNBTokenList.length) {
          dispatch(setTokenCollection(overallBNBTokenList));
        } else {
          // add defaultBNBTokens and openOcean Token List
          dispatch(setOverallBNBTokenList(defaultBNBTokenCollection));
          dispatch(setTokenCollection(defaultBNBTokenCollection));
        }
        break;

      case POLYGON_NETWORK: // for_more_chains
        // get all POLYGON tokens..(from redux) as it will have importedTokens(if any) also
        // if POLYGON tokens length is greater than 0 use that
        // otherwise prepare new list
        const overallPOLYGONTokenList: any =
          store?.getState()?.tokenCollection?.overallPOLYGONTokenList;
        if (overallPOLYGONTokenList.length) {
          dispatch(setTokenCollection(overallPOLYGONTokenList));
        } else {
          // add defaultPOLYGONTokens and openOcean Token List
          dispatch(setOverallPOLYGONTokenList(defaultPOLYGONTokenCollection));
          dispatch(setTokenCollection(defaultPOLYGONTokenCollection));
        }
        break;

      case AVALANCHE_NETWORK:
        // get all AVALANCHE tokens..(from redux) as it will have importedTokens(if any) also
        // if AVALANCHE tokens length is greater than 0 use that
        // otherwise prepare new list
        const overallAVALANCHETokenList: any =
          store?.getState()?.tokenCollection?.overallAVALANCHETokenList;
        if (overallAVALANCHETokenList.length) {
          dispatch(setTokenCollection(overallAVALANCHETokenList));
        } else {
          // add defaultAVALANCHETokens and openOcean Token List
          dispatch(
            setOverallAVALANCHETokenList(defaultAVALANCHETokenCollection),
          );
          dispatch(setTokenCollection(defaultAVALANCHETokenCollection));
        }
        break;

      case ARBITRUM_NETWORK:
        // get all ARBITRUM tokens..(from redux) as it will have importedTokens(if any) also
        // if ARBITRUM tokens length is greater than 0 use that
        // otherwise prepare new list
        const overallARBITRUMTokenList: any =
          store?.getState()?.tokenCollection?.overallARBITRUMTokenList;
        if (overallARBITRUMTokenList.length) {
          dispatch(setTokenCollection(overallARBITRUMTokenList));
        } else {
          // add defaultARBITRUMTokens and openOcean Token List
          dispatch(setOverallARBITRUMTokenList(defaultARBITRUMTokenCollection));
          dispatch(setTokenCollection(defaultARBITRUMTokenCollection));
        }
        break;

      case FANTOM_NETWORK:
        // get all FANTOM tokens..(from redux) as it will have importedTokens(if any) also
        // if FANTOM tokens length is greater than 0 use that
        // otherwise prepare new list
        const overallFANTOMTokenList: any =
          store?.getState()?.tokenCollection?.overallFANTOMTokenList;
        if (overallFANTOMTokenList.length) {
          dispatch(setTokenCollection(overallFANTOMTokenList));
        } else {
          // add defaultFANTOMTokens and openOcean Token List
          dispatch(setOverallFANTOMTokenList(defaultFANTOMTokenCollection));
          dispatch(setTokenCollection(defaultFANTOMTokenCollection));
        }
        break;

      default:
        break;
    }
  };

  const setupTokenList = async () => {
    // for_more_chains :: check if we need to setup this list or not
    // get all eth tokens..(from redux) as it will have importedTokens(if any) also
    // if eth tokens length is greater than 0 use that
    // otherwise prepare list
    const overallEthTokenList: any =
      store?.getState()?.tokenCollection?.overallEthTokenList;
    if (overallEthTokenList.length) {
      // need to check later : check when tokens are imported this length is increasing or not
      dispatch(setOverallEthTokenList(overallEthTokenList));
    } else {
      // add defaultEthTokens and openOcean Token List
      dispatch(setOverallEthTokenList(defaultEthTokenCollection));
      // get all BNB tokens..(from redux) as it will have importedTokens(if any) also
      // if BNB tokens length is greater than 0 use that
      // otherwise prepare new list
    }

    const overallBNBTokenList: any =
      store?.getState()?.tokenCollection?.overallBNBTokenList;
    if (overallBNBTokenList.length) {
      dispatch(setOverallBNBTokenList(overallBNBTokenList));
    } else {
      // add defaultBNBTokens and openOcean Token List
      dispatch(setOverallBNBTokenList(defaultBNBTokenCollection));
    }

    const overallPOLYGONTokenList: any =
      store?.getState()?.tokenCollection?.overallPOLYGONTokenList;
    if (overallPOLYGONTokenList.length) {
      dispatch(setOverallPOLYGONTokenList(overallPOLYGONTokenList));
    } else {
      // add defaultPOLYGONTokenCollection and openOcean Token List
      dispatch(setOverallPOLYGONTokenList(defaultPOLYGONTokenCollection));
    }

    const overallAVALANCHETokenList: any =
      store?.getState()?.tokenCollection?.overallAVALANCHETokenList;
    if (overallAVALANCHETokenList.length) {
      dispatch(setOverallAVALANCHETokenList(overallAVALANCHETokenList));
    } else {
      // add defaultAVALANCHETokenCollection and openOcean Token List
      dispatch(setOverallAVALANCHETokenList(defaultAVALANCHETokenCollection));
    }

    const overallARBITRUMTokenList: any =
      store?.getState()?.tokenCollection?.overallARBITRUMTokenList;
    if (overallARBITRUMTokenList.length) {
      dispatch(setOverallARBITRUMTokenList(overallARBITRUMTokenList));
    } else {
      // add defaultARBITRUMTokenCollection and openOcean Token List
      dispatch(setOverallARBITRUMTokenList(defaultARBITRUMTokenCollection));
    }

    const overallFANTOMTokenList: any =
      store?.getState()?.tokenCollection?.overallFANTOMTokenList;
    if (overallFANTOMTokenList.length) {
      dispatch(setOverallFANTOMTokenList(overallFANTOMTokenList));
    } else {
      // add defaultARBITRUMTokenCollection and openOcean Token List
      dispatch(setOverallFANTOMTokenList(defaultFANTOMTokenCollection));
    }

    const tokenCollectionETH: any =
      store.getState().tokenCollection?.overallEthTokenList;
    const tokenCollectionBNB: any =
      store.getState().tokenCollection?.overallBNBTokenList;
    const tokenCollectionPOLYGON: any =
      store.getState().tokenCollection?.overallPOLYGONTokenList;
    const tokenCollectionAVALANCHE: any =
      store.getState().tokenCollection?.overallAVALANCHETokenList;
    const tokenCollectionARBITRUM: any =
      store.getState().tokenCollection?.overallARBITRUMTokenList;
    const tokenCollectionFANTOM: any =
      store.getState().tokenCollection?.overallFANTOMTokenList;

    // HANDLING MULTICHAIN : setFromTokenCollection & toTokenCollection
    switch (fromChainValue) {
      case EHTEREUM_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionETH));
        break;
      case BNB_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionBNB));
        break;
      case POLYGON_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionPOLYGON));
        break;
      case AVALANCHE_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionAVALANCHE));
        break;
      case ARBITRUM_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionARBITRUM));
        break;
      case FANTOM_NETWORK:
        dispatch(setFromTokenCollection(tokenCollectionFANTOM));
        break;
      default:
        break;
    }

    switch (toChainValue) {
      case EHTEREUM_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionETH));
        break;
      case BNB_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionBNB));
        break;
      case POLYGON_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionPOLYGON));
        break;
      case AVALANCHE_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionAVALANCHE));
        break;
      case ARBITRUM_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionARBITRUM));
        break;
      case FANTOM_NETWORK:
        dispatch(setToTokenCollection(tokenCollectionFANTOM));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // fetchTokenList();
    // setupTokenList();
  }, [currentNetwork]);
};

export default useCookieReset;
