// GAS_MULTIPLIER gwei to wei
export const GAS_MULTIPLIER = 10 ** 9;

// 255365 is the average estimated gas fee(incase of ETH), but transaction is getting reverted in case of low gasFee,
// so we are adding buffer of 100000 wei
export const GAS_BUFFER = 100000;

export const SWAP_SUCCESS_DB_PAYLOAD = "SUCCESS";

export const maxImpactPercentage: any = -100;

// We are using this constant to subtract gasFee from max amount (when user hits max button)
export const MULTIPLIER_FACTOR = 1.5 * 10 ** 18;
