import { createSlice } from "@reduxjs/toolkit";

export const walletConnectivitySlice: any = createSlice({
  name: "walletConnectivity",
  initialState: {
    requestedNetwork: "",
  },
  reducers: {
    resetWalletConnectivitySlice: (state: any, action) => {
      state.requestedNetwork = "";
    },
    setRequestedNetwork: (state: any, action: any) => {
      state.requestedNetwork = action.payload;
    },
  },
});

export const { setRequestedNetwork, resetWalletConnectivitySlice } =
  walletConnectivitySlice.actions;
export default walletConnectivitySlice.reducer;
