import React from "react";
import "./ProgressCustom.scss";
import ProgressBar from "react-bootstrap/ProgressBar";

const ProgressCustom = ({ token_progressBar, completed }: any) => {
  // const now = 100;

  return (
    <div className="progress_bar">
      <ProgressBar className={token_progressBar} now={completed} />
    </div>
  );
};

export default ProgressCustom;
