import { message } from "antd";
import {
  APIURL,
  APIURL_OPEN_OCEAN,
} from "../../../Constants/APIconstants/apiConstants";
import {
  OPEN_OCEAN_API_KEY,
  SITE_URL,
  SITE_URL_OPEN_OCEAN,
} from "../../../Constants/AppVariables/appVariable";
import { OPEN_OCEAN_CHAIN_NAMES } from "../../../Constants/TYPES/openOceanChainNames";
import {
  apiCallGet,
  apiCallPost,
} from "../../../services/apiServices/ApiServices";
import { getMessageConfig } from "../../../utils";
import { setTradeDataLoading } from "../../reducers/loadingData/loadingData";
import store from "../../store";
import { fetch as crossFetch } from "cross-fetch";
import axios from "axios";

/** best value function gives the best price of the token that we can receive
 * @param data
 * @param dispatch
 * @returns
 */
export const bestValueFunction = async (data: any, dispatch: any) => {
  // console.log("called best value", data);
  try {
    const result: any = await apiCallPost(SITE_URL, APIURL.BEST_VALUE, data);
    // console.log("bestValueFunction result => ", result);
    if (result?.data?.status) {
      // store.dispatch(setTradeDataLoading(false));
      return result.data.data;
    }
  } catch (error: any) {
    store.dispatch(setTradeDataLoading(false));
    console.log("bestValueFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** GetSwapQuoteData function used to get the data needed for swap
 * @param data "chainName" | "inTokenAddress" | "outTokenAddress" | "amount" | "gasPrice" | "slippage" | "user account address"
 * @param dispatch
 * @returns SwapQuoteData (used to execute swap)
 */
export const getSwapQuoteDataFunction = async (
  chainName: OPEN_OCEAN_CHAIN_NAMES,
  data: any,
) => {
  try {
    const ENDPOINT =
      "/api/swap" + APIURL_OPEN_OCEAN.SWAP_QUOTE + "/" + chainName;

    const result: any = await apiCallGet(SITE_URL, ENDPOINT, data);

    if (result?.code === 200) {
      return result.data;
    }
  } catch (error: any) {
    console.log("getSwapQuoteDataFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

// saves swap transaction to db

/** havePool  function checks whether pool exists in our db or not
 * @param token
 * @param dispatch
 * @returns boolean
 */
export const saveSwap = async (data: any) => {
  try {
    const ENDPOINT = "/api/create-swap/";

    const result: any = await apiCallPost(SITE_URL, ENDPOINT, data);

    if (result?.data) {
      return result.data;
    }
  } catch (error: any) {
    console.log("saveSwap Error => ", error);
  }
};

/** Function to fetch Ethereum current estimate gas price and time (not using, hence hardCoded) for transaction execution
 * @returns gas prices and time estimations
 */
export const currentEthereumGasPricesFunction = async () => {
  try {
    const result: any = await apiCallGet(SITE_URL, APIURL.ETH_GAS_PRICES);

    if (result?.message === "Gas Fee Available") {
      const data = {
        low: Number(result?.data?.SafeGasPrice),
        medium: Number(result?.data?.SafeGasPrice),
        fast: Number(result?.data?.FastGasPrice),
        base: Number(result?.data?.SafeGasPrice),
        safeLowWait: 15, // not using this value
        avgWait: 10, // not using this value
        fastWait: 6, // not using this value
      };
      return data;
    }
  } catch (error: any) {
    console.log("currentEthereumGasPricesFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error,
      }),
    );
  }
};

/** Function to fetch BNB current estimate gas price and time for transaction execution
 * @returns gas prices and time estimations
 */
export const currentBNBGasPricesFunction = () => {
  try {
    // console.log('currentGasPricesFunction =>', result);
    const data = {
      low: 3,
      medium: 3,
      fast: 3.02,
      base: 3,
      safeLowWait: 10,
      avgWait: 10,
      fastWait: 10,
    };
    return data;
  } catch (error: any) {
    console.log("currentBNBGasPricesFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error,
      }),
    );
  }
};

/** havePool  function checks whether pool exists in our db or not
 * @param token
 * @param dispatch
 * @returns boolean
 */
export const havePoolFunction = async (token: any, dispatch: any) => {
  // console.log("called have Pools..", token);
  try {
    const result: any = await apiCallPost(SITE_URL, APIURL.HAVE_POOL, {
      token: token,
    });
    // console.log("havePool function result => ", result);
    if (result?.data?.status) {
      return result.data;
    }
  } catch (error: any) {
    console.log("havePoolFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** Liquidity Source Provider function fetches the  whether pool exists in our db or not
 * @param chainId
 * @param dispatch
 * @returns liquidity sources
 */
export const getLiquiditySourcesFunction = async (
  chainId: any,
  dispatch: any,
) => {
  try {
    const result: any = await apiCallPost(SITE_URL, APIURL.LIQUIDITY_SOURCES, {
      chainId: chainId,
    });
    // console.log("getLiquiditySourcesFunction function result => ", result);
    if (result?.data?.status) {
      return result.data;
    }
  } catch (error: any) {
    console.log("getLiquiditySourcesFunction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** Liquidity Source Provider function fetches the  whether pool exists in our db or not
 * @param chainId
 * @param dispatch
 * @returns liquidity sources
 */
export const getLiquiditySourcesFunctionOpenSea = async (
  chainId: OPEN_OCEAN_CHAIN_NAMES,
  dispatch: any,
) => {
  try {
    const headersData = {
      apikey: OPEN_OCEAN_API_KEY,
    };
    const ENDPOINT = chainId + APIURL_OPEN_OCEAN.DEX_LIST;
    const result: any = await apiCallGet(
      SITE_URL_OPEN_OCEAN,
      ENDPOINT,
      {},
      headersData,
    );
    // console.log("getLiquiditySourcesFunction function result => ", result);
    if (result?.code == 200) {
      return result;
    }
  } catch (error: any) {
    console.log("getLiquiditySourcesFunctionOpenSea Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** Add User Transaction
 * @param walletAddress
 * @param tokenFrom
 * @param amountIn
 * @param txnHash
 * @param txnStatus
 * @returns status 200 or 400
 */
export const addUserTransaction = async (data: any) => {
  console.log("called addUserTransaction", data);
  try {
    const result: any = await apiCallPost(
      SITE_URL,
      APIURL.ADD_USER_TRANSACTION,
      data,
    );
    console.log("user history result => ", result);
    if (result?.data?.status === 200) {
      const response = {
        status: 200,
        txnHash: result?.data?.txnHash,
      };
      return response;
    }
  } catch (error: any) {
    store.dispatch(setTradeDataLoading(false));
    console.log("addUserTransaction Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** Get Chart Data
 * @param tokenIn
 * @param tokenOut
 * @param interval
 * @param startDate
 * @param endDate
 * @returns status 200 or 400
 */
export const getGraphData = async (data: any) => {
  try {
    const result: any = await apiCallPost(SITE_URL, APIURL.GET_CHART, data);
    // console.log("getGraphData result => ", result);
    if (result?.data?.status === 200) {
      return result?.data;
    }
  } catch (error: any) {
    console.log("getGraphData Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/** Get User Transactions
 * @param walletAddress
 * @param page
 * @param limit
 * @param fromAddress
 * @param toAddress
 * @returns
 */
export const getUserTransactions = async (data: any) => {
  try {
    const result: any = await apiCallPost(
      SITE_URL,
      APIURL.GET_USER_TRANSACTIONS,
      data,
    );
    // console.log("User Transactions => ", result);
    if (result?.status === 200) {
      const response = {
        status: 200,
        transactions: result?.data,
      };
      return response;
    } else {
      const response = {
        status: 400,
        transactions: [],
      };
      return response;
    }
  } catch (error: any) {
    store.dispatch(setTradeDataLoading(false));
    console.log("getUserTransactions Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

/**
 * Backup function to check & alert user to install metamask
 */
//  export const checkIsMetamaskInstalled = () => {
//   const Window: any = window;
//   const { ethereum } = Window;
//   const result = Boolean(ethereum && ethereum.isMetaMask);
//   if (!result) {
//     console.log("You have to install MetaMask!");
//     message.error(getMessageConfig({
//       type: 'error',
//       title: 'Something went wrong',
//       body: 'You need to install MetaMask',
//     }));
//   }
// };

export const getCrossChainTokens = async (chain: any) => {
  try {
    const ENDPOINT = `open/v1/base/getSupportTokens/?chain=${chain}`;

    const result: any = await apiCallGet(
      "https://openapi.chainge.finance/",
      ENDPOINT,
      {},
    );

    if (result?.code === 200) {
      return result?.data?.tokenVos;
    }
  } catch (error: any) {
    console.log("getCrossChainTokens Error => ", error);
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message ?? error.mesage,
      }),
    );
  }
};

const REACT_APP_ONEINCH_API_BASE_URL =
  process.env.REACT_APP_ONEINCH_API_BASE_URL ||
  "https://dev.api.shido.baboons.tech/api/swap";

// Fetch available tokens
export const fetchTokens = async (chainId: number) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/tokens/${chainId}`)
    .then((res) => res.data);
};

// Fetch a quote for swapping tokens
export const fetchQuote = async (
  chainId: number,
  fromToken: string,
  toToken: string,
  amount: string,
) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/swap_quote/${chainId}`, {
      params: { from_token: fromToken, to_token: toToken, amount },
    })
    .then((res) => res.data);
};

type PricesType = {
  [key: string]: number;
  fromPrice: number;
  toPrice: number;
};

export const fetchTokenPrices = async (
  chainId: number,
  fromToken: string,
  toToken: string,
): Promise<PricesType> => {
  const addresses = `${fromToken},${toToken}`;
  const url = `${REACT_APP_ONEINCH_API_BASE_URL}/prices/${chainId}/${addresses}`;
  return axios
    .get(url, { params: { currency: "USD" } })
    .then((res) => {
      const prices = res.data;
      return {
        fromPrice: parseFloat(prices[fromToken]) || 0,
        toPrice: parseFloat(prices[toToken]) || 0,
      };
    })
    .catch((error) => {
      console.error("Error fetching token prices:", error);
      throw new Error("Failed to fetch token prices");
    });
};

// Fetch the 1inch contract address for token allowance
export const fetchSpenderAddress = async (chainId: number) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/spender/${chainId}`)
    .then((res) => res.data);
};

// Check the token allowance for the 1inch contract
export const fetchTokenAllowance = async (
  chainId: number,
  tokenAddress: string,
  walletAddress: string,
) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/allowance/${chainId}`, {
      params: { tokenAddress, walletAddress },
    })
    .then((res) => res.data);
};

// Fetch data for approving a token transaction
export const fetchApprovalTransaction = async (
  chainId: number,
  tokenAddress: string,
  amount: string,
) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/approve_transaction/${chainId}`, {
      params: { tokenAddress, amount },
    })
    .then((res) => res.data);
};

// Initiates a swap on the 1inch Router
export const initiateSwap = async (
  chainId: number,
  src: string,
  dst: string,
  amount: string,
  fromAddress: string,
  slippage: string,
) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/${chainId}`, {
      params: { src, dst, amount, from: fromAddress, slippage },
    })
    .then((res) => res.data);
};

export const searchTokens = async (query: string) => {
  return axios
    .get(`${REACT_APP_ONEINCH_API_BASE_URL}/search_tokens/`, {
      params: { query },
    })
    .then((res) => res.data);
};
