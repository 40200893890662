import { gsap } from "gsap";

// SidebarTL----------------------
export function SidebarTL(element) {
  let SidebarTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.inOut" },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  if (window.innerWidth > 768) {
    SidebarTL.from(
      element,
      {
        x: -100,
        opacity: 0,
      },
      "<+=0.00",
    )
      .from(
        element?.querySelector(".logo"),
        {
          opacity: 0,
        },
        "<+=0.4",
      )
      .from(
        element?.querySelector(".side-menu-wrap"),
        {
          y: 13,
          opacity: 0,
        },
        "<+=0.13",
      )
      .from(
        element?.querySelector(".shido-card"),
        {
          y: 22,
          opacity: 0,
        },
        "<+=0.13",
      )
      .from(
        element?.querySelector(".shido-card .logo-icon"),
        {
          x: -13,
          opacity: 0,
        },
        "<+=0.00",
      )
      .from(
        element?.querySelector(".shido-card .logo-name"),
        {
          x: 13,
          opacity: 0,
        },
        "<+=0.00",
      )
      .from(
        element?.querySelector(".shido-card .btn-wrap"),
        {
          y: 13,
          opacity: 0,
        },
        "<+=0.00",
      );
  }
}
// /SidebarTL----------------------
