import { Card, Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowDownStraight from "../../../../Assets/Images/arrow-straight.svg";
import arrowDown from "../../../../Assets/Images/arrowDown.svg";
import ethereumIconSm from "../../../../Assets/Images/ethereum-icon-sm.svg";
import repeatIcon from "../../../../Assets/Images/fi-repeat.svg";
import shidoLogo from "../../../../Assets/Images/shidoLogo.svg";
import UnionIconSm from "../../../../Assets/Images/union-icon-sm.svg";
import union from "../../../../Assets/Images/union.svg";
import { HomeOverviewTL } from "../../../../Assets/animation/HomeOverview";
import { statsType } from "../../../../Constants/TYPES/homePageTypes";
import { STAKING_TOKEN_VERSIONS } from "../../../../Constants/TYPES/stakingTokenTypes";
import useShidoPrice from "../../../../hooks/useShidoPrice";
import { getHomePageStats } from "../../../../redux/api/homeStats/homeStatsAPIs";
import store from "../../../../redux/store";
import { getTotalStakings } from "../../../../services/contractServices/stakingServices";
import { PrimaryButton } from "../../../Button";
import { Text } from "../../../Text";
import { Swapper } from "../Swapper/Swapper";
import LineChart from "./LineChart";
import "./Statistics.scss";

export const Statistics = () => {
  const navigate = useNavigate();
  const { xs, sm, lg } = useBreakpoint();
  const [totalStaked, setTotalStaked] = useState<any>(null);
  const [stats, setStats] = useState<statsType>();
  const OverviewRef = useRef<HTMLDivElement>(null);
  const { shidoPrice } = useShidoPrice();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      HomeOverviewTL(OverviewRef.current);
    }, OverviewRef);

    return () => ctx.revert();
  }, [OverviewRef]);

  useEffect(() => {
    (async function () {
      await fetchStakingDetails("STAKING_TOKEN_V2");
      await homePageStats();
    })();
  }, []);

  const fetchStakingDetails = async (
    STAKING_TOKEN_VERSION: STAKING_TOKEN_VERSIONS,
  ) => {
    try {
      const result = await getTotalStakings("STAKING_V2");
      const contractCollection =
        store.getState().contractCollection.contractCollection;
      const tokenData: any = contractCollection.filter(
        (a: any) => a.symbol == STAKING_TOKEN_VERSION,
      );
      let tokenDecimals = tokenData?.decmals || 18;
      const calculatedResult: any =
        Number(result) / 10 ** Number(tokenDecimals);
      setTotalStaked(calculatedResult);
    } catch (e) {
      setTotalStaked(0);
    }
  };

  const homePageStats = async () => {
    try {
      const result = await getHomePageStats();

      if (result) {
        setStats(result.data);
        // dispatch(setLoading(false));
      }
    } catch (error) {
      // dispatch(setLoading(false));
    }
  };

  return (
    <Col
      xs={24}
      lg={24}
      style={{ height: "auto" }}
      className="overview"
      ref={OverviewRef}
    >
      <Card style={{ height: "100%" }} className="overview_card">
        <Row justify={"center"}>
          <Col span={24} className="overview_card-item">
            <img src={arrowDown} />
          </Col>
          <Col span={24} className="heading overview_card-item">
            <Text
              fontSize={40}
              fontWeight={600}
              style={{ lineHeight: "normal" }}
              className="statistics-heading"
            >
              Statistics & Overview
            </Text>
          </Col>
          <Col span={24} className="info-cards-row1">
            <Row gutter={[30, 30]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className="overview_card-item-xl"
              >
                <Row className="staking" gutter={[10, 0]}>
                  <Col className="heading" span={24}>
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      style={{ lineHeight: "normal" }}
                    >
                      Trade
                    </Text>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    className="staking-container"
                  >
                    <div className="value-box">
                      <div className="heading">
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          style={{ lineHeight: "normal" }}
                          color={"#215199"}
                        >
                          Total Tradeable Assets
                        </Text>
                      </div>
                      <div className="value">
                        <Text
                          fontSize={46}
                          fontWeight={600}
                          style={{ lineHeight: "normal" }}
                          color={"#071B33"}
                          className="staking-text"
                        >
                          500+
                        </Text>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xs={0} sm={0} md={0} lg={0} xl={0}></Col> */}
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    className="staking-container"
                  >
                    <div className="unique-staker">
                      <div className="heading">
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          style={{ lineHeight: "normal" }}
                          color={"#A3D3FB"}
                        >
                          Amount of Swaps
                        </Text>
                      </div>
                      <div className="value">
                        <Text
                          fontSize={32}
                          fontWeight={600}
                          style={{ lineHeight: "normal" }}
                          color={"#FFF"}
                          className="unique-staking-text"
                        >
                          {stats && stats.swaps.toLocaleString("en-US")}
                        </Text>
                      </div>
                    </div>
                    <div className="trading-fee">
                      <div className="heading">
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          style={{ lineHeight: "normal" }}
                          color={"#A3D3FB"}
                        >
                          SHIDO Holders
                        </Text>
                      </div>
                      <div className="value">
                        <Text
                          fontSize={32}
                          fontWeight={600}
                          style={{ lineHeight: "normal" }}
                          color={"#FFF"}
                          className="trading-fee-text"
                        >
                          {stats && stats.token_holders.toLocaleString("en-US")}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className="bar-chart overview_card-item-xr"
              >
                <Row className="graph">
                  <Col className="heading" span={24}>
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      style={{ lineHeight: "normal" }}
                    >
                      SHIDO Trading Volume
                    </Text>
                  </Col>
                  <Col className="info-col">
                    <Row className="trading-volume-info" gutter={[10, 10]}>
                      <Col>
                        <img src={shidoLogo} />
                      </Col>
                      <div className="price">
                        <Col className="logo-text">
                          <Text
                            color="#F3F3F6"
                            fontWeight={500}
                            fontSize={16}
                            className="text"
                          >
                            SHIDO
                          </Text>
                        </Col>
                        <Col className="logo-text">
                          <Text
                            color="#fff"
                            fontWeight={500}
                            fontSize={16}
                            className="text"
                          >
                            $
                            {Number(
                              shidoPrice?.coinInfo?.quote?.USD?.price,
                            ).toFixed(10)}
                          </Text>
                        </Col>
                      </div>
                      <Col className="logo-text">
                        <Text
                          color="#53B635"
                          fontWeight={500}
                          fontSize={16}
                          className="text"
                        >
                          +
                          {Number(
                            shidoPrice?.coinInfo?.quote?.USD
                              ?.percent_change_24h,
                          ).toFixed(2)}
                        </Text>
                      </Col>
                      <Col className="logo-btn" flex={1}>
                        <PrimaryButton
                          onClick={() =>
                            window.open(
                              "https://app.uniswap.org/tokens/ethereum/0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b",
                              "_blank",
                            )
                          }
                          className="button"
                        >
                          Buy SHIDO
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={24}
                    className="scroll-container"
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                  >
                    {stats && (
                      <LineChart
                        weeklyStats={stats?.shido_volume_last_6_days.data}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="info-cards-row2">
            <Row gutter={[30, 30]}>
              <Col
                className="overview_card-item-xl"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
              >
                <Row className="transaction-numbers">
                  <Col span={24} className="heading">
                    <img src={repeatIcon} />
                    <Text
                      fontSize={30}
                      fontWeight={700}
                      style={{ lineHeight: "normal", marginLeft: "10px" }}
                      color={"#FFF"}
                    >
                      Start Trading
                    </Text>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    className="image-container"
                  >
                    <Row justify={"center"} className="image-container-row">
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <Swapper
                          icon={ethereumIconSm}
                          currency={"ETH"}
                          text={"Swap from"}
                        />
                      </Col>
                      <Col className="arrow">
                        <img src={arrowDownStraight} />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <Swapper
                          icon={UnionIconSm}
                          currency={"SHIDO"}
                          text={"Swap to"}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="btn-col">
                    <PrimaryButton onClick={() => navigate("/simple-mode")}>
                      Trade Now
                    </PrimaryButton>
                  </Col>
                </Row>
              </Col>
              <Col
                className="overview_card-item-xr"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
              >
                <Row className="reward">
                  <Col span={24} className="image-container">
                    <img src={union} />
                  </Col>
                  <Col span={24} className="heading">
                    <Text
                      fontSize={xs ? 15 : 30}
                      fontWeight={xs ? 400 : 700}
                      style={{ lineHeight: "normal" }}
                      color={"#FFF"}
                    >
                      Stake <span style={{ color: "#3B87F7" }}>SHIDO</span>
                    </Text>
                  </Col>
                  <Col span={12} className="curr-reward">
                    <Text
                      fontSize={16}
                      fontWeight={500}
                      style={{
                        lineHeight: "normal",
                        position: "absolute",
                        bottom: 12,
                      }}
                      color={"#9B9CA3"}
                    >
                      Current reward:
                    </Text>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                    className="curr-reward"
                  >
                    <div className="custom-btn">
                      <Text fontSize={14} fontWeight={600} color={"#fff"}>
                        8% APR
                      </Text>
                    </div>
                  </Col>
                  <Col span={24} className="total-reward">
                    <Text
                      fontSize={16}
                      fontWeight={500}
                      style={{ lineHeight: "normal" }}
                      color={"#9B9CA3"}
                    >
                      Total rewards paid:
                    </Text>
                  </Col>
                  <Col span={24}>
                    <div className="reward-btn">
                      <Text
                        fontSize={20}
                        fontWeight={600}
                        color={"#FFFFFF"}
                        className="reward-btn-text"
                      >
                        {totalStaked
                          ? Number(
                              Number(totalStaked).toFixed(4),
                            ).toLocaleString("en-US")
                          : 0}
                      </Text>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
