import React, { useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { risk_dis } from "../../../Constants/ImagesConstants";
import { BackarrowIcon, DownIcon } from "../../../Assets/Svg/SvgImages";
import "./Index.scss";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import VoteTable from "./VoteTable/VoteTable";
import ConnectWallet from "../../Common/ConnectWallet/ConnectWallet";
import { Link } from "react-router-dom";

const Voting = () => {
  const [showBottom, setShowBottom] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(isActive);
  };

  const ResultListContent = [
    {
      classText: "VotesTotl",
      subValues: "1,181,782.9899",
      LabelSub: "Total votes",
    },
    {
      classText: "VotesTotl",
      subValues: "175",
      LabelSub: "Addresses",
    },
  ];
  return (
    <div className="Voting py-70">
      <Container className="inner-cont">
        <Row className="align-items-center">
          <Col md={12}>
            <div className="HeadingsList mb-md-4">
              <h4>
                <Link to="/liquidity">
                  <button>
                    <BackarrowIcon />
                  </button>
                </Link>{" "}
                Voting for swap fee
              </h4>
              <button
                className={showBottom ? "BtnDrop" : "activeBtnDrop"}
                onClick={() => {
                  setShowBottom(!showBottom);
                  ToggleClass();
                }}
              >
                <img src={risk_dis} alt="img" />
                <p>Risk Disclosure</p>
                <DownIcon />
              </button>
            </div>
            <div className={!showBottom ? "Rishshow" : "RiskHide"}>
              <h5>Risk Disclosure</h5>
              <p>
                Acting as a liquidity provider (LP) for staking pools is an
                inherently risky undertaking. Please do not transact with more
                funds than you can afford to lose. Once you stake any amount
                into a pool, you become a liquidity provider.
              </p>
            </div>
          </Col>
          <Col md={12}>
            <div className="ResultingVote">
              <Row>
                <Col lg={7}>
                  <div className="Currentresult">
                    <div className="BarHeader">
                      <h4>Current Result</h4>
                      <div className="BarProgress">
                        <ProgressBar className="prog" now={30} />
                        <Form.Control
                          type="text"
                          placeholder="0.0%"
                          className="TextConrol"
                        />
                      </div>
                    </div>
                    <div className="Current">
                      {ResultListContent.map((item, idx) => (
                        <div className="listOfResults">
                          <h5 className={`Valuessub ${item?.classText}`}>
                            {item?.subValues}
                          </h5>
                          <label className="SubLabel">{item?.LabelSub}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className="SubInfo">
                    The Swap Fee Is A Fixed Fee Charged On Each Swap Trade On
                    The Protocol. Votes Are Counted, And A Parameter’s Value Is
                    Changed Gradually During Decay Time After Voting. Liquidity
                    Providers Can Change Default Values Separately For Each
                    Pool. They Are Determined By Liquidity Providers Using A
                    Similar Voting System. You Can Vote Or Change Your Vote At
                    Any Time.
                  </p>
                  <div className="TableSet">
                    <div className="SubVotes">
                      <h4>Votes</h4>
                    </div>
                    <VoteTable />
                  </div>
                </Col>

                <Col lg={5}>
                  <div className="Trade_Simple_Card">
                    <div className="Heading">
                      <h5>Voting</h5>
                      <button className="restBtn">Reset</button>
                    </div>
                    <div className="Main_Containt">
                      <div className="ValueField">
                        <div className="currentValue">
                          <div className="w-100">
                            <Form.Label>Current Value</Form.Label>
                            <Form.Control type="text" placeholder="0.0%" />
                          </div>
                        </div>
                        <button className="Valueaccept">
                          <BackarrowIcon />
                        </button>
                        <div className="currentValue">
                          <div className="w-100">
                            <Form.Label>New Value(applied in 24h)</Form.Label>
                            <Form.Control type="text" placeholder="--" />
                          </div>
                        </div>
                      </div>
                      <div className="RangeSlider mb-3">
                        <div className="mb-4">
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={0}
                            className="slideedit"
                          />
                          <div className="ValueSet">
                            <span>0%</span>
                            <span>100%</span>
                          </div>
                        </div>
                        <InputGroup className="InputValue">
                          <Form.Control
                            placeholder="0"
                            aria-label="0"
                            aria-describedby="basic-addon1"
                          />
                          <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                        </InputGroup>
                      </div>

                      <ConnectWallet className="mt-4 w-100" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Voting;
