import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import {
  Aurora_Bridge,
  Avalanche_Bridge,
  Fantom_Bridge,
  Klaytn_Bridge,
  aggregation,
  arbitrum,
  // simple_mode,
  // classic_mode,
  // p2p,
  // limit_order,
  // strategies,
  // pools,
  // farming,
  bnb_drop,
  documentation,
  forum,
  gnosis,
  liquidity,
  logo,
  optimism,
  polygon_icon,
  // blog,
  // help,
  // address,
  // about,
  // feature,
  // bug,
  staking,
} from "../../../Constants/ImagesConstants";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setShowTransactionModal,
  setSubmitTransactionLoading,
  setSubmittedTransactionHash,
  setSwapTransactionLoading,
  setTransactionErrorMessage,
  setTransactionHash,
  setTransactionSuccessMessage,
} from "../../../redux/reducers/loadingData/loadingData";
import { versionManager } from "../../../services/helpers/swapHelpers";
import ComingSoonModal from "../CommonModals/ComingSoonModal/ComingSoonModal";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import BridgeDropdown from "./BridgeDropdown";
import EarnDropdown from "./EarnDropdown";
import "./HeaderStyle.scss";
import MoreDropdown from "./MoreDropdown";
import PerpetualsDropdown from "./Perpetuals";
import TradeDropdown from "./TradeDropdown";

// icons with new color

import classic_mode from "../../../Assets/Images/classic_mode.svg";
import limit_order from "../../../Assets/Images/limit_order.svg";
import simple_mode from "../../../Assets/Images/simple_mode.svg";

import farming from "../../../Assets/Images/farming.svg";
import pools from "../../../Assets/Images/pools.svg";
import strategies from "../../../Assets/Images/strategies.svg";

import blog from "../../../Assets/Images/blog.svg";
import bug from "../../../Assets/Images/bug.svg";
import feature from "../../../Assets/Images/feature.svg";
import help from "../../../Assets/Images/help.svg";
import { setRecentTxnHashOpenOcean } from "../../../redux/reducers/swapData/swapData";

const currentEnvironment = process.env.REACT_APP_NODE_ENV;

let TradeItem: any;

TradeItem = [
  {
    id: 1,
    icon: simple_mode,
    title: "Simple Mode",
    subText: "The most user-friendly way to trade",
    to: "/simple-mode",
  },
  // {
  //   id: 2,
  //   icon: classic_mode,
  //   title: "Classic Mode",
  //   subText: "Take advantage of all the familiar tools",
  //   to: "/classic-mode",
  // },
  {
    id: 3,
    icon: classic_mode,
    title: "Cross Chain",
    subText: "Swap across multiple chains",
    to: "/cross-chain-swap",
  },
  {
    id: 4,
    icon: limit_order,
    title: "Limit Orders",
    subText: "Schedule a swap to get the best price",
    to: "/limit-order",
  },
  // {
  //   id: 5,
  //   icon: p2p,
  //   title: "P2P Deal",
  //   subText: "Exchange tokens privately",
  //   to: "/p2p-deal",
  //   subTextComingSoon: "Coming Soon",
  // },
];

const EarnItem = [
  // {
  //   id: 1,
  //   icon: staking,
  //   title: 'Staking',
  //   to: '/staking',
  //   subText: '',
  // },
  {
    id: 2,
    icon: strategies,
    title: "Strategies",
    to: "/strategies",
    subText: "Coming Soon",
    className: "coming_droplink_trade",
  },
  {
    id: 3,
    icon: pools,
    title: "Pools",
    to: "/pools",
    subText: "Coming Soon",
    className: "coming_droplink_trade",
  },
  {
    id: 4,
    icon: farming,
    title: "Farming",
    to: "/farming",
    subText: "Coming Soon",
    className: "coming_droplink_trade",
  },
];

const DaoItem = [
  // {
  //   id: 1,
  //   icon: staking,
  //   title: 'Staking',
  //   to: '/staking',
  // },
  {
    id: 2,
    icon: liquidity,
    title: "Liquidity Protocol",
    to: "/liquidity",
  },
  {
    id: 3,
    icon: aggregation,
    title: "Aggregation Protocol",
    to: "/aggregation",
  },
  {
    id: 4,
    icon: forum,
    title: "Forum",
    to: "#",
  },
];

const BridgeItem = [
  {
    id: 1,
    icon: bnb_drop,
    title: "BNB Chain bridge",
    to: "https://cbridge.celer.network/",
  },
  {
    id: 2,
    icon: polygon_icon,
    title: "Polygon bridge",
    to: "https://wallet.matic.network/bridge/",
  },
  {
    id: 3,
    icon: optimism,
    title: "Optimism bridge",
    to: "https://app.optimism.io/bridge",
  },
  {
    id: 4,
    icon: arbitrum,
    title: "Arbitrum bridge",
    to: "https://bridge.arbitrum.io/",
  },
  {
    id: 5,
    icon: gnosis,
    title: "Gnosis Chain bridge",
    to: "https://omni.gnosischain.com/bridge",
  },
  {
    id: 5,
    icon: Avalanche_Bridge,
    title: "Avalanche Bridge",
    to: "https://bridge.avax.network/",
  },
  {
    id: 5,
    icon: Fantom_Bridge,
    title: "Fantom Bridge",
    to: "https://app.multichain.org/#/router/",
  },
  {
    id: 5,
    icon: Klaytn_Bridge,
    title: "Klaytn Bridge",
    to: "https://scope.klaytn.com/bridge",
  },
  {
    id: 5,
    icon: Aurora_Bridge,
    title: "Aurora Bridge",
    to: "https://rainbowbridge.app/transfer",
  },
];

const MoreItem = [
  {
    id: 1,
    icon: documentation,
    title: "Blog",
    to: "https://www.shido.io/news",
  },
  {
    id: 2,
    icon: blog,
    title: "About",
    to: "https://www.shido.io/dex",
  },
  {
    id: 3,
    icon: help,
    title: "Help",
    to: "https://t.me/ShidoGlobal",
  },
  {
    id: 4,
    icon: bug,
    title: "Report a Bug",
    to: "https://t.me/+JTRrG1s7E4ZlZDhk",
  },
  {
    id: 5,
    icon: feature,
    title: "Suggest a feature",
    to: "https://t.me/+JTRrG1s7E4ZlZDhk",
  },
];

const Header = () => {
  const history = useLocation();
  const dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);
  const wrongNetworSelected = useSelector(
    (state: any) => state.loginSlice.wrongNetworkSelected,
  );
  const userMetamaskChainID = useSelector(
    (state: any) => state.loginSlice.userMetamaskChainID,
  );
  const appNetwork = useSelector((state: any) => state.networkSlice.CHAINNAME);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // HANDLING NETWORK CHANGE FROM WALLET CONNECT 2 (FROM OUTSIDE OF OUR APP)

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const init = async () => {
      dispatch(setSwapTransactionLoading(false));
      dispatch(setSubmitTransactionLoading(false));
      dispatch(setTransactionErrorMessage(""));
      dispatch(setTransactionHash(""));
      dispatch(setSubmittedTransactionHash(""));
      dispatch(setShowTransactionModal(false));
      dispatch(setTransactionSuccessMessage(""));
      dispatch(setRecentTxnHashOpenOcean(""));
      await versionManager();
    };
    init();
    // addListeners();
  }, []);

  useEffect(() => {}, [history.pathname]);

  const myRef = useRef<any>();
  const trigger = () => {
    myRef.current?.click();
  };

  const HeaderItem = ({ subText, title, icon, to, target }: any) => {
    return (
      <>
        {target ? (
          <>
            <Link
              onClick={() => setActive(false)}
              to={to}
              className="d-flex align-items-center custom-droplink__trade"
            >
              <div className="custom-droplink__icon">
                <img src={icon} alt="icon" />
              </div>
              <div className="trade-text">
                <span className="d-block ">{title}</span>
                {subText && (
                  <span className="d-block trade-text__subtext">{subText}</span>
                )}
              </div>
            </Link>
          </>
        ) : (
          <>
            <a
              target={"_blank"}
              onClick={() => setActive(false)}
              href={to}
              className="d-flex align-items-center custom-droplink__trade"
            >
              <div className="custom-droplink__icon">
                <img src={icon} alt="icon" />
              </div>
              <div className="trade-text">
                <span className="d-block ">{title}</span>
                {subText && (
                  <span className="d-block trade-text__subtext">{subText}</span>
                )}
              </div>
            </a>
          </>
        )}
      </>
    );
  };

  const TradeItemComingSoon = ({
    subText,
    title,
    icon,
    to,
    subTextComingSoon,
  }: any) => {
    return (
      <Link
        onClick={
          (currentEnvironment === "qa" && to === "/limit-order") ||
          (currentEnvironment === "production" && to === "/limit-order") ||
          (currentEnvironment === "qa" && to === "/p2p-deal") ||
          (currentEnvironment === "production" && to === "/p2p-deal")
            ? () => setActive(false)
            : () => setActive(false)
        }
        to={
          // (currentEnvironment === "qa" && to === "/limit-order") ||
          // (currentEnvironment === "production" && to === "/limit-order") ||
          (currentEnvironment === "qa" && to === "/p2p-deal") ||
          (currentEnvironment === "production" && to === "/p2p-deal")
            ? ""
            : to
        }
        className={`d-flex align-items-center custom-droplink__trade ${
          to === history.pathname ? "isActive_link" : ""
        }  ${history.pathname === "/" ? "on_home_page" : ""}`}
      >
        <div className="custom-droplink__icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="trade-text">
          <span className="d-block ">{title}</span>
          {subText && (
            <span className="d-block trade-text__subtext">{subText}</span>
          )}
        </div>
        {subTextComingSoon && (
          <div className="trade-text coming_soon_text ms-auto coming_soon_title">
            {/* <span className="d-block coming_soon_title">{title}</span> */}

            <span className="d-block trade-text__subtext">
              {subTextComingSoon}
            </span>
          </div>
        )}
      </Link>
    );
  };

  const HeaderItemComingSoon = ({
    subText,
    title,
    icon,
    to,
    className,
  }: any) => {
    return (
      <Link
        onClick={() => setActive(false)}
        // to={currentEnvironment === "development" ? to : ""}
        to={
          currentEnvironment === "qa" ||
          (currentEnvironment === "production" && to === "/staking")
            ? to
            : currentEnvironment === "development"
              ? to
              : ""
        }
        className={`custom-droplink__trade
          ${to === history.pathname ? (className = "isActive_link") : ""}}
          ${className}
          `}
      >
        <div className="custom-droplink__icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="trade-text">
          <span className="d-block coming_soon_title">{title}</span>
          {subText && (
            <span className="d-block trade-text__subtext">{subText}</span>
          )}
        </div>
      </Link>
    );
  };

  return (
    <div>
      {isLoggedIn && wrongNetworSelected && (
        <p className="wrong_network_warning">
          App network {appNetwork} doesn't match to network selected in wallet
          (network with id: {userMetamaskChainID}). Please change the network in
          the wallet
        </p>
      )}
      <Navbar expanded={isActive} className="main-header" expand="xl">
        <Container className="commn-cont">
          <Navbar.Brand href="/simple-mode">
            <img src={logo} alt="logo" className="me-2" />
          </Navbar.Brand>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <TradeDropdown myRef={myRef}>
                <ul className="custom-droplink custom-droplink_trade">
                  {TradeItem.map((data: any) => (
                    <li
                      className={
                        data.subTextComingSoon === "Coming Soon"
                          ? "coming_droplink_trade"
                          : ""
                      }
                      key={data.id}
                      onClick={trigger}
                    >
                      <TradeItemComingSoon
                        to={data.to}
                        icon={data.icon}
                        title={data.title}
                        subText={data.subText}
                        subTextComingSoon={data.subTextComingSoon}
                      />
                    </li>
                  ))}
                </ul>
              </TradeDropdown>
              <PerpetualsDropdown />
              <EarnDropdown myRef={myRef}>
                <ul className="custom-droplink">
                  {/* {currentEnvironment === "production" ?
                    EarnItem.filter(prodFilter => prodFilter.title !== "Staking").map((data: any) => (
                      <li key={data.id}>
                        <HeaderItemComingSoon
                          to={data.to}
                          icon={data.icon}
                          title={data.title}
                          subText={data.subText}
                          className="earn_dropdown_link"
                        />
                      </li>
                    )) : */}
                  {EarnItem.map((data: any) => (
                    <li
                      key={data.id}
                      className={data.className}
                      onClick={trigger}
                    >
                      <HeaderItemComingSoon
                        to={data.to}
                        icon={data.icon}
                        title={data.title}
                        subText={data.subText}
                        className="earn_dropdown_link"
                      />
                    </li>
                  ))}
                </ul>
              </EarnDropdown>
              <BridgeDropdown>
                <ul className="custom-droplink custom-droplink--bridge">
                  {BridgeItem.map((data: any) => (
                    <li key={data.id}>
                      <HeaderItem
                        to={data.to}
                        icon={data.icon}
                        title={data.title}
                      />
                    </li>
                  ))}
                </ul>
              </BridgeDropdown>
              <MoreDropdown>
                <ul className="custom-droplink">
                  {MoreItem.map((data: any) => (
                    <li key={data.id}>
                      <HeaderItem
                        to={data.to}
                        icon={data.icon}
                        title={data.title}
                      />
                    </li>
                  ))}
                </ul>
              </MoreDropdown>
            </Nav>
          </Navbar.Collapse>
          <div className="d-flex align-items-center">
            {/*<div className="mb-0 px-md-0 px-4">*/}
            {/*  <SelectBlockchain useAsSelect />*/}
            {/*</div>*/}
            <div className="connect_wallet_box">
              <div className="connect_wallet_box_inner">
                <ConnectWallet className="connect-btn" />
              </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <div
                className={`hamburger ${isActive ? "collapsed" : ""}`}
                onClick={toggleClass}
                id="hamburger-9"
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </Navbar.Toggle>
          </div>
        </Container>
      </Navbar>
      <ComingSoonModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Header;
