import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import swapperArrowDown from "../../../../Assets/icons/SwapperArrowDown.png";
import { DownIcon } from "../../../../Assets/Svg/SvgImages";
import { colors } from "../../../../Assets/Theme/colors";
import {
  ARBITRUM_AGGRESSIVE_GAS,
  AVALANCHE_AGGRESSIVE_GAS,
  BNB_AGGRESSIVE_GAS,
  ETHEREUM_AGGRESSIVE_GAS,
  FANTOM_AGGRESSIVE_GAS,
  POLYGON_AGGRESSIVE_GAS,
} from "../../../../Constants/GasConstants/GasConstants";
import { disabledLetters } from "../../../../Constants/inputConstants";
import {
  ARBITRUM_NETWORK,
  AVALANCHE_NETWORK,
  BNB_NETWORK,
  EHTEREUM_NETWORK,
  FANTOM_NETWORK,
  POLYGON_NETWORK,
} from "../../../../Constants/NetworkNames/NetworkNames";
import { MULTIPLIER_FACTOR } from "../../../../Constants/swapConstants";
import {
  NATIVE_WITH_TOKEN,
  TOKEN_WITH_TOKEN,
} from "../../../../Constants/SwapTypes/SwapTypes";
import {
  OPEN_OCEAN_CHAIN_ARBITRUM,
  OPEN_OCEAN_CHAIN_AVALANCHE,
  OPEN_OCEAN_CHAIN_BSC,
  OPEN_OCEAN_CHAIN_ETH,
  OPEN_OCEAN_CHAIN_FANTOM,
  OPEN_OCEAN_CHAIN_POLYGON,
} from "../../../../Constants/TYPES/openOceanChainNames";
import { bestValuesFunctionOpenOcean } from "../../../../redux/api/openOcean/tokenAPIs";
import { getLiquiditySourcesFunctionOpenSea } from "../../../../redux/api/swap/swapAPIs";
import {
  setRoutePathsLoading,
  setTradeDataLoading,
} from "../../../../redux/reducers/loadingData/loadingData";
import {
  setBuyPrice,
  setBuyPriceInDollars,
  setConvertedInput1Redux,
  setConvertedInput2Redux,
  setInput1ValueRedux,
  setInput2ValueRedux,
  setLiquiditySources,
  setOutTokenValueLimitOrder,
  setSellPrice,
  setSellPriceInDollars,
  setToken1,
  setToken2,
} from "../../../../redux/reducers/swapData/swapData";
import {
  setBestValues,
  updateRouterPath,
  updateRouteSteps,
  updateRouteStepV2,
  updateToken1DollarValue,
  updateToken1Name,
  updateToken1Price,
  updateToken2DollarValue,
  updateToken2Name,
  updateToken2Price,
} from "../../../../redux/reducers/tradeData/tradeData";
import store from "../../../../redux/store";
import { cancelPendingPostReq } from "../../../../services/apiServices/ApiServices";
import { nativeTokenBalance } from "../../../../services/contractServices/SwapServices";
import {
  getDecimals,
  getTokenBalanceBigList,
  getTokenBalanceFull,
} from "../../../../services/contractServices/tokenServices";
import {
  balanceFormatWithoutRoundOffCrypto,
  balanceFormatWithoutRoundOffCryptoForMax,
  decimalFormatWithoutRoundOffCrypto,
  decimalFormatWithRoundOffDollar,
  exponentialToDecimal,
} from "../../../../services/helpers/swapHelpers";
import { getModifiedTokenSymbol } from "../../../../services/helpers/tokenListHelper";
import { callWeb3 } from "../../../../services/walletServices";
import { subtractOne } from "../../../../utils";
import { PrimaryButton } from "../../../Button";
import SelectTokenModal from "../../../Common/CommonModals/SelectTokenModal/SelectTokenModal";
import ConnectWallet from "../../../Common/ConnectWallet/ConnectWallet";
import CustomShimmer from "../../../Common/CustomShimmer/CustomShimmer";
import SwapButton from "../../../Common/SwapButton/SwapButton";
import TooltipCustom from "../../../Common/TooltipCustom/TooltipCustom";
import { HeaderText, Text } from "../../../Text";
import "./swap.scss";

interface SwapType {
  fromMain?: boolean;
}

const Swap = ({
  isCoversionHide,
  showHalfPriceButton,
  fromMain,
  handleLimitPriceChange,
}: any) => {
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  const currentPath = window.location.pathname;
  const updateTradeData: any = useSelector(
    (state: any) => state.tradeData.updateData,
  );

  const limitPriceLoading = useSelector(
    (state: any) => state.loadingData.limitPriceLoading,
  );

  const limitPrice = useSelector(
    (state: any) => state.limitOrderData.limitPrice,
  );

  const chainID: any = useSelector((state: any) =>
    state.networkSlice.ChainID?.toString(),
  );

  const tokenCollection: any = useSelector(
    (state: any) => state?.tokenCollection?.tokenCollection,
  );

  const allTokens: any = useSelector(
    (state: any) => state?.tokenCollection?.allOpenOceantokens,
  );

  let select2Tokens = allTokens;

  if (currentPath === "/limit-order") {
    // List of symbols to filter by
    const symbolsToFilter = ["USDT", "DAI", "USDC", "BUSD"];

    // Filter items based on the "symbol" property
    select2Tokens = allTokens?.filter((item: any) =>
      symbolsToFilter.includes(item.symbol),
    );
  }

  const initialLimitPrice = useSelector(
    (state: any) => state.limitOrderData.initialLimitPrice,
  );

  const limitAction = useSelector((state: any) => state.limitOrderData.action);

  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);

  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );

  const [userTokenOneBalance, setUserTokenOneBalance] = useState(0);
  const [tokenOneDisplayBalance, setTokenOneDisplayBalance] = useState<any>(0);
  const [tokenOneHoverBalance, setTokenOneHoverBalance] = useState<any>(0);
  const [tokenBalanceWithDecimals, setTokenBalanceWithDecimals] = useState(0);

  const selectedToken1 = useSelector((state: any) => state.swapData.token1);
  const selectedToken2 = useSelector((state: any) => state.swapData.token2);

  const [tokenListWithBalance, setTokenListWithBalance] = useState();
  // const [tokenListWithBalance, setTokenListWithBalance] = useState<any>([]);

  const input1 = useSelector((state: any) => state.swapData.input1Value);

  const swapDataInput2Value = useSelector(
    (state: any) => state.swapData.input2Value,
  );

  const input2 = swapDataInput2Value;

  const showOrderExecutionWarning = useSelector(
    (state: any) => state.limitOrderData.showOrderExecutionWarning,
  );

  // convertedInput1 & convertedInput2 is used while exectuing swap (swap button)
  const convertedInput1 = useSelector(
    (state: any) => state.swapData.convertedInput1Redux,
  );
  const convertedInput2 = useSelector(
    (state: any) => state.swapData.convertedInput2Redux,
  );

  const [bestValuePerToken, setBestValuePerToken] = useState<any>("");

  const [swapType, setSwapType] = useState<any>();

  const [token1DollarValue, setToken1DollarValue] = useState<any>("");
  const [token2DollarValue, setToken2DollarValue] = useState<any>("");

  const [token1Price, setToken1Price] = useState<any>("");
  const [token2Price, setToken2Price] = useState<any>("");

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [opData, setOpData] = useState<any>();
  const [intervalId, setIntervalId] = useState();

  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);

  const handleShow1 = () => setShowModal1(true);
  const handleShow2 = () => setShowModal2(true);

  const onInit = async () => {
    dispatch(setToken1(tokenCollection[0]));
    dispatch(setToken2(tokenCollection[1]));
  };

  const calculatebestValueForOneInputToken = () => {
    const output = Number(input2) / Number(input1);
    const toDecimal = exponentialToDecimal(output);
    setBestValuePerToken(toDecimal);
  };

  useEffect(() => {
    const typeOfSelecetTokenOne = typeof selectedToken1;
    if (
      typeOfSelecetTokenOne === "string" ||
      typeOfSelecetTokenOne === "undefined"
    ) {
      dispatch(setToken1(tokenCollection[0]));
      if (currentPath !== "/limit-order") {
        dispatch(setToken2(tokenCollection[1]));
      } else {
        if (select2Tokens) {
          dispatch(setToken2(select2Tokens[0]));
        }
      }
    }
  }, [tokenCollection]);

  useEffect(() => {
    dispatch(setToken1(tokenCollection[0]));
    if (currentPath !== "/limit-order") {
      dispatch(setToken2(tokenCollection[1]));
    } else if (select2Tokens) {
      dispatch(setToken2(select2Tokens[0]));
    }
  }, [chainID]);

  const handleSwapType = () => {
    if (selectedToken1?.isNative) setSwapType(NATIVE_WITH_TOKEN);
    else setSwapType(TOKEN_WITH_TOKEN);
  };

  const fetchLiquiditySources = async () => {
    const result = await getLiquiditySourcesFunctionOpenSea(chainID, dispatch);

    const dexArray: any = [];
    if (result) {
      await result.data.map(async (dexlist: any) => {
        await dexArray.push({ Name: dexlist.name });
      });

      if (result?.data?.length) dispatch(setLiquiditySources(dexArray));
    }
  };

  const fetchTokenOneBalance = async () => {
    if (!address) return;
    let tokenOneAddress: any;
    let tknOneDecimals: any;
    let tokenBalance: any;

    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.filter(
      (a: any) => a?.symbol === "WETH",
    );

    if (selectedToken1?.isNative && currentPath !== "/limit-order") {
      tokenOneAddress = wethData[0]?.address;

      tknOneDecimals = await getDecimals(tokenOneAddress);
      const nativeTokenBal: any = await nativeTokenBalance(address);

      tokenBalance = nativeTokenBal;
      setTokenBalanceWithDecimals(tokenBalance);

      setTokenOneDisplayBalance(
        Number(nativeTokenBal) / 10 ** Number(tknOneDecimals),
      );

      setTokenOneHoverBalance(
        Number(nativeTokenBal) / 10 ** Number(tknOneDecimals),
      );
    } else {
      tokenOneAddress = selectedToken1?.address;
      tknOneDecimals = await getDecimals(tokenOneAddress);

      const rawTokenBalance = await getTokenBalanceFull(
        address,
        tokenOneAddress,
      );

      tokenBalance = rawTokenBalance;

      setUserTokenOneBalance(tokenBalance ** (10 ** Number(tknOneDecimals)));
      setTokenOneDisplayBalance(tokenBalance);
      return;
    }

    setUserTokenOneBalance(tokenBalance);
  };

  const fetchTokenBalance = async (
    isNative: any,
    tokenAddress: any,
    tokenDecimals: any,
  ) => {
    if (!address) return;
    let tokenOneAddress;
    let tokenBalance;
    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.filter(
      (a: any) => a?.symbol === "WETH",
    );

    if (isNative && currentPath !== "/limit-order") {
      tokenOneAddress = wethData[0]?.address;
      const tokenDecimals: any = await getDecimals(tokenOneAddress);
      const nativeTokenBal: any = await nativeTokenBalance(address);
      tokenBalance = Number(nativeTokenBal) / 10 ** Number(tokenDecimals);
    } else {
      tokenOneAddress = tokenAddress;
      tokenBalance = await getTokenBalanceBigList(
        address,
        tokenOneAddress,
        tokenDecimals,
      );
    }
    return tokenBalance;
  };

  const prepareTokenListWithBalance = async () => {
    let tempList: any = [];
    for (const token of tokenCollection) {
      // need to fix later : fetch token balances of long list
      let result = await fetchTokenBalance(
        token?.isNative,
        token?.address,
        token?.decimals,
      );

      const newObj = Object.assign({ balance: Number(result) }, token);
      tempList.push(newObj);
    }
    setTokenListWithBalance(tempList);
  };

  const updateTradeValuesFunction = async () => {
    const latestSelectedtoken1 = store.getState().swapData.token1;
    const latestSelectedtoken2 = store.getState().swapData.token2;
    dispatch(updateToken1Name(latestSelectedtoken1?.symbol));
    dispatch(updateToken2Name(latestSelectedtoken2?.symbol));
    dispatch(updateToken1DollarValue(token1DollarValue));
    dispatch(updateToken2DollarValue(token2DollarValue));

    dispatch(updateToken1Price(token1Price));
    dispatch(updateToken2Price(token2Price));
  };

  const handleChangeOne = async (inputValue: any) => {
    const value = String(inputValue);

    let values: any;
    const maxLength = 18;
    const regexHere = /^(\d+)?([.,]?\d{0,6})?$/;
    const isInputValid = regexHere.test(value);

    if (isInputValid) {
      values = value.replace(",", ".");
    } else {
      return;
    }

    if (values?.length <= maxLength) {
    } else if (values?.length > maxLength) {
      return;
    }

    if (values?.toString().charAt(0) === ".") {
      values = "0" + values;
    }

    dispatch(setInput1ValueRedux(values));
    const latestSelectedtoken1 = store.getState().swapData.token1;
    const latestSelectedtoken2 = store.getState().swapData.token2;
    dispatch(updateToken1Name(latestSelectedtoken1?.symbol));
    dispatch(updateToken2Name(latestSelectedtoken2?.symbol));
    if (Number(values) <= 0 || values === "") {
      dispatch(setConvertedInput1Redux(""));
      dispatch(setInput2ValueRedux(""));
      dispatch(setConvertedInput2Redux(""));
    } else {
      dispatch(setRoutePathsLoading(true));
      fetchInput2Value(values);
    }
  };

  const getTokenDetails = async (address: any) => {
    const chainId = store.getState().networkSlice.ChainID.toString();
    const data = {
      tokenAddress: address,
      chainId: chainId,
    };
    try {
      // TODO get token details from data above
      const res: any = false;
      if (res?.status === 200) {
        const tokenData: any = {
          symbol: res?.token?.symbol,
          icon: res?.token?.icon,
        };
        return tokenData;
      } else if (res?.status === 400) {
        return "";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInput2Value = async (values: any) => {
    let tokenAddressOne;
    let tokenAddressTwo;
    let tokenSymbolOne;
    let tokenSymbolTwo;

    const contractCollection =
      store.getState().contractCollection.contractCollection;
    const wethData: any = contractCollection.filter(
      (a: any) => a?.symbol == "WETH",
    );
    // we are fetching latest state of selectedToken1 as interval will
    // pick initial provided value every time
    const latestSelectedtoken1 = store.getState().swapData.token1;
    // const isNative = await isNativeToken(latestSelectedtoken1.address);

    tokenAddressOne = latestSelectedtoken1?.address;
    tokenSymbolOne = latestSelectedtoken1?.symbol;
    // }

    // const tokenInDecimals = await getDecimals(tokenAddressOne);
    const tokenInDecimals = latestSelectedtoken1?.decimals;

    const cv1: any = values * 10 ** Number(tokenInDecimals);

    // we are fetching latest state of selectedToken2 as interval will
    // pick initial provided value every time
    const latestSelectedtoken2 = store.getState().swapData.token2;

    if (latestSelectedtoken2?.isNative) {
      tokenAddressTwo = wethData[0]?.address;
      tokenSymbolTwo = "WETH";
    } else {
      tokenAddressTwo = latestSelectedtoken2?.address;
      tokenSymbolTwo = latestSelectedtoken2?.symbol;
    }

    dispatch(setConvertedInput1Redux(cv1));

    if (tokenAddressOne && tokenAddressTwo) {
      // we will pass this data to swap
      const data = {
        inputAmount: cv1,
        tokens: [tokenAddressOne, tokenAddressTwo],
      };

      setOpData(data); // we are using this data later at the time of executing swap

      const slippageTolerance = store.getState().swapData.slippageTolerance;
      const web3 = await callWeb3();

      const gasPriceResult = await web3.eth.getGasPrice();

      const estimatedGasPrice = web3.utils.fromWei(gasPriceResult, "Gwei"); // need to check later :::: "Gwei" or "ether"

      const payload = {
        inTokenAddress: tokenAddressOne,
        outTokenAddress: tokenAddressTwo,
        amount: Number(values),
        gasPrice: estimatedGasPrice,
        slippage: slippageTolerance,
      };

      const currentNetwork = store.getState().networkSlice.currentNetwork;

      let result: any;
      switch (currentNetwork) {
        case EHTEREUM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_ETH,
            payload,
          );
          break;

        case BNB_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_BSC,
            payload,
          );
          break;

        case POLYGON_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_POLYGON,
            payload,
          );
          break;

        case AVALANCHE_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_AVALANCHE,
            payload,
          );
          break;

        case ARBITRUM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_ARBITRUM,
            payload,
          );
          break;

        case FANTOM_NETWORK:
          result = await bestValuesFunctionOpenOcean(
            OPEN_OCEAN_CHAIN_FANTOM,
            payload,
          );
          break;

        default:
          break;
      }

      const latestInput1Val = store.getState().swapData.input1Value;

      const dollarWorthToken1 = selectedToken1?.usd;

      if (result) {
        const allRoutes = result?.path?.routes;
        const tempRoutes: any = [];
        for (let index = 0; index < allRoutes?.length; index++) {
          const element = allRoutes[index];

          for (let index2 = 0; index2 < element?.subRoutes?.length; index2++) {
            const element2 = element?.subRoutes[index2];
            const tokenData: any = await getTokenDetails(element2.to);
            element2.toName = tokenData?.symbol;
            element2.toIcon = tokenData?.icon;
          }
          tempRoutes.push(element);
        }

        dispatch(updateRouteStepV2(tempRoutes));

        dispatch(updateRouteSteps(result?.path?.routes?.length));
        dispatch(setRoutePathsLoading(false));

        const dollarWorthToken2 = selectedToken2?.usd;

        // Fetch the best output value for token 2
        const rawValue = result.outAmount;
        const tokenOutDecimals = result.outToken.decimals;

        dispatch(setConvertedInput2Redux(rawValue));

        const resultoutput = rawValue / 10 ** Number(tokenOutDecimals);

        const convertedResultOutput = exponentialToDecimal(resultoutput);

        const latestInput2Val = Number(convertedResultOutput);

        // value that is making it 0
        setToken2DollarValue(
          Number(latestInput2Val) * Number(dollarWorthToken2),
        );
        dispatch(
          setOutTokenValueLimitOrder(
            Number(latestInput2Val) * Number(dollarWorthToken2),
          ),
        );

        dispatch(
          setBuyPrice(
            exponentialToDecimal(
              Number(latestInput1Val) / Number(latestInput2Val),
            ),
          ),
        );
        dispatch(
          setSellPrice(
            exponentialToDecimal(
              Number(latestInput2Val) / Number(latestInput1Val),
            ),
          ),
        );

        dispatch(
          setBuyPriceInDollars(
            exponentialToDecimal(
              (Number(latestInput1Val) / Number(latestInput2Val)) *
                Number(dollarWorthToken1),
            ),
          ),
        );
        dispatch(
          setSellPriceInDollars(
            exponentialToDecimal(
              (Number(latestInput2Val) / Number(latestInput1Val)) *
                Number(dollarWorthToken2),
            ),
          ),
        );

        const truncatedResultOutput = Number(convertedResultOutput)?.toFixed(4);

        dispatch(setInput2ValueRedux(truncatedResultOutput));

        dispatch(setTradeDataLoading(false));
      } else {
        dispatch(setBestValues(""));
        setToken2DollarValue("");
        dispatch(setOutTokenValueLimitOrder(""));
        setToken1Price("");
        setToken2Price("");
        dispatch(updateRouterPath([]));
        dispatch(updateRouteSteps(0));
        dispatch(setTradeDataLoading(false));
      }
    }
  };

  const switchTokens = async () => {
    dispatch(setToken1(selectedToken2));
    dispatch(setToken2(selectedToken1));
    dispatch(setInput1ValueRedux(input2));
  };

  useEffect(() => {
    if (currentPath !== "/limit-order") {
      const id: any = setInterval(async () => {
        const latestInput1Val = store.getState().swapData.input1Value;
        await handleChangeOne(latestInput1Val);
      }, 20000);
      setIntervalId(id);
      return () => {
        clearInterval(id);
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    fetchLiquiditySources();
  }, [chainID]);

  useEffect(() => {
    if (input1 === 0) {
      setToken1DollarValue("0");
      setToken2DollarValue("0");
      dispatch(setOutTokenValueLimitOrder(""));
    }
    setToken1DollarValue(Number(selectedToken1?.usd) * Number(input1));
  }, [input1, selectedToken1]);

  useEffect(() => {
    dispatch(setTradeDataLoading(true));
    setToken2DollarValue(Number(selectedToken2?.usd) * Number(input2));
    dispatch(setTradeDataLoading(false));
  }, [input2, selectedToken2]);

  useEffect(() => {
    fetchTokenOneBalance();
    prepareTokenListWithBalance();
  }, [address]);

  useEffect(() => {
    prepareTokenListWithBalance();
  }, [tokenCollection]);

  useEffect(() => {
    updateTradeValuesFunction();
    fetchTokenOneBalance();
    calculatebestValueForOneInputToken();
  }, [
    token1DollarValue,
    input2,
    updateTradeData,
    tokenCollection,
    selectedToken1,
  ]);

  // To update trade data on handleSelecToken
  useEffect(() => {
    handleSwapType();
    handleChangeOne(input1);
  }, [selectedToken1, selectedToken2]);

  // Fetching input2 value(bestvalue) might take some time, so incase while fetching
  // input2 value if user clears the input field we should cancel the ongoing request,
  // set other values to 0 and show loading as true
  useEffect(() => {
    if (Number(input1) <= 0 || (input1 === "" && tradeDataLoading)) {
      cancelPendingPostReq();
      dispatch(setConvertedInput1Redux(""));
      dispatch(setInput2ValueRedux(""));
      dispatch(setConvertedInput2Redux(""));
      dispatch(setRoutePathsLoading(true));
    }
  }, [tradeDataLoading]);

  useEffect(() => {
    const latestInput1Val = store.getState().swapData.input1Value;
    handleChangeOne(latestInput1Val);
  }, [updateTradeData]);

  useEffect(() => {
    const latestInput1Val = store.getState().swapData.input1Value;
    handleChangeOne(latestInput1Val);
  }, [updateTradeData]);

  // if useMax is true then max possible amount will be used, otherwise amount will be halved
  const enterInputOneMax = async (amount: any, useMax: boolean) => {
    // aggressive gas prices for different chains
    let aggressiveGasPrice: any;
    switch (chainID) {
      case "1":
        aggressiveGasPrice = ETHEREUM_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;
      case "56":
        aggressiveGasPrice = BNB_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;
      case "137":
        aggressiveGasPrice = POLYGON_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;
      case "43114":
        aggressiveGasPrice = AVALANCHE_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;
      case "42161":
        aggressiveGasPrice = ARBITRUM_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;
      case "250":
        aggressiveGasPrice = FANTOM_AGGRESSIVE_GAS * MULTIPLIER_FACTOR;
        break;

      default:
        break;
    }

    const MaxGasFee = aggressiveGasPrice;

    const decimatedAmount = amount * 10 ** Number(selectedToken1.decimals);

    // if user balance is less than MaxGasFee set the max amount to 0
    let safeAmount: any;

    if (selectedToken1.isNative) {
      if (decimatedAmount <= MaxGasFee) {
        safeAmount = 0;
      } else {
        if (useMax) {
          safeAmount = decimatedAmount - MaxGasFee;
        } else {
          safeAmount = decimatedAmount / 2;
        }
      }
      await handleChangeOne(
        balanceFormatWithoutRoundOffCryptoForMax(
          safeAmount / 10 ** Number(selectedToken1.decimals),
        ),
      );
    } else {
      if (useMax) {
        await handleChangeOne(
          subtractOne(balanceFormatWithoutRoundOffCryptoForMax(Number(amount))),
        );
      } else {
        await handleChangeOne(
          balanceFormatWithoutRoundOffCryptoForMax(Number(amount / 2)),
        );
      }
    }
  };

  useEffect(() => {
    if (tokenCollection.length > 0) {
      dispatch(setToken1(tokenCollection[0]));
      if (currentPath !== "/limit-order") {
        dispatch(setToken2(tokenCollection[1]));
      } else if (select2Tokens) {
        dispatch(setToken2(select2Tokens[0]));
      }
    }
  }, [tokenCollection]);

  useEffect(() => {
    if (currentPath === "/limit-order" && select2Tokens) {
      dispatch(setToken2(select2Tokens[0]));
    }
  }, []);

  const SwapToComponent = (
    <div className="swap-to">
      <div className="swap-to-dropdown">
        <Row
          align={"middle"}
          gutter={4}
          onClick={handleShow2}
          className={"swap_from_content"}
          style={{ gap: "8px", cursor: "pointer" }}
        >
          <Col>
            {tradeDataLoading ? (
              <div className="height_adjust">
                <CustomShimmer width={60} height={35} light />
              </div>
            ) : (
              <img
                className="SwapIcon"
                src={selectedToken2?.icon}
                alt="icons"
                width={"36px"}
                height={"36px"}
              />
            )}
          </Col>
          <Col style={{ display: "grid", justifyItems: "start" }}>
            <Text fontSize={12} color={colors.secondary500}>
              Swap to
            </Text>
            <Row align={"middle"} gutter={8}>
              <Col>
                <HeaderText
                  fontSize={18}
                  className={"clickable"}
                  onClick={handleShow2}
                >
                  {tradeDataLoading ? (
                    <div className="height_adjust">
                      <CustomShimmer width={100} height={15} light />
                    </div>
                  ) : (
                    getModifiedTokenSymbol(
                      selectedToken2?.symbol,
                      selectedToken2?.isNative,
                      currentPath,
                    )
                  )}
                  {"  "}
                </HeaderText>
              </Col>
              <Col className={"swap-svg"}>
                <DownIcon />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="swap-from-amount">
        {tradeDataLoading ? (
          <div className="height_adjust">
            <CustomShimmer width={160} height={15} light />
          </div>
        ) : (
          <input
            style={{
              border: "none",
              textAlign: "end",
              backgroundColor: "none !important",
            }}
            disabled
            value={input2}
            // value={`${input2?.split(".")[0]}.${input2?.split(".")[1]?.slice(0,6)}`}
            type="text"
            className="form-control"
            placeholder="0.00"
          />
        )}
        <Row align={"middle"} gutter={4} justify={"end"}>
          <Col>
            <Text fontSize={12} color={colors.secondary500}>
              ~$
            </Text>
          </Col>
          <Col>
            {tradeDataLoading ? (
              <CustomShimmer width={45} height={12} light />
            ) : (
              <Text fontSize={12} color={colors.secondary500}>
                {decimalFormatWithRoundOffDollar(
                  Number(selectedToken2?.usd) * Number(input2),
                )}
              </Text>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );

  const TokenValueDetails = (
    <div>
      {!isCoversionHide && (
        <div>
          <span style={{ color: `${colors.secondary500}` }}>1</span>
          <strong
            className="ms-2 me-1"
            style={{ color: `${colors.secondary500}` }}
          >
            {selectedToken1?.symbol}
          </strong>{" "}
          <span style={{ color: `${colors.secondary500}` }}> = </span>
          <span
            className="token_buyPrice mx-2 tooltip_container"
            style={{ color: `${colors.secondary500}` }}
          >
            <span style={{ color: `${colors.secondary500}` }}>
              {tradeDataLoading || Number(input1) === 0 ? (
                <CustomShimmer width={45} height={15} />
              ) : Number(bestValuePerToken) < 0.01 ? (
                `${bestValuePerToken
                  ?.toString()
                  .slice(0, 3)}..${bestValuePerToken
                  ?.toString()
                  .slice(bestValuePerToken - 4, bestValuePerToken.length)}`
              ) : (
                decimalFormatWithoutRoundOffCrypto(Number(bestValuePerToken))
              )}
            </span>
            {Number(input1) !== 0 && (
              <p
                className="hover_tooltip"
                style={{ color: `${colors.secondary500}` }}
              >
                {bestValuePerToken}
              </p>
            )}
          </span>
          <strong style={{ color: `${colors.secondary500}` }}>
            {selectedToken2?.symbol}{" "}
          </strong>
        </div>
      )}
    </div>
  );

  const ConnectionComponent = (
    <div>
      {isConnected && currentPath !== "/limit-order" ? (
        <SwapButton
          userTokenOneBalance={userTokenOneBalance}
          tokenSelect1={selectedToken1}
          tokenSelect2={selectedToken2}
          swapType={swapType}
          className=" w-100"
          opData={opData}
          input1Amount={convertedInput1}
          input2Amount={convertedInput2}
          fetchTokenOneBalance={fetchTokenOneBalance}
          refreshTokenListBalances={prepareTokenListWithBalance}
        />
      ) : (
        <ConnectWallet className="w-100" />
      )}
    </div>
  );

  const SwapFromComponent = (
    <div className="swap-from">
      <div className="swap-from-dropdown">
        <Row
          align={"middle"}
          gutter={4}
          className={"swap_from_content"}
          onClick={handleShow1}
          style={{
            cursor: "pointer",
            gap: "8px",
          }}
        >
          <Col>
            {tradeDataLoading ? (
              <div className="height_adjust">
                <CustomShimmer width={60} height={35} light />
              </div>
            ) : (
              <img
                className="SwapIcon"
                src={selectedToken1?.icon}
                alt="icons"
                width={"36px"}
                height={"36px"}
              />
            )}
          </Col>
          <Col
            style={{
              display: "grid",
              justifyItems: "start",
            }}
          >
            <Text fontSize={12} color={colors.secondary500}>
              Swap from
            </Text>
            <Row align={"middle"} gutter={8}>
              <Col>
                <HeaderText fontSize={18} className={"clickable"}>
                  {tradeDataLoading ? (
                    <div className="height_adjust">
                      <CustomShimmer width={100} height={15} light />
                    </div>
                  ) : (
                    getModifiedTokenSymbol(
                      selectedToken1?.symbol,
                      selectedToken1?.isNative,
                      currentPath,
                    )
                  )}
                </HeaderText>
              </Col>
              <Col className={"swap-svg"}>
                <DownIcon />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="swap-from-amount">
        <input
          style={{
            border: "none",
            textAlign: "end",
          }}
          onKeyDown={(evt) => {
            evt.code === "ArrowDown" && evt.preventDefault();
            evt.code === "ArrowUp" && evt.preventDefault();
            disabledLetters.includes(evt.key) && evt.preventDefault();
          }}
          type="text"
          inputMode="decimal"
          onWheel={(e: any) => e.target.blur()}
          onChange={(e) => handleChangeOne(e.target.value)}
          value={input1}
          className="form-control"
          placeholder="0.00"
        />

        <Row align={"middle"} gutter={4} justify={"end"}>
          <Col>
            <Text fontSize={12} color={colors.secondary500}>
              ~$
            </Text>
          </Col>
          <Col>
            {tradeDataLoading ? (
              <CustomShimmer width={45} height={12} light />
            ) : (
              <Text fontSize={12} color={colors.secondary500}>
                {decimalFormatWithRoundOffDollar(Number(token1DollarValue))}
              </Text>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );

  const swapContent = (
    <Col
      className="swaps"
      xs={fromMain ? 24 : 0}
      sm={fromMain ? 24 : 0}
      md={fromMain ? 24 : 0}
      lg={fromMain ? 12 : 0}
      xl={fromMain ? 0 : 7}
      xxl={fromMain ? 0 : 7}
    >
      <Text fontSize={24} fontWeight={600} color="#FFFFFF" className="heading">
        Swap
      </Text>

      {address && (
        <div className="balance">
          <Text
            fontSize={14}
            fontWeight={500}
            color="#FFFFFF"
            className="heading"
          >
            Balance:
          </Text>
          <TooltipCustom
            text={<p className="mb-0">{tokenOneHoverBalance}</p>}
            customIcon={
              <p>
                {tokenOneDisplayBalance ? (
                  <>
                    {balanceFormatWithoutRoundOffCrypto(
                      Number(tokenOneDisplayBalance),
                    )}
                  </>
                ) : (
                  0
                )}
              </p>
            }
          />
          <PrimaryButton
            onClick={() => enterInputOneMax(tokenOneDisplayBalance, true)}
          >
            Max
          </PrimaryButton>
          {showHalfPriceButton && (
            <span
              onClick={() => enterInputOneMax(tokenOneDisplayBalance, false)}
              className="use_max_button"
            >
              HALF
            </span>
          )}
        </div>
      )}

      {SwapFromComponent}

      <div className="arrow">
        <img
          src={swapperArrowDown}
          onClick={() => {
            switchTokens();
          }}
        />
      </div>
      {SwapToComponent}
      <div style={{ marginTop: "20px" }}>{TokenValueDetails}</div>
      {ConnectionComponent}
    </Col>
  );

  console.log("isCoversionHide", isCoversionHide);

  return (
    <>
      {allTokens ? (
        <>{swapContent}</>
      ) : (
        <div
          className={"text-center"}
          style={{ marginBottom: "30px", fontSize: "20px" }}
        >
          Token list empty
        </div>
      )}
      <SelectTokenModal
        tokenList={tokenListWithBalance}
        show={showModal1}
        handleClose={handleClose1}
        type={"tk1"}
        disableToken={selectedToken2}
        allTokens={allTokens}
      />
      <SelectTokenModal
        tokenList={
          currentPath === "/limit-order" ? select2Tokens : tokenListWithBalance
        }
        show={showModal2}
        handleClose={handleClose2}
        type={"tk2"}
        disableToken={selectedToken1}
        allTokens={select2Tokens}
      />
    </>
  );
};

export default Swap;
