export const TokenExpiryFunction = (error: any) => {
  console.log("error", error);
};

export const searchFilter = (searchlist: any, getfilters: any) => {
  var result: any = [];
  for (const [key, value] of Object.entries(getfilters)) {
    switch (key) {
      case "searchVault":
        if (value == "") {
          result = searchlist;
        } else {
          result = searchlist.filter((item: any) => {
            return item.altSymbol.includes(getfilters.searchVault) && item;
          });
        }
        break;
      case "ftype":
        if (value != "All") {
          result = result.filter((item: any) => {
            if (value == "Single Asset" && item.isLpToken == false) {
              return item;
            } else if (value == "Liquidity Asset" && item.isLpToken == true) {
              return item;
            }
          });
        } else {
          return result;
        }
        break;
    }
  }

  return result;
};
