import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($userData: LoginInput!) {
    login(userData: $userData) {
      accessToken
      refreshToken
    }
  }
`;

export const REGISTER = gql`
  mutation Register($registerInputData: RegisterInput!) {
    register(registerInputData: $registerInputData)
  }
`;

export const ADD_COIN = gql`
  mutation AddCoin($newCoinData: NewCoinInput!) {
    addCoin(newCoinData: $newCoinData) {
      id
      name
      symbol
      address
      decimals
      network
      createdAt
      updatedAt
      coinInfo {
        id
        cmcId
        name
        symbol
        slug
        num_market_pairs
        tags
        max_supply
        circulating_supply
        total_supply
        cmc_rank
        infinite_supply
        is_active
        is_fiat
        createdAt
        updatedAt
        platform {
          id
          token_address
          name
          symbol
          slug
        }
        quote {
          USD {
            currency
            price
            volume_24h
            volume_change_24h
            percent_change_1h
            percent_change_24h
            percent_change_7d
            percent_change_30d
            percent_change_60d
            percent_change_90d
            market_cap
            market_cap_dominance
            fully_diluted_market_cap
            tvl
            last_updated
          }
        }
      }
    }
  }
`;

export const REMOVE_COIN_BY_ID = gql`
  mutation RemoveCoin($removeCoinId: Float!) {
    removeCoin(id: $removeCoinId)
  }
`;
