import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowTransactionModal,
  setSwapTransactionLoading,
} from "../../../../redux/reducers/loadingData/loadingData";
import { decimalFormatWithRoundOffDollar } from "../../../../services/helpers/swapHelpers";
import { getModifiedTokenSymbol } from "../../../../services/helpers/tokenListHelper";
import { roundToFiveDecimalPlaces } from "../../../../utils";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CustomShimmer from "../../CustomShimmer/CustomShimmer";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import "./ReviewOrderModal.scss";

const ReviewOrderModal = ({
  handleClose,
  show,
  selectedTimePeriod,
  createLimitOrder,
  token1DollarValue,
  token2DollarValue,
  priceLoading,
}: any) => {
  const dispatch = useDispatch();

  const limitTimePeriodoptions: any = [
    { value: "10M", label: "10 Minutes" },
    { value: "1H", label: "1 Hour" },
    { value: "1D", label: "1 Day" },
    { value: "3D", label: "3 Days" },
    { value: "7D", label: "7 Days" },
    { value: "30D", label: "30 Days" },
    { value: "1Month", label: "1 Month" },
    { value: "3Month", label: "3 Months" },
    { value: "6Month", label: "6 Months" },
    { value: "1Y", label: "1 Year" },
  ];

  const selectedtoken1 = useSelector((state: any) => state.swapData.token1);
  const selectedtoken2 = useSelector((state: any) => state.swapData.token2);

  const currentPath = window.location.pathname;
  const token1DollarValueRedux = useSelector(
    (state: any) => state.tradeData.token1DollarValue,
  );
  const outTokenValueLimitOrder = useSelector(
    (state: any) => state.swapData.outTokenValueLimitOrder,
  );

  const tradeDataLoading = useSelector(
    (state: any) => state.loadingData.tradeDataLoading,
  );
  const limitPrice = useSelector(
    (state: any) => state.limitOrderData.limitPrice,
  );

  const limitAmount = useSelector((state: any) => state.limitOrderData.amount);

  const limitValue = useSelector((state: any) => state.limitOrderData.value);

  const limitAction = useSelector((state: any) => state.limitOrderData.action);

  const input1 = useSelector((state: any) => state.swapData.input1Value);

  const input2LimitOrder = useSelector(
    (state: any) => state.swapData.input2Value,
  );

  const confirmLimitOrder = async (e: any) => {
    e.preventDefault();
    try {
      createLimitOrder();
      dispatch(setSwapTransactionLoading(true));
      dispatch(setShowTransactionModal(true));
      handleClose();
    } catch (error) {
      console.error("Error in createLimitOrder:", error);
    }
  };

  return (
    <Modal
      className="Common_Modal review_order_modal"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="confirm_token">
          <ul>
            <li>
              <div className="confirm_token_title">
                <span>{limitAction}</span>
              </div>
              <div className="confirm_token_value">
                <img
                  className="SwapIcon"
                  src={selectedtoken1?.logoURI}
                  alt="icons"
                />
                {getModifiedTokenSymbol(
                  selectedtoken1?.symbol,
                  selectedtoken1 ? selectedtoken1?.isNative : false,
                  currentPath,
                )}{" "}
              </div>
            </li>
            <li>
              <div className="confirm_token_title">
                <span>
                  ~$
                  {tradeDataLoading || priceLoading ? (
                    <CustomShimmer width={45} height={15} />
                  ) : (
                    decimalFormatWithRoundOffDollar(
                      Number(token1DollarValue || 1) * Number(limitAmount),
                    )
                  )}
                </span>
              </div>
              <div className="confirm_token_value">
                <span>
                  {tradeDataLoading ? (
                    <CustomShimmer width={45} height={15} />
                  ) : (
                    roundToFiveDecimalPlaces(limitAmount)
                  )}
                </span>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <div className="confirm_token_title">
                <span>{limitAction === "Buy" ? "Sell" : "Buy"}</span>
              </div>
              <div className="confirm_token_value">
                <img
                  className="SwapIcon"
                  src={selectedtoken2?.logoURI}
                  alt="icons"
                />
                {getModifiedTokenSymbol(
                  selectedtoken2?.symbol,
                  selectedtoken2 ? selectedtoken2?.isNative : false,
                  currentPath,
                )}{" "}
              </div>
            </li>
            <li>
              <div className="confirm_token_title">
                <span>
                  ~$
                  {tradeDataLoading || priceLoading ? (
                    <CustomShimmer width={45} height={15} />
                  ) : (
                    decimalFormatWithRoundOffDollar(
                      Number(token2DollarValue || 1) * Number(limitValue),
                    )
                  )}
                </span>
              </div>
              <div className="confirm_token_value">
                <span>
                  {tradeDataLoading ? (
                    <CustomShimmer width={45} height={15} />
                  ) : (
                    roundToFiveDecimalPlaces(limitValue)
                  )}
                </span>
              </div>
            </li>
          </ul>
        </div>

        <ul className="confirm_list">
          <li>
            <span>Limit Price </span>
            <span>
              1{" "}
              {getModifiedTokenSymbol(
                selectedtoken1?.symbol,
                selectedtoken1 ? selectedtoken1?.isNative : false,
                currentPath,
              )}
              = {limitPrice}{" "}
              {getModifiedTokenSymbol(
                selectedtoken2?.symbol,
                selectedtoken2 ? selectedtoken2.isNative : false,
                currentPath,
              )}
            </span>
          </li>
          <li>
            <span>{limitAction} Amount </span>
            <span>
              {roundToFiveDecimalPlaces(limitAmount)}{" "}
              {getModifiedTokenSymbol(
                selectedtoken1?.symbol,
                selectedtoken1?.isNative,
                currentPath,
              )}
              {"  "}({roundToFiveDecimalPlaces(limitValue)}){" "}
              {getModifiedTokenSymbol(
                selectedtoken2 ? selectedtoken2?.symbol : false,
                selectedtoken2 ? selectedtoken2?.isNative : false,
                currentPath,
              )}
            </span>
          </li>
          <li>
            <span>Expires In </span>
            <span>
              <DropdownCustom
                placeHolder={selectedTimePeriod}
                options={limitTimePeriodoptions}
              />
            </span>
          </li>
          {/* <li>
            <span>Est. execution price </span>
            <span>~ 1 WBNB = 242.1155551 BUSD</span>
          </li> */}
        </ul>

        <ButtonCustom
          className=""
          style={{ marginRight: "12px" }}
          onClick={(e: any) => confirmLimitOrder(e)}
          title="Confirm order"
        />

        <ButtonCustom
          className="w-100"
          onClick={() => handleClose()}
          title="Cancel"
        />
      </Modal.Body>
    </Modal>
  );
};

export default ReviewOrderModal;
