import { createSlice } from "@reduxjs/toolkit";
import { SEPOLIAContracts } from "../../../Assets/Collections/ContractCollection/TestnetContracts";
import { EthMainnetContracts } from "../../../Assets/Collections/ContractCollection/MainnetContracts";

let initialContractCollection: any = [];
const currentEnvironment = process.env.REACT_APP_NODE_ENV;
switch (currentEnvironment) {
  case "development":
    initialContractCollection = SEPOLIAContracts;
    break;
  case "production":
  case "qa":
    initialContractCollection = EthMainnetContracts;
    break;
  default:
    break;
}

export const contractCollectionSlice: any = createSlice({
  name: "contractCollection",
  initialState: {
    contractCollection: initialContractCollection,
  },
  reducers: {
    resetContractCollectionSlice: (state: any, action) => {
      state.contractCollection = initialContractCollection;
    },
    setContractCollection: (state: any, action: any) => {
      state.contractCollection = action.payload;
    },
  },
});

export const { setContractCollection, resetContractCollectionSlice } =
  contractCollectionSlice.actions;

export default contractCollectionSlice.reducer;
