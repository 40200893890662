import "./ButtonCustom.scss";
import { PrimaryButton } from "../../Button";

const ButtonCustom = ({
  title,
  className,
  disabled,
  onClick,
  ...rest
}: any) => {
  return (
    <PrimaryButton disabled={disabled} onClick={onClick} {...rest}>
      {title}
    </PrimaryButton>
  );
};

export default ButtonCustom;
