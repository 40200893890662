import { createSlice } from "@reduxjs/toolkit";
import { SepoliaCurrentToken } from "../../../Assets/Collections/CurrentChainToken/Testnet";
import { EthMainnetCurrentToken } from "../../../Assets/Collections/CurrentChainToken/Mainnet";

let initialCurrentChainToken: any = [];
const currentEnvironment = process.env.REACT_APP_NODE_ENV;
switch (currentEnvironment) {
  case "development":
    initialCurrentChainToken = SepoliaCurrentToken;
    break;
  case "production":
  case "qa":
    initialCurrentChainToken = EthMainnetCurrentToken;
    break;
  default:
    break;
}

export const currentChainTokenSlice: any = createSlice({
  name: "tokenCollection",
  initialState: {
    currentChainToken: initialCurrentChainToken,
  },
  reducers: {
    resetCurrentChainTokenSlice: (state: any, action) => {
      state.currentChainToken = initialCurrentChainToken;
    },
    setCurrenChainToken: (state: any, action: any) => {
      state.currentChainToken = action.payload;
    },
  },
});

export const { setCurrenChainToken, resetCurrentChainTokenSlice } =
  currentChainTokenSlice.actions;

export default currentChainTokenSlice.reducer;
