import { createSlice } from "@reduxjs/toolkit";
import { LimitOrderData } from "../../../Constants/Interfaces/LimitOrderData";

const initialState: LimitOrderData = {
  tokenOutAmount: 0,
  limitPrice: 1,
  orderExpireDate: "7D",
  showOrderExecutionWarning: true,
  initialLimitPrice: 0,
  amount: 1,
  value: 1,
  action: "Buy",
};

export const limitOrderData = createSlice({
  name: "limitOrderData",
  initialState,
  reducers: {
    resetLimitOrderDataSlice: (state: any, action) => {
      state.tokenOutAmount = 0;
      state.limitPrice = 1;
      state.orderExpireDate = "7D";
      state.showOrderExecutionWarning = true;
      state.initialLimitPrice = 0;
      state.amount = 1;
      state.value = 1;
      state.action = "Buy";
    },
    setTokenOutAmount: (state, action) => {
      state.tokenOutAmount = action.payload;
    },
    setLimitOrderAction: (state, action) => {
      state.action = action.payload;
    },
    setLimitOrderAmount: (state, action) => {
      state.amount = action.payload;
    },
    setLimitOrderValue: (state, action) => {
      state.value = action.payload;
    },
    setLimitPrice: (state, action) => {
      state.limitPrice = action.payload;
    },
    setOrderExpireDate: (state, action) => {
      state.orderExpireDate = action.payload;
    },
    setShowOrderExecutionWarning: (state, action) => {
      state.showOrderExecutionWarning = action.payload;
    },
    setInitialLimitPrice: (state, action) => {
      state.initialLimitPrice = action.payload;
    },
  },
});
export const {
  resetLimitOrderDataSlice,
  setTokenOutAmount,
  setLimitPrice,
  setInitialLimitPrice,
  setOrderExpireDate,
  setShowOrderExecutionWarning,
  setLimitOrderAmount,
  setLimitOrderValue,
  setLimitOrderAction,
} = limitOrderData.actions;

export default limitOrderData.reducer;
