import { message } from "antd";
import { SITE_URL } from "../../../Constants/AppVariables/appVariable";
import { apiCallGet } from "../../../services/apiServices/ApiServices";
import { getMessageConfig } from "../../../utils";

export const getHomePageStats = async () => {
  try {
    const ENDPOINT = "/api/homepage-stats";
    const result: any = await apiCallGet(SITE_URL, ENDPOINT);
    console.log("result", result);
    if (result) {
      return result;
    }
  } catch (error: any) {
    message.error(
      getMessageConfig({
        type: "error",
        title: "Something went wrong",
        body: error.message || error.mesage,
      }),
    );
  }
};
