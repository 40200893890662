import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { DownIcon } from "../../../Assets/Svg/SvgImages";
import "./DropdownCustom.scss";
import { setOrderExpireDate } from "../../../redux/reducers/limitOrderData/limitOrderData";
import { LIMIT_ORDER_EXPIRE_DATE } from "../../../Constants/TYPES/openOceanChainNames";
import { colors } from "../../../Assets/Theme/colors";

const DropdownCustom = ({ placeHolder, options }: any) => {
  const dispatch = useDispatch();

  const getPlaceHolderValue = (value: LIMIT_ORDER_EXPIRE_DATE) => {
    let placeHolderValue;
    switch (value) {
      case "10M":
        placeHolderValue = "10 Minutes";
        break;
      case "1H":
        placeHolderValue = "1 Hour";
        break;
      case "1D":
        placeHolderValue = "1 Day";
        break;
      case "3D":
        placeHolderValue = "3 Days";
        break;
      case "7D":
        placeHolderValue = "7 Days";
        break;
      case "30D":
        placeHolderValue = "30 Days";
        break;
      case "1Month":
        placeHolderValue = "1 Month";
        break;
      case "3Month":
        placeHolderValue = "3 Month";
        break;
      case "6Month":
        placeHolderValue = "6 Month";
        break;
      case "1Y":
        placeHolderValue = "1 Year";
        break;

      default:
        break;
    }

    return placeHolderValue;
  };
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="d-flex align-items-center justify-content-between w-100 "
        >
          <span className="text-truncate d-inline-block text-start w-md-75">
            {getPlaceHolderValue(placeHolder)}
          </span>
          <DownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((option: any, index: number) => (
            <Dropdown.Item
              style={{
                borderRadius: 8,
                backgroundColor:
                  option.value === placeHolder ? colors.primary : "",
              }}
              key={index}
              //@ts-ignore
              value={option.value}
              onClick={() => {
                dispatch(setOrderExpireDate(option.value));
              }}
              href="#"
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownCustom;
