import { Card, Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useNavigate } from "react-router-dom";
import coin3d from "../../../../Assets/Images/coins3d.png";
import coinsMobile from "../../../../Assets/Images/coinsmobile.png";
import { HeaderText, Text } from "../../../Text";
import "./exploreHero.scss";

export const ExploreHero = () => {
  const { xs, sm, md, lg, xxl } = useBreakpoint();
  const navigate = useNavigate();
  return (
    <div>
      <Col span={24}>
        <Card className="explore-card">
          <Row>
            <Col
              className="text-section card-item-right"
              xs={{ span: 24, order: 3 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              xl={{ span: 13, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Row>
                <Col className="header card-col" span={24}>
                  <HeaderText
                    className="heading"
                    fontWeight={700}
                    style={{ lineHeight: "normal" }}
                  >
                    Explore SHIDO network!
                  </HeaderText>
                </Col>
                <Col span={24} className="text card-col">
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    style={{ lineHeight: "normal" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum venenatis finibus nisi, nec iaculis tellus
                    rhoncus ut. Nam at mi ligula. Vestibulum.
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 0, order: 2 }}
              md={{ span: 1, order: 2 }}
              lg={{ span: 1, order: 2 }}
              xl={{ span: 1, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            ></Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 11, order: 3 }}
              lg={{ span: 11, order: 3 }}
              xl={{ span: 10, order: 3 }}
              xxl={{ span: 6, order: 3 }}
              className="img-container card-item-left"
            >
              {xs ? <img src={coinsMobile} /> : <img src={coin3d} />}
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
};
