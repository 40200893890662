const CDN_IMAGES_URL = "https://d298434wfo2zcr.cloudfront.net/Images/";
const CDN_ICONS_URL = "https://d298434wfo2zcr.cloudfront.net/icons/";
const CDN_ANIMATIONS_URL = "https://d298434wfo2zcr.cloudfront.net/animation/";
const CDN_SVG_URL = "https://d298434wfo2zcr.cloudfront.net/Svg/";

/** IMAGES **/
export const SHIDO_ICON_DARK = CDN_IMAGES_URL + "shido_dx_dark.svg";
export const SHIDO_ICON_LIGHT = CDN_IMAGES_URL + "shido_dx_light.svg";
export const Aurora_Bridge = CDN_IMAGES_URL + "AuroraBridge.svg";
export const Avalanche_Bridge = CDN_IMAGES_URL + "AvalancheBridge.svg";
export const Klaytn_Bridge = CDN_IMAGES_URL + "KlaytnBridge.svg";
export const Fantom_Bridge = CDN_IMAGES_URL + "FantomBridge.svg";
export const AlcazarIcon = CDN_IMAGES_URL + "alcazarIcon.svg";

export const Pancakewap_Icon = CDN_IMAGES_URL + "pancakeswap.svg";
export const Uniswap_Icon = CDN_IMAGES_URL + "uniswap.svg";
export const Apeswap_Icon = CDN_IMAGES_URL + "apeswap.svg";
export const Sushiswap_Icon = CDN_IMAGES_URL + "sushiswap.svg";

export const EthIcon = CDN_IMAGES_URL + "ethicon.svg";
export const BusdIcon = CDN_IMAGES_URL + "BUSD.svg";
export const UsdtIcon = CDN_IMAGES_URL + "USDT.svg";
export const UsdcIcon = CDN_IMAGES_URL + "USDC.svg";
export const DollarMoonIcon = CDN_IMAGES_URL + "dollarmoon.svg";
export const BitriseTokenIcon = CDN_IMAGES_URL + "bitrise.svg";
export const CrogeTokenIcon = CDN_IMAGES_URL + "croge.svg";
export const FlokiIcon = CDN_IMAGES_URL + "floki.svg";
export const VerasityIcon = CDN_IMAGES_URL + "verasity.svg";
export const DogelonMarsIcon = CDN_IMAGES_URL + "dogelonmars.svg";
export const ChicoInuIcon = CDN_IMAGES_URL + "ChicoInu.svg";
export const AstralIcon = CDN_IMAGES_URL + "Astral-Portal.svg";
export const OkageIcon = CDN_IMAGES_URL + "OkageInu.svg";
export const MarvinIcon = CDN_IMAGES_URL + "MarvinInu.svg";
export const YotaIcon = CDN_IMAGES_URL + "YeartheApes.svg";
export const SnipifyIcon = CDN_IMAGES_URL + "snipify.svg";
export const FlokitaIcon = CDN_IMAGES_URL + "Flokita-Portal.svg";
export const FlockiCeoIcon = CDN_IMAGES_URL + "Floki-Ceo.svg";
export const KataIcon = CDN_IMAGES_URL + "KatanaInu.svg";
export const ShibElonIcon = CDN_IMAGES_URL + "ShibElon.svg";
export const HourglassIcon = CDN_IMAGES_URL + "Hourglass-official.svg";
export const MarleyIcon = CDN_IMAGES_URL + "MarleyToken.svg";
export const PitbullToken = CDN_IMAGES_URL + "Pitbull-Token-Official.svg";
export const DosaIcon = CDN_IMAGES_URL + "Dosa.svg";
export const Kumamon = CDN_IMAGES_URL + "Kumamon-Official.svg";
export const WalterInu = CDN_IMAGES_URL + "WalterInu.svg";
export const Ethera_Icon = CDN_IMAGES_URL + "Ethera X.png";

export const AaveIcon = CDN_IMAGES_URL + "AaveLiqSrc.png";
export const BalancerIcon = CDN_IMAGES_URL + "BalancerLiqSrc.png";
export const KyberswapIcon = CDN_IMAGES_URL + "KyberLiqSrc.png";
export const CurveIcon = CDN_IMAGES_URL + "CurveLiqSrc.png";
export const SushiswapIcon = CDN_IMAGES_URL + "SushiswapLiqSrc.png";
export const DfxIcon = CDN_IMAGES_URL + "DfxLiqSrc.png";
export const HashflowIcon = CDN_IMAGES_URL + "HashflowLiqSrc.png";
export const DodoV2Icon = CDN_IMAGES_URL + "DodoV2LiqSrc.png";
export const ClipperIcon = CDN_IMAGES_URL + "ClipperLiqSrc.png";
export const Defiswap_Icon = CDN_IMAGES_URL + "DefiswapLiqSrc.png";
export const Synthetix_Icon = CDN_IMAGES_URL + "SynthetixLiqSrc.png";
export const Frax_Icon = CDN_IMAGES_URL + "FraxLiqSrc.png";
// export const VerseDex_Icon = CDN_IMAGES_URL + "VerseDexLiqSrc.png";
export const Oneinch_Icon = CDN_IMAGES_URL + "1InchLiqSrc.png";
export const Pancakeswap_Icon = CDN_IMAGES_URL + "pancakeswapLiqSrc.png";
export const Bancor_Icon = CDN_IMAGES_URL + "BancorLiqSrc.png";
export const KyberswapElasticIcon =
  CDN_IMAGES_URL + "KyberswapelasticLiqSrc.png";
// export const Apeswap_Icon = CDN_IMAGES_URL + "ApeswapLiqSrc.png";
export const Lido2_Icon = CDN_IMAGES_URL + "Lido2LiqSrc.png";
// export const Stakewise_Icon = CDN_IMAGES_URL + "StakewiseLiqSrc.png";
// export const Synthetix_Icon = CDN_IMAGES_URL + "SynthetixLiqSrc.png";
export const Maverick_Icon = CDN_IMAGES_URL + "MaverickLiqSrc.png";
// export const Maverick_Icon = CDN_IMAGES_URL + "MaverickLiqSrc.png";

export const InuTubeIcon = CDN_IMAGES_URL + "InuTube.svg";
export const ElonGoatIcon = CDN_IMAGES_URL + "elonGoat.svg";
export const VitaInuIcon = CDN_IMAGES_URL + "vitalnu.svg";
export const RickquackIcon = CDN_IMAGES_URL + "richquack.svg";
export const DioneIcon = CDN_IMAGES_URL + "dione.svg";
export const SheikhInuIcon = CDN_IMAGES_URL + "sheikhInu.svg";
export const CultDaoIcon = CDN_IMAGES_URL + "cultDao.svg";
export const ShibaInuIcon = CDN_IMAGES_URL + "shibaInu.svg";
export const KingIcon = CDN_IMAGES_URL + "king.svg";
export const BermudaIcon = CDN_IMAGES_URL + "bermudaPortal.svg";
export const CubeBaseIcon = CDN_IMAGES_URL + "cubeBase.svg";
export const RottoIcon = CDN_IMAGES_URL + "rottokenOfficial.svg";
export const TsukaEntryPortal = CDN_IMAGES_URL + "tsukaEntryPortal.svg";
export const DaiIcon = CDN_IMAGES_URL + "DAI.svg";
export const WbtcIcon = CDN_IMAGES_URL + "WBTC.svg";
export const BnbIcon = CDN_IMAGES_URL + "bnb.svg";
export const noRecord = CDN_IMAGES_URL + "no-record.svg";
export const tokenIcon = CDN_IMAGES_URL + "benchmark-icon.svg";
export const GasRefund = CDN_IMAGES_URL + "Gas_refund.png";
export const GasIcon = CDN_IMAGES_URL + "gasicon.svg";
export const SlipIcon = CDN_IMAGES_URL + "slipicon.svg";
export const PartialIcon = CDN_IMAGES_URL + "PartialIcon.svg";
export const RouteIcon = CDN_IMAGES_URL + "router.svg";
export const modeIcon = CDN_IMAGES_URL + "compatibility.svg";

export const LegacyIcon = CDN_IMAGES_URL + "use-legacy.svg";
export const LiquidityIcon = CDN_IMAGES_URL + "liquidity.svg";
export const tokensIcon = CDN_IMAGES_URL + "custom_token.svg";

export const metamask = CDN_IMAGES_URL + "Metamask_Icons.svg";
export const walletIcon = CDN_IMAGES_URL + "Wallet_Connect_icon.svg";
export const TrustwalletIcon = CDN_IMAGES_URL + "Trust_wallet_icon.svg";
export const Ethereum = CDN_IMAGES_URL + "eth_icons.svg";
export const Binance = CDN_IMAGES_URL + "bnb-drop_icon.svg";
export const Web3Image = CDN_IMAGES_URL + "web3.svg";
export const logo = CDN_IMAGES_URL + "shido-main-icon.png";

export const simple_mode = CDN_IMAGES_URL + "simple_mode.svg";
export const classic_mode = CDN_IMAGES_URL + "classic_mode.svg";
export const p2p = CDN_IMAGES_URL + "p2p.svg";
export const limit_order = CDN_IMAGES_URL + "limit_order.svg";
export const strategies = CDN_IMAGES_URL + "strategies.svg";
export const pools = CDN_IMAGES_URL + "pools.svg";
export const farming = CDN_IMAGES_URL + "farming.svg";

export const bnb_drop = CDN_IMAGES_URL + "bnb-drop.svg";
export const polygon_icon = CDN_IMAGES_URL + "polygen.svg";
export const optimism = CDN_IMAGES_URL + "optimism.svg";
export const arbitrum = CDN_IMAGES_URL + "arbitrum.png";
export const gnosis = CDN_IMAGES_URL + "gnosis.png";
export const documentation = CDN_IMAGES_URL + "documentation.svg";
export const blog = CDN_IMAGES_URL + "blog.svg";
export const help = CDN_IMAGES_URL + "help.svg";
export const address = CDN_IMAGES_URL + "address.svg";
export const about = CDN_IMAGES_URL + "about.svg";
export const feature = CDN_IMAGES_URL + "feature.svg";
export const bug = CDN_IMAGES_URL + "bug.svg";
export const staking = CDN_IMAGES_URL + "staking.svg";
export const liquidity = CDN_IMAGES_URL + "liquidity.svg";
export const aggregation = CDN_IMAGES_URL + "aggregation.svg";
export const forum = CDN_IMAGES_URL + "forum.svg";

export const ethicon = CDN_IMAGES_URL + "ethicon.svg";
export const bnb = CDN_IMAGES_URL + "bnb.svg";

export const wbtc = CDN_IMAGES_URL + "wbtc.png";
export const filtericon = CDN_IMAGES_URL + "filtericon.svg";
export const chart = CDN_IMAGES_URL + "chart.png";

export const eth = CDN_IMAGES_URL + "eth-icon.svg";

export const editIcon = CDN_IMAGES_URL + "editicon.svg";

export const earn = CDN_IMAGES_URL + "earn.svg";
export const gas = CDN_IMAGES_URL + "gas.svg";
export const dao = CDN_IMAGES_URL + "dao.svg";

export const infoIcon1 = CDN_IMAGES_URL + "about.svg";

export const plusIcon = CDN_IMAGES_URL + "plusicon.svg";
export const RefreshIcon = CDN_IMAGES_URL + "refreshicon.svg";
export const FilterIcon = CDN_IMAGES_URL + "filtericon.svg";

export const noteIcon = CDN_IMAGES_URL + "documentation.svg";
export const infoIcon = CDN_IMAGES_URL + "about.svg";

export const swapIcon = CDN_IMAGES_URL + "ethicon.svg";
export const daiIcon = CDN_IMAGES_URL + "DAI.svg";

export const horsIcon1 = CDN_IMAGES_URL + "horsicon1.svg";
export const horsIcon2 = CDN_IMAGES_URL + "horsicon2.svg";
export const horsIcon3 = CDN_IMAGES_URL + "horsicon3.svg";

export const risk_dis = CDN_IMAGES_URL + "risk-diagnose.svg";

export const token_icon = CDN_IMAGES_URL + "ethicon.svg";
export const direction_arrow = CDN_IMAGES_URL + "direction-left-arrow.svg";
export const horsicon1 = CDN_IMAGES_URL + "horsicon1.svg";

export const balance_icon = CDN_IMAGES_URL + "balance-icon.svg";
export const right_icon = CDN_IMAGES_URL + "right-icon.svg";
export const clock_icon = CDN_IMAGES_URL + "clock-icon.svg";

export const info_icon = CDN_IMAGES_URL + "about.svg";

export const banchmark_icon = CDN_IMAGES_URL + "benchmark-icon.svg";
export const graph_img = CDN_IMAGES_URL + "graph-up.png";
export const right_arrow = CDN_IMAGES_URL + "direction-right-arrow.svg";

export const cross_icon = CDN_IMAGES_URL + "cross.svg";
export const table_graph_img = CDN_IMAGES_URL + "table-graph.png";
export const tradingImg = CDN_IMAGES_URL + "tradingimg.svg";
export const fullIcon = CDN_IMAGES_URL + "fullicon.svg";
export const TableImg = CDN_IMAGES_URL + "ethicon.svg";
export const ethIcon = CDN_IMAGES_URL + "ethicon.svg";
export const default_icon = CDN_IMAGES_URL + "benchmark-icon.svg";

export const Synapse_icon = CDN_IMAGES_URL + "synapse_icon.png";
export const acryptos_icon = CDN_IMAGES_URL + "acryptos_icon.png";
export const acsifinance_icon = CDN_IMAGES_URL + "acsifinance_icon.png";
export const ankr_icon = CDN_IMAGES_URL + "ankr_icon.png";
export const annex_icon = CDN_IMAGES_URL + "annex_icon.png";
export const autoshark_icon = CDN_IMAGES_URL + "autoshark_icon.png";
export const babyswap_icon = CDN_IMAGES_URL + "babyswap_icon.png";
export const bakery_icon = CDN_IMAGES_URL + "bakery_icon.png";
export const biswap_icon = CDN_IMAGES_URL + "biswap_icon.png";
export const cafeswap_icon = CDN_IMAGES_URL + "cafeswap_icon.png";
export const ellipsis_icon = CDN_IMAGES_URL + "ellipsis_icon.png";
export const ellipsisv2_icon = CDN_IMAGES_URL + "ellipsisv2_icon.png";
export const hyperjump_icon = CDN_IMAGES_URL + "hyperjump_icon.png";
export const impossible_icon = CDN_IMAGES_URL + "impossible_icon.png";
export const izumi_icon = CDN_IMAGES_URL + "izumi_icon.png";
export const jetswap_icon = CDN_IMAGES_URL + "jetswap_icon.png";
export const julswap_icon = CDN_IMAGES_URL + "julswap_icon.png";
export const knightswap_icon = CDN_IMAGES_URL + "knightswap_icon.png";
export const mdex_icon = CDN_IMAGES_URL + "mdex_icon.png";
export const nerve_icon = CDN_IMAGES_URL + "nerve_icon.png";
export const nomiswap_icon = CDN_IMAGES_URL + "nomiswap_icon.png";
export const nomiswapstable_icon = CDN_IMAGES_URL + "nomiswapstable_icon.png";
export const pantherswap_icon = CDN_IMAGES_URL + "pantherswap_icon.png";
export const saddle_icon = CDN_IMAGES_URL + "saddle_icon.png";
export const solidly_icon = CDN_IMAGES_URL + "solidly_icon.png";
export const stakewise_icon = CDN_IMAGES_URL + "stakewise_icon.png";
export const swapr_icon = CDN_IMAGES_URL + "swapr_icon.png";
export const thena_icon = CDN_IMAGES_URL + "thena_icon.png";
export const venus_icon = CDN_IMAGES_URL + "venus_icon.png";
export const verse_icon = CDN_IMAGES_URL + "verse_icon.png";
export const waultswap_icon = CDN_IMAGES_URL + "waultswap_icon.png";
export const wombat_icon = CDN_IMAGES_URL + "wombat_icon.png";
export const woofi_icon = CDN_IMAGES_URL + "woofi_icon.png";
export const shibaswap_icon = CDN_IMAGES_URL + "shibaswap_icon.png";
export const babydoge_icon = CDN_IMAGES_URL + "babydoge_icon.png";

export const Balancer_icon = CDN_IMAGES_URL + "Balancer_icon.png";
export const Canary_icon = CDN_IMAGES_URL + "Canary_icon.png";
export const chronos_icon = CDN_IMAGES_URL + "chronos_icon.png";
export const cometh_icon = CDN_IMAGES_URL + "cometh_icon.png";
export const Darknight_icon = CDN_IMAGES_URL + "Darknight_icon.png";
export const Dfyn_icon = CDN_IMAGES_URL + "Dfyn_icon.png";
export const Dystopia_icon = CDN_IMAGES_URL + "Dystopia_icon.png";
export const Elk_icon = CDN_IMAGES_URL + "Elk_icon.png";
export const Equalizer_icon = CDN_IMAGES_URL + "Equalizer_icon.png";
export const Excalibur_icon = CDN_IMAGES_URL + "Excalibur_icon.png";
export const Glacier_icon = CDN_IMAGES_URL + "Glacier_icon.png";
export const Lydia_icon = CDN_IMAGES_URL + "Lydia_icon.png";
export const Meshswap_icon = CDN_IMAGES_URL + "Meshswap_icon.png";
export const Mmfinance_icon = CDN_IMAGES_URL + "Mmfinance_icon.png";
export const Morpheusswap_icon = CDN_IMAGES_URL + "Morpheusswap_icon.png";
export const Paintswap_icon = CDN_IMAGES_URL + "Paintswap_icon.png";
export const Pangolin_icon = CDN_IMAGES_URL + "Pangolin_icon.png";
export const polycat_icon = CDN_IMAGES_URL + "polycat_icon.png";
export const Protofi_icon = CDN_IMAGES_URL + "Protofi_icon.png";
export const quickswap_icon = CDN_IMAGES_URL + "quickswap_icon.png";
export const quickswapv3_icon = CDN_IMAGES_URL + "quickswapv3_icon.png";
export const Solidlizard_icon = CDN_IMAGES_URL + "Solidlizard_icon.png";
export const Soulswap_icon = CDN_IMAGES_URL + "Soulswap_icon.png";
export const Spookyswap_icon = CDN_IMAGES_URL + "Spookyswap_icon.png";
export const Sushitrident_icon = CDN_IMAGES_URL + "Sushitrident_icon.png";
export const Tomb_icon = CDN_IMAGES_URL + "Tomb_icon.png";
export const Traderjoe_icon = CDN_IMAGES_URL + "Traderjoe_icon.png";
export const Wakaswap_icon = CDN_IMAGES_URL + "Wakaswap_icon.png";
export const Yoshi_icon = CDN_IMAGES_URL + "Yoshi_icon.png";
export const Zyberswapv2_icon = CDN_IMAGES_URL + "Zyberswapv2_icon.png";
export const Susd_icon = CDN_IMAGES_URL + "SUSD.svg";
export const stablize_icon = CDN_IMAGES_URL + "Sablize.svg";

export const Camelot_icon = CDN_IMAGES_URL + "Camelot_icon.png";
export const Camelotv3_icon = CDN_IMAGES_URL + "Camelot_icon.png";
export const Gmx_icon = CDN_IMAGES_URL + "Gmx_icon.png";
export const IzumiNew_icon = CDN_IMAGES_URL + "IzumiNew_icon.png";
export const Level_icon = CDN_IMAGES_URL + "Level.svg";
export const Lif3V2_icon = CDN_IMAGES_URL + "Lif3V2.svg";
export const Maverickv2_icon = CDN_IMAGES_URL + "MaverickV2_icon.png";
export const Metavault_icon = CDN_IMAGES_URL + "Metavault_icon.png";
export const Mmfinancev2_icon = CDN_IMAGES_URL + "Mmfinancev2_icon.png";
export const Olive_icon = CDN_IMAGES_URL + "Olive_icon.png";
export const ThenFusion_icon = CDN_IMAGES_URL + "ThenaFusion_icon.png";
export const Traderjoev2_icon = CDN_IMAGES_URL + "TraderjoeV2.1_icon.png";
export const Usdfi_icon = CDN_IMAGES_URL + "Usdfi.svg";
export const Zyberswapv3_icon = CDN_IMAGES_URL + "ZyberswapV3_icon.png";

export const Retro_icon = CDN_IMAGES_URL + "Retro_icon.png";
export const polyzap_icon = CDN_IMAGES_URL + "polyzap_icon.png";
export const Gravity_icon = CDN_IMAGES_URL + "Gravity_icon.png";
export const Sushistable_icon = CDN_IMAGES_URL + "Sushistable_icon.png";
export const Polydex_icon = CDN_IMAGES_URL + "Polydex_icon.png";
export const Yetiswap_icon = CDN_IMAGES_URL + "Yetiswap_icon.png";
export const Baguette_icon = CDN_IMAGES_URL + "Baguette_icon.png";
export const Mummy_icon = CDN_IMAGES_URL + "Mummy_icon.png";
export const Crowdswap_icon = CDN_IMAGES_URL + "Crowdswap_icon.png";
export const Arbidex_icon = CDN_IMAGES_URL + "Arbidex_icon.png";
export const Ramses_icon = CDN_IMAGES_URL + "Ramses_icon.png";
export const sterling_icon = CDN_IMAGES_URL + "sterling_icon.png";
export const Arbidexv3_icon = CDN_IMAGES_URL + "Arbidex_icon.png";
export const Mmfinancev3_icon = CDN_IMAGES_URL + "Mmfinance_icon.png";
export const ClipperCove_icon = CDN_IMAGES_URL + "ClipperCove_icon.png";

/** ICONS **/
export const shido_img = CDN_ICONS_URL + "shido-small-icon.png";
export const table_icon = CDN_ICONS_URL + "SvgImages";

/** ANIMATIONS **/
// export const NotYet = CDN_ANIMATIONS_URL + "notfound.json";
// import { DownIcon } from '../../../../Assets/Svg/SvgImages';
