import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const ArrowDown = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.46518 10.8302L4.54516 6.50164C4.05448 5.77412 4.47045 4.66666 5.23448 4.66666H11.432C12.196 4.66666 12.6121 5.77495 12.1213 6.50164L9.20132 10.8302C8.74858 11.5011 7.91792 11.5011 7.46518 10.8302Z"
      fill="#EA332D"
    />
  </svg>
);

export const ArrowDownIconRed = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowDown} {...props} />
);
