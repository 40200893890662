import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Card, Col, Layout, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import downArrowIcon from "../Assets/Images/downArrow.svg";
import upwardArrowIcon from "../Assets/Images/upwardArrow.svg";
import { colors } from "../Assets/Theme/colors";
import { HeaderTL } from "../Assets/animation/Header";
import { HamburgerIcon } from "../Assets/icons/Hamburger";
import { GET_TICKER_COINS } from "../graphql";
import { Query } from "../graphql/__generatedTypes__";
import { appState, toggleMenu } from "../redux/reducers";
import { SecondaryButton } from "./Button";
import ConnectWallet from "./Common/ConnectWallet/ConnectWallet";
import SelectBlockchain from "./Common/SelectBlockchain/SelectBlockchain";
import { Text, TextScroller } from "./index";

const { Header: AntHeader } = Layout;

const Header = () => {
  const dispatch = useDispatch();
  const { sm, md, lg } = useBreakpoint();
  const { menuVisible } = useSelector(appState);
  const isLoggedIn = useSelector((state: any) => state.loginSlice.authStatus);
  const { data } = useQuery<Query>(GET_TICKER_COINS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 60000,
  });

  const AntHeaderRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      HeaderTL(AntHeaderRef.current);
    }, AntHeaderRef);
    return () => ctx.revert();
  }, [, AntHeaderRef]);

  return (
    <AntHeader
      ref={AntHeaderRef}
      style={{
        backgroundColor: colors.secondary800,
        padding: 0,
        height: "auto",
        marginBottom: 20,
      }}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Card style={{ height: "auto" }}>
            <Row className="header-body">
              <Col
                className="header-left"
                xs={8}
                md={0}
                lg={8}
                style={{ textAlign: "left" }}
              >
                {!md ? (
                  <div className="logo">
                    <img src={require("../Assets/Images/iconLogo.png")} alt={"shido"} />
                  </div>
                ) : null}
              </Col>
              <Col className="header-right" xs={16} md={24} lg={16}>
                <Row
                  align={"middle"}
                  gutter={[12, 12]}
                  justify={md && !lg ? "space-between" : "end"}
                >
                  {md && (
                    <Col>
                      <SecondaryButton
                        onClick={() =>
                          window.open(
                            "https://app.uniswap.org/tokens/ethereum/0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b",
                            "_blank",
                          )
                        }
                        target="_blank"
                        icon={
                          <img
                            src={require("../Assets/Images/iconLogo.png")}
                            style={{ height: 20 }}
                          />
                        }
                      >
                        Buy SHIDO
                      </SecondaryButton>
                    </Col>
                  )}
                  {isLoggedIn && md && (
                    <Col>
                      <SelectBlockchain useAsSelect />
                    </Col>
                  )}
                  <Col>
                    <ConnectWallet header />
                  </Col>
                  {!md && (
                    <Col
                      className={"clickable"}
                      onClick={() => dispatch(toggleMenu())}
                    >
                      {menuVisible ? <CloseOutlined /> : <HamburgerIcon />}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="TextScroller-wrap" lg={24}>
          <TextScroller
            text={
              <Row
                className="ticker-wrap"
                align={"middle"}
                style={{ width: "max-content", display: "flex", gap: "16px" }}
              >
                {data?.getTickerCoins?.map((ticker) => (
                  <Col
                    key={ticker.id}
                    className="text-scroller"
                    style={{
                      lineHeight: "normal",
                      borderRadius: "22px",
                      backgroundColor: "#252527",
                    }}
                  >
                    <Row align={"middle"}>
                      <Col>
                        <img
                          src={ticker.logo ?? ""}
                          style={{ height: 24 }}
                        />
                      </Col>
                      <Col
                        style={{
                          paddingLeft: "12px",
                        }}
                      >
                        <Text color={colors.secondary400}>{ticker.symbol}</Text>
                      </Col>
                      <Col
                        style={{
                          padding: "0 32px",
                        }}
                      >
                        <Text color={colors.secondary400}>
                          {Number(ticker.coinInfo?.quote?.USD?.price).toFixed(
                            4,
                          )}
                        </Text>
                      </Col>
                      {ticker.coinInfo?.quote?.USD?.percent_change_24h && (
                        <Col
                          className={
                            ticker.coinInfo?.quote?.USD?.percent_change_24h > 0
                              ? "success"
                              : "error"
                          }
                        >
                          <Text
                            color={
                              ticker.coinInfo?.quote?.USD?.percent_change_24h >
                              0
                                ? "#39CA7F"
                                : "#EA332D"
                            }
                          >
                            {Number(
                              ticker.coinInfo?.quote?.USD?.percent_change_24h,
                            ).toFixed(2)}
                            %
                          </Text>
                          <img
                            style={{ marginLeft: "5px" }}
                            src={
                              ticker.coinInfo?.quote?.USD?.percent_change_24h >
                              0
                                ? upwardArrowIcon
                                : downArrowIcon
                            }
                            alt={"arrow"}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}
              </Row>
            }
          />
        </Col>
        {isLoggedIn && (
          <Col xs={24} md={0}>
            <Row>
              <SelectBlockchain useAsSelect />
            </Row>
          </Col>
        )}
      </Row>
    </AntHeader>
  );
};

export default Header;
