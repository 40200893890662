import { Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { HomeTL } from "../../../Assets/animation/Home";
import { HomeHeader } from "./Header/header";
import "./home.scss";
import { Statistics } from "./Statistics/Statistic";
import { TradeMain } from "./Trading/tradeMain";

const Home = () => {
  const { sm, lg } = useBreakpoint();

  const HomeRowRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      HomeTL(HomeRowRef.current);
    }, HomeRowRef);

    return () => ctx.revert();
  }, [HomeRowRef]);

  return (
    <>
      <Row gutter={[20, 20]} ref={HomeRowRef}>
        <HomeHeader />
        <TradeMain />
        <Statistics />
      </Row>
    </>
  );
};

export default Home;
