import { Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Hero } from "./Hero/Hero";
import { Info } from "./MyInfo/Info";

const Profile = () => {
  const { sm, lg } = useBreakpoint();

  return (
    <Row gutter={[0, 20]}>
      <Hero />
      <Info />
      {/* <MySwaps /> */}
    </Row>
  );
};

export default Profile;
