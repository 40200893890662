export const EHTEREUM_NETWORK = "EHTEREUM_NETWORK";
export const BNB_NETWORK = "BNB_NETWORK";
export const POLYGON_NETWORK = "POLYGON_NETWORK";
export const FANTOM_NETWORK = "FANTOM_NETWORK";
export const ARBITRUM_NETWORK = "ARBITRUM_NETWORK";
export const AVALANCHE_NETWORK = "AVALANCHE_NETWORK";
export const SEPOLIA_NETWORK = "SEPOLIA_NETWORK";
export const BNB_TEST_NETWORK = "BNB_TEST_NETWORK";

export const EHTEREUM_CHAIN_ID = 1;
export const BNB_CHAIN_ID = 56;
export const POLYGON_CHAIN_ID = 137;
export const AVALANCHE_CHAIN_ID = 43114;
export const ARBITRUM_CHAIN_ID = 42161;
export const FANTOM_CHAIN_ID = 250;

export const SEPOLIA_CHAIN_ID = 11155111;
export const BNB_TEST_CHAIN_ID = 97;
