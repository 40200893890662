import { useEffect, useState } from "react";
import { getShidoTotalSupply } from "../../../services/commonServices";

const TotalSupply = () => {
  const [totalSupply, setTotalSupply] = useState("");

  useEffect(() => {
    getShidoTotalSupply().then((data: any) => {
      setTotalSupply(
        String(
          data.data.totalSupply.toLocaleString("fullwide", {
            useGrouping: false,
          }),
        ),
      );
    });
  }, []);

  return <div>{totalSupply}</div>;
};

export default TotalSupply;
