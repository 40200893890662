import React, { useState } from "react";
import { Col, Container, Form, ProgressBar, Row } from "react-bootstrap";
import { risk_dis } from "../../../Constants/ImagesConstants";
import { DownIcon } from "../../../Assets/Svg/SvgImages";
import "../Liquidity/Index.scss";
import "rc-slider/assets/index.css";
import VoteTable from "../Liquidity/VoteTable/VoteTable";
import YouSellBuyCard from "../../Common/TradeCards/SwapCard/YouSellBuyCard";
import ConnectWallet from "../../Common/ConnectWallet/ConnectWallet";
// import VoteTable from '../Liquidity/VoteTable/VoteTable.tsx';

const AggregationPage = () => {
  const [showBottom, setShowBottom] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(isActive);
  };

  const ResultListContent = [
    {
      // classText: "VotesTotl",
      subValues: "1,181,782.9899",
      LabelSub: "Total votes",
    },
    {
      // classText: "VotesTotl",
      subValues: "4,912",
      LabelSub: "Addresses",
    },
    {
      classText: "VotesTotl",
      subValues: "22.48%",
      LabelSub: "Referrer",
    },
    {
      classText2: "VotesTotl2",
      subValues: "77.52%",
      LabelSub: "Treasury",
    },
    {
      // classText: "VotesTotl",
      subValues: "00",
      LabelSub: "Your votes",
    },
  ];
  return (
    <div className="Voting Aggregation_Page py-70">
      <Container className="inner-cont">
        <Row className="align-items-center">
          <Col md={12}>
            <div className="HeadingsList mb-md-4">
              <h4>Aggregation protocol governance</h4>
              <button
                className={showBottom ? "BtnDrop" : "activeBtnDrop"}
                onClick={() => {
                  setShowBottom(!showBottom);
                  ToggleClass();
                }}
              >
                <img src={risk_dis} alt="img" />
                <p>Risk Disclosure</p>
                <DownIcon />
              </button>
            </div>
            <div className={!showBottom ? "Rishshow" : "RiskHide"}>
              <h5>Risk Disclosure</h5>
              <p>
                Acting as a liquidity provider (LP) for staking pools is an
                inherently risky undertaking. Please do not transact with more
                funds than you can afford to lose. Once you stake any amount
                into a pool, you become a liquidity provider.
              </p>
            </div>
          </Col>
          <Col md={12}>
            <div className="ResultingVote">
              <Row>
                <Col lg={7}>
                  <div className="Currentresult">
                    <div className="BarHeader">
                      <h4>Current Result</h4>
                      <div className="BarProgress">
                        <ProgressBar className="prog" now={30} />
                        <Form.Control
                          type="text"
                          placeholder="0.0%"
                          className="TextConrol"
                        />
                      </div>
                    </div>
                    <div className="Current">
                      {ResultListContent.map((item, idx) => (
                        <div className="listOfResults">
                          <h5
                            className={`Valuessub ${item?.classText} ${item?.classText2}`}
                          >
                            {item?.subValues}
                          </h5>
                          <label className="SubLabel">{item?.LabelSub}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className="SubInfo">
                    The Swap Surplus Is The Difference Between The Swap Price At
                    The Time Of The Quote And That At The Time That The
                    Transaction Is Mined. It Occurs In Swap Transactions When
                    The Executed Price Is Slightly Better Than The Price Quoted.{" "}
                    <span>Read More.</span>
                  </p>
                  <div className="TableSet">
                    <div className="SubVotes d-flex">
                      <h4>Top addresses by voting power</h4>
                      <div className="vote_value">
                        <span>5930</span>
                      </div>
                    </div>
                    <VoteTable />
                  </div>
                </Col>

                <Col lg={5}>
                  <div className="Trade_Simple_Card">
                    <div className="Heading">
                      <h5>Voting</h5>
                      <button className="restBtn">Reset</button>
                    </div>
                    <div className="Main_Containt">
                      <YouSellBuyCard />
                      <ConnectWallet className="mt-5 w-100" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AggregationPage;
