// this function return wrapped token name in case if your is limit -order
export const getModifiedTokenName = (
  tokenName: any,
  isNative: boolean,
  currentPath: string,
) => {
  if (currentPath === "/limit-order" && isNative) {
    if (tokenName === "Ethereum") return "Wrapped Ethereum";
    if (tokenName === "BNB") return "Wrapped BNB";
    if (tokenName === "Polygon") return "Wrapped  Polygon";
    if (tokenName === "Fantom") return "Wrapped  Fantom";
    if (tokenName === "Ethereum") return "Wrapped  Ethereum";
    if (tokenName === "AVALANCHE") return "Wrapped  AVALANCHE";
  } else {
    return tokenName;
  }
};
// // this function return wrapped token symbol in case if your is limit -order
export const getModifiedTokenSymbol = (
  tokenSymbol: any,
  isNative: boolean,
  currentPath: string,
) => {
  if (currentPath === "/limit-order" && isNative) {
    if (tokenSymbol === "ETH") return "WETH";
    if (tokenSymbol === "BNB") return "WBNB";
    if (tokenSymbol === "MATIC") return "WMATIC";
    if (tokenSymbol === "FTM") return "WFTM";
    if (tokenSymbol === "ETH") return "WETH";
    if (tokenSymbol === "AVAX") return "WAVAX";
  } else {
    return tokenSymbol;
  }
};
