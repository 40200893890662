import type { InputNumberProps } from "antd";
import { InputNumber } from "antd";
import React, { useState } from "react";

const CustomInputNumber: React.FC<InputNumberProps> = (props) => {
  const [value, setValue] = useState<any>(props.value);

  const handleChange = (value: string | number | null) => {
    if (typeof value === "string" || typeof value === "number") {
      setValue(value);
      if (props.onChange) {
        props.onChange(value);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const char = String.fromCharCode(event.which);
    const target = event.target as HTMLInputElement;
    const dotCount = (target.value.match(/\./g) || []).length;
    if (!/[0-9.,]/.test(char) || (char === "." && dotCount >= 1)) {
      event.preventDefault();
    }
    if (char === ",") {
      event.preventDefault();
      const cursorPosition = target.selectionStart || 0;
      const newValue =
        target.value.slice(0, cursorPosition) +
        "." +
        target.value.slice(cursorPosition + 1);
      setValue(newValue);
      if (props.onChange) {
        props.onChange(newValue);
      }
    }
  };

  const formatter = (value: string | number | undefined) => {
    const formattedValue = value?.toString().replace(/[^0-9.]/g, "") ?? "";
    if (formattedValue === "") return "";
    if (isNaN(Number(formattedValue)) || Number(formattedValue) < 0) return "0";
    return formattedValue;
  };

  const parser = (value: string | number | undefined) => {
    let parsedValue = value?.toString().replace(/[^0-9.]/g, "") ?? "";
    const dotCount = (parsedValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      parsedValue = parsedValue.substring(0, parsedValue.lastIndexOf("."));
    }
    if (parsedValue === "") return "";
    if (isNaN(Number(parsedValue)) || Number(parsedValue) < 0) return "0";
    if (parsedValue.startsWith(".")) return "0" + parsedValue;
    return parsedValue;
  };

  return (
    <InputNumber
      {...props}
      value={value}
      onChange={handleChange}
      formatter={formatter}
      parser={parser}
      min={0}
      className={props.className}
      onKeyPress={handleKeyPress}
    />
  );
};

export default CustomInputNumber;
