import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const Perpetual = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_244_44)">
      <path
        d="M5.16122 14.7082C5.16122 15.1137 4.82942 15.4455 4.42389 15.4455H1.37625C0.970715 15.4455 0.638916 15.1137 0.638916 14.7082V11.3656C0.638916 10.9601 0.970715 10.6283 1.37625 10.6283H4.42389C4.82942 10.6283 5.16122 10.9601 5.16122 11.3656V14.7082Z"
        fill="#3B87F7"
      />
      <path
        d="M10.5806 14.7083C10.5806 15.1138 10.2488 15.4456 9.84329 15.4456H6.79565C6.39012 15.4456 6.05832 15.1138 6.05832 14.7083V9.5961C6.05832 9.19056 6.39012 8.85876 6.79565 8.85876H9.84329C10.2488 8.85876 10.5806 9.19056 10.5806 9.5961V14.7083Z"
        fill="#3B87F7"
      />
      <path
        d="M16 14.7083C16 15.1138 15.6682 15.4456 15.2627 15.4456H12.215C11.8095 15.4456 11.4777 15.1138 11.4777 14.7083V7.62991C11.4777 7.22438 11.8095 6.89258 12.215 6.89258H15.2627C15.6682 6.89258 16 7.22438 16 7.62991V14.7083Z"
        fill="#3B87F7"
      />
      <path
        d="M15.3622 1.5682L12.0503 2.37395C11.7875 2.43756 11.729 2.64603 11.9201 2.83719L12.6268 3.52739C12.6862 3.59502 12.6158 3.68498 12.5599 3.72307C12.1149 4.02685 11.4087 4.4726 10.4325 4.9819C5.11811 7.75461 0.737227 7.58453 0.671359 7.58153C0.319701 7.56369 0.0190171 7.83458 0.000829564 8.18683C-0.0173088 8.53928 0.253636 8.83976 0.606081 8.8579C0.631985 8.85923 0.737326 8.864 0.914433 8.864C2.05027 8.864 6.13037 8.66801 11.0237 6.11503C12.1816 5.51096 12.9841 4.98996 13.4527 4.66012C13.519 4.61348 13.6344 4.53306 13.741 4.64154L14.514 5.41446C14.7051 5.60563 14.9126 5.54679 14.9751 5.28376L15.7264 1.93082C15.7889 1.66774 15.6249 1.50459 15.3622 1.5682Z"
        fill="#3B87F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_244_44">
        <rect
          width="16"
          height="16"
          fill="#3B87F7"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PerpetualIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Perpetual} {...props} />
);
