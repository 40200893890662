import { Card, Col } from "antd";
import { Chart, ChartOptions, registerables } from "chart.js";
import "chartjs-adapter-moment";
import { Bar } from "react-chartjs-2";
import { Text } from "../../../Text";
import "./shidoVolume.scss";

Chart.register(...registerables);

export const ShidoVolume = () => {
  const data = {
    labels: [24, 25, 26, 27, 28],
    datasets: [
      {
        label: "Dataset 1",
        data: [1, 2, 3, 4, 5, 6],
        backgroundColor: "#3B87F7",
        borderColor: "#3B87F7",
        borderWidth: 0,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        borderRadius: 14,
      },
      {
        label: "Dataset 2",
        data: [8, 9, 10, 11, 12],
        backgroundColor: "#3B87F7",
        borderColor: "#3B87F7",
        borderWidth: 0,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        borderRadius: 14,
      },
      {
        label: "Dataset 2",
        data: [14, 17, 19, 11, 20],
        backgroundColor: "#3B87F7",
        borderColor: "#3B87F7",
        borderWidth: 0,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        borderRadius: 14,
      },
      {
        label: "Dataset 2",
        data: [12, 5, 6, 9, 8],
        backgroundColor: "#3B87F7",
        borderColor: "#3B87F7",
        borderWidth: 0,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        borderRadius: 14,
      },
      {
        label: "Dataset 2",
        data: [6, 7, 8, 9, 10],
        backgroundColor: "#3B87F7",
        borderColor: "#3B87F7",
        borderWidth: 0,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
        borderRadius: 14,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
      <Card className="shido-volume-card" style={{ overflowY: "auto" }}>
        <div className="textual-info">
          <Text
            className="heading"
            fontWeight={600}
            color="#7C7C82"
            fontSize={"16px"}
            style={{ textAlign: "left" }}
          >
            SHIDO TVL
          </Text>
          <Text
            className="amount"
            fontWeight={600}
            color="#fff"
            fontSize={"36px"}
            style={{ textAlign: "left" }}
          >
            $6.03B
          </Text>
        </div>
        <div className="line-chart-container">
          <Bar data={data as any} options={options} />
        </div>
      </Card>
    </Col>
  );
};
