import React from "react";
import "./SwapCard.scss";
import LimitCard from "../LimitCard/LimitCard";

const LimitOrderSwapCard = ({
  createLimitOrder,
}: {
  createLimitOrder: any;
}) => {
  return (
    <div className="SwapCards">
      <LimitCard createLimitOrder={createLimitOrder} />
    </div>
  );
};

export default LimitOrderSwapCard;
