import { createSlice } from "@reduxjs/toolkit";

export const loginSlice: any = createSlice({
  name: "login",
  initialState: {
    accessToken: "",
    email: "",
    userId: "",
    authStatus: false,
    wrongNetworkSelected: false,
    userMetamaskChainID: "",
  },
  reducers: {
    resetLoginSlice: (state: any, action) => {
      state.accessToken = "";
      state.email = "";
      state.userId = "";
      state.authStatus = false;
      state.wrongNetworkSelected = false;
      state.userMetamaskChainID = "";
    },
    tokenAction: (state: any, action: any) => {
      state.accessToken = action.payload.accessToken || "";
      state.email = action.payload.email || "";
      state.userId = action.payload.userId || "";
      state.authStatus = action.payload.authStatus || false;
    },
    setIsLoggedIn: (state: any, action: any) => {
      state.authStatus = action.payload;
    },
    setWrongNetworkSelected: (state: any, action: any) => {
      state.wrongNetworkSelected = action.payload;
    },
    setUserMetamaskChainID: (state: any, action: any) => {
      state.userMetamaskChainID = action.payload;
    },
  },
});

export const {
  tokenAction,
  setIsLoggedIn,
  setWrongNetworkSelected,
  setUserMetamaskChainID,
  resetLoginSlice,
} = loginSlice.actions;
export default loginSlice.reducer;
