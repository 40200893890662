import { gsap } from "gsap";

// HomeHeaderTL----------------------
export function HomeHeaderTL(element) {
  let HomeHeaderTL = gsap.timeline({
    defaults: {
      duration: 1.5,
      ease: "power3.inOut",
      stagger: { from: "start", amount: 0.7 },
    },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  HomeHeaderTL.from(
    element,
    {
      x: 22,
      opacity: 0,
      delay: 1.0,
    },
    "<+=0.00",
  )
    .from(
      element.querySelector(".card-item-left"),
      {
        x: window.innerWidth > 1200 ? -22 : 22, // Desktop|Mobile -------------
        opacity: 0,
      },
      "<+=0.13",
    )
    .from(
      element.querySelector(".card-item-right"),
      {
        delay: window.innerWidth > 1200 ? 0 : 0.2, // Desktop|Mobile -------------
        x: 22,
        opacity: 0,
      },
      "<+=0.0",
    )
    .from(
      element.querySelectorAll(".card-item-right .card-col"),
      {
        opacity: 0,
      },
      "<+=0.0",
    );
}
// /HomeHeaderTL----------------------
