import { gsap } from "gsap";

// HeaderTL----------------------
export function HeaderTL(element) {
  let HeaderTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.inOut" },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  HeaderTL.from(
    element,
    {
      x: window.innerWidth > 768 ? 100 : 0, // Desktop|Mobile -------------
      opacity: 0,
    },
    "<+=0.00",
  )
    .from(
      element.querySelector(".TextScroller-wrap"),
      {
        y: 22,
        opacity: 0,
      },
      "<+=0.13",
    )
    .from(
      element.querySelector(".header-body .header-right"),
      {
        x: -13,
        opacity: 0,
      },
      "<+=0.13",
    );
}
// /HeaderTL----------------------
