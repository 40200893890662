let SITE_URL = "";
let SITE_URL_OPEN_OCEAN = "";
let SITE_URL_OPEN_OCEAN_FREE = "";
let SITE_URL_LIMIT_ORDER = "";
let OPEN_OCEAN_API_KEY: any = "";
let GRAPH_URL = "";
let ETH_GAS_URL = "";
let ENCRYPTION_TOKEN: any = "";
let DAPP_URL = "";
let OPEN_OCEAN_CONTRACT_ROUTER = "";
let APP_KEY_CHAINGE: any = "";
let APP_SECRET_CHAINGE: any = "";
let SITE_URL_CHAINGE = "";
let SITE_URL_CROSS_CHAIN = "";
let CHAINGE_TREASURY_ACCOUNT = "";

// *********** INITIALIZE ETHEREUM MAINNET CONTRACTS VARIABLES **********
let ADDRESS_WETH_ETH = "";
let ADDRESS_LOCK_V1_ETH = "";
let ADDRESS_STAKING_V1_ETH = "";
let ADDRESS_STAKING_V2_ETH = "";
let ADDRESS_STAKING_TOKEN_V1_ETH = "";
let ADDRESS_STAKING_TOKEN_V2_ETH = "";

// ************ INITIALIZE BSC MAINNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_BSC = "";
let ADDRESS_LOCK_V1_BSC = "";
let ADDRESS_STAKING_V1_BSC = "";
let ADDRESS_STAKING_V2_BSC = "";
let ADDRESS_STAKING_TOKEN_V1_BSC = "";
let ADDRESS_STAKING_TOKEN_V2_BSC = "";

// ************ INITIALIZE POLYGON MAINNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_POLYGON = "";

// ************ INITIALIZE AVALANCHE MAINNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_AVALANCHE = "";

// ************ INITIALIZE ARBITRUM MAINNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_ARBITRUM = "";

// ************ INITIALIZE FANTOM MAINNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_FANTOM = "";

// ************ INITIALIZE SEPOLIA (ETHEREUM TESTNET) CONTRACTS VARIABLES ************
let ADDRESS_WETH_SEPOLIA = "";
let ADDRESS_LOCK_V1_SEPOLIA = "";
let ADDRESS_STAKING_V1_SEPOLIA = "";
let ADDRESS_STAKING_V2_SEPOLIA = "";
let ADDRESS_STAKING_TOKEN_V1_SEPOLIA = "";
let ADDRESS_STAKING_TOKEN_V2_SEPOLIA = "";

// ************ INITIALIZE BSC TESTNET CONTRACTS VARIABLES ************
let ADDRESS_WETH_BSC_TESTNET = "";
let ADDRESS_LOCK_V1_BSC_TESTNET = "";
let ADDRESS_STAKING_V1_BSC_TESTNET = "";
let ADDRESS_STAKING_V2_BSC_TESTNET = "";
let ADDRESS_STAKING_TOKEN_V1_BSC_TESTNET = "";
let ADDRESS_STAKING_TOKEN_V2_BSC_TESTNET = "";

// VARIABLES FOR PRODUCTION
if (process.env.REACT_APP_NODE_ENV === "production") {
  //REACT_APP_SITE_URL=http://10.1.8.45:3000/sor/
  //REACT_APP_SITE_URL=http://10.1.5.208:3002/sor/
  SITE_URL = "https://supply.shido.io";
  // SITE_URL = "https://dev.api.shido.baboons.tech";
  SITE_URL_CROSS_CHAIN = "https://open-api.openocean.finance/cross_chain/v1/";
  SITE_URL_OPEN_OCEAN = "https://open-api-pro.openocean.finance/v3/";
  SITE_URL_OPEN_OCEAN_FREE = "https://open-api.openocean.finance/v3/";
  SITE_URL_LIMIT_ORDER = "https://open-api.openocean.finance/v1/";
  OPEN_OCEAN_API_KEY = process.env.REACT_APP_OPEN_OCEAN_API_KEY;
  GRAPH_URL = "https://uat-api.shido.io/sor/getChart";
  ETH_GAS_URL = "https://ethgasstation.info/json/ethgasAPI.json";
  ENCRYPTION_TOKEN = process.env.REACT_APP_ENCRYPTION_TOKEN; // need to fix later : if we are not encrypting any data we can remove this
  DAPP_URL = "dex.shido.io";
  OPEN_OCEAN_CONTRACT_ROUTER = "0x6352a56caadc4f1e25cd6c75970fa768a3304e64";
  APP_KEY_CHAINGE = process.env.REACT_APP_APP_KEY_CHAINGE;
  APP_SECRET_CHAINGE = process.env.REACT_APP_APP_SECRET_CHAINGE;
  SITE_URL_CHAINGE = "https://openapi.chainge.finance/";
  CHAINGE_TREASURY_ACCOUNT = "0x456325F2AC7067234dD71E01bebe032B0255e039";

  // 739AFB73Ef922ef92fEeB6940f9a2DdB5Bc11719
  // ********* ETHEREUM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_ETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  ADDRESS_LOCK_V1_ETH = "0x9FA3077F9cc888936907bC907E39a2C087A29ae2";
  ADDRESS_STAKING_V1_ETH = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354";
  ADDRESS_STAKING_V2_ETH = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354"; // update staking eth address
  ADDRESS_STAKING_TOKEN_V1_ETH = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b";
  ADDRESS_STAKING_TOKEN_V2_ETH = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b"; // update staking token eth address

  // ********* BSC MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_BSC = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  ADDRESS_LOCK_V1_BSC = "0xaF0CA21363219C8f3D8050E7B61Bb5f04e02F8D4";
  ADDRESS_STAKING_V1_BSC = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354";
  ADDRESS_STAKING_V2_BSC = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354"; // update staking bsc address
  ADDRESS_STAKING_TOKEN_V1_BSC = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b";
  ADDRESS_STAKING_TOKEN_V2_BSC = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b"; // update staking token bsc address

  // ********* POLYGON MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_POLYGON = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";

  // ********* AVALANCHE MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_AVALANCHE = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";

  // ********* ARBITRUM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_ARBITRUM = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

  // ********* FANTOM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_FANTOM = "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83";
}

// VARIABLES FOR QA
if (
  process.env.REACT_APP_NODE_ENV === "qa" ||
  process.env.REACT_APP_NODE_ENV === "development"
) {
  console.log(
    " process.env.REACT_APP_NODE_ENV",
    process.env.REACT_APP_NODE_ENV,
  );
  //REACT_APP_SITE_URL=http://10.1.8.45:3000/sor/
  //REACT_APP_SITE_URL=http://10.1.5.208:3002/sor/
  // SITE_URL = "https://supply.shido.io";
  SITE_URL = "https://dev.api.shido.baboons.tech";
  SITE_URL_CROSS_CHAIN = "https://open-api.openocean.finance/cross_chain/v1/";
  SITE_URL_OPEN_OCEAN = "https://open-api-pro.openocean.finance/v3/";
  SITE_URL_OPEN_OCEAN_FREE = "https://open-api.openocean.finance/v3/";
  SITE_URL_LIMIT_ORDER = "https://open-api.openocean.finance/v1/";
  OPEN_OCEAN_API_KEY = process.env.REACT_APP_OPEN_OCEAN_API_KEY;
  GRAPH_URL = "https://uat-api.shido.io/sor/getChart";
  ETH_GAS_URL = "https://ethgasstation.info/json/ethgasAPI.json";
  ENCRYPTION_TOKEN = process.env.REACT_APP_ENCRYPTION_TOKEN; // need to fix later : if we are not encrypting any data we can remove this
  DAPP_URL = "dex.shido.io";
  OPEN_OCEAN_CONTRACT_ROUTER = "0x6352a56caadc4f1e25cd6c75970fa768a3304e64";
  APP_KEY_CHAINGE = process.env.REACT_APP_APP_KEY_CHAINGE;
  APP_SECRET_CHAINGE = process.env.REACT_APP_APP_SECRET_CHAINGE;
  SITE_URL_CHAINGE = "https://openapi.chainge.finance/";
  CHAINGE_TREASURY_ACCOUNT = "0x456325F2AC7067234dD71E01bebe032B0255e039";

  // 739AFB73Ef922ef92fEeB6940f9a2DdB5Bc11719
  // ********* ETHEREUM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_ETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  ADDRESS_LOCK_V1_ETH = "0x9FA3077F9cc888936907bC907E39a2C087A29ae2";
  ADDRESS_STAKING_V1_ETH = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354";
  ADDRESS_STAKING_V2_ETH = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354"; // update staking eth address
  ADDRESS_STAKING_TOKEN_V1_ETH = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b";
  ADDRESS_STAKING_TOKEN_V2_ETH = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b"; // update staking token eth address

  // ********* BSC MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_BSC = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  ADDRESS_LOCK_V1_BSC = "0xaF0CA21363219C8f3D8050E7B61Bb5f04e02F8D4";
  ADDRESS_STAKING_V1_BSC = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354";
  ADDRESS_STAKING_V2_BSC = "0xcda954A0C574d8C408F0b8c89a2B367d6A2D3354"; // update staking bsc address
  ADDRESS_STAKING_TOKEN_V1_BSC = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b";
  ADDRESS_STAKING_TOKEN_V2_BSC = "0xe2512A2f19F0388aD3D7A5263eaA82AcD564827b"; // update staking token bsc address

  // ********* POLYGON MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_POLYGON = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";

  // ********* AVALANCHE MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_AVALANCHE = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";

  // ********* ARBITRUM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_ARBITRUM = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

  // ********* FANTOM MAINNET CONTRACTS PRODUCTION ***********
  ADDRESS_WETH_FANTOM = "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83";
}

// EXPORT ALL VARIABLES
export {
  APP_KEY_CHAINGE,
  APP_SECRET_CHAINGE,
  CHAINGE_TREASURY_ACCOUNT,
  DAPP_URL,
  ENCRYPTION_TOKEN,
  ETH_GAS_URL,
  GRAPH_URL,
  OPEN_OCEAN_API_KEY,
  OPEN_OCEAN_CONTRACT_ROUTER,
  SITE_URL,
  SITE_URL_CHAINGE,
  SITE_URL_CROSS_CHAIN,
  SITE_URL_LIMIT_ORDER,
  SITE_URL_OPEN_OCEAN,
  SITE_URL_OPEN_OCEAN_FREE,
};
// ### Ethereum Contracts
export {
  ADDRESS_LOCK_V1_ETH,
  ADDRESS_STAKING_TOKEN_V1_ETH,
  ADDRESS_STAKING_TOKEN_V2_ETH,
  ADDRESS_STAKING_V1_ETH,
  ADDRESS_STAKING_V2_ETH,
  ADDRESS_WETH_ETH,
};
// ### BSC Contracts
export {
  ADDRESS_LOCK_V1_BSC,
  ADDRESS_STAKING_TOKEN_V1_BSC,
  ADDRESS_STAKING_TOKEN_V2_BSC,
  ADDRESS_STAKING_V1_BSC,
  ADDRESS_STAKING_V2_BSC,
  ADDRESS_WETH_BSC,
};
// ### Sepolia (Ethereum Testnet) Contracts
export {
  ADDRESS_LOCK_V1_SEPOLIA,
  ADDRESS_STAKING_TOKEN_V1_SEPOLIA,
  ADDRESS_STAKING_TOKEN_V2_SEPOLIA,
  ADDRESS_STAKING_V1_SEPOLIA,
  ADDRESS_STAKING_V2_SEPOLIA,
  ADDRESS_WETH_SEPOLIA,
};
// ### Bsc Testnet Contracts
export {
  ADDRESS_LOCK_V1_BSC_TESTNET,
  ADDRESS_STAKING_TOKEN_V1_BSC_TESTNET,
  ADDRESS_STAKING_TOKEN_V2_BSC_TESTNET,
  ADDRESS_STAKING_V1_BSC_TESTNET,
  ADDRESS_STAKING_V2_BSC_TESTNET,
  ADDRESS_WETH_BSC_TESTNET,
};
// ### POLYGON Mainnet Contracts
export { ADDRESS_WETH_POLYGON };
// ### AVALANCHE Mainnet Contracts
export { ADDRESS_WETH_AVALANCHE };
// ### ARBITRUM Mainnet Contracts
export { ADDRESS_WETH_ARBITRUM };
// ### FANTOM Mainnet Contracts
export { ADDRESS_WETH_FANTOM };

// ### CUSTOM RPC URL
export const CUSTOM_RPC_URL_ETHEREUM: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_ETHEREUM;
export const CUSTOM_RPC_URL_BSC: any = process.env.REACT_APP_CUSTOM_RPC_URL_BSC;
export const CUSTOM_RPC_URL_POLYGON: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_POLYGON;
export const CUSTOM_RPC_URL_AVALANCHE: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_AVALANCHE;
export const CUSTOM_RPC_URL_ARBITRUM: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_ARBITRUM;
export const CUSTOM_RPC_URL_FANTOM: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_FANTOM;
export const CUSTOM_RPC_URL_SEPOLIA: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_SEPOLIA;
export const CUSTOM_RPC_URL_BSC_TESTNET: any =
  process.env.REACT_APP_CUSTOM_RPC_URL_BSC_TESTNET;

// ### RPC URL
export const RPC_URL_ETHEREUM = "https://mainnet.infura.io/v3/";
export const RPC_URL_BSC = "https://bsc-dataseed1.binance.org/";
export const RPC_URL_POLYGON = "https://polygon-rpc.com/";
export const RPC_URL_AVALANCHE = "https://avalanche-mainnet.infura.io/";
export const RPC_URL_ARBITRUM = "https://arbitrum-mainnet.infura.io/";
export const RPC_URL_FANTOM = "https://rpc.ftm.tools/";

// ### SCAN URLS
export const ETHER_SCAN = "https://etherscan.io/tx/";
export const BSC_SCAN = "https://bscscan.com/tx/";
export const POLYGON_SCAN = "https://polygonscan.com/tx/";
export const AVALANCHE_SCAN = "https://snowtrace.io/tx/";
export const ARBITRUM_SCAN = "https://explorer.arbitrum.io/tx/";
export const FANTOM_SCAN = "https://ftmscan.com/";

// Module - Depending on this we will show basic chains (Ethereum & BSC)
// or multi chains (Ethereum, BSC, Polygon, Avalanche, Arbitrum, Fantom)
export const APP_MODULE = process.env.REACT_APP_MODULE;

export const SHIDO_ADDRESS = {
  eth: "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b",
};
