import { Card, Col } from "antd";
import { Chart, ChartOptions, registerables } from "chart.js";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { Text } from "../../../Text";
import "./shidoTvl.scss";

Chart.register(...registerables);

export const ShidoTvl = () => {
  function getGradient(ctx: any, chartArea: any) {
    let gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom,
    );

    gradient.addColorStop(0, "rgba(59, 135, 247, 0.50)");
    gradient.addColorStop(1, "rgba(59, 135, 247, 0.00)");

    return gradient;
  }

  const data = {
    labels: [24, 25, 26, 27, 28],
    datasets: [
      {
        label: "Transaction volume",
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: "#3B87F7",
        borderWidth: 2,
        hoverBackgroundColor: "#3B87F7",
        hoverBorderColor: "#3B87F7",
        data: [500, 100, 300, 200, 900],
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
      <Card className="shido-tvl-card" style={{ overflowY: "auto" }}>
        <div className="textual-info">
          <Text
            className="heading"
            fontWeight={600}
            color="#7C7C82"
            fontSize={"16px"}
            style={{ textAlign: "left" }}
          >
            SHIDO TVL
          </Text>
          <Text
            className="amount"
            fontWeight={600}
            color="#fff"
            fontSize={"36px"}
            style={{ textAlign: "left" }}
          >
            $6.03B
          </Text>
        </div>
        <div className="line-chart-container">
          <Line data={data as any} options={options} />
        </div>
      </Card>
    </Col>
  );
};
