import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { SHIDO_ADDRESS } from "../Constants/AppVariables/appVariable";
import { GET_TICKER_COINS } from "../graphql";
import { Coin, Query } from "../graphql/__generatedTypes__";

const useShidoPrice = () => {
  const { data } = useQuery<Query>(GET_TICKER_COINS, {
    fetchPolicy: "cache-and-network",
    // pollInterval: 60000,
  });
  const [shidoPrice, setShidoPrice] = useState<Coin | null>(null);

  useEffect(() => {
    if (data?.getTickerCoins) {
      setShidoPrice(
        data.getTickerCoins.find(
          (coin: Coin) =>
            coin?.address?.toLowerCase() === SHIDO_ADDRESS.eth.toLowerCase(),
        ) || null,
      );
    }
  }, [data]);

  return {
    shidoPrice,
  };
};

export default useShidoPrice;
